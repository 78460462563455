import styled from "styled-components";

export const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: auto;
`;

export const TasksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    gap: 8px;
    background-color: #E6EBEF;
    border-radius: 4px;
    overflow: hidden;
    min-width: 250px;
`;

export const HeaderContainer = styled.div`
    width: 250px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
`;