import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTable } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {sortByPersonName} from '../../utils/funcoes/sort';

import './style.css';
import { useTranslation } from 'react-i18next';

export default function Financeiro({
	valorPessoaHoraPorTarefa,
	dataInicial,
	dataFinal,
	loadingGetHoras,
	tipoDeRelarorio,
	getRelatorioCSV,
	getRelatorioXLSX,
	loadingRelatorioCSV,
	handlePrint
}) {
	const {t} = useTranslation();	// Objeto extraido da requisição
	const relatorioTitle = t("Financeiro")
	const [listTarefas, setListTarefas] = useState([]);
	// const [listTarefasData, setListTarefasData] = useState([]);
	const [dataInicialFinal, setDataInicialFinal] = useState('');

	const [headerFirstColumn, setHeaderFirstColumn] = useState('');
	// horaValorPorPessoa
	useEffect(() => {
		setDataInicialFinal(
			`${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
		);
	}, []);

	useEffect(() => {
		geraObjPessoasPorTarefa(tipoDeRelarorio.tipo);
		setDataInicialFinal(
			`${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
		);
		// setListTarefasData(valorPessoaHoraPorTarefa);
		setHeaderFirstColumn(TYPES[tipoDeRelarorio.tipo]);
	}, [valorPessoaHoraPorTarefa]);

	const TYPES = {
		pessoa: 'Pessoa',
		tarefa: 'Tarefa',
		projeto: 'Projeto',
	};

	const geraObjPessoasPorTarefa = valor => {
		/*
		Retorna um array de objetos padrao para criar o relatorio.
		A primeira coluna(que pode ser pessoa, projeto ou tarefa) sera o item mais externo e os registros de hora ficam em registros ou registros_projetos.
		O item mais externo também tem o total de horas por projeto/tarefa/pessoa.
		*/
		const arrayPessoaPorTarefa = [];
		// Cria o objeto da primeira coluna, ela sempre sera unica mesmo que tenha dois registros de hora.(ex: relatorio por pessoa o nome da pessoa aprece uma vez).
		valorPessoaHoraPorTarefa.forEach(registro => {
			if (valor === 'pessoa'){
				let valorDuplicado = arrayPessoaPorTarefa.findIndex(item => {
					return  registro[valor] === item.id
				}) !== -1;
				if (!valorDuplicado) {
					arrayPessoaPorTarefa.push({
						tipo: registro.tipo,
						id: registro[valor],
						nome: registro[`${valor}_nome`],
						tarefa_horas_total: 0,
						tarefa_valor_total: 0,
						registros: [],
						registros_projetos: [],
					});
				}
			}else{
			// Se for tarefa/projeto tambem preciso ver se e ocioso, ociosos sao agrupados em uma tarefa/projeto separada
			let valorDuplicado =
				arrayPessoaPorTarefa.findIndex(item => {
					return  registro.tipo != 'ocio' && registro[valor] === item.id || registro.tipo === "ocio" && item.tipo === "ocio";
				}) !== -1;
			if (!valorDuplicado) {
				arrayPessoaPorTarefa.push({
					tipo: registro.tipo,
					id: registro[valor],
					nome: registro.tipo === "ocio" ? "Tempo Ocioso" : registro[`${valor}_nome`],
					tarefa_horas_total: 0,
					tarefa_valor_total: 0,
					registros: [],
					registros_projetos: [],
				});
			}
			}
		});


		// ADIÇÃO DE REGISTROS POR TAREFA/PESSOA
		valorPessoaHoraPorTarefa.forEach(registro => {
			arrayPessoaPorTarefa.forEach(item => {
				let valor_hora = {
					id: registro.id,
					pessoa: registro.pessoa,
					pessoa_custo: registro.valor_pessoaporhora,
					pessoa_nome: registro.pessoa_nome,
					projeto_nome:  registro.tipo == "ocio" ? "Tempo Ocioso" : registro.projeto_nome,
					duracao: registro.total_hora_pessoatarefa,
					tarefa: registro.tarefa,
					tarefa_nome:  registro.tipo == "ocio" ? "Tempo Ocioso" : registro.tarefa_nome,
					valor_pessoatarefa: registro.valor_pessoatarefa,
				}
				// SE pessoa, basta que o id da pessoa seja igual ao id da pessoa que registrou hora.
				if (valor === 'pessoa') {
					if (registro[valor] === item.id){
						item.registros.push(valor_hora);
					}
				}else{
					// Se projeto ou tarefa o tipo deve bater(horas ociosas são agrupadas em um 'projeto/tarefa' próprio)
					if (registro[valor] === item.id  && registro.tipo != 'ocio' || registro.tipo === "ocio" && item.tipo === "ocio" ) {
						if (valor === 'projeto') {
							item.registros_projetos.push(valor_hora);
						} else {
							item.registros.push(valor_hora);
						}
					}
				}
			});
		});

		if (valor === 'projeto') {
			// logica
			arrayPessoaPorTarefa.forEach(item => {
				item.registros_projetos.forEach(registroPessoa => {
					let valorDuplicado =
						item.registros.findIndex(valor => {
							return valor.pessoa === registroPessoa.pessoa;
						}) !== -1;
					if (!valorDuplicado) {
						item.registros.push({
							pessoa: registroPessoa.pessoa,
							pessoa_nome: registroPessoa.pessoa_nome,
							valor_pessoatarefa: registroPessoa.valor_pessoatarefa,
							pessoa_custo: registroPessoa.pessoa_custo,
							duracao: '00:00:00',
						});
					}
				});
			});
			arrayPessoaPorTarefa.forEach(item => {
				item.registros_projetos.forEach(itemRegistro => {
					item.registros.forEach((itemProjeto, index) => {
						if (itemRegistro.pessoa === itemProjeto.pessoa) {
							item.registros[index] = {
								...itemProjeto,
								duracao: somaHorasTotaisPorPessoa(
									itemProjeto.duracao,
									itemRegistro.duracao,
								),
							};
						}
					});
				});
			});
		}else if (valor === 'pessoa' || valor === 'tarefa'){
			arrayPessoaPorTarefa.forEach( item => {
					if(item.registros.filter(item => item.tarefa_nome == "Tempo Ocioso").length > 0 ){
						let ocio_insert = [];
						let acc = "00:00:00";

						let pessoas = new Set(item.registros.filter(item => item.tarefa_nome === "Tempo Ocioso").map(item => item.pessoa));
						pessoas.forEach(pessoa =>{

									acc = "00:00:00";
									item.registros.filter(item => item.tarefa_nome === "Tempo Ocioso" && item.pessoa === pessoa).forEach((item, i) => {
										acc = somaHorasTotaisPorPessoa(acc, item.duracao)
									});
									if(acc != "00:00:00"){
										let ocio = item.registros.filter(item => item.tarefa_nome === "Tempo Ocioso" && item.pessoa === pessoa)[0]
										ocio.duracao = acc;

										ocio_insert.push(ocio)
									}
							})
						item.registros = item.registros.filter(item => item.tarefa_nome != "Tempo Ocioso")
						item.registros = item.registros.concat(ocio_insert)

					}
			})

		}
		// ORDENACAO
		arrayPessoaPorTarefa.sort((a, b) =>{
				return a.nome.localeCompare(b.nome)
		})
		arrayPessoaPorTarefa.forEach( i =>{
				i.registros.sort((a, b) =>{
							try{
								if (valor === 'tarefa'){

									if(a.tarefa_nome === b.tarefa_nome){
										return a.pessoa_nome.localeCompare(b.pessoa_nome)
									}
									return a.tarefa_nome.localeCompare(b.tarefa_nome)

								}else if (valor === 'pessoa') {
									if(a.pessoa_nome === b.pessoa_nome){
										return a.tarefa_nome.localeCompare(b.tarefa_nome)
									}
									return a.pessoa_nome.localeCompare(b.pessoa_nome)

								}else{
									if(a.projeto_nome === b.projeto_nome){
										return a.projeto_nome.localeCompare(b.projeto_nome)
									}
									return a.tarefa_nome.localeCompare(b.tarefa_nome)
								}
							}catch(exception){
								console.log(exception);
								return -1;
							}
					})
		})

		// somaHorasTotaisTrabalhadasPorTarefa(arrayPessoaPorTarefa);
		somaCustoTodalDeCadaTarefa(arrayPessoaPorTarefa, valor);

	};

	const somaHorasTotaisTrabalhadasPorTarefa = arrayPessoaPorTarefa => {
		arrayPessoaPorTarefa.forEach(tarefa => {
			let totalHoras = 0;
			let totalMinutos = 0;
			let totalSegundos = 0;
			let totalAbsoluto = 0;
			tarefa.registros.forEach(registro => {
				if (registro.duracao !== undefined) {
					let extraiHoraMinuto = registro.duracao.split(':');
					totalHoras = totalHoras + parseInt(extraiHoraMinuto[0]);
					totalMinutos = totalMinutos + parseInt(extraiHoraMinuto[1]);
					totalSegundos = totalSegundos + parseFloat(extraiHoraMinuto[2]);

				}
			});
			const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60)
			const minutes = tempTotalMinutos % 60;
			let final_hora = totalHoras + parseInt(tempTotalMinutos / 60);
			let final_minuto = minutes < 10 ? `0${minutes}` : `${minutes}`;
			totalAbsoluto = final_hora + ':' + final_minuto;

			tarefa.tarefa_horas_total = totalAbsoluto;
		});

		if(tipoDeRelarorio.tipo === 'projeto') {
			const sortArrayPessoaPorTarefa = arrayPessoaPorTarefa.map(item => {
				return {...item, registros: sortByPersonName(item.registros, "pessoa_nome")}
			})
			setListTarefas(sortArrayPessoaPorTarefa);
		} else if(tipoDeRelarorio.tipo === 'pessoa') {
			const sortArrayPessoaPorTarefa = arrayPessoaPorTarefa.map(item => {
				return {...item, registros: sortByPersonName(sortByPersonName(item.registros, "tarefa_nome"), "projeto_nome")}
			})
			setListTarefas(sortArrayPessoaPorTarefa);
		} else {
			setListTarefas(arrayPessoaPorTarefa);
		}
	};

	const somaHorasTotaisPorPessoa = (duracaoInicial, duracaoAtual) => {
		let totalAbsoluto = 0;
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalSegundos = 0;
		let validaInicial = duracaoInicial ? duracaoInicial : '00:00:00';
		let validaAtual = duracaoAtual ? duracaoAtual : '00:00:00';
		totalHoras = valorSoma(validaInicial)[0] + valorSoma(validaAtual)[0];
		totalMinutos = valorSoma(validaInicial)[1] + valorSoma(validaAtual)[1];
		totalSegundos  = valorSoma(validaInicial)[2] + valorSoma(validaAtual)[2];
		const tempTotalSegundos = totalSegundos % 60;
		const final_segundo = tempTotalSegundos < 10 ? `0${tempTotalSegundos}` : `${tempTotalSegundos}`;

		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60)
		const minutes = tempTotalMinutos % 60;
		let final_hora = totalHoras + parseInt(tempTotalMinutos / 60);
		let final_minuto = minutes < 10 ? `0${minutes}` : `${minutes}`;
		
		totalAbsoluto = final_hora + ':' + final_minuto + ':' + final_segundo;

		return totalAbsoluto;
	};
	const valorSoma = valor => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalSegundos = 0;
		// if (valor !== undefined) {
		let extraiHoraMinuto = valor.split(':');
		totalHoras = totalHoras + parseInt(extraiHoraMinuto[0]);
		totalMinutos = totalMinutos + parseInt(extraiHoraMinuto[1]);
		totalSegundos = totalSegundos + parseInt(extraiHoraMinuto[2]);
		// }
		return [totalHoras, totalMinutos, totalSegundos];
	};

	const somaCustoTodalDeCadaTarefa = (arrayPessoaPorTarefa, valor) => {
		if (valor === 'projeto') {
			arrayPessoaPorTarefa.forEach(tarefa => {
				let totalHoras = 0;
				tarefa.registros_projetos.forEach(registro => {
					totalHoras = totalHoras + parseFloat(registro.valor_pessoatarefa);
				});
				tarefa.tarefa_valor_total = totalHoras;
			});
		} else {
			arrayPessoaPorTarefa.forEach(tarefa => {
				let totalHoras = 0;
				tarefa.registros.forEach(registro => {
					totalHoras = totalHoras + parseFloat(registro.valor_pessoatarefa);
				});
				tarefa.tarefa_valor_total = totalHoras;
			});
		}

		somaHorasTotaisTrabalhadasPorTarefa(arrayPessoaPorTarefa);
		// setListTarefas(arrayPessoaPorTarefa);
	};

	const somaHorasGeralTarefas = () => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		let totalSegundos = 0;
		valorPessoaHoraPorTarefa.forEach(registro => {
			let extraiHoraMinuto = registro.total_hora_pessoatarefa.split(':');
			totalHoras = totalHoras + parseInt(extraiHoraMinuto[0]);
			totalMinutos = totalMinutos + parseInt(extraiHoraMinuto[1]);
			totalSegundos = totalSegundos + parseFloat(extraiHoraMinuto[2]);

		});
		const tempTotalMinutos = totalMinutos + parseInt(totalSegundos / 60)
		const minutes = tempTotalMinutos % 60;
		let final_hora = totalHoras + parseInt(tempTotalMinutos / 60);
		let final_minuto = minutes < 10 ? `0${minutes}` : `${minutes}`;
		totalAbsoluto = final_hora + ':' + final_minuto;
		return totalAbsoluto;
	};

	const somaCustoGeralDasTarefas = () => {
		let totalHoras = 0;
		valorPessoaHoraPorTarefa.forEach(registro => {
			totalHoras = totalHoras + parseFloat(registro.valor_pessoatarefa);
		});
		return totalHoras;
	};

	const formatter = new Intl.NumberFormat('pt-br', {
		style: 'currency',
		currency: 'BRL',
	});

	const RemoveSeconds = value => {
		const valueSplit = value.split(':');
		return valueSplit.length === 3 ? value.slice(0, -3) : value;
	};

	const handlePrintData = () => {
		const dataInfo = listTarefas.map(tarefa => {
			return tarefa.registros.map((item) => {
				const tempRow = {
					[headerFirstColumn]: tarefa.nome,
				}
	
				if(tipoDeRelarorio.enviando !== 'pessoa') {
					tempRow[t("Usuário")] = item.pessoa_nome
				} else {
					tempRow[`${t("Projetos")} - ${t("Tarefas")}`] = `${item.projeto_nome} - ${item.tarefa_nome}`
				}
	
				return tempRow
			})
		}).flat()

		let dataValues = listTarefas.map(tarefa => {
			return tarefa.registros.map((item) => {
				const tempRow = {
					[t("Valor por Hora")]: formatter.format(item.pessoa_custo),
					[t("Horas Trabalhadas")]: RemoveSeconds(item.duracao),
					[t("Horas Totais")]: tarefa.tarefa_horas_total,
					[t("VALOR TOTAL")]: formatter.format(tarefa.tarefa_valor_total),
				}
	
				return tempRow
			})
		}).flat()
	
		const totalRow = {
			[t("Horas Totais")]: somaHorasGeralTarefas(),
			[t("VALOR TOTAL")]: formatter.format(somaCustoGeralDasTarefas()),
		}

		dataValues = [...dataValues, totalRow]

		const periodoString = dataInicialFinal
		
		handlePrint({
			dataInfo, 
			dataValues, 
			periodoString, 
			title: relatorioTitle
		})
	}

	return (
		<div className="print-background" >
			<div className="relatorio-header-container" >
				<p className="relatorio-title">{t("Financeiro")}</p>
				<div style={{ display: 'flex' }}>
					{
						<>
							<div
								onClick={() => getRelatorioCSV()}
								className="relatorio-print-container"
								disabled={true}
								style={{
									pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
									opacity: loadingRelatorioCSV ? '0.5' : '1',
								}}
							>
								<FontAwesomeIcon
									icon={faTable}
									className="print-icon-relatorio"
								/>
								<p className="print-text-relatorio">Gerar CSV</p>
								{loadingRelatorioCSV ? (
									<span style={{ marginLeft: '4px' }}>
										<CarregandoDados />{' '}
									</span>
								) : (
									<></>
								)}
							</div>
							<div
								onClick={() => getRelatorioXLSX()}
								className="relatorio-print-container"
								disabled={true}
								style={{
									pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
									opacity: loadingRelatorioCSV ? '0.5' : '1',
								}}
							>
								<FontAwesomeIcon
									icon={faTable}
									className="print-icon-relatorio"
								/>
								<p className="print-text-relatorio">{t("Gerar Excel")}</p>
								{loadingRelatorioCSV ? (
									<span style={{ marginLeft: '4px' }}>
										<CarregandoDados />{' '}
									</span>
								) : (
									<></>
								)}
							</div>
						</>
					}
					<div
						onClick={() => handlePrintData()}
						className="relatorio-print-container"
					>
						<FontAwesomeIcon icon={faPrint} className="print-icon-relatorio" />
						<p className="print-text-relatorio">{t("Imprimir")}</p>
					</div>
				</div>
			</div>
			<div id="print-background">
				{loadingGetHoras ? (
					<CarregandoDados />
				) : (
					<table className="relatorio-table">
						<thead>
							<tr>
								<th className="cabecalho-table-financeiro print-background" colSpan="3">
									{dataInicialFinal}
								</th>
							</tr>
							<tr>
								<th  className="cabecalho-financeiro print-background">{headerFirstColumn}</th>
								<th className="cabecalho-financeiro print-background">
									{tipoDeRelarorio.enviando !== 'pessoa' ? (
										<span>{t("Usuário")}</span>
									) : (
										<span>{t("Projetos")} - {t("Tarefas")}</span>
									)}
								</th>
								<th className="cabecalho-financeiro print-background">{t("Valor por Hora")}</th>
								<th className="cabecalho-financeiro print-background">{t("Horas Trabalhadas")}</th>
								<th className="cabecalho-financeiro print-background">{t("Horas Totais")}</th>
								<th className="cabecalho-financeiro-total print-background">{t("VALOR TOTAL")}</th>
							</tr>
						</thead>
						<tbody>
							{listTarefas != undefined && (
								<>
									{listTarefas.map(tarefa => (
										<>
											{tarefa.registros.map((item, index) => (
												<>
													{index === 0 ? (
														<tr>
															<td
																className="projeto-td-financeiro print-background"
																rowSpan={tarefa.registros.length}
																style={{ borderBottom: '6px solid #d6d6d6' }}
															>
																{tarefa.nome}
															</td>

															{tipoDeRelarorio.enviando !== 'pessoa' && (
																<td
																	className="tarefa-td-pessoa-financeiro print-background"
																	style={{
																		borderBottom:
																			tarefa.registros.length === index + 1
																				? '6px solid #d6d6d6'
																				: '',
																	}}
																>
																	{item.pessoa_nome}
																</td>
															)}
															{tipoDeRelarorio.enviando === 'pessoa' && (
																<td
																	className="tarefa-td-pessoa-financeiro print-background"
																	style={{
																		borderBottom:
																			tarefa.registros.length === index + 1
																				? '6px solid #d6d6d6'
																				: '',
																	}}
																>
																	{item.projeto_nome} - {item.tarefa_nome}
																</td>
															)}
															<td
																className="tarefa-td-financeiro-valor print-background"
																style={{
																	borderBottom:
																		tarefa.registros.length === index + 1
																			? '6px solid #d6d6d6'
																			: '',
																}}
															>
																{formatter.format(item.pessoa_custo)}
															</td>
															<td
																className="tarefa-td-financeiro print-background"
																style={{
																	borderBottom:
																		tarefa.registros.length === index + 1
																			? '6px solid #d6d6d6'
																			: '',
																}}
															>
																{RemoveSeconds(item.duracao)}
															</td>
															<td
																className="tarefa-td-valorTarefa-financeiro print-background"
																rowSpan={tarefa.registros.length}
																style={{ borderBottom: '6px solid #d6d6d6' }}
															>
																{tarefa.tarefa_horas_total}
															</td>
															<td
																className="td-registrosHoras-financeiro print-background"
																rowSpan={tarefa.registros.length}
																style={{ borderBottom: '6px solid #d6d6d6' }}
															>
																{/* {tarefa.tarefa_valor_total} */}
																{formatter.format(tarefa.tarefa_valor_total)}
															</td>
														</tr>
													) : (
														<tr>
															{tipoDeRelarorio.enviando !== 'pessoa' && (
																<td
																	className="tarefa-td-pessoa-financeiro print-background"
																	style={{
																		borderBottom:
																			tarefa.registros.length === index + 1
																				? '6px solid #d6d6d6'
																				: '',
																	}}
																>
																	{item.pessoa_nome}
																</td>
															)}
															{tipoDeRelarorio.enviando === 'pessoa' && (
																<td
																	className="tarefa-td-pessoa-financeiro print-background"
																	style={{
																		borderBottom:
																			tarefa.registros.length === index + 1
																				? '6px solid #d6d6d6'
																				: '',
																	}}
																>
																	{item.projeto_nome} - {item.tarefa_nome}
																</td>
															)}
															<td
																className="tarefa-td-financeiro-valor print-background"
																style={{
																	borderBottom:
																		tarefa.registros.length === index + 1
																			? '6px solid #d6d6d6'
																			: '',
																}}
															>
																{formatter.format(item.pessoa_custo)}
															</td>
															<td
																className="tarefa-td-financeiro print-background"
																style={{
																	borderBottom:
																		tarefa.registros.length === index + 1
																			? '6px solid #d6d6d6'
																			: '',
																}}
															>
																{RemoveSeconds(item.duracao)}
															</td>
														</tr>
													)}
												</>
											))}
										</>
									))}
								</>
							)}
						</tbody>
						<tfoot>
							<tr>
								<th className="span-table-botton" colSpan="4"></th>
								<th id="totalHoras-financeiro">{somaHorasGeralTarefas()} hs</th>
								<th id="totalValor-financeiro">
									{formatter.format(somaCustoGeralDasTarefas())}
								</th>
							</tr>
						</tfoot>
					</table>
				)}
			</div>
		</div>
	);
}

const CarregandoDados = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-conteudo"></div>
		</div>
	</>
);
