import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { LoaderCircle } from './LoaderCircle';

import '../../../i18n';
import { useTranslation } from 'react-i18next';

const LoaderProjeto = ({ isGridStyle , fileData, showLoader, loading, ...rest }) => {
	const {t} = useTranslation();
	return (
		<>
			{showLoader ? (
				<></>
			) : (
				<div className="projetos-btn-loading">
					{loading ? (
						<>
							{(fileData.length > 0 || !isGridStyle)&& (
								<span className="btn-loading-conteudo">
									<span>{<LoaderCircle />}</span>
								</span>
							)}
						</>
					) : (
						<>
							{(fileData.length > 0 || !isGridStyle) && (
								<button {...rest}>
									<span className="btn-loading-conteudo">
										<span>{t("Carregar mais")}</span>
										<span>
											<FontAwesomeIcon
												icon={faAngleDown}
												// className="projetos-tarefa-icone"
											/>
										</span>
									</span>
								</button>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
};

export default LoaderProjeto;
