import React, { } from 'react';
import HeaderLink from '../../../components/common/HeaderLink';
import { PageInsideContainer } from '../../../components/containers/PageInsideContainer';
import { Kanban } from '../../Registros/Tarefas/Kanban/Kanban';
import FilterOkrKanban from './filtro';
import { BackButton } from '../../Registros/Horas/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../utils/hooks/useQuery';
import { useFilters } from '../../../contexts/Filter/Task/Filter';
import { SelectOptions } from '../../../models/Select';

const KanbanOKRsPage = () => {
	const history = useHistory();
	const query = useQuery();
  const {
		selectedKeyResult
	} = useFilters();

  const keyresultId = (selectedKeyResult as SelectOptions).value

  return (
    <>
      <HeaderLink type="okrs" headerButtons={[]}></HeaderLink>

      <PageInsideContainer >
        <div className='flex items-center justify-center gap-2'>
          {!!query.get('urlBack') && (
            <BackButton
              onClick={() => history.push(`/okrs/times?keyresultId=${keyresultId}`)}
              className="btn-go-back"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="voltar-icon" />
              {t('Voltar')}
            </BackButton>
          )}
          <FilterOkrKanban kanban={true} />
        </div>

        <Kanban />
      </PageInsideContainer>
    </>
  );
};

export default KanbanOKRsPage;
