import styled from 'styled-components';

export const PageContainer = styled.div`
	height: calc(100vh - 200px);
	overflow: hidden auto;
	margin-top: 20px;
`;
export const InfoTarefaContent = styled.div`
	width: 90%;
	margin: auto;
	margin-bottom: 10px;
`;
export const InfoDescriptionTitle = styled.div`
	border-bottom: 2px solid rgb(233, 238, 241);
	font-weight: 500;
	color: #8099a7;
	font-size: 16px;
	padding: 10px 15px 10px 15px;
`;

export const InfoDescriptionContainer = styled.div`
	background: #fff;
	border-radius: 10px;
	margin-top: 20px;
`;

export const InfoDescriptionText = styled.div`
	font-size: 16px;
	padding: 10px 15px 10px 15px;
	color: #406173;
	font-weight: normal;
	min-height: 60px;
`;
