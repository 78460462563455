import { atom } from "jotai";
import { ColumnsManager } from "../types";
import { ProjectKanban } from "../../../models/Project";

export const initialValues = {
    columnsManager: {
        columns: {},
    columnsOrder: []
    }
}

export const columnsManagerAtom = atom<ColumnsManager<ProjectKanban>>(initialValues.columnsManager)