import React, { useState, useEffect } from 'react';

import PessoaService from '../../../services/providers/responsible';
import CalendarioGeralTable from './calendarioGeralTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPrint,
	faArrowAltCircleLeft,
	faArrowAltCircleRight,
	faTable,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { sortByPersonName } from '../../../utils/funcoes/sort';

import './style.css';

export default function CalendarioGeral({
	registros,
	diasDaSemana,
	dataInicial,
	dataFinal,
	navegacaoSemanal,
	loadingGetHoras,
	pessoasFilterArray,
	projetosFilterArray,
	clientesFilterArray,
	getRelatorioCSV,
	getRelatorioXLSX,
	loadingRelatorioCSV,
	setAtalhoPessoaHorasMensais,
	handlePrint
}) {
	const {t} = useTranslation();
	const [listaPorPessoa, setListaPorPessoa] = useState([]);
	const [diaAlvo, setDiaAlvo] = useState(moment());
	const [periodoString, setPeriodoString] = useState('');
	const [dataInicialFinal, setDataInicialFinal] = useState('');
	const [periodoDias, setPeriodoDias] = useState([]);
	const [mebrosAtivos, setmebrosAtivos] = useState([]);
	const [loadingPessoas, setLoadingPessoas] = useState(false);

	const [listTarefasPessoas, setListTarefasPessoas] = useState([]);
	const [listRegistrosDataTarefaAuxPessoas, setListRegistrosDataTarefaPessoas] = useState([]);
	const [listDatasPessoas, setListDatasPessoas] = useState([]);

	useEffect(() => {
		_getPessoasByID();
		handlePeriodoString(dataInicial, dataFinal);
		setDataInicialFinal(
			`${moment(dataInicial).format('L')} - ${moment(dataFinal).format('L')}`,
		);
		setPeriodoDias(diasDaSemana);
		setListRegistrosDataTarefaPessoas([])
		setListTarefasPessoas([])
		setListDatasPessoas([])
	}, [registros]);

	const handlePeriodoString = (dataInicial, dataFinal) => {
		const diaAtualConvertido = moment().format('YYYY-MM-DD');
		if (
			moment(diaAtualConvertido).isBetween(
				dataInicial,
				dataFinal,
				undefined,
				'[]',
			)
		) {
			setPeriodoString(t('Esta Semana'));
		} else {
			if (
				moment(diaAtualConvertido)
					.subtract(7, 'days')
					.isBetween(dataInicial, dataFinal, undefined, '[]')
			) {
				setPeriodoString(t('Semana Passada'));
			} else {
				setPeriodoString(
					`${moment(dataInicial).format('L')} - ${moment(dataFinal).format(
						'L',
					)}`,
				);
			}
		}
	};

	const upperCaseDiaSemana = data => {
		return data[0].toUpperCase() + data.slice(1);
	};

	const _getPessoasByID = () => {
		setLoadingPessoas(true);
		const pessoaService = new PessoaService();
		pessoaService.getPessoasByID(
			pessoasFilterArray,
			projetosFilterArray,
			clientesFilterArray,
			response => {
				if (response.data) {
					setmebrosAtivos(response.data);
					const pessoaService = new PessoaService();
					pessoaService.getPessoaActiveMembersByID(
						pessoasFilterArray,
						projetosFilterArray,
						clientesFilterArray,
						response => {
							if (response.data) {
								addArrayPessoaSemHoraRegistrada(response.data);
								setLoadingPessoas(false);
							}
						},
						error => {
							setLoadingPessoas(false);
						},
					);
				}
			},
			error => {
				setLoadingPessoas(false);
			},
		);
	};

	const addArrayPessoaSemHoraRegistrada = arrayPessoa => {
		const listRegistros = registros;
		const listTarefasAux = [];

		arrayPessoa.forEach(pessoa => {
			//Se nao existe registros para a pessoa e ela for memebro ativo incluir ela sem os registros desse período
			let existePessoa =
				registros.findIndex(tarefa => {
					return pessoa.id === tarefa.pessoa;
				}) !== -1;
			let existeMembroAtivo =
				mebrosAtivos.findIndex(membro => {
					return membro.id === pessoa.id;
				}) !== -1;

			if (!existePessoa && existeMembroAtivo) {
				listTarefasAux.push({
					pessoa: pessoa.id,
					pessoa_nome: pessoa.nome,
					pessoa_foto: pessoa.foto_perfil,
					tarefa_nome: t('Sem registros neste período'),
					duracao: '00:00:00',
					projeto_nome: '',
					cliente_nome: '',
				});
			}
		});
		listRegistros.push(...listTarefasAux);
		estruturaObjPorPessoa(listRegistros);
	};

	const estruturaObjPorPessoa = objRegistros => {
		const listTarefasAux = [];
		objRegistros.forEach(registro => {
			let tarefaDuplicada =
				listTarefasAux.findIndex(tarefa => {
					return registro.pessoa === tarefa.pessoa_id;
				}) !== -1;
			if (!tarefaDuplicada) {
				listTarefasAux.push({
					pessoa_id: registro.pessoa,
					pessoa_nome: registro.pessoa_nome,
					pessoa_foto: registro.pessoa_foto,
					registros: [],
				});
			}
		});
		atribuiObjPorPessoa(listTarefasAux);
	};

	const atribuiObjPorPessoa = listTarefasAux => {
		registros.forEach(registro => {
			listTarefasAux.forEach(data => {
				if (registro.pessoa === data.pessoa_id) {
					data.registros.push(registro);
				}
			});
		});

		const tempNoHoursAux = listTarefasAux.filter(item => item.registros.length === 1 && item.registros[0].duracao === "00:00:00");
		const tempWithHoursAux = listTarefasAux.filter(item => item.registros.length > 0 && item.registros[0].duracao !== "00:00:00");
		const sortListTarefasAux = [...sortByPersonName(tempWithHoursAux, "pessoa_nome"), ...tempNoHoursAux]

		setListaPorPessoa(sortListTarefasAux);
	};

	const calculaTotalHorasGeral = (pessoasHoras) => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		let totalSegundos = 0;
		pessoasHoras.registros.forEach(registro => {
			let extraiHoraMinuto = registro.duracao.split(':');
			totalHoras = totalHoras + parseInt(extraiHoraMinuto[0]);
			totalMinutos = totalMinutos + parseInt(extraiHoraMinuto[1]);
			totalSegundos = totalSegundos + parseInt(extraiHoraMinuto[2]);

		});
		let final_hora = totalHoras + Math.floor(totalMinutos / 60);
		const tempTotalMinutos = + (totalMinutos % 60) + parseInt(totalSegundos / 60);
		let final_minuto = tempTotalMinutos < 10 ? `0${tempTotalMinutos}` : `${tempTotalMinutos}`;
		totalAbsoluto = final_hora + ':' + final_minuto;
		return totalAbsoluto;
	};

	const calculaTotalHorasEspecifico = somaHoras => {
		let totalHoras = 0;
		let totalMinutos = 0;
		let totalAbsoluto = 0;
		let totalSegundos = 0;

		somaHoras.forEach(dado => {
			let extraiHoraMinuto = dado.split(':');
			totalHoras = totalHoras + parseInt(extraiHoraMinuto[0]);
			totalMinutos = totalMinutos + parseInt(extraiHoraMinuto[1]);
			totalSegundos = totalSegundos + parseInt(extraiHoraMinuto[2]);

		});
		let final_hora = totalHoras + Math.floor(totalMinutos / 60);
		const tempTotalMinutos = + (totalMinutos % 60) + parseInt(totalSegundos / 60);
		let final_minuto = tempTotalMinutos < 10 ? `0${tempTotalMinutos}` : `${tempTotalMinutos}`;
		totalAbsoluto = final_hora + ':' + final_minuto;
		return totalAbsoluto;
	};

	const handlePrintData = () => {
		const dataInfo = listTarefasPessoas.map(pessoaRegistros => {
			let tempPersonRows = pessoaRegistros.map(tarefa => {
				const tempRow = {
					[dataInicialFinal]: `${tarefa.nome}\n${tarefa.cliente_nome} - ${tarefa.projeto_nome}`,
				}
	
				return tempRow
			})

			tempPersonRows = [
				...tempPersonRows, 
				{
					[dataInicialFinal]: pessoaRegistros[0].pessoa_nome,
				}
			]

			return tempPersonRows
		}).flat()

		let dataValues = listTarefasPessoas.map((pessoaRegistros, pessoaIndex) => {
			let tempPersonRows = pessoaRegistros.map(tarefa => {
				const tempRow = {
				}
	
				periodoDias.forEach(date => {
					tempRow[`${upperCaseDiaSemana(date.diaSemana)} \n ${moment(date.data).format('DD/MM')}`] = listRegistrosDataTarefaAuxPessoas[pessoaIndex].map(dataTarefa => {
						if (
							dataTarefa.tarefa === tarefa.id &&
							dataTarefa.data === date.data &&
							dataTarefa.registros.length !== 0
						) {
							return calculaTotalHorasEspecifico(dataTarefa.registros);
						}
						return null;
					}).filter(item => item)[0]
				})
	
				tempRow[t('Total')] = calculaTotalHorasEspecifico(tarefa.registros)
	
				return tempRow
			})

			const tempTotalRow = {}

			periodoDias.forEach(date => {
				const tempRegistros = listDatasPessoas?.[pessoaIndex]?.find(dataTarefa => date.data === dataTarefa.data)

				tempTotalRow[`${upperCaseDiaSemana(date.diaSemana)} \n ${moment(date.data).format('DD/MM')}`] = (
					tempRegistros && tempRegistros.registros.length > 0 
						? calculaTotalHorasEspecifico(tempRegistros.registros)
						: null
				)
			})

			tempTotalRow[t('Total')] = calculaTotalHorasGeral(listaPorPessoa?.[pessoaIndex] ?? [])


			tempPersonRows = [
				...tempPersonRows, 
				tempTotalRow
			]

			return tempPersonRows
		}).flat()

		
		handlePrint({
			dataInfo, 
			dataValues, 
			periodoString, 
			title: t('Calendário Semanal')
		})
	}

	return (
		<div>
			<div className="relatorio-header-container">
				<p className="relatorio-title"> {t("Calendário Semanal")} </p>{' '}
				<div style={{ display: 'flex' }}>
					<div
						onClick={() => getRelatorioXLSX()}
						className="relatorio-print-container"
						disabled={true}
						style={{
							pointerEvents: loadingRelatorioCSV ? 'none' : 'auto',
							opacity: loadingRelatorioCSV ? '0.5' : '1',
						}}
					>
						<FontAwesomeIcon icon={faTable} className="print-icon-relatorio" />
						<p className="print-text-relatorio"> {t("Gerar Excel")} </p>{' '}
						{loadingRelatorioCSV ? (
							<span style={{ marginLeft: '4px' }}>
								<Loader />{' '}
							</span>
						) : (
							<> </>
						)}{' '}
					</div>{' '}
					<div
						onClick={() => handlePrintData()}
						className="relatorio-print-container"
					>
						<FontAwesomeIcon icon={faPrint} className="print-icon-relatorio" />
						<p className="print-text-relatorio"> {t("Imprimir")} </p>{' '}
					</div>{' '}
				</div>{' '}
			</div>{' '}
			<div className="relatorio-weekNavigation">
				<FontAwesomeIcon
					style={{
						opacity:
							loadingGetHoras === false && loadingPessoas === false
								? '1'
								: '0.5',
					}}
					onClick={() => {
						if (loadingGetHoras === false && loadingPessoas === false) {
							setListaPorPessoa([]);
							
							navegacaoSemanal('prev', moment(diaAlvo).subtract(7, 'days'));
							setDiaAlvo(moment(diaAlvo).subtract(7, 'days'));
						}
					}}
					icon={faArrowAltCircleLeft}
					className="navigation-icon-relatorio"
				/>
				<p className="relatorio-weekNavigation-text"> {periodoString} </p>{' '}
				<FontAwesomeIcon
					style={{
						opacity:
							loadingGetHoras === false && loadingPessoas === false
								? '1'
								: '0.5',
					}}
					onClick={() => {
						if (loadingGetHoras === false && loadingPessoas === false) {
							setListaPorPessoa([]);
							
							navegacaoSemanal('next', moment(diaAlvo).add(7, 'days'));
							setDiaAlvo(moment(diaAlvo).add(7, 'days'));
						}
					}}
					icon={faArrowAltCircleRight}
					className="navigation-icon-relatorio"
				/>
			</div>{' '}
			<div id="printable">
				<table className="relatorio-table">
					<thead style={{ userSelect: 'none' }}>
						<tr style={{ maxHeight: 52 }}>
							<th className="cabecalho-visual-periodo-totalhorasdiarias">
								<div className="relatorio-visual-periodo">
									<span> {dataInicialFinal} </span>{' '}
									<span>
										{' '}
										{loadingGetHoras === false && loadingPessoas === false
											? ''
											: Loader()}{' '}
									</span>{' '}
								</div>{' '}
							</th>{' '}
							{periodoDias.map((dia, index) => (
								<th
									className="relatorio-visual-cabecalho-data"
									style={{
										color: index === 0 || index === 6 ? '#a2a2a2' : '#000',
									}}
								>
									<span className="cabecalho-table-calendario-diaSemana">
										{' '}
										{upperCaseDiaSemana(dia.diaSemana)}{' '}
									</span>{' '}
									<br />
									<span className="cabecalho-table-calendario-data">
										{' '}
										{moment(dia.data).format('DD/MM')}{' '}
									</span>{' '}
								</th>
							))}{' '}
							<th className="cabecalho-visual-total"> {t("TOTAL")} </th>{' '}
						</tr>{' '}
					</thead>{' '}
					{loadingGetHoras === false && loadingPessoas === false ? (
						listaPorPessoa.map((item, index) => (
							<>
								<CalendarioGeralTable
									loadingGetHoras={loadingGetHoras}
									pessoasHoras={item}
									diasDaSemana={diasDaSemana}
									dataInicial={dataInicial}
									dataFinal={dataFinal}
									classeImpaPar={index % 2 !== 0}
									setAtalhoPessoaHorasMensais={setAtalhoPessoaHorasMensais}
									setListTarefasPessoas={setListTarefasPessoas}
									setListRegistrosDataTarefaPessoas={setListRegistrosDataTarefaPessoas}
									setListDatasPessoas={setListDatasPessoas}
									calculaTotalHorasGeral={calculaTotalHorasGeral}
									calculaTotalHorasEspecifico={calculaTotalHorasEspecifico}
								/>
							</>
						))
					) : (
						<> </>
					)}
					<tfoot>
						<tr>
							<td
								className="relatorio-visual-footer-left"
								style={{
									height: '35px',
								}}
							></td>
							<td colSpan="7" className="relatorio-visual-footer-center"></td>
							<td className="relatorio-visual-footer-right"> </td>{' '}
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
}

const Loader = () => (
	<>
		<div className="loader-alinhamento">
			<div className="loader-visual"> </div>{' '}
		</div>{' '}
	</>
);
