import React, { useCallback, useState } from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import moment from "moment";
import { MdOutlineDateRange, MdOutlineDeleteForever } from "react-icons/md";
import { BsPersonPlus } from "react-icons/bs";

import { Subtask } from "../../../../../models/Subtask";
import { Responsible } from "../../../../../models/Responsible";
import { DateDiv, Card, CheckInput, MenuButton, NameInput, NameText, ProfileImage, AssignContainer, DateText, DateInput } from "./styled";
import { SelectAssign } from "../../SelectAssign";
import profileImage from '../../../../../assets/images/profile.png';

export interface DetailsSubtaskProps {
    subtask: Subtask;
    openModalConfirm: (sub: Subtask) => void;
    handlePatchSubtask: (newSub: Subtask) => void;
    team: Responsible[];
}

export function DetailsSubtask({
    team, 
    subtask, 
    openModalConfirm,
    handlePatchSubtask
}: DetailsSubtaskProps) {
	const { t } = useTranslation();

    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [isAssigning, setIsAssigning] = useState(false);
    const sub: Subtask = {
        ...subtask,
        pessoas_responsaveis: team.find(resp =>  resp.id === subtask.responsavel)
    }

    
    const patchSubtaskName = (title: string) => {
        const newSub: Subtask =  {
            ...sub,
            nome: title
        }
        
        handlePatchSubtask(newSub);
    };

    const patchSubtaskDate = (date: string) => {
        const newSub: Subtask =  {
            ...sub,
            data_conclusao: moment(date).format("YYYY-MM-DDThh:mm:ss").toString(),
        }
        
        handlePatchSubtask(newSub);
    };

    const patchSubtaskResponsible = (responsible?: Responsible) => {
        const newSub: Subtask =  {
            ...sub,
            pessoas_responsaveis: responsible,
            responsavel: responsible?.id
        }
        
        handlePatchSubtask(newSub);
    };

    const checkSubtask = (value: boolean) => {
        const newSub: Subtask =  {
            ...sub,
            completada: value
        }
        
        handlePatchSubtask(newSub);
    }

    const handleSetIsEditingName = useCallback(() => setIsEditingName(true), [setIsEditingName]);

    const exitEditingName = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setIsEditingName(false);
        e.target.value = "";
      }, [setIsEditingName]);

    const handlefocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => e.target.select(), []);

    const keyDownName = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (e.key === 'Enter') {
            patchSubtaskName(target.value);
            setIsEditingName(false);                        
            target.value = "";                        
        }
    }, [patchSubtaskName, setIsEditingName]);

    const handleSetIsEditingDate = useCallback(() => setIsEditingDate(true), [setIsEditingDate]);

    const exitEditingDate = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (
            value !== sub.data_conclusao?.slice(0, 10)
            && value !== ''
        ) {
            patchSubtaskDate(value);
            e.target.value = "";                        
        }
        setIsEditingDate(false);                        
      }, [patchSubtaskDate, setIsEditingDate]);

    const keyDownDate = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (e.key === 'Enter' && target.value !== '') {
            patchSubtaskDate(target.value);
            setIsEditingDate(false);                        
            target.value = "";                        
        }
    }, [patchSubtaskDate, setIsEditingDate]);

    return (
        <Card>
            <div className='subtask-nameDiv'>
                <CheckInput 
                    type="checkbox" 
                    name={`sub-${sub.id}`} 
                    id={`sub-${sub.id}`} 
                    checked={sub.completada}
                    onChange={e => checkSubtask(e.target.checked)}
                />
                {isEditingName && (
                  <NameInput
                    defaultValue={sub.nome}
                    type="text"
                    title={`${t("Editar nome")} input`}
                    onBlur={exitEditingName}
                    autoFocus
                    onFocus={handlefocus}
                    onKeyDown={keyDownName}
                  />
                )}
                <NameText 
                    completed={sub.completada}
                    isEditingName={isEditingName}
                    title={sub.nome}
                    onClick={handleSetIsEditingName}
                >
                    {sub.nome}
                </NameText>
            </div>
            <div className='subtask-bottom'>
                <DateDiv>  
                    <div className="subtask-profiles">
                        {sub.pessoas_responsaveis ? (
                            <ProfileImage
                                className='subtask-profile'
                                key={sub.pessoas_responsaveis.id}
                                src={
                                    sub.pessoas_responsaveis.foto_perfil ? sub.pessoas_responsaveis.foto_perfil : profileImage
                                }
                                alt={sub.pessoas_responsaveis.nome}
                                title={sub.pessoas_responsaveis.nome}
                            />
                        ) : (
                            <span>{t('Sem Responsável')}</span>
                        )}
                    </div>
                    <div className="subtask-date">
                        {isEditingDate && (
                            <DateInput
                                defaultValue={moment(sub.data_conclusao).format('YYYY-MM-DD')}
                                type="date"
                                title={`${t("Editar data subtarefa")} input`}
                                onBlur={exitEditingDate}
                                autoFocus
                                onFocus={handlefocus}
                                onKeyDown={keyDownDate}
                            />
                        )}
                        <DateText
                            isEditingDate={isEditingDate}
                            title={t("Data entrega").toString()}
                            onClick={handleSetIsEditingDate}
                        >
                            {sub.data_conclusao !== null && sub.data_conclusao !== "" 
                                ? moment(sub.data_conclusao).format('DD/MM')
                                : <MdOutlineDateRange />
                            }
                        </DateText>
                        
                    </div>
                </DateDiv>

                <div className='subtask-menuButtons'>
                    {isAssigning && (
                        <AssignContainer>
                            <SelectAssign 
                                responsibles={team} 
                                saveChanges={(responsible?: Responsible) => {
                                    patchSubtaskResponsible(responsible)
                                }} 
                                setVisibility={setIsAssigning}
                            />
                        </AssignContainer>
                    )}
                    <MenuButton 
                                        
                        type='button'
                        onClick={() => {
                            setIsAssigning(true);
                        }}
                    >
                        <BsPersonPlus size={14} />
                        {t('Atribuir')}
                    </MenuButton>
                    <MenuButton 
                        type='button' 
                        onClick={() => {
                            openModalConfirm(sub);
                        }}
                    >
                        <MdOutlineDeleteForever size={14} />
                        {t('Remover')}
                    </MenuButton>
                </div>
            </div>
        </Card>
    )
}