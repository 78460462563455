import { useAtom } from 'jotai';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectTeamAtom, ProjectTeamLoading, ProjectTeamTempAtom, projectInfoAtom } from '../../../../../atoms/ProjectDetails';
import { TableHeader, TableContainer, TableHeaderTitle, TableHeaderContent } from './styled';
import ProjetoService from '../../../../../services/providers/project';
import CustomTable, { FieldsHeader, TableBody } from '../../../../../components/containers/CustomTable';
import { toast } from 'react-toastify';
import { TeamPerson } from '../../../../../models/TeamPerson';
import { theme } from '../../../../../styles/theme';
import { calculateCurrentValue } from '../Team/utils';
import { useProject } from '../../../../../contexts/Project';
import ModalConfig from '../ModalConfig';
import { formatBRL } from '../../../../../utils/currency';
import { HoursRecord } from '../../../../../models/HoursRecord';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'
import { horasParaTempo, tempoParaHoras } from '../../../../../utils/funcoes/horasParaString';

interface TableProps {
    projectId: number;
    hoursRecord: HoursRecord[];
    getProjectTeam: (id: number) => void;
    expandTable: boolean;
    setExpandTable: Dispatch<SetStateAction<boolean>>
}

export default function TeamTable({projectId, getProjectTeam, expandTable, setExpandTable}: TableProps) {
    const { t } = useTranslation();
    const [, setProjectTeamTemp] = useAtom(ProjectTeamTempAtom);
    const [projectTeam] = useAtom(ProjectTeamAtom);

    const [loadingTeam] = useAtom(ProjectTeamLoading);
    const [projectInfo, setProjectInfo] = useAtom(projectInfoAtom);
    const [projectTable, setProjectTable] = useState<TableBody[]>([]);
    const [totalCost, setTotalCost] = useState<string>('R$ 00,00');
    const [loading, setLoading] = useState(false);
    const {modalProjectVisible, setModalProjectVisible, setProjects} = useProject();


    const handlePostPatchProjeto = (saveAndContinue: any) => {
		if (saveAndContinue === true) {
			setModalProjectVisible(true);
		} else {
			setModalProjectVisible(false);
		}
        getProjectTeam(projectId);
		getAllProjects(projectId);
	};

    const getAllProjects = (projectId: number) => {
		setLoading(true);
		const projetoService = new ProjetoService();
		projetoService.getProject(
			projectId
		).then(response => {
			if (response) {
				setProjectInfo(prev => ({ ...prev, ...response }));
				setProjects((prevData: any) => ({
					...prevData,
					[projectId]: { ...prevData[projectId], ...response },
				}));
			}
			setLoading(false);
		}).catch(() => {
			setLoading(false);
		});

		projetoService.getDetalheProjeto(
			projectId,
			response => {
				if (response) {
					setProjectInfo(prev => prev ? ({ ...prev, ...response}) : undefined);
                    setProjects((prevData: any) => ({
						...prevData,
						[projectId]: { ...prevData[projectId], ...response },
					}));
				}
				setLoading(false);
			},
			e => {
				setLoading(false);
				console.log(e);
			},
		);
	};


    useEffect(() => {
        if(projectTeam) {
            calculateTotalCost();
            setProjectTable(formatTableRow(projectTeam))
        }
    }, [projectTeam])

    const calculateTotalCost = () => {
        let cost = 0;
        projectTeam.forEach((team) => {
            cost = cost + calculateCurrentValue(team.horas_realizadas, team.preco);
        })

        setTotalCost(formatBRL(cost));
    }



    const patchTeamMember = (memberId: number, key: keyof TeamPerson, value: string | number) => {
		setLoading(true);
        const form = new FormData();
        const tempValue = value.toString()
        form.append(key, tempValue.toString())
		const projetoService = new ProjetoService();
		projetoService.patchMembroTime(
			memberId,
			form,
			response => {
				if (response) {
					toast.success(t('Membro atualizado como sucesso!'));
					setProjectTeamTemp(prev => {
                        const newItem = prev.map((item) => item.id === memberId ? {...item, [key]: tempValue} : item);
                        return newItem;
                    })
                    setLoading(false)
                }
			},
			error => {
				toast.error(error);
                setLoading(false)

			},
		);
	};

    const handleBlur = useCallback(
        (e: string | number | boolean | File, name: string, id?: number | string) => {
            if(id) {
                patchTeamMember(parseInt(id.toString()), name as keyof TeamPerson, e.toString());

                if(name === "horas_previstas" ) {
                    return horasParaTempo(Number(e))
                }
            }
        }, [patchTeamMember]
    )

    const handleChangeHorasPrevistas = useCallback(
        (e: string | number | boolean | File) => {
            if(/^\d+$/.test(e.toString())) {
                return e.toString()
            } else {
                return e.toString().slice(0, -1)
            }
        }, []
    )

    const handleFocusHorasPrevistas = useCallback(
        (e: string | number | boolean | File) => {
            return tempoParaHoras(e.toString()).toString()
        }, []
    )


    const formatTableRow = (projectTeam: TeamPerson[]) => {
        return projectTeam.filter(team => team.horas_realizadas !== "00:00:00" || !team.deleted).map((team): TableBody => {
            return (
                {
                    id: team.id,
                    bgColor: team.deleted ? '#E8E8E8' : '',
                    row: [
                        {
                            id: team.id,
                            name: 'nome',
                            disabled: true,
                            type: 'text',
                            value: team.pessoa.nome,

                        }, 
                        {
                            id: team.id,
                            name: 'funcao',
                            type: 'textarea',
                            value: team.funcao,
                            disabled: team.deleted
                        },
                        {
                            id: team.id,
                            name: 'preco',
                            type: 'text',
                            value: team.preco,
                            inputType: 'number',
                            disabled: team.deleted,
                            isCurreny: true
                        },
                        {
                            id: team.id,
                            name: 'horas_previstas',
                            type: 'text',
                            disabled: team.deleted,
                            value: horasParaTempo(team.horas_previstas),
                            onChange: handleChangeHorasPrevistas,
                            onFocus: handleFocusHorasPrevistas

                            // inputType: 'time'
                        },
                        {
                            id: team.id,
                            name: 'horas_realizadas',
                            type: 'text',
                            value: team.horas_realizadas,
                            disabled: true,
                            color: handleHourColor(team.horas_realizadas, team.horas_previstas),

                        }, {
                            id: team.id,
                            name: 'valor_atual',
                            type: 'text',
                            value: formatBRL(calculateCurrentValue(team.horas_realizadas, team.preco)),
                            disabled: true
                        }
                    ]
                }
            )
        })
    }

    const handleHourColor = (horas_realizadas: string, horas_previstas: number) => {
        const splitHour = horas_realizadas.split(':');
        const hour = parseInt(splitHour[0]);
        const minute = parseInt(splitHour[1]);
        const seconds = parseInt(splitHour[2]);
        const totalHour = hour + (minute/60) + (seconds/3600);
        return totalHour > horas_previstas ? '#CB0D0D' : undefined
    }

    

    const tableHeader: FieldsHeader[] = [
        {
            title: 'Nome',
            // width: '120'
        }, {
            title: "Função",
            // width: '120'
        },
        {
            title: "Valor hora",
            // width: '120'
        },
        {
            title: "Horas previstas",
            tooltip: "Mês"
            // width: '120'
        },
        {
            title: "Horas realizadas",
            tooltip: "Mês"
            // width: '120'
        },
        {
            title: "Valor atual",
            // width: '120'

        }
    ]


    return (
        <TableContainer expanded={expandTable}>
            {modalProjectVisible && !loading && !loadingTeam &&
                <ModalConfig 
                    handleDeletar={handlePostPatchProjeto} 
                    handleEncerrar={handlePostPatchProjeto} 
                    editingProjeto={true} 
                    itemSelecionado={projectInfo} 
                    projetoTime={
                        projectTeam.filter(
                            (person) => person.time === projectId && !person.deleted)
                            .map((time) => time.pessoa)
                        } 
                    handlePostPatchProjeto={handlePostPatchProjeto} 
                />
            }
            
            <TableHeader>
                <TableHeaderTitle>
             {t('Time')}
           </TableHeaderTitle>           
          <TableHeaderContent >
             {projectTeam.filter(item => !item.deleted).length ?? 0} {t('membros no total')} 
             <span style={{cursor: 'pointer', fontSize: '20px'}}  onClick={() => setModalProjectVisible(true)} className="material-symbols-outlined">
                settings_account_box
            </span>
            {expandTable ? <FontAwesomeIcon cursor={'pointer'} icon={faDownLeftAndUpRightToCenter} color={theme.colors.text.titleText} onClick={() => setExpandTable(false)} /> : <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} color={theme.colors.text.titleText} cursor={'pointer'} onClick={() => setExpandTable(true)} />}
          </TableHeaderContent>

            </TableHeader>
            <CustomTable
            onSubmit={handleBlur}
            tableBody={projectTable}
            tableHeader={tableHeader}
            loading={loadingTeam || loading}
            tableFontSize={`font-size: 0.75rem;
               @media (max-width: 690px) {
                    font-size: 0.6rem;
               }
            `}
            customTableStyle={`
                    background-color: rgb(255, 255, 255);
                    margin-bottom: 0;
                    width: 100%;
                    border-collapse: collapse;
                    table-layout:fixed;
                    ${!expandTable ? '' : 'height: 100%;'}
                `
            }

            customTableBodyStyle={
                `
                    ${!expandTable ? 'height: 220px;' : 'height: 100%;'}
                    overflow: auto;
                    display:block;
                `
            }

            customHeaderStyle={
                `
                width: calc( 100% - 1em );
                display:table;
                @media print {
                    width: 100%;
                }
                `
            }

          
            customTableWrapperStyle={`
            background-color: white;
            ${!expandTable ? 'height: auto;' : 'height: calc(100% - 40px); overflow-y: hidden;'}
            ${!expandTable ? 'padding-bottom: 6px;' : ''}
        `}

        customRowHeaderStyle={`
            display: table;
            width: 100%;
            table-layout: fixed;
        `}

        customTotalStyle={`
        display: table;
        width: calc(100% - 10px);
        table-layout: fixed;
            @media print {
                width: 100%;
            }
        `}
        customRowStyle={`
        background-color: white;
        border-bottom: none;
        color: ${theme.colors.text.titleText};
        height: 57px;
        display: table;
        width: 100%;
        table-layout: fixed;
        @media print {
            color: black;
        }
        `}

        customCellStyle = {
            `
                padding-left: 10px;
                @media print {
                    font-size: 10px
                }
            `
        }
        tableTotal={[
            {
                title: 'Valor total',
                colSpan: 5
            },
            {
                title: totalCost
            }
        ]}

        />
        </TableContainer>
    )
}