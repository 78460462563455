import { FieldsHeader, TableBody, TableCell } from "../../components/containers/CustomTable"
import { Responsible } from "../../models/Responsible"
import { SelectOptions } from "../../models/Select"
import pessoaImage from '../../assets/images/profile.png';
import { t } from "i18next";
import moment from "moment";
import { ExtraColumnValues } from "../../models/ExtraColumnValue";
import { HTMLInputTypeAttribute } from "react";



export const header: FieldsHeader[] = 
[{title: '', width: '50'},
{title: t('Nome'), width: '150'}, 
{title: t('E-mail'), width: '250'}, 
{title: t('Perfil do usuário'), width: '150'}, 
{title: t('Carga Horária'), width: '120'}, 
{title: t('Custo Hora'), width: '100'}, 
{title: t('Ativo'),width: '80'}, 
{title: t('Último Login'), width: '120'}
]


export const acessoOptions: SelectOptions[] = [
    {label: t("Membro"), value: 'm'},
    {label: t("Gerente de Projeto"), value: 'g'},
    {label: t('Administrador'), value: "a"},
    {label: t("Visitante"), value: "e"}
]

export const deletedOptions: SelectOptions[] = [
    {value: 'true', label: t("Não")}, 
    {value: 'false', label: t("Sim") }
]

export const formatTableRow = (people: Responsible[]): TableBody[] => {
    return people.map((person): TableBody  => {
        return (
            {
                id: person.id,
                row: [
                    {
                        value: person.foto_perfil || '',
                        id: person.id,
                        name: 'foto_perfil',
                        type: 'img',
                        src: person.foto_perfil || pessoaImage,
                    },
                    {
                        value: person.nome,
                        id: person.id,
                        name: 'nome',
                        type: 'text',
                        width: '148'
                    },
                    {
                        value: person.email,
                        name: 'email',
                        id: person.id,
                        type: 'text',
                        width: '248'

                    },
                    {
                        value: person.acesso,
                        name: 'acesso',
                        type: 'select',
                        options: acessoOptions,
                        id: person.id
                    },
                    {
                        value: person.carga_horaria,
                        name: 'carga_horaria',
                        type: 'text',
                        id: person.id,
                        width: '120',
                        inputType: 'number'
                    },
                    {
                        value: person.custo_hora,
                        name: 'custo_hora',
                        type: 'text',
                        id: person.id,
                        width: '100',
                        inputType: 'number'

                    },
                    {
                        value: !person.deleted,
                        name: 'deleted',
                        type: 'toggle',
                        id: person.id,
                        width: '80'
                    },{
                        value: person.datahora_ultimo_login ? moment(person.datahora_ultimo_login).format('DD/MM/YYYY')  : '-',
                        id: person.id,
                        name: 'datahora_ultimo_login',
                        type: 'text',
                        disabled: true,
                        width: '120'
                    },
                    ...formatExtraRows(person.valor_colunas_preenchidas || [])
                ]
            }
        )
    })
}

export const formatExtraRows = (extraFields: ExtraColumnValues[]) => {
    const extraRows: TableCell[] = extraFields.map(item => {
        return ({
            value: item.valor,
            id: `${item.coluna_empresa}!!${item.pessoa}${item.id ? "!!" + item.id : ""}`,
            name: `${item.coluna_expanded?.nome}-${item.coluna_empresa}-${item.pessoa}-extraField`,
            type: getExtraFieldType(item.coluna_expanded?.tipo_expanded?.tipo || "").table,
			width: item.coluna_expanded?.tipo_expanded?.tipo === "boolean" ? "100" : "140",
            inputType: getExtraFieldType(item.coluna_expanded?.tipo_expanded?.tipo || "").form
        })
    })

    return extraRows
}

interface ExtraFieldTypeReturn {
    form: HTMLInputTypeAttribute;
    table:  'text' | 'select' | 'img' | 'toggle';
}

export const getExtraFieldType = (type: string): ExtraFieldTypeReturn => {
    switch(type) {
        case "boolean":
            return {form: "checkbox", table: "toggle"}

        case "number":
            return {form: "number", table: "text"}

        case "date":
            return {form: "date", table: "text"}

        default:
            return {form: "text", table: "text"}
    }
}
