import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { MainProject, ProjectBasic } from '../../../../../../models/Project';
import ProfileImg from '../../../../../../assets/images/profile.png';
import CompanyImg from '../../../../../../assets/images/company.png'

import {
	ClientName,
	CompanyImageContainer,
	IconContainer,
	ImageContainer,
	ImageOver,
	ProjectName,
	ProjectNameContainer,
	TableRowContainer,
	TableTd,
	TaskContainer,
} from './styled';
import { t } from 'i18next';
import ProjetoService from '../../../../../../services/providers/project';
import { useProject } from '../../../../../../contexts/Project';
import { Responsible } from '../../../../../../models/Responsible';
import { MdEdit } from 'react-icons/md';
import { theme } from '../../../../../../styles/theme';
import { useHistory} from 'react-router-dom';
import { useFilters } from '../../../../../../contexts/Filter/Task/Filter';
import { useTranslation } from 'react-i18next';
import { verifyCanEditProject } from '../../../../../../utils/funcoes/validarPerfil';
import { useUser } from '../../../../../../contexts/User';
import {ReactComponent as KanbanIcon}  from '../../../../../../assets/svgs/kanbanIcon.svg'

interface TableRowProps {
	project: MainProject;
    setEditingProjeto: Dispatch<SetStateAction<boolean>>;
    setModalProjectVisible:Dispatch<SetStateAction<boolean>>;
    setProjetoEditado: Dispatch<SetStateAction<ProjectBasic | undefined>>;
    projetoEditado: ProjectBasic | undefined;
}

export default function TableRow({ project, projetoEditado, setEditingProjeto, setModalProjectVisible, setProjetoEditado }: TableRowProps) {
	const [isLoading, setIsLoading] = useState(true);
	const { userDetails, loadingUser } = useUser();
	const { setProjects, projectsDetails, setProjectsDetails, loadingPostPatch } = useProject();
    const {setSelectedProject} = useFilters();
    const history = useHistory();
	const { t } = useTranslation();

    const handleEditProject = useCallback(() => {
		if(verifyCanEditProject(userDetails, project.is_member, project.admins)) {
			setEditingProjeto(true);
			setProjetoEditado(project);
			setModalProjectVisible(true);
		}
        
    }, [project])


    useEffect(() => {
        if(projetoEditado?.id === project.id && projetoEditado.time === undefined) {
            setProjetoEditado(project);
        }
    }, [projetoEditado, project])

	const getProjetoDetails = async () => {
		setIsLoading(true);
		const projectService = new ProjetoService();
		projectService.getDetalheProjeto(
			project.id,
			response => {
				if (response !== null) {
					project.is_member = response.is_member;
					project.admins = response.admins;
					project.time = response.time;
					project.tags = response.tags;
					project.is_favorite = response.is_favorite;
					setProjects((prevData: any) => ({
						...prevData,
						[project.id]: project,
					}));
					setProjectsDetails((prev: any) => ({...prev, [response.id]: response}))
				}
				setIsLoading(false);
			},
			e => {
				console.log(e);
			},
		);
	};


	useEffect(() => {
        if (
            project.admins == undefined ||
            project.time == undefined ||
            project.tags == undefined ||
            project.is_favorite == undefined ||
            project.is_member == undefined
        ) {
            const tempDetails = projectsDetails?.[project.id];

            if(tempDetails) {
                project.is_member = tempDetails.is_member;
                project.admins = tempDetails.admins;
                project.time = tempDetails.time;
                project.tags = tempDetails.tags;
                project.is_favorite = tempDetails.is_favorite;
                setIsLoading(false);
            } else {
                getProjetoDetails();
            }
        } else {
            setIsLoading(false);
        }
    }, [project]);

	const overTeam = project.time && project.time.length - 6;
	const slicedTeam =
		project.time &&
		project.time.slice(0, overTeam > 0 ? overTeam * -1 : undefined);
	const restOfTheTeam =
		project.time && project.time.slice(project.time.length - overTeam);

    const handleGoKanban = useCallback(() => {
        setSelectedProject({value: project.id, label: project.nome})
        history.push(`/registros/kanban`);
        
    }, [history, project])

	
	const handleGoTask = useCallback(() => {
		setSelectedProject([{value: project.id, label: project.nome}])
        history.push(`/registros/tarefas`);
	}, [history, project]);

	const handleGoProject = useCallback(() => {
		if(verifyCanEditProject(userDetails, project.is_member, project.admins)) {
			history.push(`/registros/projetos/${project.id}/parametros`);
		}
	}, [project, history]);

	const whenLoad = () => loadingUser || loadingPostPatch;
	const disabledClick = () => (whenLoad() ? 'none' : 'auto');

	return (
		<TableRowContainer>
			<TableTd>
				<CompanyImageContainer>
					<img 
						src={project.cliente.logo_cliente ? project.cliente.logo_cliente : CompanyImg} 
						title={project.cliente.nome} 
						/>
				</CompanyImageContainer>
			</TableTd>
			<TableTd cursor={verifyCanEditProject(userDetails, project.is_member, project.admins) ? 'pointer' : 'default'} pointerEvents={disabledClick()} className="project-name" onClick={handleGoProject}>
				<ProjectNameContainer>
					<ProjectName>{project.nome}</ProjectName>
					<ClientName>{project.cliente.nome}  {!project.item_ativo && ` [${t('DESATIVADO')}]`}</ClientName>
				</ProjectNameContainer>
			</TableTd>
			<TableTd>
				<ImageContainer>
					{isLoading && !project.time && !slicedTeam ? (
						<>
							<img src={ProfileImg} alt="" title={'...'} />
							<img src={ProfileImg} alt="" title={'...'} />
							<img src={ProfileImg} alt="" title={'...'} />
							<img src={ProfileImg} alt="" title={'...'} />
							<img src={ProfileImg} alt="" title={'...'} />
							<img src={ProfileImg} alt="" title={'...'} />
							<ImageOver title={'+1'}>
								+1
							</ImageOver>
						</>
					) : (
						<ProjectTeam
							overTeam={restOfTheTeam}
							overTeamNumber={overTeam}
							team={slicedTeam}
						/>
					)}
				</ImageContainer>
			</TableTd>
			<TableTd  >
				<TaskContainer 
					onClick={handleGoTask}
				>
					{Array.isArray(project.tarefas) ? project.tarefas.length : project.tarefas} {t('tarefas')}
				</TaskContainer>
			</TableTd>
			<TableTd>
				<IconContainer disabled={!verifyCanEditProject(userDetails, project.is_member, project.admins)}  onClick={handleEditProject}>
					<MdEdit color={theme.colors.background.mainBg} size={20} />
				</IconContainer>
			</TableTd> 
			<TableTd>
				<IconContainer onClick={handleGoKanban}>
					<KanbanIcon />
				</IconContainer>
			</TableTd>
		</TableRowContainer>
	);
}

interface ProjectTeamProps {
	team: Responsible[];
	overTeam: Responsible[];
	overTeamNumber: number;
}

const ProjectTeam = ({ team, overTeam, overTeamNumber }: ProjectTeamProps) => {	
	return team !== undefined ? (
		<>
			{team.map((responsible) => {
				return (
					<>
						<img
							src={
								responsible.foto_perfil !== null
									? responsible.foto_perfil
									: ProfileImg
							}
							key={responsible.id}
							alt=""
							title={responsible.nome}
						/>
					</>
				);
			})}
			{overTeamNumber > 0 && (
				<ImageOver
					title={overTeam.map(responsible => ' ' + responsible.nome).toString()}
				>
					+{overTeamNumber}
				</ImageOver>
			)}
		</>
	) : (
		<span style={{ height: '20px' }}>{t('Sem time')}</span>
	);
};
