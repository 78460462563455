import styled from 'styled-components';

interface ContainerProps {
	loadingFilters: boolean;
}

export const Container = styled.div<ContainerProps>`
	margin: 2rem;
	cursor: ${({ loadingFilters }) => (loadingFilters ? 'progress' : 'initial')};
`;
