import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { AddButtonContainer, AddInputContainer, AddButton } from "./styled";

import { KanbanAddSubtaskProps } from './types';

export function KanbanAddSubtask({setIsAdding, handleAddSubtask}: KanbanAddSubtaskProps) {
	const { t } = useTranslation();
    const [title, setTitle] = useState("");

    const addSubtask = () => {
        if(title.trim() === "") {
            return;
        }

        handleAddSubtask(title);
        setTitle("");
    };

    return (
        <div className='kanban-subtask-addModal'>
            <AddInputContainer className='add-subtask-inputDiv'>
                <input 
                    type='text' 
                    name='subtask-title' 
                    id='subtask-title' 
                    placeholder={t('Nome da Subtarefa').toString()}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    autoFocus
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            addSubtask();
                        }
                    }}
                />
            </AddInputContainer>
            
            <AddButtonContainer className='add-subtask-buttonDiv'>
                <AddButton 
                    type='button'
                    isPrimary={false} 
                    onClick={() => setIsAdding(false)}
                >
                    {t('Cancelar')}
                </AddButton>
                <AddButton 
                    isPrimary
                    type='button' 
                    onClick={addSubtask}
                >
                    {t('Salvar')}
                </AddButton>
            </AddButtonContainer>
        </div>
    )
}