import styled from "styled-components";

interface ItemWrapperProps {
    isRequestingAdd: boolean;
}

export const ItemWrapper = styled.div<ItemWrapperProps>`
    pointer-events: ${({isRequestingAdd}) => isRequestingAdd ? 'none' : 'initial'};
`
export interface ItemContainerProps {
    isDragging: boolean;
}

export const ItemContainer = styled.div<ItemContainerProps>`
	user-select: none;
    margin: 0 0 8px 0;
    min-height: 50px;
    color: white;
    background: ${({isDragging}) => isDragging ? '#263b4a' : '#496070'};
    border-radius: 4px;
    overflow: hidden;
`;