import React, { useEffect, useState } from "react"

import { ActivesCountWrapper, CountDiv } from "./styled";
import { t } from "i18next";
import CompanyService from "../../../services/providers/company";
import { Company } from "../../../models/Company";
import { useUser } from "../../../contexts/User";

export interface ActivesCountProps {
}

export function ActivesCount() {
    const companyService = new CompanyService();

    const [companyInfo, setCompanyInfo] = useState<Company>();
    const { loadingUser } = useUser();

    useEffect(() => {
        companyService.getCompany().then(result => {
            setCompanyInfo(result)
        });
    }, []);

    return (
        <ActivesCountWrapper>
            <CountDiv active>
                {t("Usuários ativos")}: {!loadingUser && companyInfo?.quantidade_usuarios_ativos}
            </CountDiv>

            <CountDiv >
                {t("Usuários inativos")}: {!loadingUser && companyInfo?.quantidade_usuarios_inativos}
            </CountDiv>
        </ActivesCountWrapper>
    )
}