import React, { useCallback, useState } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { useKanban } from '../../../../../contexts/Kanban/Kanban';

import {IoEllipsisVertical} from 'react-icons/io5';
import {MdPersonAddAlt, MdOutlineDeleteForever, MdOutlineDateRange, MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import { 
    Card, 
    CheckInput, 
    NameInput, 
    NameText, 
    MenuButton, 
    MenuModal, 
    ProfileImage, 
    AssignContainer,
    DateInput,
    DateText
} from "./styled";
import { KanbanSubtaskProps } from './types';
import { SelectAssign } from '../../SelectAssign';
import { Responsible } from '../../../../../models/Responsible';
import { SubtaskService } from '../../../../../services/providers/subtask';
import { Subtask } from '../../../../../models/Subtask';
import { toast } from 'react-toastify';
import profileImage from '../../../../../assets/images/profile.png';

export function KanbanSubtask({
    subtasks, 
    taskId, 
    columnId, 
    subtask, 
    editSubtaskId, 
    setEditSubtaskId,
    openConfirmSubtarefaModal,
    index

}: KanbanSubtaskProps) {
	const { t } = useTranslation();
    const orderIndex = index + 1;

	const { handleSetSubtasks } = useKanban();
    const [editingName, setEditingName] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [isAssigning, setIsAssigning] = useState(false);
	const { team } = useKanban();

    const sub: Subtask = {
        ...subtask,
        pessoas_responsaveis: team.find(resp =>  resp.id === subtask.responsavel)
    }

    const subtaskService = new SubtaskService();
    
    const switchMenu = useCallback(() => {
        setEditSubtaskId(editSubtaskId === sub.id ? null : sub.id);
        setIsAssigning(false)
    }, [setEditSubtaskId, setIsAssigning, editSubtaskId]);

    const handleAssigning = useCallback(() => {
        setIsAssigning(true);
    }, [setIsAssigning])


    const patchSubtask = (newSub: Subtask, notCompleteToAdd = 0) => {
        const tempSubtasks = [...subtasks];
        const newSubtaskIndex = tempSubtasks.findIndex((tempSub: any) => tempSub.id === newSub.id);

        subtaskService.patchSubtask(newSub)
            .catch((err) => {
                handleSetSubtasks(tempSubtasks, taskId, columnId, 0, notCompleteToAdd);
                console.log(err);
                toast.error(t('Erro ao tentar atualizar subtarefa!'));
            });

        tempSubtasks.splice(newSubtaskIndex, 1, newSub);
        const [removed] = tempSubtasks.splice(index, 1);
        tempSubtasks.splice(newSub.ordenacao - 1, 0, removed);

        const tempOrderSubtasks = tempSubtasks.map((item, index) => {
            return {
                ...item,
                order: index
            }
        })
        handleSetSubtasks(tempOrderSubtasks, taskId, columnId,0, notCompleteToAdd);
    };
    
    const patchSubtaskName = (title: string) => {
        const newSub: Subtask =  {
            ...sub,
            nome: title
        }
        
        patchSubtask(newSub);
    };

    const patchSubtaskDate = (date: string) => {
        const newSub: Subtask =  {
            ...sub,
            data_conclusao: moment(
                date,
                'YYYY-MM-DD',
            ).format()
        }
        
        patchSubtask(newSub);
    };

    const patchUpOrder = useCallback(() => {
        if(index === 0) {
            return;
        }

        const newSub: Subtask =  {
            ...sub,
            ordenacao: orderIndex - 1
        }
        
        patchSubtask(newSub);
    }, [patchSubtask]);

    const patchDownOrder = useCallback(() => {
        if(index === subtasks.length - 1) {
            return;
        }

        const newSub: Subtask =  {
            ...sub,
            ordenacao: orderIndex + 1
        }
        
        patchSubtask(newSub);
    }, [patchSubtask]);
    
    const patchSubtaskResponsible = useCallback((responsible?: Responsible) => {
        const newSub: Subtask =  {
            ...sub,
            pessoas_responsaveis: responsible,
            responsavel: responsible?.id
        }
        
        patchSubtask(newSub);
        switchMenu();
    }, [patchSubtask, switchMenu, sub]);
    
    const checkSubtask = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        const newSub: Subtask =  {
            ...sub,
            completada: value
        }
        
        patchSubtask(newSub, value ? -1 : 1);
    }, [patchSubtask])

    const handleSetIsEditingName = useCallback(() => setEditingName(true), [setEditingName]);

    const exitEditingName = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setEditingName(false);
        e.target.value = "";
      }, [setEditingName]);

    const handlefocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => e.target.select(), []);

    const keyDownName = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (e.key === 'Enter') {
            patchSubtaskName(target.value);
            setEditingName(false);                        
            target.value = "";                        
        }
    }, [patchSubtaskName, setEditingName]);

    const handleSetIsEditingDate = useCallback(() => setIsEditingDate(true), [setIsEditingDate]);

    const exitEditingDate = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (
            value !== sub.data_conclusao?.slice(0, 10)
            && value !== ''
        ) {
            patchSubtaskDate(value);
            e.target.value = "";                        
        }
        setIsEditingDate(false);                        
      }, [setIsEditingDate, patchSubtaskDate]);

    const keyDownDate = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (e.key === 'Enter' && target.value !== '') {
            patchSubtaskDate(target.value);
            setIsEditingDate(false);                        
            target.value = "";                        
        }
    }, [patchSubtaskDate, setIsEditingDate]);

    const handleDeleteModal = useCallback(() => {
        openConfirmSubtarefaModal(sub);
        switchMenu();
    }, [openConfirmSubtarefaModal, switchMenu]);

    return (
        <Card>
            <div className='kanban-subtask-nameDiv'>
                <CheckInput 
                    type="checkbox" 
                    name={`sub-${sub.id}`} 
                    id={`sub-${sub.id}`} 
                    checked={sub.completada}
                    onChange={checkSubtask}
                />
                {editingName && (
                  <NameInput
                    title='editar nome'
                    defaultValue={sub.nome}
                    type="text"
                    onBlur={exitEditingName}
                    autoFocus
                    onFocus={handlefocus}
                    onKeyDown={keyDownName}
                  />
                )}
                <NameText 
                    style={{textDecorationLine: sub.completada ? 'line-through' : "initial", display: editingName ? 'none' : "-webkit-box"}}
                    title={sub.nome}
                    onClick={handleSetIsEditingName}
                >
                    {sub.nome}
                </NameText>
            </div>
            <div className='kanban-subtask-dateDiv'>

                {sub.pessoas_responsaveis && (
                    <ProfileImage
                            className='kanban-subtask-profile'
                            key={sub.pessoas_responsaveis.id}
                            src={
                                sub.pessoas_responsaveis.foto_perfil ? sub.pessoas_responsaveis.foto_perfil : profileImage
                            }
                            alt={''}
                            title={sub.pessoas_responsaveis.nome}
                        />
                )}
                {isEditingDate  && (
                    <DateInput
                        title={`${t('Editar data subtarefa')} input`}
                        defaultValue={moment(sub.data_conclusao).format('YYYY-MM-DD')}
                        type="date"
                        onBlur={exitEditingDate}
                        autoFocus
                        onFocus={handlefocus}
                        onKeyDown={keyDownDate}
                    />
                )}
                <DateText
                    isEditingDate={isEditingDate}
                    title={`${t('Editar data subtarefa')}`}
                    onClick={handleSetIsEditingDate}
                >
                    {sub.data_conclusao !== null && sub.data_conclusao !== "" 
                        ? moment(sub.data_conclusao).format('DD/MM')
                        : <MdOutlineDateRange size={14} />
                    }
                </DateText>
                
                <div className='kanban-subtask-modal-container' >
                    <MenuButton 
                        type='button'
                        title='botão menu'
                        onClick={switchMenu}
                        isOpen={editSubtaskId === sub.id}
                    >
                        <IoEllipsisVertical size={16} />
                    </MenuButton>

                    {editSubtaskId === sub.id && (
                        <>

                            <MenuModal isAssigning={isAssigning}>
                                <MenuButton 
                                    type='button'
                                    onClick={patchUpOrder}
                                >
                                    <MdKeyboardArrowUp size={16} />
                                    {t("Mover para cima")}
                                </MenuButton>
                                <MenuButton 
                                    type='button'
                                    onClick={patchDownOrder}
                                >
                                    <MdKeyboardArrowDown size={16} />
                                    {t("Mover para baixo")}
                                </MenuButton>
                                {isAssigning && (
                                    <AssignContainer>
                                        <SelectAssign 
                                            responsibles={team}
                                            saveChanges={patchSubtaskResponsible}
                                            setVisibility={setIsAssigning}
                                        />
                                    </AssignContainer>
                                )}
                                <MenuButton 
                                    
                                    type='button'
                                    onClick={handleAssigning}
                                >
                                    <MdPersonAddAlt size={16} style={{marginLeft: "2px"}} />
                                    {t("Atribuir")}
                                </MenuButton>
                                <MenuButton 
                                    type='button' 
                                    onClick={handleDeleteModal}
                                >
                                    <MdOutlineDeleteForever size={16} />
                                    {t('Remover')}
                                </MenuButton>
                            </MenuModal>
                        </>
                    )}
                </div>
            </div>
        </Card>
    )
}