import styled from "styled-components";

interface ContainerProps {
    isAssigning: boolean;
}

export const Container = styled.div<ContainerProps>`
    padding: 8px;
    padding-top: 0;

    >div {
        border-top: 1px solid #D9D9D9; //rgba(255, 255, 255, 0.2);
        padding-top: 8px;
    }

    .paginacao {
		max-height: 200px;
        min-height: ${({isAssigning}) => isAssigning ? "200px" : "initial"};
        height: initial !important;
		padding-top: 4px;
        padding-bottom: 4px;
        transition: all 0.2s;
	}
`;

export const SubtaskTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subtask-title {
        background: #D7F205;
        color: #000;
        border-radius: 4px;
        padding: 2px 8px;
        width: fit-content;
    }

    .subtask-expand {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: transparent;
        color: #fff;
        border: none;
        padding: 0;

        cursor: pointer;
    }
`;

export const SubtaskBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 12px;
`;

export const NewButton = styled.div`
    width: fit-content;
    color: #fff;
    background: #23D8B6;
    border: none;
    border-radius: 4px;
    padding: 2px 8px;
`;

export const CloseModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
`;