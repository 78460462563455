import { faCheck, faCircle, faClock, faPencilAlt, faRedo } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback } from "react";
import {  useHistory } from "react-router-dom";
import CheckboxList from "../../../../../../components/common/Checkboxes/CheckboxList";
import CopyButton from "../../../../../../components/common/CopyButton";
import { Task } from "../../../../../../models/Task";
import ProfileImg from '../../../../../../assets/images/profile.png';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDataTask } from "../../../../../../contexts/InfoTarefa/DataTask";
import 
{ 
	ActionsContainer, 
	ActionsIcon, 
	DeliveryTime, 
	DeliveryTimeIcon, 
	EntregaConteiner,
	EntregaContent, 
	ResponsaveisImg, 
	ResponsaveisImgContainer, 
	ResponsaveisName, 
	TableLink, 
	TableNameContainer, 
	TableNameContent, 
	TableNameProject, 
	TableResponsaveisContainer, 
	TableTdActions, 
	TableTdBoxCopy, 
	TableTdEntrega, 
	TableTdHour, 
	TableTdId, 
	TableTdName, 
	TableTdResponsaveis, 
	TableRow 
} 
	from "./styled";
import { t } from "i18next";


interface TbodyProps {
	dataAtual: number;
	addSelecionados: (selectedTask: string, checkedValue: any) => void;
	widthContainer: number;
	converterDuracaoTotal: (hour: string) => string;
	accessCode: string;
	setEditingHoras: React.Dispatch<React.SetStateAction<boolean>>;
	setModalHorasShow: (value: boolean) => void;
	setEditingTarefa: React.Dispatch<React.SetStateAction<boolean>>;
	setTarefaEditada: React.Dispatch<React.SetStateAction<Task | undefined>>;
	setModalTarefaShow: React.Dispatch<React.SetStateAction<boolean>>;
	loadingEncerrarTarefa: boolean;
	handleEncerrar: (id: string | number) => void;
	setHoraEditada: React.Dispatch<React.SetStateAction<Task | undefined>>;
	reabrirTarefa: (id: string | number) => void;

}

interface ReopenTaskProps {
	id: string | number;
	reabrirTarefa: (id: string | number) => void;
}

const ReOpenTask = ({ id, reabrirTarefa }: ReopenTaskProps) => {
	const handleOpenTask = useCallback(() => reabrirTarefa(id), [
		id,
		reabrirTarefa,
	]);
	return (
		<div style={{ textAlign: 'center' }}>
			<FontAwesomeIcon
				onClick={handleOpenTask}
				icon={faRedo}
				title="Reativar tarefa"
				className="tarefas-icon-clock"
			/>
		</div>
	);
};

interface IconDeliveryTimeProps {
	deliveryDate: string;
	currentDate: number;
}


const IconDeliveryTime = ({deliveryDate, currentDate}: IconDeliveryTimeProps) => {
	const checkDeliveryTime = () => {
		const finalDate = new Date(deliveryDate).getTime();
		if (finalDate > currentDate) {
			return 'onTime';
		}
		if (finalDate === currentDate) {
			return 'endsToday';
		}
		if (finalDate < currentDate) {
			return 'deadline';
		}
		return 'endsToday';
	};
	const Times = {
		onTime: { color: '#23d9b7', title: t('Dentro do prazo') },
		endsToday: { color: '#e9d018', title: t('Prazo excede hoje') },
		deadline: { color: '#db5461', title: t('Prazo excedido') },
	};
	const type = Times[checkDeliveryTime()];
	return (
		<DeliveryTime
			title={type?.title}
			color={type?.color}
		>
			<DeliveryTimeIcon icon={faCircle} fontSizeIcon="7px" title={undefined} type={undefined} colorIcon={undefined} pointer={undefined} />
		</DeliveryTime>
	);
};


const Tbody = ({
	dataAtual,
	addSelecionados,
	widthContainer,
	converterDuracaoTotal,
	accessCode,
	setEditingHoras,
	setModalHorasShow,
	setEditingTarefa,
	setTarefaEditada,
	setModalTarefaShow,
	loadingEncerrarTarefa,
	handleEncerrar,
	setHoraEditada,
	reabrirTarefa,
}: TbodyProps) => {
	const { dataTask } = useDataTask();
	const history = useHistory();


	return (
		<tbody>
			{dataTask.map(item => {
				return (
					<TableRow
						role={'row'}
						key={item.id}
						id={`tarefa-row${item.id}`}
						className="tarefas-list-tr"
					>
						<TableTdBoxCopy>
							<span>
								<CheckboxList
									itemID={item.id}
									className={`check-list-tarefas`}
									addSelecionados={addSelecionados}
								/>
							</span>
						</TableTdBoxCopy>
						<TableTdBoxCopy>
							<CopyButton
								iconTitle={'Copiar Tarefa'}
								textToCopy={`${window.location.href}/${item.id}/${item.nome.replace(/\s/g, '').replace(/[/\\]/g,'')}`} containerClassname={undefined} className={undefined}							/>
						</TableTdBoxCopy>
						<TableTdId>
							<span>{item.id}</span>
						</TableTdId>

						<TableNameContainer>
							<TableNameContent
							>
								<TableTdName status={item.status}
								>
									<TableLink
										status={item.status}
										to={{
											pathname: `tarefas/${item.id}/${item.nome
												.replace(/\s/g, '')
												.replace(/[/\\]/g,'')}`,
											state: {
												task: item,
												urlBack: history.location.pathname,
											},
										}}
									>
										{item.nome}
									</TableLink>
								</TableTdName>
								<TableNameProject status={item.status}>
									{item.projeto_nome}
								</TableNameProject>
							</TableNameContent>
						</TableNameContainer>
						<TableTdResponsaveis>
							<TableResponsaveisContainer>
								{item.pessoas_responsaveis.map((responsavel, index) => (
									<React.Fragment key={responsavel.id}>
										<ResponsaveisImgContainer>
											<ResponsaveisImg
												checkWidth={(index + 1) * 24 > widthContainer}
												isLengthOne={item.pessoas_responsaveis.length === 1}
			
												title={responsavel.nome}
												src={
													responsavel.foto_perfil !== null
														? responsavel.foto_perfil
														: ProfileImg
												}
												alt=""
											/>
										</ResponsaveisImgContainer>

										{item.pessoas_responsaveis.length === 1 && (
											<ResponsaveisName>
												{responsavel.nome}
											</ResponsaveisName>
										)}
									</React.Fragment>
								))}
								{item.pessoas_responsaveis.length * 24 > widthContainer && (
									<span className="tarefas-ellipsis">...</span>
								)}
								{item.pessoas_responsaveis.length === 0 && (
									<span>{t('Sem responsável')}</span>
								)}
							</TableResponsaveisContainer>
						</TableTdResponsaveis>
						<TableTdEntrega
						status={item.status}
						>
							<EntregaConteiner
							>
								<EntregaContent
								status={item.status}
								>
									{moment(item.data_conclusao).format('L')}
								</EntregaContent>

								<IconDeliveryTime currentDate={dataAtual} deliveryDate={item.data_conclusao} />
							</EntregaConteiner>
						</TableTdEntrega>
						<TableTdHour
						>
							{converterDuracaoTotal(item.duracao_total!)}
						</TableTdHour>
						{accessCode !== 'e' && (
							<TableTdActions>
								{item.status === 'started' || item.status === 'running' ? (
									<ActionsContainer>
										<div
											onClick={() => {
												setEditingHoras(true);
												setHoraEditada(item);
												setModalHorasShow(true);
											}}
										>
											<ActionsIcon
												icon={faClock}
												title={t("Adicionar registro de hora")}
											/>
										</div>
										<div
											id={`btn-tarefa-edit-${item.id}`}
											onClick={() => {
												setEditingTarefa(true);
												setTarefaEditada(item);
												setModalTarefaShow(true);
											}}
										>
											<ActionsIcon
												icon={faPencilAlt}
												title={t("Editar")}
											/>
										</div>
										<div
											onClick={() => {
												if (loadingEncerrarTarefa === false) {
													handleEncerrar(item.id);
												}
											}}
										>
											<ActionsIcon
												icon={faCheck}
												title={t("Encerrar")}
											/>
										</div>
									</ActionsContainer>
								) : null}
								{item.status === 'done' && (
									<ReOpenTask
										reabrirTarefa={reabrirTarefa}
										id={item.id}
										key={item.id}
									/>
								)}
							</TableTdActions>
						)}
					</TableRow>
				);
			})}
		</tbody>
	);
};

export default Tbody;