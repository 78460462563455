import React, { useCallback, useState } from "react"
import { AddExtraColumnButton } from "../AddExtraColumnButton";
import { AddExtraColumnModal } from "../AddExtraColumnModal";
import { AddWrapper } from "./styled";
import { ExtraColumn } from "../../../models/ExtraColumn";

export interface AddContainerModalProps {
    handleAfterPost: (newColumn: ExtraColumn) => void;
}

export function AddContainer({handleAfterPost}: AddContainerModalProps) {
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const closeModal = useCallback(() => {
        setIsAddModalOpen(false);
    }, [])
    
    return (
        <AddWrapper>
            <AddExtraColumnButton handleClick={() => setIsAddModalOpen(!isAddModalOpen)} />
            {isAddModalOpen && (
                <AddExtraColumnModal handleCloseModal={closeModal} handleAfterPost={handleAfterPost} />
            )}
        </AddWrapper>
    )
}
