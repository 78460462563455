import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useProject } from "../../../../contexts/Project";
import { ProjetoCard } from "../components/ProjetoCard";
import { useUser } from "../../../../contexts/User";
import {  ProjectBasic } from "../../../../models/Project";

interface GridProjectProps {
    setEditingProject: Dispatch<SetStateAction<boolean>>;
	setEditedProject: Dispatch<SetStateAction<ProjectBasic | undefined>>;
}

export default function GridProject({setEditingProject, setEditedProject}: GridProjectProps) {
    const { projects, selectProjectGrid } = useProject();
    const { userDetails} = useUser();

    useEffect(() => {
		selectProjectGrid();
	}, []);
    return (<>
    {Object.keys(projects)
    .sort((a, b) => {
        const nameA = projects[a]['nome']?.toLowerCase(),
            nameB = projects[b]['nome']?.toLowerCase();
        const conditionName = nameA > nameB ? 1 : 0;
        return nameA < nameB ? -1 : conditionName;
    })
    .map(key => {
        return (
            <ProjetoCard
                reloadID={null}
                acessCode={userDetails?.acesso_cod}
                usuario={userDetails}
                data={projects[key]}
                id={key}
                key={`lista-${key}`}
                handleFavoritos={() => {}}
                bgHeader={'projetos-bg-todos'}
                card_is_favorite={true}
                setEditingProjeto={setEditingProject}
                setProjetoEditado={setEditedProject}
            />
        );
    })}
    </>)
}