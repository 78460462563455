import React, { ForwardedRef, ReactNode, forwardRef, useEffect } from 'react';
import HeaderLink from '../../../common/HeaderLink';
import { PageContent, PageContentScroll } from './styled';
import H from 'history';
import { useHistory } from 'react-router-dom';
import { Project } from '../../../../models/Project';
import ProjetoService from '../../../../services/providers/project';
import { verifyCanEditProject } from '../../../../utils/funcoes/validarPerfil';
import { useUser } from '../../../../contexts/User';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import { projectInfoAtom } from '../../../../atoms/ProjectDetails';
import { LoaderPagination } from '../../../common/loaders/LoaderPagination';

export interface LocationProjectDetails {
	data?: Project,
	relatorioProjetos: any
}

interface PageContainerProps {
    projectId: number;
    location: H.Location<LocationProjectDetails>;
    children:  ReactNode;
	loading?: boolean;
	expandTable?: boolean;
}

const PageContainerProjectDetail = forwardRef(({children, projectId, expandTable, location, loading}: PageContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
	const [projectInfo, setProjectInfo] = useAtom(projectInfoAtom);
    const {userDetails} = useUser();

    const history = useHistory();
    useEffect(() => {
		if (location.state?.data) {
			setProjectInfo(location.state.data);

		} else {
			const projetoService = new ProjetoService();
			projetoService.getProjectDetail({id: projectId, serializer: "none"}).then(details => {
				const canEdit = verifyCanEditProject(userDetails, details.is_member, details.admins);
				if(canEdit) {
					projetoService.getProject(projectId).then((result) => {
						setProjectInfo(result)
					}).catch(() => {
						history.push('/registros/projetos');
					});
				} else {
					history.push('/registros/projetos');
				}

			}).catch(err => {
				toast.error(err);
                history.push('/registros/projetos');

			});
		}

	}, [projectId]);
    return (
        <>
        <HeaderLink projectId={projectId} type='projectDetails' />
       <PageContentScroll >

       <PageContent expandTable={!!expandTable} ref={ref} className="print-background">
           {!projectInfo || (projectId !== projectInfo?.id) || loading ? <LoaderPagination showLoader={false} loading={true} /> : children}
        </PageContent>
       </PageContentScroll>
        </>
    )

})

PageContainerProjectDetail.displayName = "PageContainerProjectDetail"

export default PageContainerProjectDetail;