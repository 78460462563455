import styled from 'styled-components';

export const TableRow = styled.tr`
	height: 40px;
	width: 7%;
	text-align: center;
	position: relative;
	border-bottom: 2px solid #e9eef1;
	font-size: 14px;
	line-height: normal;
	background-color: #fff;
	color: #406173;
	font-weight: 700;
	@media (max-width: 768px) {
		font-size: 10px;
	}
`;

interface TableThProps {
	width: string;
	display?: string;
}

export const TableThEmpty = styled.th<TableThProps>`
	height: 40px;
	font-size: 14px;
	width: ${({ width }) => width};
	text-align: center;
	padding: 6px;
`;

export const TableTh = styled.th<TableThProps>`
	text-align: left;
	width: ${({ width }) => width};
	@media (max-width: 768px) {
		${({ display }) => display && `display: ${display}`};
	}
`;

export const TableThEntrega = styled.th<TableThProps>`
	text-align: center;
	padding-right: 20px;
	width: ${({ width }) => width};
	@media (max-width: 768px) {
		display: none;
	}
`;
export const TableThHoras = styled.th<TableThProps>`
	text-align: center;
	width: ${({ width }) => width};
`;
