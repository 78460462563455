import React, { useEffect, useState } from 'react';

import Login from '../index';
import CadastroService from '../../../services/security/cadastro';
// import CheckBox from '../components/CheckBox';
import BannerLogin from '../components/BannerLogin';
import FormInput from '../components/FormInput';
import ButtonForm from '../components/ButtonForm';

import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';

import './style.css';
import { CadastroSchema } from './validation';
import TermCheckTxt from '../components/TermCheckTxt';

import '../../../i18n';
import { useTranslation } from 'react-i18next';

const Cadastro = () => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);
	const [termoPrivacidade, setTermoPrivacidade] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

	useEffect(() => {
		window.dataLayer.push({
			event: 'acesso_cadastro'
		  });
	}, [])

	const _postCadastro = params => {
		setLoading(true);
		const cadastroService = new CadastroService();
		cadastroService.postCadastro(
			params,
			response => {
				if (response) {
				}
				setLoading(false);
			},
			error => {
				setLoading(false);
				toast.error(error.toString());
			},
		);
	};

	return (
		<>
			<Login type="cadastro">
				<div className="cadastro-limiter">
					<Formik
						className="form-grid"
						initialValues={{
							email: '',
							password: '',
							password_confirm: '',
							first_name: '',
							nome_empresa: '',
							assinou_politica_uso: false
						}}
						validationSchema={CadastroSchema}
						onSubmit={(values, actions) => {
							const form_data = new FormData();
							for (var key in values) {
								if (key === 'assinou_politica_uso') {
									form_data.append(key, termoPrivacidade);
								} else {
									form_data.append(key, values[key]);
								}
							}
							actions.setSubmitting(true);
							_postCadastro(form_data);
						}}
						// onSubmit={(values, actions) => {
						// 	actions.setSubmitting(true);
						// 	_postCadastro(values);
						// }}
					>
						{({ errors, touched, values }) => (
							<Form className="login-form-container">
								<BannerLogin
									logoMargin={'logo-cadastro-margin'}
									titulo={t("Cadastro de empresa")}
									subTitulo={t("Cadastre a conta da sua empresa e comece a gerenciar sua equipe")}
								/>
								
								<FormInput
									nome={t("Empresa")}
									campo="nome_empresa"
									autocomplete="off"
									errors={errors.nome_empresa}
									touched={touched.nome_empresa}
								/>
								<FormInput
									nome={t("Administrador")}
									campo="first_name"
									autocomplete="off"
									errors={errors.first_name}
									touched={touched.first_name}
								/>

								<FormInput
									id="input-email-cadastro"
									nome="E-mail"
									campo="email"
									autocomplete="off"
									errors={errors.email}
									touched={touched.email}
									readonly={'readonly'}
									onClick={() =>
										document
											.getElementById('input-email-cadastro')
											.removeAttribute('readonly')
									}
								/>

								<FormInput
									id="input-password-cadastro"
									nome={t("Senha")}
									campo="password"
									autocomplete="off"
									errors={errors.password}
									touched={touched.password}
									showPassword={showPassword}
									setShowPassword={setShowPassword}
									type={showPassword ? 'text' : 'password'}
									readonly={'readonly'}
									onClick={() =>
										document
											.getElementById('input-password-cadastro')
											.removeAttribute('readonly')
									}
								/>

								<FormInput
									id="input-password-confirm-cadastro"
									nome={t("Confirmar senha")}
									campo="password_confirm"
									autocomplete="off"
									errors={errors.password_confirm}
									touched={touched.password_confirm}
									showPassword={showPasswordConfirm}
									setShowPassword={setShowPasswordConfirm}
									type={showPasswordConfirm ? 'text' : 'password'}
									readonly={'readonly'}
									onClick={() =>
										document
											.getElementById('input-password-confirm-cadastro')
											.removeAttribute('readonly')
									}
								/>


								{/* <div
									onClick={() => setTermoPrivacidade(!termoPrivacidade)}
									style={{ display: 'flex', marginTop: '16px' }}
								>
									<CheckBox checked={termoPrivacidade} />

									<span className="login-termo">
										Eu li e concordo com os{' '}
										<NavLink
											to="/politicas_de_uso"
											className="cadastro-navlink-termos"
										>
											Termos de uso e Privacidade
										</NavLink>{' '}
										do site
									</span>
								</div> */}
								<TermCheckTxt
									termoPrivacidade={termoPrivacidade}
									setTermoPrivacidade={setTermoPrivacidade}
								/>
								<ButtonForm loading={loading} btnType="cadastro" />
							</Form>
						)}
					</Formik>
				</div>
			</Login>
		</>
	);
};

export default Cadastro;
