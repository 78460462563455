import React, { Suspense, useState, useCallback, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import ModalIntegracoes from './ModalIntegracoes';
import ModalInfoPessoal from './ModalInfoPessoal';
import ModalFeedback from './ModalFeedback';
import ModalContaPessoal from './ModalContaPessoal';
import HeaderContainer from '../../../components/containers/Header';
import SideBarContainer from '../../../components/containers/SideBar';
import MainContainer from '../../../components/containers/MainContainer';

import 'bootstrap/dist/css/bootstrap.min.css';

import './style.css';
import { useUser } from '../../../contexts/User';
import { StopwatchService } from '../../../services/providers/stopwatch';
import { Loader } from '../../Registros/Tarefas/InfoTarefa/styled';
export default function AppTemplate({ routes }) {
	const [modalIntegracoesShow, setModalIntegracoesShow] = useState(false);
	const [modalInfoPessoaShow, setModalInfoPessoaShow] = useState(false);
	const [modalContaShow, setModalContaShow] = useState(false);
	const [modalFeedbackShow, setModalFeedback] = useState(false);
	const [editingConta, setEditingConta] = useState(true);
	const [autoRefresh, setAutoRefresh] = useState(true);
	const {userDetails, setUserDetails, loadingUser} = useUser();


	useEffect(() => {
		getUserStopwatch();
	}, []);

	const getUserStopwatch = () => {
		const stopwatchService = new StopwatchService();
		stopwatchService.getStopwatch({data: '',id_tarefa: ''});
	}

	const handlePostPatchInfoPessoa = useCallback(data => {
		setUserDetails({
				acesso_cod: data.acesso,
				apelido: data.apelido,
				foto_perfil: data.foto_perfil,
				funcao: data.funcao,
				id_pessoa: data.id,
				username: data.nome,
				has_finished_tour: data.has_finished_tour
			});
	}, []);

	return (
		<div className="principal-container">
			{modalIntegracoesShow ? (
				<ModalIntegracoes
					modalInfoPessoaShow={setModalIntegracoesShow}
					modalIsShowing={modalIntegracoesShow}
					itemSelecionado={userDetails}
				/>
			) : null}
			{modalInfoPessoaShow ? (
				<ModalInfoPessoal
					modalInfoPessoaShow={setModalInfoPessoaShow}
					modalIsShowing={modalInfoPessoaShow}
					// editingInfoPessoa={editingInfoPessoa}
					itemSelecionado={userDetails}
					handlePostPatchInfoPessoa={handlePostPatchInfoPessoa}
				/>
			) : null}
			{modalFeedbackShow ? (
				<ModalFeedback
					modalFeedbackShow={setModalFeedback}
					modalIsShowing={modalFeedbackShow}
					// editingInfoPessoa={editingInfoPessoa}
					itemSelecionado={userDetails}
					handlePostFeedback={handlePostPatchInfoPessoa}
				/>
			) : null}

			{modalContaShow ? (
				<ModalContaPessoal
					modalInfoPessoaShow={setModalContaShow}
					modalIsShowing={modalContaShow}
					editingInfoConta={editingConta}
				/>
			) : null}
			<button
				id="show-infoPessoa-modal"
				style={{ display: 'none' }}
				disabled={!userDetails}
				onClick={() => {
					setEditingConta(true);
					setModalInfoPessoaShow(true);
				}}
			></button>
			<button
				id="show-feedback-modal"
				style={{ display: 'none' }}
				onClick={() => {
					setModalFeedback(true);
				}}
			></button>
			<button
				id="show-conta-modal"
				style={{ display: 'none' }}
				onClick={() => {
					setEditingConta(true);
					setModalContaShow(true);
				}}
			></button>
			<button
				id="show-integracoes-modal"
				style={{ display: 'none' }}
				onClick={() => {
					setEditingConta(true);
					setModalIntegracoesShow(true);
				}}
			></button>

			<div className="display-header">
				<HeaderContainer userDetails={userDetails} />
			</div>
			<div className="row m-0" style={{ height: '100%' }}>
				<div className="display-sideBar">
					<SideBarContainer userDetails={userDetails} />
				</div>
				<MainContainer autoRefresh={autoRefresh}>
				{!loadingUser ? (
					<Suspense>
						<Switch>
							{routes.map((route, index) => {
								return route.component ? (
									<Route
										key={route.path}
										path={route.path}
										exact={route.exact}
										render={props => {
											setAutoRefresh(
												route.autoRefresh !== undefined
													? route.autoRefresh
													: true,
											);
											return <route.component {...props} />;
										}}
									/>
								) : null;
							})}
						</Switch>
					</Suspense>
				) : (
					<div style={{display: "flex", alignItems: "center", justifyContent: "center", height:"100%"}}>
						<Loader />
					</div>
				)}
				</MainContainer>
			</div>
		</div>
	);
}
