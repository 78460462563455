import styled from 'styled-components';
import { theme } from '../../../styles/theme';

interface ContainerProps { 
	loadingFilters: boolean;
	kanban: boolean;
}

export const Container = styled.div<ContainerProps>`
	margin: 2rem;
	cursor: ${({loadingFilters}) => loadingFilters ? "progress" : "initial"};
	${({kanban}) => kanban && `
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;
		height: calc(100vh - (7.8vw + 80px));	
		margin: 2rem 2rem 0 2rem;
		@media (max-width: 1920px) {
			height: calc(100vh - 220px);	
		}
		@media (max-width: 1600px) {
			height: calc(100vh - 200px);	
		}
		@media (max-width: 400px) {
			height: calc(100vh - 220px);	
		}
	`}

	
`;

export const Header = styled.div`
	/* height: 2rem; */
	.client-name {
		font-size: 0.75rem;
		font-weight: 700;
		line-height: 0.863rem;
		letter-spacing: 0.016rem;
		height: 0.875rem;
		color: #496070;
	}
`;

export const NavlinkButton = styled.button`
	border: none;
	background-color: ${theme.colors.button.darkBtn};
	border-radius: 10px;
	color: white;
	padding: 5px 15px;
	font-weight: 600;
	font-size: small;
	width: 120px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
	&:disabled {
		opacity: 0.8;
		cursor: auto;
	}
`;
