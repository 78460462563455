import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../../../../../components/common/Icons/icon';

export const TableRow = styled.tr`
	border-bottom: 1px solid #dae2e6;
	height: 40px;
	font-size: 14px;
	color: #8099a7;
	background: #f7f6f5;
	&:hover {
		border-bottom: 1px solid #cbd7dd;
		color: #6d8c9c;
		background: #d3f3f7;
		white-space: normal;
	}
`;

export const TableTdBoxCopy = styled.th`
	height: 40px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	min-width: 10%;
	padding: 10px;
	color: #8099a7;
	&:hover {
		color: #6d8c9c;
		white-space: normal;
	}
`;

export const TableTdId = styled.th`
	height: 40px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	padding: 10px;
	color: #8099a7;

	&:hover {
		color: #6d8c9c;
		white-space: normal;
	}
`;

export const TableNameContainer = styled.th`
	padding: 6px;
	text-align: left;
	font-weight: 500;
	overflow: hidden;
`;

export const TableNameContent = styled.div`
	line-height: 16px;
`;

interface TableTdProps {
	status: string;
}

interface TableLinkProps {
	status: string;
}
export const TableTdName = styled.div<TableTdProps>`
	text-decoration: ${({ status }) =>
		status === 'done' ? 'line-through' : 'blink'};
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	&:hover {
		white-space: normal;
	}
`;

export const TableLink = styled(Link)<TableLinkProps>`
	text-decoration: ${({ status }) =>
		status === 'done' ? 'line-through' : 'blink'};
	color: #8099a7;
	background-color: initial;
	&:hover {
		text-decoration: ${({ status }) =>
			status === 'done' ? 'line-through' : 'blink'};
		color: #8099a7;
	}
`;

export const TableNameProject = styled.div<TableTdProps>`
	text-decoration: ${({ status }) =>
		status === 'done' ? 'line-through' : 'blink'};
	opacity: 0.6;
	font-weight: 500;
	font-size: 12px;
	color: #5f5f5f;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const TableTdResponsaveis = styled.th`
	height: 40px;
	font-size: 14px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 5%;
	font-weight: 500;
	color: #8099a7;
	&:hover {
		color: #6d8c9c;
		white-space: normal;
	}
`;

export const TableResponsaveisContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

export const ResponsaveisImgContainer = styled.span``;

interface ResponsaveisImgProps {
	checkWidth: boolean;
	isLengthOne: boolean;
}

export const ResponsaveisImg = styled.img<ResponsaveisImgProps>`
	margin-right: ${({ isLengthOne }) => (isLengthOne ? '' : '-8px')};
	display: ${({ checkWidth }) => (checkWidth ? 'none' : '')};
	border-radius: 50%;
	width: 24px;
	height: 24px;
`;

export const ResponsaveisName = styled.span`
	margin-left: 4px;
`;

export const TableTdEntrega = styled.th<TableTdProps>`
	text-decoration: ${({ status }) =>
		status === 'done' ? 'line-through' : 'blink'};
	width: 15%;
	position: relative;
	text-align: center;
	font-weight: 500;
	color: #8099a7;
	font-size: 14px;
	&:hover {
		color: #6d8c9c;
		white-space: normal;
	}
`;

export const EntregaConteiner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	text-align: center;
	font-size: 14px;
`;

export const EntregaContent = styled.div<TableTdProps>`
	text-decoration: ${({ status }) =>
		status === 'done' ? 'line-through' : 'blink'};
	width: 70px;
	font-weight: 500;
	text-align: center;
	font-size: 14px;
`;

interface DeliveryTimeProps {
	color: string;
}

export const DeliveryTime = styled.div<DeliveryTimeProps>`
	padding-left: 5px;
	margin-left: 5px;
	color: ${({ color }) => color};
	text-align: center;
	font-weight: 500;
	font-size: 14px;
`;

export const TableTdHour = styled.th`
	text-align: center;
	font-weight: 500;
	color: #8099a7;
	&:hover {
		color: #6d8c9c;
		white-space: normal;
	}
`;

export const TableTdActions = styled.th`
	text-align: left;
	font-weight: 500;
`;

export const ActionsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const ActionsIcon = styled(FontAwesomeIcon)`
	font-size: 15px;
	color: rgba(66, 97, 114, 0.5019607843137255);
	cursor: pointer;
	margin-right: 10px;
`;

export const DeliveryTimeIcon = styled(Icon)`
	width: 100%;
	fill: #00f;
	max-width: 11px;
`;
