export const dinamicPagination = ({
    element,
    page,
    totalPages,
    loading,
    callBack
}) => {
    if (totalPages <= page) {
        return;
    } else {
        if (!loading) {
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                const currentPage = page + 1;

                callBack(currentPage);
                element.scrollTop -= 10;
            }
        }
    }
};