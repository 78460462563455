import React,  { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { Responsible } from '../../models/Responsible';
import { Subtask } from '../../models/Subtask';
import { useFilters, initialValues as filterInitialValues } from '../Filter/Task/Filter';
import {  KanbanContextInitialType, KanbanProviderProps, PaginationInfo, RequestingAddColumn, TaskFunctionsProps, TaskManaged } from './types';
import { v4 as uuid } from 'uuid';
import { status } from '../../pages/Registros/Tarefas/Kanban/Kanban';
import { KanbanService } from '../../services/providers/kanban';
import { setAddedItems, updateColumnsValues } from '../../pages/Registros/Tarefas/Kanban/functions';
import { verifyCanEditProject } from '../../utils/funcoes/validarPerfil';
import { toast } from 'react-toastify';
import { Task } from '../../models/Task';
import { removeDuplicates } from '../../utils/funcoes/arraysFunctions';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useUser } from '../User';
import { ColumnManaged, ColumnsManager } from '../../atoms/Kanban/types';
import { handleInitialOrderColumns } from '../../utils/funcoes/kanban';
import ProjetoService from '../../services/providers/project';
import { Project } from '../../models/Project';

export const initialValues: KanbanContextInitialType = {
	columnsManager: {
		project: {
			admins: [],
			cliente: {id: 0, nome: "", logo_cliente: null},
			colunas_kanban_criadas: false,
			created_at: "",
			deleted: false,
			deleted_at: "",
			empresa: 0,
			horas_previstas: 0,
			id: 0,
			id_azure: "",
			inicio_previsto: "",
			investimento: "",
			is_favorite: false,
			is_member: false,
			item_ativo: false,
			nome: "",
			notas: "",
			primeira_coluna: "",
			tags: [],
			tarefas: [],
			termino_previsto: "",
			time: [],
			updated_at: "",
		},
		columns: {},
		columnsOrder: [],
	},
	handleSetSubtasks: () => {},
	selectKanban: () => {},
	selectTask: () => {},
	setColumnsManager: () => {},
	subtasksByTask: () => [],
	paginationInfoByTask: () => ({
		loading: false,
		totalPages: 0,
		currentPage: 0
	}),
	setPaginantionInfo: () => {},
	setLoadingPagination: () => {},
	setTeam: () => {},
	team: [],
	selectedHeaderLink: "",
	handleGetInitialValues: async () => {},
	setLoadingProject: () => {},
	setLoadingKanban: () => {},
	loading: true,
	canEdit: false,
	setCanEdit: () => {},
	isAddingColumn: false,
	setIsAddingColumn: () => {},
	requestingAddColumn: { componentId: null},
	setRequestingAddColumn: () => {},
	handleGetFirstColumnPaginate:  async () => {},
    handleGetLastColumnPaginate: async () => {},
    handleGetColumnsPaginate: async () => {},
	handleAddTaskModal: () => {},
	loadingFilters: false
};

export const KanbanContext = createContext(initialValues);

const KanbanProvider = ({ children }: KanbanProviderProps) => {
	const { t } = useTranslation();
	const [selectedHeaderLink, setSelectedHeaderLink] = useState<'tarefas' | 'kanban'>('tarefas');
	const [team, setTeam] = useState<Responsible[]>(initialValues.team);
	const [columnsManager, setColumnsManager] = useState<ColumnsManager<TaskManaged>>(initialValues.columnsManager);
	const [canEdit, setCanEdit] = useState<boolean>(initialValues.canEdit);
	const [isAddingColumn, setIsAddingColumn] = useState<boolean>(initialValues.isAddingColumn);
	const [requestingAddColumn, setRequestingAddColumn] = useState<RequestingAddColumn>(initialValues.requestingAddColumn);
	const [loadingProject, setLoadingProject] = useState<boolean>(true);
	const [loadingKanban, setLoadingKanban] = useState<boolean>(true);
	const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

	const kanbanService = new KanbanService();
	const projectService = new ProjetoService();

	const {pathname} = useLocation();
	const { userDetails} = useUser();

	const isOkr = pathname === '/okrs/kanban'

	const selectTask = useCallback(() => setSelectedHeaderLink('tarefas'), [setSelectedHeaderLink]);
	const selectKanban = useCallback(() => setSelectedHeaderLink('kanban'), [setSelectedHeaderLink]);

	const subtasksByTask = useCallback((taskId: number | string, columnId: number | string) => {
		const tempSubtasks = columnsManager.columns[columnId].items.find(task => task.id === taskId)?.sub_tarefas;

		if(tempSubtasks){
			return tempSubtasks
		}
		return [];
	}, [columnsManager])

	const {
		selectedProject,
		setSelectedClient,
		setSelectedProject
	} = useFilters();
	const filterContext = useFilters();


	const updateStateTask = () => {
		if (!Array.isArray(selectedProject)) {
			if (Object.prototype.hasOwnProperty.call(selectedProject, 'value')) {
				setSelectedProject([selectedProject]);
			} else {
				setSelectedProject(filterInitialValues.selectedProject);
			}
		}

	}

	const updateStateKanban = () => {
		if(Array.isArray(selectedProject)) {
			if(selectedProject.length > 0) {
				setSelectedProject(selectedProject[0]);
			}else {
				setSelectedProject({label: 'Projetos', value: ''})
			}
		}
	}
	useEffect(() => {
		if(selectedHeaderLink === 'tarefas') {
			updateStateTask();
		}else if(selectedHeaderLink === 'kanban') {
			updateStateKanban();
		}
	}, [selectedHeaderLink])

	useEffect(() => {
		if(pathname === '/registros/kanban' && !Array.isArray(selectedProject) && selectedProject.value === '' && columnsManager.project?.id !== 0) {
			setSelectedProject({label: columnsManager?.project?.nome ?? "", value: columnsManager.project?.id ?? ""})
		}
	}, [pathname, selectedProject, columnsManager])

	const getProject = async () => {
		try {
			const selected = Array.isArray(selectedProject) ? selectedProject : [selectedProject];

			const [project, projectDetail]  =
				await Promise.all([
					projectService.getProject(parseInt(selected[0].value?.toString()), ''),
					projectService.getProjectDetail({
						id: parseInt(selected[0].value?.toString()),
						serializer: 'none'
					})
				])
				
			setTeam(projectDetail.time)

			const fullProject: Project = {
				...project,
				...projectDetail
			}

			return fullProject;
		} catch(err ) {
			setSelectedClient([{value: '', label: t('Clientes')}]);
			setLoadingProject(false);
			toast.error((err as Error).message)
		}
	};
	
	const handleGetInitialValues = useCallback(async (loading: boolean = true) => {
		setLoadingFilters(true);
		const project = Array.isArray(selectedProject) ? selectedProject : [selectedProject];
		const projectId = project[0].value
		try {
			setLoadingKanban(loading);
			setLoadingProject(loading);
			const [
				projectColumnsResponse,
				todoTasksResponse,
				doneTasksResponse,
				projectResponse,
			] = await Promise.all([
				kanbanService.getColumnsByProject(filterContext, isOkr),
				kanbanService.getTasksWithNoColumn({
					filterContext,
					isOkr,
					projectId: projectId as number
				}),
				kanbanService.getTasksWithNoColumn({
					filterContext,
					isOkr,
					projectId: projectId as number,
					status: status[2].title
				}),
				getProject(),
			]);

		if(projectResponse) {
			const initialColumnId = uuid();
			let tempColumns = {
				[initialColumnId]: {
					name: t('A fazer'),
					status: status[0],
					totalPages: 1,
					order: 0,
					addedItemsIds: [],
					doneTotalPages: 0,
					id: '',
					items: [],
					nome: "",
					ordenacao: 0,
					page: 0,
					removedQnt: 0,
				} as ColumnManaged<TaskManaged>,
			};

			tempColumns[initialColumnId] = {
				...tempColumns[initialColumnId],
				id: initialColumnId,
				nome: projectResponse.primeira_coluna || tempColumns[initialColumnId].nome,
				totalPages: todoTasksResponse.total_pages,
				page: 1,
				addedItemsIds: [],
				removedQnt: 0,
				items: todoTasksResponse.results,
			};

			projectColumnsResponse.results.forEach(item => {
				tempColumns = {
					...tempColumns,
					[item.id]: {
						id: item.id,
						nome: item.nome,
						status: status[0],
						order: item.ordenacao,
						totalPages: item.total_pages,
						page: 1,
						removedQnt: 0,
						addedItemsIds: [],
						items: item.tarefas,
					},
				};
			});
			const newColumnOrder = handleInitialOrderColumns(tempColumns);
			tempColumns = updateColumnsValues(tempColumns, newColumnOrder);

			tempColumns[newColumnOrder[newColumnOrder.length - 1]] = {
				...tempColumns[newColumnOrder[newColumnOrder.length - 1]],
				doneTotalPages: doneTasksResponse.total_pages,
				items: [
					...tempColumns[newColumnOrder[newColumnOrder.length - 1]].items,
					...doneTasksResponse.results,
				],
			};

			let prevTasks: TaskManaged[] = [];

			columnsManager.columnsOrder.forEach(columnId => {
				prevTasks = [...prevTasks, ...columnsManager.columns[columnId].items];
			})

			newColumnOrder.forEach(columnId => {
				tempColumns[columnId].items = tempColumns[columnId].items
					.map(item => {
						const tempTask = prevTasks.find(prev => prev.id === item.id);
						return tempTask ? tempTask : item;
					})
			})
			setColumnsManager({
				project: {...projectResponse},
				columns: tempColumns,
				columnsOrder: newColumnOrder,
			});
			setCanEdit(
				verifyCanEditProject(
					userDetails,
					projectResponse.is_member,
					projectResponse.admins,
				),
			);
		}

			setLoadingKanban(false);
			setLoadingProject(false);
			setLoadingFilters(false);
		} catch (err) {
			setLoadingKanban(false);
			setLoadingFilters(false);
			setLoadingProject(false);
			console.log(err);

			toast.error(t('Erro ao carregar o Kanban!'));
		}
	}, [selectedProject, kanbanService, filterContext, status, getProject, uuid, t, handleInitialOrderColumns, updateColumnsValues,columnsManager, verifyCanEditProject, userDetails])

	const filterAddedItems = (results: Task[], column: ColumnManaged<TaskManaged>) => {
		if (column.addedItemsIds.length > 0) {
			return results.filter((item) => column.addedItemsIds.every((el) => el != item.id));
		} else {
			return results;
		}
	}

	const handleGetFirstColumnPaginate = async (page: number) => {
		const { project } = columnsManager;
		if(project) {
			const columnsTaskResponse = await kanbanService.getTasksWithNoColumn({
				filterContext,
				page,
				projectId: project.id,
				isOkr
			});

			return ({
				paginateItems: [...filterAddedItems(columnsTaskResponse.results, columnsManager.columns[columnsManager.columnsOrder[0]])],
				totalPages: columnsTaskResponse?.total_pages,
				doneTotalPages: 0
			})
		}
	};

	const handleGetLastColumnPaginate = async (page: number, column: any) => {
		const { project } = columnsManager;
		if(project) {
			const columnsTaskResponse = column.totalPages < page
			? { results: [], total_pages: 0 }
			: await kanbanService.getTasksByColumn({
				filterContext,
				page,
				projectId: project.id,
				columnId: column.id,
				isOkr
			})
  
			const doneTasksResponse = column.doneTasksPages < page
				? { results: [], total_pages: 0 }
				: await kanbanService.getTasksWithNoColumn({
					filterContext,
					page,
					projectId: project.id,
					status: status[2].title,
					isOkr
				})
	
			return ({
				paginateItems: [...filterAddedItems(columnsTaskResponse.results, column), ...filterAddedItems(doneTasksResponse.results, column)],
				totalPages: columnsTaskResponse?.total_pages,
				doneTotalPages: doneTasksResponse.total_pages
			})
		}
		
	};

	const handleGetColumnsPaginate = async (page: number, column: ColumnManaged<TaskManaged>) => {
		const { project } = columnsManager
		if(project) {
			const prevPaginates: any[] = column.removedQnt > 0 && page > 1
			? (await handleGetColumnsPaginate(
				page - 1,
				{ ...column, removedQnt: column.removedQnt - (column.items.length / page) * -1 }
				)).paginateItems
			: [];

		const columnsTaskResponse = await kanbanService.getTasksByColumn({
			filterContext,
			page,
			projectId: project.id,
			columnId: column.id,
			isOkr
		});

		return ({
			paginateItems: [...prevPaginates.slice(column.removedQnt * -1), ...filterAddedItems(columnsTaskResponse.results, column)],
			totalPages: columnsTaskResponse?.total_pages,
			doneTotalPages: 0
		})
		}
		return ({
			paginateItems: [],
			totalPages: null,
			doneTotalPages: 0
		})
	};


	const paginationInfoByTask = (taskId: number | string, columnId: number | string) => {
		const tempTask = columnsManager.columns[columnId].items.find(task => task.id === taskId);

		if(tempTask){
			const tempParsedTask = {
				loading: tempTask.loading ? tempTask.loading : false,
				totalPages: tempTask.totalPages ? tempTask.totalPages : 1,
				currentPage: tempTask.currentPage ? tempTask.currentPage : 1,
			}

			return tempParsedTask
		}

		return {
			loading: false,
			totalPages: 0,
			currentPage: 0
		};
	};

	const setPaginantionInfo = ({ columnId, taskId, value }: TaskFunctionsProps<PaginationInfo>) => {
		const tempColumns = {...columnsManager.columns};

		const tempTaskIndex = tempColumns[columnId].items.findIndex(task => task.id === taskId);
		const tempTask = tempColumns[columnId].items[tempTaskIndex];

		if(tempTask) {
			tempColumns[columnId].items.splice(tempTaskIndex, 1, {
				...tempTask,
				currentPage: value.currentPage !== undefined ? value.currentPage : tempTask.currentPage,
				totalPages: value.totalPages !== undefined ? value.totalPages : tempTask.totalPages,
				loading: value.loading !== undefined ? value.loading : tempTask.loading,
			});

			setColumnsManager({
				...columnsManager,
				columns: tempColumns
			});
		}
	};

	const setLoadingPagination = ({ columnId, taskId, value }: TaskFunctionsProps<boolean>) => {
		const tempColumns = {...columnsManager.columns};

		const tempTaskIndex = tempColumns[columnId].items.findIndex(task => task.id === taskId);
		const tempTask = tempColumns[columnId].items[tempTaskIndex];

		if(tempTask) {
			tempColumns[columnId].items.splice(tempTaskIndex, 1, {
				...tempTask,
				loading: value
			});

			setColumnsManager({
				...columnsManager,
				columns: tempColumns
			});
		}
	};

	const handleSetSubtasks = (
		subtasks: Subtask[],
		taskId: number | string,
		columnId: number | string,
		valueToAdd = 0,
		notCompleteToAdd = 0
	) => {
		const tempSubtasks = removeDuplicates(subtasks, "id") as Subtask[];
		const tempColumns = {...columnsManager.columns};

		const tempTaskIndex = tempColumns[columnId].items.findIndex(task => task.id === taskId);
		const tempTask = tempColumns[columnId].items[tempTaskIndex];

		if(tempTask) {
			tempColumns[columnId].items.splice(
				tempTaskIndex,
				1,
				{
					...tempTask,
					sub_tarefas: [...tempSubtasks],
					total_subtarefas: tempTask.total_subtarefas + (valueToAdd),
					total_subtarefas_incompletas: tempTask.total_subtarefas_incompletas + notCompleteToAdd
				}
				);

			setColumnsManager({
				...columnsManager,
				columns: tempColumns
			});
		}
	};

	const handleAddTaskModal = (response: Task) => {
		const selected = Array.isArray(selectedProject) ? selectedProject : [selectedProject];
		if(selected[0].value === response.projeto && columnsManager.project && columnsManager.columnsOrder[0]){
			const column = columnsManager.columnsOrder[0]
			const destColumn = columnsManager.columns[column];
			const destItems = [...destColumn.items];

			const task: Task = {
				id: response.id,
				nome: response.nome,
				status: destColumn.status.title,
				ordenacao: destItems.length,
				pessoas_responsaveis: [],
				data_conclusao: moment().add(1, 'days').format("YYYY-MM-DDThh:mm:ss"),
				coluna_kanban: null,
				projeto: columnsManager.project.id,
				sub_tarefas: [],
				total_subtarefas: 0,
				total_subtarefas_incompletas: 0,
				projeto_nome:columnsManager.project.nome,
				};

				const tempItems = [...destItems, {...task, id: response.id}];
					const newColunm: ColumnManaged<TaskManaged> = {
						...destColumn,
						addedItemsIds: setAddedItems(destColumn, tempItems),
						items: tempItems,
					};
					const newColumnsManager = {
						...columnsManager,
						columns: {
							...columnsManager.columns,
							[column]: newColunm,
						},
						columnsOrder: columnsManager.columnsOrder,
					}
					setColumnsManager(newColumnsManager);
		}
	};

	const kanbanValue = useMemo(
		() => ({
			loadingFilters,
			setLoadingPagination,
			setPaginantionInfo,
			selectedHeaderLink,
			selectTask,
			selectKanban,
			columnsManager,
			setColumnsManager,
			handleSetSubtasks,
			subtasksByTask,
			paginationInfoByTask,
			team,
			setTeam,
			canEdit,
			handleGetInitialValues,
			isAddingColumn,
			loading: loadingKanban || loadingProject,
			requestingAddColumn,
			setCanEdit,
			setIsAddingColumn,
			setLoadingKanban,
			setLoadingProject,
			setRequestingAddColumn,
			handleGetColumnsPaginate,
			handleGetFirstColumnPaginate,
			handleGetLastColumnPaginate,
			handleAddTaskModal
		}),
		[
				loadingFilters,
				setLoadingPagination,
				setPaginantionInfo,
				selectedHeaderLink,
				selectTask,
				selectKanban,
				columnsManager,
				setColumnsManager,
				handleSetSubtasks,
				subtasksByTask,
				paginationInfoByTask,
				team,
				setTeam,
				canEdit,
				handleGetInitialValues,
				isAddingColumn,
				loadingKanban,
				loadingProject,
				requestingAddColumn,
				setCanEdit,
				setIsAddingColumn,
				setLoadingKanban,
				setLoadingProject,
				setRequestingAddColumn,
				handleGetColumnsPaginate,
				handleGetFirstColumnPaginate,
				handleGetLastColumnPaginate,
				handleAddTaskModal
		],
	);

	return (
		<KanbanContext.Provider
			value={kanbanValue}
		>
			{children}
		</KanbanContext.Provider>
	);
};

function withKanban (Child: any){
	return function KanbanComponent (props: any) {
		return (
			<KanbanContext.Consumer>
				{context => <Child {...props} {...context} />}
			</KanbanContext.Consumer>
		);
	}
} 

const useKanban = () => {
	const context = useContext(KanbanContext);
	return { ...context };

}

export { KanbanProvider, withKanban, useKanban };

