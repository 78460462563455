import React, { ButtonHTMLAttributes } from "react"
import { twMerge } from "tailwind-merge"

interface GenericButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {

}

export function GenericButton({className, ...props}: GenericButtonProps) {
    const classes = twMerge(
        `flex items-center justify-center gap-2 w-9 h-9 rounded-lg border-0 
        bg-ivory-secondary font-medium transition-all hover:brightness-95`,
        className
    )

    return (
        <button className={classes} {...props}></button>
    )
}