import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React,{ useCallback, useEffect, useState } from "react"
import { ModalSide } from "../../../components/containers/Modais/ModalSide";
import { CreateExtraColumn, ExtraColumn } from "../../../models/ExtraColumn";
import { ColumnType } from "../../../models/ColumnType";
import { FormInput, FormSelect, ModalLabelSide } from "../../../styles/globalStyles";
import { AddForm, AddModalWrapper, BackBox } from "./styled";
import { PeopleManagementService } from "../../../services/providers/peopleManagement";
import { useAtom } from "jotai";
import { columnsTypesAtom } from "../../../atoms/PersonManagementAtoms";

export interface AddExtraColumnModalProps {
    handleCloseModal: () => void;
    handleAfterPost: (newColumn: ExtraColumn) => void;
}

export function AddExtraColumnModal({handleCloseModal, handleAfterPost}: AddExtraColumnModalProps) {
	const peopleManagementService = new PeopleManagementService();
    
    const [fieldName, setFieldName] = useState("");
    const [nameError, setNameError] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
	const [columnsTypes] = useAtom(columnsTypesAtom);
    const [selectedColumnType, setSelectedColumnType] = useState<ColumnType>(columnsTypes[0]);

    const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNameError("");
        setFieldName(e.target.value);
    }, []);

    const handleChangeType = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const tempType = columnsTypes.find(item => item.id.toString() === e.target.value);

        if(tempType) {
            setSelectedColumnType(tempType);
        }
    }, [columnsTypes]);

    const onClickSave = useCallback(() => {
        if(fieldName.trim() === "" || !selectedColumnType) {
            setNameError(t("Campo obrigatório."));
            return;
        }

        setLoadingBtn(true);

        const newField: CreateExtraColumn = {
            nome: fieldName,
            tipo: selectedColumnType?.id
        }

        peopleManagementService.postCompanyColumn(newField).then(result => {
            handleAfterPost(result)
        }).finally(() => {
            handleCloseModal();
            setLoadingBtn(false);
        });
    }, [fieldName, selectedColumnType]);

    useEffect(() => {
        setSelectedColumnType(columnsTypes[0]);
    }, [columnsTypes])

    return (
       <>
            <BackBox onClick={handleCloseModal}></BackBox>

            <AddModalWrapper>
                <ModalSide
                    handleCloseModal={handleCloseModal}
                    btnSave={true}
                    onClickSave={onClickSave}
                    loadingBtn={loadingBtn}
                    saveAndContinue={false}
                    isSide={false}
                    isActived={true}
                >
                   <AddForm>
                        <div>   
                            <ModalLabelSide htmlFor="nome">
                                {t("Nome do campo")}*
                            </ModalLabelSide>
                            <FormInput 
                                name="nome"
                                type="text" 
                                value={fieldName}
                                placeholder={t("Digite o nome do campo")}
                                onChange={handleChangeName}
                                error={nameError !== ""}
                            />

                            {nameError !== "" && (
                                <div className="input-error-style" role="alert">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        className="mr-1"
                                    />
                                    {nameError}
                                </div>
                            )}
                        </div>

                        <div>
                            <ModalLabelSide htmlFor="type">
                                {t("Tipo do campo")}*
                            </ModalLabelSide>
                            <FormSelect name="type" id="type" error={false}  onChange={handleChangeType}>
                                {columnsTypes.map(type => {
                                    return (
                                        <option key={type.id} value={type.id} label={t(type.nome)} selected={type.tipo === 'text'} />
                                    )
                                })}
                            </FormSelect>
                        </div>
                    </AddForm>
                </ModalSide>
            </AddModalWrapper>
        </>
    )
}