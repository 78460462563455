import React, { ReactElement } from 'react';
import Button from './button';

import '../../../i18n';
import { useTranslation } from 'react-i18next';

interface FilterBtnsProps {
	onClearFields: () => void;
	onFilter: () => void;
}

const FilterBtns = ({
	onClearFields,
	onFilter,
}: FilterBtnsProps): ReactElement => {
	const { t } = useTranslation();

	return (
		<>
			<div className="horas-container-btn">
				<span onClick={onClearFields} className="horas-text-limpar">
					{t('limpar tudo').toString()}
				</span>{' '}
				<Button text={t('filtrar')} onClick={onFilter} />
			</div>
		</>
	);
};

export default FilterBtns;
