import React from 'react';

import Moment from 'react-moment';
import ProfileImg from '../../../../assets/images/profile.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { useUser } from '../../../../contexts/User';
import { useStopwatch } from '../../../../contexts/Stopwatch';

const Lista = ({
	data,
	loading,
}) => {
	const { t } = useTranslation();
	const {
		setPreSelectedRecord,
		setSelectedMenuIndex,
		handleToggleModal,
	} = useStopwatch();
	const { userDetails } = useUser();
	var datas = '';
	const calendarStrings = {
		sameDay: `[${t('Hoje')}], LL`,
		lastDay: `[${t('Ontem')}], LL`,
		lastWeek: 'LL',
		sameElse: 'LL',
	};
	const headerHoras = dataValue => {
		if (datas === '') {
			datas = dataValue;
			return (
				<div className="horas-titulo">
					<Moment locale={i18n.language} calendar={calendarStrings}>
						{dataValue}
					</Moment>
				</div>
			);
		} else if (datas !== dataValue) {
			datas = dataValue;
			return (
				<div className="horas-titulo">
					<Moment locale={i18n.language} calendar={calendarStrings}>
						{dataValue}
					</Moment>
				</div>
			);
		} else if (datas === dataValue) {
			return;
		}
	};

	const ifUserExterno = () => userDetails?.acesso_cod === 'e' || !userDetails;

	return (
		<>
			<div className="horas-container">
				<div
					style={{
						gridTemplateColumns: ifUserExterno() && ' 8% 45% 15% 15% 12% ',
					}}
					className="horas-thead"
				>
					<div style={{ textAlign: 'center' }}>ID</div>
					<div>{t('Nome da Tarefa')}</div>
					<div>{t('Responsáveis')}</div>
					{/* <div>Projeto</div> */}
					<div style={{ textAlign: 'center' }}>{t('Horas')}</div>
					{ifUserExterno() ? null : (
						<div style={{ textAlign: 'center' }}>{t('Ações')}</div>
					)}
				</div>

				<div>
					{data.map(item => (
						<div key={item.id}>
							<div
								style={{
									// position: 'sticky',
									// top: '38px',
									background: 'white',
								}}
							>
								{headerHoras(item.data, datas)}
							</div>
							<div
								className="horas-tr-valores"
								style={{
									gridTemplateColumns:
										ifUserExterno() && ' 8% 45% 15% 15% 12% ',
									color: loading ? '#8099a738' : '#8099a7',
								}}
							>
								<div style={{ fontWeight: '500', textAlign: 'center' }}>
									{item.id}
								</div>
								<div className="horas-tr-valores-nome" title={item.tarefa_nome}>
									{item.tarefa_nome}
									<div
										className="horas-list-th-nome-projeto-width"
										style={{
											textDecoration:
												item.status === 'done' ? 'line-through' : 'blink',
										}}
									>
										{item.projeto_nome}
									</div>
								</div>
								<div
									style={{
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										display: 'flex'
									}}
									title={item.pessoa_nome}
								>
									<span className="tarefas-img-container">
										<img
											style={{
												marginRight: '8px',
												marginTop: '-2px',
												opacity: loading ? '0.4' : '1',
											}}
											title={item.pessoa_nome}
											src={
												item.pessoa_foto !== null
													? item.pessoa_foto
													: ProfileImg
											}
											alt=""
										/>
									</span>
									{item.pessoa_nome}
								</div>
								<div style={{ textAlign: 'center' }}>
									{item.duracao.slice(0, 5)}
								</div>
								{ifUserExterno() === false && (
									<div
										onClick={() => {
											if (loading) return;
											setPreSelectedRecord({
												projeto: item.projeto,
												projeto_nome: item.projeto_nome,
												tarefa: item.tarefa,
												tarefa_nome: item.tarefa_nome,
												duracao: item.duracao,
												hourId: item.id,
												tipo: item.tipo,
												data: item.data,
												descricao: item.descricao
											});
											setSelectedMenuIndex(1);
											handleToggleModal(true);
										}}
										style={{ textAlign: 'center', cursor: 'pointer' }}
									>
										<FontAwesomeIcon
											icon={faEdit}
											title="Editar"
											className="external-icon-lista-tarefas"
											style={{ fontSize: '14px' }}
										/>
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Lista;
