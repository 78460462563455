import { t } from "i18next";
import React from "react"
import { BiPlus } from "react-icons/bi";
import { AddButton } from "./styled";

export interface AddExtraColumnButtonProps {
    handleClick: () => void;
}

export function AddExtraColumnButton({handleClick}: AddExtraColumnButtonProps) {
    return (
        <AddButton id='react-tour_add-field' onClick={handleClick}>
            <BiPlus size={20} />
            {t("Novo campo")}
        </AddButton>
    )
}