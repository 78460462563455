import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

@font-face {
	font-family: 'keepCalm';
	src: url('./assets/fonts/KeepCalm-Medium.ttf');
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body,
html {
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	background-color: var(--main-bg-color);
	font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

button {
	cursor: pointer;
}

.header-main-container {
	height: 40px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 100%;
	box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
}

.text-header-main-container {
	display: inline-block;
	margin: 0 0 0 20px;
	padding: 0;
	color: #406173;
	font-weight: 700;
}

.btn-go-back {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: transparent 0% 0% no-repeat padding-box;
	border-radius: 10px;
	margin-right: 27px;
	opacity: 1;
	color: #426172;
	-webkit-user-select: none;
	user-select: none;
	font-weight: 600;
	transition: all 0.2s;

	font-size: 16px;
	letter-spacing: 0px;
}

.btn-go-back:hover {
    filter: brightness(0.9);
}

.btn-go-back:active {
    filter: brightness(0.7);
}

.btn-go-back:focus {
	outline: none;
	border: none;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px white inset;
	-webkit-text-fill-color: var(--subtitle-text-color);
	-webkit-text-font-size: var(--medium-text-size);
}
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 50px white inset;
	-webkit-text-fill-color: var(--subtitle-text-color);
	-webkit-text-font-size: var(--medium-text-size);
}

/* FORMS */

.campo-desabilitado {
	opacity: 0.5;
	pointer-events: none;
}

/******************** MEDIA **********************/

@media (max-width: 575.98px) {
	body {
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
	body {
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	body {
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	body {
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	body {
	}
}

/******************************************/

.react-datepicker-popper {
	z-index: 2;
}
.react-datepicker-wrapper {
	width: 100%;
}


	.form-default-style-input:focus {
		border: solid 1px #5ad8e3;
	}

	.form-default-style-input-projetos {
		border-radius: 7px;
		display: block;
		width: 100%;
		font-size: 14px;
		padding: 7px;
		outline: none;
		box-shadow: none;
		border: 1px solid #c0cfd8d9;
		transition: 0.3s ease;
		color: #808080;
	}
	.form-default-style-textarea-projetos {
		border-radius: 7px;
		display: block;
		width: 100%;
		font-size: 14px;
		padding: 7px;
		outline: none;
		min-height: calc(100% - 37px);
		box-shadow: none;
		border: 1px solid #c0cfd8d9;
		transition: 0.3s ease;
		color: #808080;
		resize: none;
	}

	.form-error-style-input-projetos {
		border-radius: 7px;
		display: block;
		width: 100%;
		height: 37px;
		min-height: 37px;
		font-size: 14px;
		padding: 7px;
		margin-bottom: 18px;
		outline: none;
		box-shadow: none;
		border: 1px solid #f16d84;
		transition: 0.3s ease;
		color: #355060;
	}

	.form-error-style-textarea-projetos {
		border-radius: 7px;
		display: block;
		width: 100%;
		height: 37px;
		min-height: 37px;
		font-size: 14px;
		padding: 7px;
		margin-bottom: 18px;
		outline: none;
		min-height: calc(100% - 37px);
		box-shadow: none;
		border: 1px solid #f16d84;
		transition: 0.3s ease;
		color: #355060;
		resize: none;
	}
`;

export const ModalLabel = styled.label`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
	'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-size: var(--medium-text-size);
	font-weight: 500;
	color: var(--subtitle-text-color);
	margin: 16px 0 2px 0;
`;

export const ModalLabelSide = styled.label`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
	'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-size: var(--medium-text-size);
	font-weight: 500;
	color: var(--subtitle-text-color);
	margin: 1vh 0 0.8vh 0;
`;

interface FormInputProps {
	error: boolean;
}

export const FormInput = styled.input<FormInputProps>`
	border-radius: 7px;
	width: 100%;
	font-size: 14px;
	padding: 7px;
	outline: none;
	box-shadow: none;
	border: 1px solid ${({error}) => error ? "#f16d84" : "#c0cfd8d9"};
	transition: 0.3s ease;
	color: var(--input-text-color);
	margin-bottom: ${({error}) => error ? "18px" : "0"};
`;

interface FormSelectProps {
	error: boolean;
}

export const FormSelect = styled.select<FormSelectProps>`
	border-radius: 7px;
	display: block;
	width: 100%;
	font-size: 14px;
	padding: 7px;
	outline: none;
	box-shadow: none;	
	border: 1px solid ${({error}) => error ? "#f16d84" : "#c0cfd8d9"};
	transition: 0.3s ease;
	color: var(--input-text-color);
	margin-bottom: ${({error}) => error ? "18px" : "0"};
`;