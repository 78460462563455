import { atom } from 'jotai'
import { ObjetivoResponse } from '../../services/providers/okrs/objetivo/types'
import { EmpresaProgressoResponse, TimeResponse } from '../../services/providers/okrs/times/types'
import { KeyResultResponse } from '../../services/providers/okrs/keyresult/types'
import { CicloResponse } from '../../services/providers/okrs/ciclo/types'
import { SelectOptions } from '../../models/Select'
import { t } from 'i18next'

interface ModalProps<T> {
    editing: boolean
    selectedItem: T | undefined
    isModalOpen: boolean
}

interface TimesOkrsManagerProps {
    timesOkrs: TimeResponse[]
    currentPage: number
    totalPages: number
}

interface CiclosOkrsManagerProps {
    ciclosOkrs: CicloResponse[]
    currentPage: number
    totalPages: number
}

interface ObjetivosManagerProps {
    objetivosManager: {
        [key: string]:{
            objetivos: ObjetivoResponse[]
            currentPage: number
            totalPages: number
        } | undefined
    }
}

interface KeyresultsManagerProps {
    keyresultsManager: {
        [key: string]:{
            keyresults: KeyResultResponse[]
            currentPage: number
            totalPages: number
        } | undefined
    }
}

// Create your atoms and derivatives
export const objetivoModalAtom = atom<ModalProps<ObjetivoResponse>>({editing: false, selectedItem: undefined, isModalOpen: false})
export const timeModalAtom = atom<ModalProps<TimeResponse>>({editing: false, selectedItem: undefined, isModalOpen: false})
export const cicloModalAtom = atom<ModalProps<CicloResponse>>({editing: false, selectedItem: undefined, isModalOpen: false})

export const openTeamsAtom = atom<number[]>([])
export const openObjectsAtom = atom<number[]>([])

export const ciclosOkrsAtom = atom<CiclosOkrsManagerProps>({
    ciclosOkrs: [],
    currentPage: 1,
    totalPages: 1
})

export const timesOkrsAtom = atom<TimesOkrsManagerProps>({
    timesOkrs: [],
    currentPage: 1,
    totalPages: 1
})

export const loadingTimesAtom = atom<boolean>(false)

export const objetivosManagerAtom = atom<ObjetivosManagerProps>({
    objetivosManager: {}
})

export const keyresultsManagerAtom = atom<KeyresultsManagerProps>({
    keyresultsManager: {}
})

//Times filtros

interface FiltrosTimesOkrsProps {
    search: string
    times: SelectOptions[]
    ciclo: SelectOptions | undefined
}

export const filtrosTimesOkrsInitialValues = {
    search: "",
    times: [{ value: '', label: t('Times') }],
    ciclo: { value: '', label: t('Ciclos') },
}

export const filtrosTimesOkrsAtom = atom<FiltrosTimesOkrsProps>(filtrosTimesOkrsInitialValues)
export const empresaProgressoAtom = atom<EmpresaProgressoResponse | null>(null)