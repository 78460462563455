import React, {  useEffect } from 'react';
import Login from '../index';

import ReactGA from 'react-ga';
import './style.css';

import { PoliticasDeUsoPTBR } from './pt_br';
import i18n from '../../../i18n';
import { PoliticasDeUsoENUS } from './en_us';

export const PoliticasDeUso = () => {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<Login type="termoDeUso">
			{i18n.language === "pt_BR" ? <PoliticasDeUsoPTBR /> : <PoliticasDeUsoENUS />}
		</Login>
	);
};
