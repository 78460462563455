import { t } from 'i18next';
import React, { ReactNode } from 'react';
import { ButtonContainer, ModalLoader } from '../modalStyled';
import { BodySide, FormHeader, FormSide } from './styled';
import { GenericButton } from '../../../tailwindCommon/Buttons/GenericButton';

export interface ModalSideProps {
	title?: string;
	handleCloseModal: () => void;
	loadingBtn: boolean;
	onClickSave: () => void;
	children: ReactNode;
	btnSave: boolean;
	hour? : boolean;
	saveAndContinue: boolean;
	onClickSaveAndContinue?: () => void;
    isSide?: boolean;
	isActived: boolean;
	width?: number;
}


export function ModalSide(props: ModalSideProps) {
	return (
		<FormSide 
            id="side-content-generico"  
            isSide={props.isSide === undefined ? true : props.isSide}
			isActived={props.isActived}
			width={props.width}
        >
			{props.title && (
				<FormHeader>
					<p className="modal-header-generico-side-text">{props.title}</p>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<span
							onClick={() => {
								props.handleCloseModal();
							}}
							className="close-generico-side-form"
						>
							&times;
						</span>
					</div>
				</FormHeader>
			)}
			<BodySide
				hour={props.hour !== undefined && props.hour === true}
				side={true}
			>
				{props.children}
			</BodySide>
			<div className="">
				{props.btnSave ? (
					<ButtonContainer>
						<GenericButton
							title={props.loadingBtn ? t('Salvando...').toString() : t('Salvar').toString()}
							disabled={props.loadingBtn}
							onClick={props.onClickSave}
							className='w-full'
							>
							{props.loadingBtn ? (
								<ModalLoader></ModalLoader>
							) : (
								t('Salvar')
							)}
						</GenericButton>

						{props.saveAndContinue ? (
							<GenericButton
								title={props.loadingBtn ? t('Salvando...').toString() : t('Salvar e Continuar').toString()}
								disabled={props.loadingBtn}
								onClick={props.onClickSaveAndContinue}
								className='w-full bg-[#23d9b7]'
							>
								{props.loadingBtn ? (
									<ModalLoader></ModalLoader>
								) : (
									t('Salvar e Continuar')
								)}
							</GenericButton>
						) : null}
					</ButtonContainer>
				) : null}
			</div>
		</FormSide>
	);
}