import styled from 'styled-components';
import { PAGETOPMARGEM, theme } from '../../../styles/theme';

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const BackButton = styled.button`
	margin-bottom: 30px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	border: none;
	background: 0% 0% no-repeat padding-box padding-box transparent;
	border-radius: 10px;
	margin-right: 27px;
	opacity: 1;
	color: rgb(66, 97, 114);
	user-select: none;
	font-weight: 600;
	transition: all 0.2s ease 0s;
	font-size: 16px;
	letter-spacing: 0px;
`;

export const NavlinkButton = styled.button`
	border: none;
	background-color: ${theme.colors.button.darkBtn};
	border-radius: 10px;
	color: white;
	padding: 5px 15px;
	font-weight: 600;
	font-size: small;
	width: 120px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
	&:disabled {
		opacity: 0.8;
		cursor: auto;
	}
`;

export const RegistrosContainerBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - (${(PAGETOPMARGEM) + "vw"} + 185px));
	overflow: auto;
	overflow-x: hidden;

	@media (min-width: 767.99px) and (max-width: 960px) {
		height: calc(100vh - 260px);
    }
`;
