import styled from 'styled-components';
import { PAGETOPMARGEM } from '../../../../styles/theme';

export const PageContainerFilter = styled.div`
	display: grid;
	transition: all 2s ease 0s;
	grid-template-columns: 99.5% 0.5%;
	width: 100%;
	margin: auto;
`;

export const RegistrosContainer = styled.div`
	width: 100%;
	margin: auto;
`;

export const RegistrosContainerBody = styled.div`
	height: calc(100vh - ( ${(PAGETOPMARGEM) + "vw"} + 180px));
	overflow: auto;
	overflow-x: hidden;

	@media (min-width: 767.99px) and (max-width: 960px) {
		height: calc(100vh - 255px);
    }
`;
