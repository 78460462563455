import React, { Dispatch, SetStateAction } from 'react';
import { AddColumns, ColumnsContainer, KanbanWrapperForm } from './styled';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import KanbanSkeleton from '../../../../pages/Registros/Tarefas/Kanban/KanbanSkeleton';

interface KanbanContainerProps {
	react_tour_id: string;
	onDragEnd: (result: DropResult) => void;
	children: React.ReactNode;
	isAddingColumn: boolean;
	setIsAddingColumn: Dispatch<SetStateAction<boolean>>;
	handleAddColumn: (title: string) => void;
	canEdit: boolean;
	loadingKanban: boolean;
	loadingFilters: boolean;
}

export default function KanbanContainer({
	react_tour_id,
	onDragEnd,
	children,
	isAddingColumn,
	setIsAddingColumn,
	handleAddColumn,
	canEdit,
	loadingKanban,
	loadingFilters
}: KanbanContainerProps) {

	return (
		loadingKanban ? <KanbanSkeleton />
		:
		(
			<KanbanWrapperForm
				loading={loadingFilters}
				id={react_tour_id}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable
					droppableId="single-column"
					direction="horizontal"
					type="column"
				>
					{provided => (
						<ColumnsContainer
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{children}
							<AddColumns>
								{isAddingColumn && (
									<input
										type="text"
										name="add-column"
										title="Adicionar coluna"
										autoComplete="off"
										onBlur={() => setIsAddingColumn(false)}
										onKeyDown={e => {
											const target = e.target as HTMLInputElement;
											if (e.key === 'Enter') {
												handleAddColumn(target.value);
												target.value = '';
											}
										}}
										autoFocus
									/>
								)}
								{canEdit && (
									<button
										className="actions-menu-button kanban-add-column-button"
										type="button"
										onClick={() => setIsAddingColumn(true)}
										title={t('Adicionar coluna').toString()}
									>
										<FontAwesomeIcon icon={faPlus} className="icon-actions" />
									</button>
								)}
							</AddColumns>

							{provided.placeholder}
						</ColumnsContainer>
					)}
				</Droppable>
			</DragDropContext>
		</KanbanWrapperForm>
		)
	);
}
