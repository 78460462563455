import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const SelectFilterContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

interface PageContainerProps {
	loadingFilters: boolean;
	search?: boolean;
	kanban: boolean;
}

export const PageContainer = styled.div<PageContainerProps>`
	font-family: 'Inter', sans-serif;
	display: grid;
	grid-template-columns: 1fr min-content;
	flex-direction: column;
	width: 100%;
	min-width: 200px;
	border: 1px solid ${theme.colors.input.borderBackgroundInput};
	margin-bottom: 30px;
	background-color: white;
	border-radius: 10px;
	cursor: ${({loadingFilters}) => loadingFilters ? 'progress' : 'default'};
	@media (min-width: ${({kanban}) => kanban ? "1200px" : "900px"}) {
		grid-template-columns: ${({ search }) => (search ? '30% 1fr min-content' : '1fr min-content')};
		height: 3rem;
		flex-direction: row;
	}
`;

interface CenterFilterContainerProps {
	kanban: boolean;
}

export const CenterFilterContainer = styled.div<CenterFilterContainerProps>`
	order: 2;
	grid-column-start: 1;
    grid-column-end: 3;
	display: grid;
	justify-content: end;
	gap: 0;
	align-items: center;
	padding: ${({kanban}) => kanban ? "0 10px 0 0" : " 0 10px"};    
	overflow: hidden;
	border-top: 1px solid ${theme.colors.input.borderBackgroundInput};
	@media (min-width: 320px) {
		flex-direction: row;
	}
	@media (min-width: 600px) {
		flex-direction: row;
		display: flex;
		gap: 15px;
	}
	@media (min-width: ${({kanban}) => kanban ? "1200px" : "900px"}) {
		grid-column-start: initial;
		grid-column-end: initial;
		flex-direction: row;
		order: 1;
		border-top: none;
	}
`;

interface SearchContainerProps {
	kanban: boolean;
}

export const SearchContainer = styled.div<SearchContainerProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	@media (min-width: 320px) {
		flex-direction: row;
	}
	@media (min-width: ${({kanban}) => kanban ? "1200px" : "900px"}) {
		border-bottom: none;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		min-width: 150px;
		order: 0;
	}
`;

interface OpenFiltersContainerProps {
	kanban: boolean;
}

export const OpenFiltersContainer = styled.div<OpenFiltersContainerProps>`
	display: flex;
	justify-content: end;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	gap: 15px;
	height: 3rem;
	margin: 0 10px 0 0;
	min-width: 100px;
	width: 100%;
	align-items: center;
`;

export const OpenFiltersContent = styled(OpenFiltersContainer)`
	width: initial;
	justify-content: initial;
	-ms-overflow-style: none;
	scrollbar-width: none;
	max-width: 100%;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		display: none;
	}
	@media (min-width: ${({kanban}) => kanban ? "1200px" : "900px"}) {
		min-width: 100px;
		margin: 0px;
	}
`;

interface FilterPickerContainerProps {
	kanban: boolean;
}

export const FilterPickerContainer = styled.div<FilterPickerContainerProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: end;
	order: 1;
	@media (min-width: ${({kanban}) => kanban ? "1200px" : "900px"}) {
		display: flex;
		flex-direction: row;
		order: 2;
	}
`;

export const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const BtnSpan = styled.span`
	color: #406173;
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;
	align-self: flex-end;
`;

export const Button = styled.button`
	padding: 0 20px;
	width: 126px;
	height: 42px;
	align-items: center;
	justify-content: center;
	letter-spacing: 0px;
	border-radius: 10px;
	border: none;
	outline: none !important;
	/* font-size: 16px; */
	font-weight: 600;
	background: #5ad8e3;
	color: white;
`;

export const AddIcon = styled(FontAwesomeIcon)`
	font-weight: 600;
	margin-right: 10px;
	color: white;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px;
	height: max-content;
`;

export const SelectValueContainer = styled.div`
	-webkit-box-align: center;
	align-items: center;
	display: grid;
	flex: 1;
	flex-wrap: wrap;
	padding: 0;
	position: relative;
	overflow: auto;
	box-sizing: border-box;
	margin: 0;
	font-size: 0.875rem;
	color: #426172;
	min-height: 24px !important;
	height: 24px !important;
	padding-right: 2px;
`;

export const SelectInput = styled.input`
	border: none;
	&:focus {
		outline: none;
	}
	&::placeholder {
		color: #426172;
	}
`;

export const DivBar = styled.div`
	height: calc(100% - 16px);
	background-color: ${theme.colors.input.borderBackgroundInput};
	width: 1px;
	align-self: center;
`;

export const FixedFilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
`;

export const CheckboxLabel = styled.label`
	font-size: 14px;
	color: #496070;
	margin-left: 10px;
	margin-bottom: 0 !important;
`;
export const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
`;
