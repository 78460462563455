import React, { useCallback, useEffect, useState } from 'react';
import HeaderLink from '../../components/common/HeaderLink';
import Table, {
	FieldsHeader,
	TableBody,
	THeadIcons,
} from '../../components/containers/CustomTable';
import { Responsible } from '../../models/Responsible';
import PessoaService from '../../services/providers/responsible';
import {PeopleManagementService} from '../../services/providers/peopleManagement';
import { toast } from 'react-toastify';
import { dinamicPagination } from '../../utils/funcoes/dinamicPagination';
import { t } from 'i18next';
import { BsPersonDash } from 'react-icons/bs';

import PersonFilter from './PersonFilter';
import PersonModal from './PersonModal';
import { acessoOptions, formatTableRow, header } from './utils';
import {
	PageContainer,
} from './styled';
import { PAGETOPMARGEM, theme } from '../../styles/theme';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { HeaderButton } from '../../components/common/HeaderLink/types';
import { verifyPeopleManagment } from '../../utils/funcoes/validarPerfil';
import { useHistory } from 'react-router-dom';
import { SelectOptions } from '../../models/Select';
import formatObjectToFilter from '../../utils/funcoes/formatObjectToFilter';
import { ConfirmModal } from '../../components/containers/Modais/ConfirmModal';
import { AddContainer } from './AddContainer';
import { ActivesCount } from './ActivesCount';
import { ExtraColumn } from '../../models/ExtraColumn';
import { useAtom } from 'jotai';
import { columnsTypesAtom, extraColumnsAtom } from '../../atoms/PersonManagementAtoms';
import { useUser } from '../../contexts/User';
import { InputValuesType } from '../../services/providers/responsible/types';
import { sortByPersonName } from '../../utils/funcoes/sort';
import TurTutorial from '../../components/tourTutorial';
import { DropResult } from 'react-beautiful-dnd';

let controller: AbortController | null = null; 

export default function PersonManagement() {
	const [columnsTypes, setColumnsTypes] = useAtom(columnsTypesAtom);

	const [extraColumns, setExtraColumns] = useAtom(extraColumnsAtom);
	const [extraHeaderColumns, setExtraHeaderColumns] = useState<FieldsHeader[]>([]);
	const [people, setPeople] = useState<Responsible[]>([]);
	const [peopleTable, setPeopleTable] = useState<TableBody[]>([]);
	const [loadingPeople, setLoadingPeople] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState<null | number>(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [checked, setChecked] = useState<number[]>([]);
	const [checkedCallback, setCheckedCallback] = useState<(() => void )| undefined>(() => () => {})
	const [addIds, setAddIds] = useState<number[]>([]);

	const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);

	const history = useHistory();
	const {userDetails, loadingUser} = useUser();
	
    const [search, setSearch] = useState<string>('');
    const [acesso, setAcesso] = useState<SelectOptions>({label: t('Perfil'), value: ''});
    const [isActive, setIsActive] = useState<boolean>(true);

	const personService = new PessoaService();
	const peopleManagementService = new PeopleManagementService();

	const handleAddPerson = (person: Responsible) => {
		if(extraColumns) {
			setAddIds(prev => [...prev, person.id])
			setPeople(prev => sortByPersonName([...prev, {
				...person, 
				valor_colunas_preenchidas: handleExtraValues(person, extraColumns)
			}], 'nome'));
		}
	};

	const getPeople = useCallback(
		async (
			page = 1,
			values: { search: string; acesso: string, deleted: boolean } = { acesso: formatObjectToFilter(acesso), search: search, deleted: !isActive },
			additional = 'all=true'
		) => {
			if(page === 1) {
				setCurrentPage(1);
				setTotalPages(null)
			}

			setLoadingPeople(true);
			
			if (controller !== null) {
				controller.abort();
			}

			controller = new AbortController();

			const personService = new PessoaService();
			const params = {
				search: values.search,
				acesso: values.acesso,
				deleted: values.deleted,
				additional: additional + "&serializer=gestao"
			}
			let tempExtraValue: ExtraColumn[] = [];
			if(extraColumns === null || page === 1) {
				tempExtraValue = await handleGetExtraConlumns();
			} else {
				tempExtraValue = extraColumns;
			}

			personService.getPessoasPaginacao(
				page,
				params,
				response => {
					if (response && !response.cancelled) {
						const tempPeople = response?.results.map(item => {
							return {
								...item, 
								valor_colunas_preenchidas: handleExtraValues(item, tempExtraValue)
							}
						})
						if (page === 1) {
							setPeople(tempPeople);
						} else {
							const existsPerson = tempPeople.find(item => addIds.includes(item.id));
							if(existsPerson){
								const prevPeople = people.filter(item => item.id !== existsPerson.id);

								setPeople([...prevPeople, ...tempPeople]);
								setAddIds(prev => prev.filter(id => id !== existsPerson.id))
							} else {
								setPeople(prev => [...prev, ...tempPeople]);
							}
						}
						setCurrentPage(page);
						setTotalPages(response?.total_pages);
					}
					if(!response?.cancelled) {
						setLoadingPeople(false);
					}
				},
				() => {
					setLoadingPeople(false)
				},
				{ signal: controller?.signal }
			);
		},
		[PessoaService, people, extraHeaderColumns],
	);
	
	const handleExtraValues = (responsible: Responsible, tempExtra: ExtraColumn[] = []) => {
		const tempExtraColumns = tempExtra.length === 0 ? extraColumns : tempExtra;
		const tempResult = tempExtraColumns?.map(extra => {
			const tempColumnValue = responsible.valor_colunas_preenchidas?.find(value => value.coluna_empresa === extra.id);
			return {
				id: tempColumnValue?.id,
				valor: tempColumnValue?.valor || "",
				coluna_empresa: extra.id,
				coluna_expanded: extra,
				pessoa: responsible.id
			}
		})
		return tempResult || [];
	}

	const updatePerson = (
		personId: number,
		key: keyof Responsible,
		value: InputValuesType,
	) => {
		const personService = new PessoaService();
		personService.patchPessoa(
			{ [key]: key === "deleted" ? !value : value },
			personId,
			response => {
				setPeople(prev => {
					return prev.map((item) => {
						if(item.id === personId) {
							return {
								...item,
								...response.data
							}
						}
						return item;
					})
				})
				toast.success(t('Usuário atualizado com sucesso!'));
			},
			() => toast.error(t('Ocorreu um erro!')),
			'all=true'
		);
	};
	const deletePerson = (id: number) => {
		const pessoaService = new PessoaService();
		
		pessoaService.deletePessoa(
			id,
			response => {
				if (response) {
					toast.success(t('Perfil desativado com sucesso!'));
					setPeople(prev => prev.map((item) => {
						if(item.id === id) {
							return {
								...item,
								deleted: true
							}
						}
						return item;
					}))
				}
				
			},
			error => {
				setPeople(prev => prev.map((item) => {
					if(item.id === id) {
						return {
							...item,
							deleted: false
						}
					}
					return item;
				}))
				toast.error(error);
			},
		);
	};

	const deleteExtraColumn = (
		id: string
	) => {
		peopleManagementService.deleteCompanyColumn(id).then(() => {
		}).catch(() => {
			setExtraHeaderColumns([...extraHeaderColumns]);
			setPeople([...people]);
			toast.error(t("Erro ao deletar coluna!"));
		});

		const tempPeople = people.map(item => ({
			...item,
			valor_colunas_preenchidas: item.valor_colunas_preenchidas.filter(
				values => values.coluna_empresa !== id
				)
		}))

		const tempHeaders = extraHeaderColumns.filter(item => item.id !== id);

		setExtraHeaderColumns(tempHeaders);
		setPeople(tempPeople);
	};

	const patchExtraColumn = (
		value: string,
		id: string
	) => {
		peopleManagementService.patchCompanyColumn({id, nome: value}).then(() => {
		}).catch(() => {
			setExtraHeaderColumns([...extraHeaderColumns]);
			setPeople([...people]);
			toast.error(t("Erro ao editar coluna!"));
		});

		const tempHeaders = [...extraHeaderColumns];
		const tempIndex = tempHeaders.findIndex(item => item.id === id);
		tempHeaders[tempIndex] = {
			...tempHeaders[tempIndex],
			title: value
		}

		setExtraHeaderColumns(tempHeaders);
	};

	const patchExtraColumnOrder = (
		value: number,
		id: string
	) => {
		peopleManagementService.patchCompanyColumn({id, ordenacao: value}).then(() => {
		}).catch(() => {
			toast.error(t("Erro ao editar coluna!"));
		});

	};

	const AddExtraColumn = (
		newColumn: ExtraColumn,
	) => {
		setExtraHeaderColumns(prev => [...prev, {
			title: newColumn.nome,
			deletable: true,
			handleDelete: deleteExtraColumn,
			editable: true,
			handleBlur: patchExtraColumn,
			width: newColumn.tipo === "boolean" ? "80" : "120",
			id: newColumn.id
		}]);

		setPeople(prev => prev.map(person => ({
			...person,
			valor_colunas_preenchidas: [...person.valor_colunas_preenchidas, {
				id: "",
				valor: "",
				coluna_empresa: newColumn.id,
				coluna_expanded: {
					...newColumn,
					tipo_expanded: columnsTypes.find(item => item.id === newColumn.tipo)
				},
				pessoa: person.id
			}]
		})))
	};

	const postExtraValue = (
		id: string,
		value: string | number | File | boolean,
	) => {
		const coluna_empresa = id.split("!!")[0];
		const pessoa = id.split("!!")[1];

		if(id.split("!!")[2]) {
			const valueId = id.split("!!")[2];
			peopleManagementService.patchUserValueColumn({
				valueId,
				pessoa, 
				valor: value.toString()
			}).then(() => {	
				toast.success(t('Usuário atualizado com sucesso!'));
			});
		} else {
			peopleManagementService.postUserValueColumn({
				coluna_empresa,
				pessoa, 
				valor: value.toString()
			}).then(() => {	
				toast.success(t('Usuário atualizado com sucesso!'));
			});
		}
	};

	const handleBlur = useCallback(
		(e: string | number | File | boolean, name: string, id?: number | string) => {
			if(typeof id === "string" && name.endsWith("extraField")) {
				postExtraValue(id, e)
			}else if (typeof id === "number" && !(name === 'deleted' && e === 'true' )) {
				updatePerson(id, name as keyof Responsible, e);
			}else if(typeof id === "number") {
				deletePerson(id)
			}
		},
		[postExtraValue, updatePerson, deletePerson, people, peopleTable],
	);

	const handleScroll = useCallback(
		(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
			dinamicPagination({
				element: e.target,
				callBack: getPeople,
				loading: loadingPeople,
				page: currentPage,
				totalPages: totalPages,
			});
		},
		[dinamicPagination, getPeople, loadingPeople, currentPage, totalPages],
	);

	const handleLoaderPagination = useCallback(() => {
		getPeople(currentPage + 1);
	}, [currentPage, getPeople]);

	const deletePeople = () => {
		setLoadingDeleteAll(true);
		checked.forEach(id => {
			const person = people.filter((item) => item.id === id)[0]
			personService.deletePessoa(
				id,
				() => {
					setPeople(prev =>
						{
							return prev.map(item => {
							if (item.id === id) {
								return {
									...item,
									deleted: true,
								};
							}
							return item;
						})}
					);
					toast.success(`${t('Perfil de')} ${person.nome} ${t('desativado com sucesso')}`);
					if (checkedCallback) {
						checkedCallback();
					}
					setChecked(prev=> prev.filter((item) => item !== id))
				},
				() => {
					setChecked(prev => prev.filter((item) => item !== id))
					toast.error(t(`Ocorreu um erro ao desativar o perfil de ${person.nome}!`))
				})
		});
	};

	const handleDeletePeople = (ids: number[], callback?: () => void) => {
		if(ids.length > 0) {
			setChecked(ids);
			if(callback) {
				setCheckedCallback(() => () => callback());
			}else{
				setCheckedCallback(callback)
			}
			setIsConfirmModalOpen(true);
		}
	}

	const headerIcons: THeadIcons[] = [
		{
			color: theme.colors.text.subtitleText,
			Icon: BsPersonDash,
			onClick: handleDeletePeople,
			title: 'Desativar perfis',
		},
	];

	const handleToogleModal = useCallback(() => {
		setIsModalVisible(prev => !prev);
	}, []);

	const headerButtons: HeaderButton[] = [
		{
			handleClick: handleToogleModal,
			icon: faPlus,
			isHidden: false,
			text: 'Pessoa',
			id: 'react-tour_add-person'
		}
	]

	const handleGetExtraConlumns = async () => {
		const typesResult = await peopleManagementService.getColumnsTypes();
		const columnsResult = await peopleManagementService.getCompanyColumns();
		const tempColumnsResult = columnsResult.results.map(item => ({
			...item,
			tipo_expanded: typesResult.results.find(type => type.id === item.tipo)
		}))
		
		const extraHeader: FieldsHeader[] = tempColumnsResult.map(item => {
			return {
				id: item.id,
				title: item.nome,
				width: item.tipo_expanded?.tipo === "boolean" ? "80" : "120",
				deletable: true,
				handleDelete: deleteExtraColumn,
				editable: true,
				handleBlur: patchExtraColumn
			}
		});
		
		setColumnsTypes(typesResult.results);
		setExtraHeaderColumns(prev => [...prev, ...extraHeader]);
		setExtraColumns(tempColumnsResult);

		return tempColumnsResult;
	}

	const handleOnDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination){
				return;
			}

			const { source, destination } = result;

			if (source.index === destination.index){
				return;
			}

			const tempExtraHeaderColumns = [...extraHeaderColumns];
			const [removedHeader] = tempExtraHeaderColumns.splice(source.index, 1);
			tempExtraHeaderColumns.splice(destination.index, 0, removedHeader);

			const tempExtraColumns = extraColumns ? extraColumns : [];
			const [removedColumn] = tempExtraColumns.splice(source.index, 1);
			removedColumn.ordenacao = destination.index + 1;
			tempExtraColumns.splice(destination.index, 0, removedColumn);


			const tempPeople = people.map(item => {
				return {
					...item, 
					valor_colunas_preenchidas: handleExtraValues(item, tempExtraColumns)
				}
			})

			patchExtraColumnOrder(removedColumn.ordenacao, removedColumn.id);

			setExtraColumns(tempExtraColumns);
			setExtraHeaderColumns(tempExtraHeaderColumns);
			setPeople(tempPeople);
		},
		[extraHeaderColumns],
	);

	useEffect(() => {
		setPeopleTable(formatTableRow(people));
		setExtraHeaderColumns(extraHeaderColumns.map(item => ({
			...item,
			handleDelete: deleteExtraColumn,
			handleBlur: patchExtraColumn
		})))
	}, [people]);


	useEffect(() => {
		if(checked.length === 0){
			setLoadingDeleteAll(false);
		}
	}, [checked])

	useEffect(() => { 
		if(!loadingUser && userDetails) {
			const hasAccess = verifyPeopleManagment(userDetails?.acesso_cod);
			if(!hasAccess) {
				history.push('/home');
			}
		}
	}, [userDetails, loadingUser])

	return (
		<>
			{userDetails && !userDetails.has_finished_tour && <TurTutorial nomeRota={'pessoas'} />}
			<HeaderLink type="person" headerButtons={headerButtons}>
					<PersonModal
						isShowingModal={isModalVisible}
						setIsShowingModal={setIsModalVisible}
						handleAfterPost={handleAddPerson}
					/>
			</HeaderLink>
			<PageContainer loading={loadingPeople}>
				<PersonFilter
					acessoOptions={acessoOptions}
					getPeople={getPeople}
					loading={loadingPeople}
					acesso={acesso}
					isActive={isActive}
					search={search}
					setAcesso={setAcesso}
					setIsActive={setIsActive}
					setSearch={setSearch}
				/>

				<Table
					tableId='react-tour_pessoas-table'
					headerIcons={headerIcons}
					onSubmit={handleBlur}
					tableBody={peopleTable}
					hasCheckbox={true}
					tableHeader={header}
					handleOnDragEnd={handleOnDragEnd}
					onScroll={handleScroll}
					extraHeaderColumns={extraHeaderColumns}
					handleLoaderPagination={handleLoaderPagination}
					isPaginate={true}
					loading={loadingPeople || loadingUser}
					showLoader={totalPages === currentPage}
					extraHeader={columnsTypes.length > 0 && <AddContainer handleAfterPost={AddExtraColumn} />}
					customTableWrapperStyle={`
						height: calc(100vh - (${(PAGETOPMARGEM) + "vw"} + 245px));

						@media(min-height: 720px) {
							height: calc(100vh - (${(PAGETOPMARGEM) + "vw"} + 265px));
						}
					`}

				/>

				<ActivesCount />			
			</PageContainer>
			<ConfirmModal loading={loadingDeleteAll} text={"Desativar usuários selecionados?"} handleConfirm={deletePeople} isConfirmModalOpen={isConfirmModalOpen} setIsConfirmModalOpen={setIsConfirmModalOpen} />
		</>
	);
}
