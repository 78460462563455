import styled from 'styled-components';

export const TarefasTable = styled.table`
	table-layout: fixed;
	width: 100%;
	margin-bottom: 14px;
`;

export const TarefasList = styled.div`
	display: flex;
	flex-direction: column;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`;

interface TarefasContainerProps {
	loading: boolean;
}

export const TarefasContainer = styled.div<TarefasContainerProps>`
	width: 100%;
	margin: auto;
	cursor: ${({loading}) => loading ? "progress" : "initial"};
`;
