
import React, { useEffect, useState } from 'react';
import { RowWrapper } from '../../../../components/tailwindCommon/Table/RowWrapper';
import { LoadingRow } from '../../../../components/tailwindCommon/Table/LoadingRow';
import { LoadMoreRow } from '../../../../components/tailwindCommon/Table/LoadMoreRow';
import { ciclosOkrsAtom } from '../../../../atoms/Okrs';
import { useAtom } from 'jotai';
import { CicloRow } from './CicloRow';

interface CiclosTableProps {
    readonly getCiclosPagination: (page:number) => Promise<void>
}

export function CiclosTable({getCiclosPagination}: CiclosTableProps) {
    const [loadingTimes, setLoadingTimes] = useState(false)
    
    const [ciclosOkrs,] = useAtom(ciclosOkrsAtom)

    const handlePagination = (page: number) => {
        setLoadingTimes(true)
        getCiclosPagination(page).finally(() => {
            setLoadingTimes(false)
        }) 
    }
    
    useEffect(() => {
        handlePagination(ciclosOkrs.currentPage)
    }, [])

    return (
        <div>
            <div className='rounded-t-lg bg-ivory-primary-light'>
                <RowWrapper>
                    <div className='col-span-5'>
                        <p>Nome</p>
                    </div>

                    
                    <div className='col-span-2'>
                        <p>Data inicial</p>
                    </div>

                    
                    <div className='col-span-2'>
                        <p>Data final</p>
                    </div>
                    
                    <div className='col-span-'>
                        <p>Ações</p>
                    </div>
                </RowWrapper>
            </div>

            <div>
                {ciclosOkrs.ciclosOkrs.map(ciclo => {
                    return (
                        <div key={ciclo.id}>
                            <CicloRow 
                                ciclo={ciclo} 
                            />
                        </div>
                    )
                })}

                {loadingTimes && (
                    <LoadingRow />
                )}

                {ciclosOkrs?.totalPages !== ciclosOkrs?.currentPage && (
                    <LoadMoreRow
                        handlePagination={() => handlePagination(ciclosOkrs?.currentPage + 1)} 
                        hideLoader={false}
                        loading={loadingTimes}
                    />
                )}
            </div>
        </div>
    )
}