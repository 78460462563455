import React, { useCallback, useEffect, useRef, useState } from 'react';
import TarefaService from '../../../../../services/providers/task';

import { toast } from 'react-toastify';

import { useDataTask } from '../../../../../contexts/InfoTarefa/DataTask';
import './style.css';
import { Task } from '../../../../../models/Task';
import { useFilters } from '../../../../../contexts/Filter/Task/Filter';
import { LoaderPagination } from '../../../../../components/common/loaders/LoaderPagination';
import formatArrayToFilter from '../../../../../utils/funcoes/formatArrayToFilter';
import Thead from './Thead';
import TheadTools from './TheadTools';
import Tbody from './Tbody';
import { TarefasContainer, TarefasList, TarefasTable } from './styled';
import { t } from 'i18next';
import { useUser } from '../../../../../contexts/User';

interface ListProps {
	accessCode: string;
	dataAtual: number;
	loadingEncerrarTarefa: boolean;
	setEditingHoras: React.Dispatch<React.SetStateAction<boolean>>;
	setHoraEditada: React.Dispatch<React.SetStateAction<Task | undefined>>;
	setModalHorasShow: (value: boolean) => void;
	setEditingTarefa: React.Dispatch<React.SetStateAction<boolean>>;
	setTarefaEditada: React.Dispatch<React.SetStateAction<Task | undefined>>;
	setModalTarefaShow: React.Dispatch<React.SetStateAction<boolean>>;
	handleEncerrar: (id: string | number) => void;
	reabrirTarefa: (id: string | number) => void;
	converterDuracaoTotal: (hour: string) => string;
}

const List = ({
	accessCode,
	dataAtual,
	converterDuracaoTotal,
	setEditingHoras,
	setHoraEditada,
	setModalHorasShow,
	setEditingTarefa,
	setTarefaEditada,
	setModalTarefaShow,
	loadingEncerrarTarefa,
	handleEncerrar,
	reabrirTarefa
}: ListProps) => {
	const responsavelWidthContainer = useRef<any>();
	const { widthContainer } = useResize(responsavelWidthContainer);

	const { loadingTasks, totalPages, pages, getTasksPagination } = useDataTask();
	const { loadingUser } = useUser();
	const { selectedProject } = useFilters();
	const [selectArrayList, setSelectArrayList] = useState<string[]>([]);
	const [finishTaskLoad, setFinishTaskLoad] = useState(false);
	const addSelecionados = useCallback(
		(tarefaSelecionada: string, valorChecado: boolean) => {
			const tarefaSelecionadaString = tarefaSelecionada.toString();
			const element = document.getElementById(`tarefa-row${tarefaSelecionadaString}`);
			if (valorChecado) {
				setSelectArrayList(prev => [...prev, tarefaSelecionadaString]);
				handleStyleHover(element!, true);
			} else {
				setSelectArrayList(prev => prev.filter(
					item => item !== tarefaSelecionadaString,
				));
				handleStyleHover(element!, false);
			}
		},
		[selectArrayList, setSelectArrayList],
	);

	const handleStyleHover = (element: Element, add: any) => {
		if (add) {
			element.classList.add('tarefas-list-tr-hover');
		} else {
			element.classList.remove('tarefas-list-tr-hover');
		}
	};

	const _encerrarTarefa = (id: number | string) => {
		setFinishTaskLoad(true);
		const tarefaService = new TarefaService();
		tarefaService.encerrarTarefa({
			id: id,
			success: response => {
				if (response) {
					getTasksPagination();
					encerrarTarefaAnimacao(id);
					toast.success(`${t('Tarefa encerrada com sucesso')}!`);
				}
				setFinishTaskLoad(false);
			},
			errorCallback: error => {
				toast.error(error);
				setFinishTaskLoad(false);
			},
		});
	};

	const encerrarTarefaAnimacao = (id: number | string) => {
		const rowEncerrada = document.getElementById(`tarefa-row${id}`);
		if (rowEncerrada) {
			rowEncerrada.style.transition = 'all 0.3s';
			rowEncerrada.style.opacity = '0%';
			setTimeout(() => {
				rowEncerrada.style.display = 'none';
			}, 600);
		}
	};

	const endMultiTasks = useCallback(
		(tarefas: string[]) => {
			tarefas.forEach(tarefa => _encerrarTarefa(tarefa));

			setSelectArrayList([]);
		},
		[_encerrarTarefa],
	);

	const selectAllCheckboxList = useCallback(
		(source: React.ChangeEvent<HTMLInputElement>) => {
			const rowList = document.querySelectorAll('.tarefas-list-tr');
			const checkboxes = document.querySelectorAll(
				'input[type="checkbox"].check-list-tarefas',
			) as NodeListOf<HTMLInputElement>;

			setSelectArrayList([]);

			for (let i = 0; i < checkboxes.length; i++) {
				if (source.target.checked) {
					checkboxes[i].checked = true;
					addSelecionados(checkboxes[i].value, true);
					handleStyleHover(rowList[i], true);
				} else {
					checkboxes[i].checked = false;
					addSelecionados(checkboxes[i].value, false);
					handleStyleHover(rowList[i], false);
				}
			}
		},
		[addSelecionados, handleStyleHover],
	);

	const handleLoaderPagination = useCallback(() => {
		const selected = Array.isArray(selectedProject)
			? selectedProject
			: [selectedProject];
		getTasksPagination(formatArrayToFilter(selected) || '', pages + 1);
	}, [selectedProject, pages, getTasksPagination]);

	return (
		<>
			<div>
				<TarefasContainer loading={finishTaskLoad}>
					<TarefasList>
						<TheadTools
							endMultiTasks={endMultiTasks}
							selectArrayList={selectArrayList}
							selectAllCheckboxList={selectAllCheckboxList}
						/>
						<TarefasTable>
							<Thead
								responsavelWidthContainer={responsavelWidthContainer}
								accessCode={accessCode}
							/>
							<Tbody
								reabrirTarefa={reabrirTarefa}
								setHoraEditada={setHoraEditada}
								converterDuracaoTotal={converterDuracaoTotal}
								accessCode={accessCode}
								handleEncerrar={handleEncerrar}
								loadingEncerrarTarefa={loadingEncerrarTarefa}
								setEditingHoras={setEditingHoras}
								setEditingTarefa={setEditingTarefa}
								setModalHorasShow={setModalHorasShow}
								setModalTarefaShow={setModalTarefaShow}
								setTarefaEditada={setTarefaEditada}
								widthContainer={widthContainer}
								addSelecionados={addSelecionados}
								dataAtual={dataAtual}
							/>
						</TarefasTable>
						<LoaderPagination
							showLoader={totalPages === pages}
							loading={loadingTasks || loadingUser}
							onClick={handleLoaderPagination}
						/>
					</TarefasList>
				</TarefasContainer>
			</div>
		</>
	);
};

export default List;

const useResize = (myRef: React.MutableRefObject<any>) => {
	const [widthContainer, setWidthContainer] = useState(0);
	useEffect(() => {
		setWidthContainer(myRef.current.offsetWidth);
	}, []);
	useEffect(() => {
		const handleResize = () => {
			setWidthContainer(myRef.current.offsetWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [myRef]);

	return { widthContainer };
};
