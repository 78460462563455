import React from 'react';
import { NavLink } from 'react-router-dom';
import CheckBox from '../CheckBox';
import './style.css';

import '../../../../i18n';
import { useTranslation } from 'react-i18next';

const TermCheckTxt = ({ termoPrivacidade, setTermoPrivacidade }) => {
	const {t} = useTranslation();
	return (
		<div
			onClick={() => setTermoPrivacidade(!termoPrivacidade)}
			style={{ display: 'flex', marginTop: '16px' }}
		>
			<CheckBox checked={termoPrivacidade} />

			<span className="login-termo">
				{t("Eu li e concordo com os")}{' '}
				<NavLink to="/politicas_de_uso" className="cadastro-navlink-termos">
					{t("Termos de uso e Privacidadedo site")}
				</NavLink>
			</span>
		</div>
	);
};

export default TermCheckTxt;
