import React, { useCallback, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { KanbanService } from '../../../../../services/providers/kanban';

import {
	dragColumn,
	dragTaskDifferentColumns,
	dragTaskSameColumn,
	updateColumnsValues,
} from '../functions';

import { useFilters } from '../../../../../contexts/Filter/Task/Filter';
import {
	initialValues,
	useKanban,
} from '../../../../../contexts/Kanban/Kanban';

import { Status } from './types';
import KanbanContainer from '../../../../../components/containers/Kanban/KanbanContainer';
import KanbanBody from '../KanbanBody';
import { ColumnManaged } from '../../../../../atoms/Kanban/types';
import { TaskManaged } from '../../../../../contexts/Kanban/types';
import { useLocation } from 'react-router-dom';
import KeyResultService from '../../../../../services/providers/okrs/keyresult';
import ProjetoService from '../../../../../services/providers/project';
import { useQuery } from '../../../../../utils/hooks/useQuery';

export const status: Status[] = [
	{
		id: 1,
		title: 'started',
	},
	{
		title: 'running',
		id: 2,
	},
	{
		id: 3,
		title: 'done',
	},
];

export function Kanban() {
	const {pathname} = useLocation();
	const { t } = useTranslation();
	const query = useQuery();

	const keyresultService = new KeyResultService();
	const kanbanService = new KanbanService();
	const projectService = new ProjetoService();
	const {
		selectedProject,
		selectedStatus,
		selectedTag,
		startDate,
		endDate,
		checkClosedTask,
		selectedAuthor,
		selectedPerson,
		selectedKeyResult,
		setSelectedProject
	} = useFilters();

	const {
		columnsManager,
		setColumnsManager,
		handleGetInitialValues,
		setLoadingKanban,
		setLoadingProject,
		loading,
		canEdit,
		isAddingColumn,
		setIsAddingColumn,
		requestingAddColumn,
		setRequestingAddColumn,
		selectKanban,
		loadingFilters
	} = useKanban();

	useEffect(() => {
		if (columnsManager.project) setLoadingProject(false);
	}, [columnsManager]);

	const isOkr = pathname === '/okrs/kanban'

	const projectId = Array.isArray(selectedProject)
		? selectedProject[0].value
		: selectedProject.value;

	const keyresultId = Array.isArray(selectedKeyResult)
		? selectedKeyResult[0].value
		: selectedKeyResult.value;

	useEffect(() => {
		if(isOkr && keyresultId !== '') {
			keyresultService.getKeyResultsById({id: keyresultId.toString()}).then((result) => {
				setSelectedProject({
					label: result.time.nome,
					value: result.time.id
				})
			}).catch(() => {
				toast.error(t("Erro ao buscar key result!"))
			})
		}
	}, [keyresultId]);

	const handleSearchParams = async () => {
		const projectId = query.get('project');
		if(projectId) {
			const project = await projectService.getProject(parseInt(projectId));
			setSelectedProject({
				label: project?.nome,
				value: project?.id,
				image: project?.cliente?.logo_cliente
			})
		}
	}
	
	useEffect(() => {
		selectKanban();
		handleSearchParams();
	}, []);

	useEffect(() => {
		if ((projectId !== '') && columnsManager.project ) {
			const currentColumnsManager = JSON.stringify(columnsManager);
			const initialColumnsManager = JSON.stringify(
				initialValues.columnsManager,
			);
			const isInitialValue = currentColumnsManager === initialColumnsManager;
			const isProjectDifferent = columnsManager.project.id !== projectId;
			if (isInitialValue || isProjectDifferent) {
				handleGetInitialValues();
				return;
			}
			setLoadingProject(false);
			setLoadingKanban(false);
		}
	}, [selectedProject]);

	useEffect(() => {
		if (projectId !== '' && !isOkr) {
			if (selectedStatus.value === 'custom' && !startDate && !endDate) return;
			handleGetInitialValues(
				columnsManager === initialValues.columnsManager,
			);
		}
	}, [
		selectedAuthor,
		selectedPerson,
		checkClosedTask,
		endDate,
		selectedStatus,
		selectedTag,
		startDate,
	]);

	useEffect(() => {
		if (keyresultId !== '' && isOkr && projectId !== '') {
			if (selectedStatus.value === 'custom' && !startDate && !endDate) return;

			handleGetInitialValues(
				columnsManager === initialValues.columnsManager,
			);
		}
	}, [
		selectedAuthor,
		selectedPerson,
		checkClosedTask,
		endDate,
		selectedStatus,
		selectedTag,
		startDate,
		selectedKeyResult
	]);

	const handlePatchDragColumn = (result: DropResult) => {
		if (!result.destination && !columnsManager.project) {
			return;
		}

		const columnData = {
			id: result.draggableId,
			projeto: columnsManager.project!.id,
			ordenacao: result.destination!.index,
		};

		kanbanService.patchColumn(columnData).catch(() => {
			setColumnsManager({ ...columnsManager });

			toast.error(t('Erro ao reposicionar coluna!'));
		});
	};

	const handleOnDragEnd = useCallback(
		(result: DropResult) => {
			if (
				!result.destination ||
				requestingAddColumn.componentId === result.destination.droppableId
			)
				return;

			const { source, destination, type } = result;

			if (type === 'column' && !!result.draggableId) {
				if (
					source.index === 0 ||
					destination.index === 0 ||
					source.index === destination.index
				)
					return;
				handlePatchDragColumn(result);

				setColumnsManager(prev => {
					const tempColumnsManager = dragColumn(prev, result);
					return tempColumnsManager
				});
				return;
			}

			if (source.droppableId !== destination.droppableId) {
				setColumnsManager(prev => {
					const tempColumnsManager = dragTaskDifferentColumns(
						prev,
						result,
					);

					return tempColumnsManager
				});
			} else {
				setColumnsManager(prev => {
					const tempColumnsManager = dragTaskSameColumn(prev, result);

					return tempColumnsManager
				});
			}

			handlePatchDragTask(
				result.draggableId,
				destination.index,
				destination.droppableId === columnsManager.columnsOrder[0]
					? null
					: destination.droppableId,
			);
		},
		[
			requestingAddColumn,
			handlePatchDragColumn,
			dragTaskDifferentColumns,
			dragColumn,
			columnsManager,
		],
	);

	const handlePatchDragTask = async (
		id: string,
		ordenacao: number,
		coluna_kanban: string | null,
	) => {
		const taskData = {
			id,
			ordenacao,
			coluna_kanban,
			status: coluna_kanban
				? columnsManager.columns[coluna_kanban].status.title
				: 'started',
		};
		await kanbanService.patchTaskInColumn(taskData).catch(() => {
			setColumnsManager({ ...columnsManager });

			toast.error(t('Erro ao reposicionar tarefa!'));
		});
	};

	const handleAddColumn = (title: string) => {
		if (title.trim() !== '') {
			const newColumnId = uuid();
			const column: ColumnManaged<TaskManaged> = {
				id: newColumnId,
				nome: title,
				order:
					columnsManager.columns[
						columnsManager.columnsOrder[columnsManager.columnsOrder.length - 1]
					].order + 1,
				status: status[2],
				totalPages: 1,
				page: 1,
				removedQnt: 0,
				addedItemsIds: [],
				items: [],
				doneTotalPages: 0,
				ordenacao:
					columnsManager.columns[
						columnsManager.columnsOrder[columnsManager.columnsOrder.length - 1]
					].order + 1,
			};

			handlePostAddColumn(column);
			setRequestingAddColumn({
				componentId: newColumnId,
			});
			setColumnsManager(prev => {
				const newColumnOrder = [...prev.columnsOrder, newColumnId];
				const tempColumns = { ...prev.columns, [newColumnId]: column };

				return {
					...prev,
					columns: updateColumnsValues(tempColumns, newColumnOrder),
					columnsOrder: newColumnOrder,
			}});
		}

		setIsAddingColumn(false);
	};

	function handlePostAddColumn(column: ColumnManaged<TaskManaged>) {
		kanbanService
			.postColumn({
				nome: column.nome,
				projeto: columnsManager.project!.id,
				ordenacao: column.order,
			})
			.then(response => {
				if (response) {
					const newColumnId = response.id;

					setColumnsManager(prev => {
						const newColumnOrder = [...prev.columnsOrder.filter(item => item !== column.id), newColumnId];
						const tempColumns = {
							...prev.columns,
							[newColumnId]: { ...column, id: newColumnId },
						};

						delete tempColumns[column.id] 

						return {
							...prev,
							columns: updateColumnsValues(tempColumns, newColumnOrder),
							columnsOrder: newColumnOrder,
					}});
				}
			})
			.catch(() => {
				setColumnsManager({ ...columnsManager });

				toast.error(t('Erro ao adicionar coluna!'));
			});
	}

	return <KanbanContainer
				loadingFilters={loadingFilters}
				loadingKanban={loading || loadingFilters}
				canEdit={canEdit}
				handleAddColumn={handleAddColumn}
				isAddingColumn={isAddingColumn}
				onDragEnd={handleOnDragEnd}
				react_tour_id="react-tour_kanban"
				setIsAddingColumn={setIsAddingColumn}
		>
			{columnsManager.columnsOrder.map((id: string, index: number) => {
				return <KanbanBody key={id} id={id} index={index} />;
			})}
		</KanbanContainer>
}
