import { normalize, schema } from 'normalizr';
import React, { useEffect, useState } from 'react';

import clienteImage from '../../../../../assets/images/company.png';
import {
	FormSideGenerico,
	ModalConteudoGenerico,
	ModalPrincipalGenerico,
} from '../../../../../components/containers/Modais/ModalComSideGenerico';
import ClienteService from '../../../../../services/providers/client';
import { ClienteForm } from '../form';

import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
	faPen,
	faPlus,
	faSearch,
	faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { toast } from 'react-toastify';

import { useProject } from '../../../../../contexts/Project';

import '../../style.css';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../../contexts/User';

export default function ModalCliente(props) {
	const { t } = useTranslation();
	document.addEventListener('keyup', event => {
		if (event.defaultPrevented) {
			return;
		}
		var key = event.key || event.keyCode;
		if (key === 'Escape' || key === 'Esc' || key === 27) {
			if (props) {
				props.modalClienteShow(false);
			}
		}
	});

	const [adding, setAdding] = useState(false);
	const [editing, setEditing] = useState(false);
	const [deleteInterval, setDeleteInterval] = useState(0);

	const [submitting] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dadosPesquisa, setDadosPesquisa] = useState('');
	const [dataCliente, setDataCliente] = useState([]);
	const [editedClient, setEditedClient] = useState({});
	const [acessCode, setAcessCode] = useState('m');

	const { projects, setProjects } = useProject();
	const {userDetails} = useUser();

	useEffect(() => {
		getUserdetails();
		_getClientes();
	}, []);

	const getUserdetails = () => {
		if (userDetails !== null) {
			setAcessCode(userDetails.acesso_cod);
		}
	};

	//
	const handleAdicionar = (isSideOpen = true) => {
		const formSide = document.getElementById('side-content-generico');

		if (!isSideOpen) {
			formSide.className = 'form-content-generico-side';
			setEditing(false);
			setAdding(false);
		} else {
			if (editing) {
				formSide.className = 'form-content-generico-side';
				setTimeout(() => {
					setAdding(true);
					setEditing(false);
					formSide.className = 'form-content-generico-side--ACTIVED';
				}, 400);
			} else {
				setAdding(true);
				setEditing(false);
				formSide.className = 'form-content-generico-side--ACTIVED';
			}
		}
	};

	const handleEditar = (editingBool, cliente) => {
		const formSide = document.getElementById('side-content-generico');

		if ((editingBool && adding) || editing) {
			formSide.className = 'form-content-generico-side';
			setTimeout(() => {
				setEditing(true);
				setAdding(false);
				setEditedClient(cliente);
				formSide.className = 'form-content-generico-side--ACTIVED';
			}, 400);
		} else {
			setEditing(true);
			setAdding(false);
			setEditedClient(cliente);
			formSide.className = 'form-content-generico-side--ACTIVED';
		}
	};

	const toggleClass = (elementName, selectedClass) => {
		document
			.getElementsByName(elementName)[0]
			.setAttribute('class', selectedClass);
	};

	const handleBlurItem = id => {
		toggleClass(`cliente${id}`, 'modal-imagem-item');
		toggleClass(`delete-cliente${id}`, 'modal-container-item-delete');
		toggleClass(`edit-cliente${id}`, 'modal-container-item-edit');
	};

	const startDeleteItem = id => {
		const clienteButton = document.getElementById(`cliente-button${id}`);
		const progressDeleteCliente = document.getElementsByClassName(
			`progress-delete-cliente${id}`,
		)[0];
		const cancel_delete_cliente_button = document.getElementById(
			`cancel-delete-cliente${id}`,
		);

		const trail = progressDeleteCliente.getElementsByTagName('path')[0];
		const path = progressDeleteCliente.getElementsByTagName('path')[1];

		clienteButton.blur();
		clienteButton.disabled = true;

		trail.setAttribute(
			'style',
			'opacity: 1; stroke-dasharray: 289.027px; stroke-dashoffset: 0px;',
		);
		path.setAttribute(
			'style',
			'stroke: #ff2929; transition: stroke-dashoffset 5s ease 0s; opacity: 1; stroke-dasharray: 289.027px; stroke-dashoffset: 0px;',
		);

		setDeleteInterval(100);
		setDeleteInterval(0);

		let cancel_cliente_time = setTimeout(() => {
			_deleteCliente(id);

			clienteButton.setAttribute('class', 'grid__item delete-item-animation');

			setTimeout(() => {
				clienteButton.setAttribute('class', 'd-none');
			}, 400);
		}, 4280);

		cancel_delete_cliente_button.setAttribute(
			'class',
			'button-cancel-item-delete',
		);
		cancel_delete_cliente_button.onclick = () => {
			clearTimeout(cancel_cliente_time);
			trail.setAttribute(
				'style',
				'opacity: 0; stroke-dasharray: 289.027px, 289.027px; stroke-dashoffset: 0px;',
			);
			path.setAttribute(
				'style',
				'stroke: #ff2929; transition: stroke-dashoffset 0s ease 0s; opacity: 0; stroke-dasharray: 0px; stroke-dashoffset: 289.027px;',
			);
			cancel_delete_cliente_button.setAttribute('class', 'd-none');
			clienteButton.disabled = false;
		};
	};

	const handleClick = () => {
		if (!submitting) {
			document.getElementById('formSideModal-cliente').click();
		}
	};

	const _getClientes = () => {
		setDataCliente([]);
		setLoading(true);
		const clienteService = new ClienteService();

		clienteService.getClientes(
			response => {
				if (response.data) {
					setDataCliente(response.data);
				}
				setLoading(false);
			},
			error => {
				setDataCliente([]);
				setLoading(false);
				// toast.error(error);
			},
			dadosPesquisa,
		);
	};

	const _postCliente = params => {
		setLoading(true);
		const clienteService = new ClienteService();
		clienteService.postCliente(
			params,
			response => {
				if (response) {
					toast.success(t('Cliente salvo com sucesso!'));
					_getClientes();
					handleAdicionar(false);
				}
				setLoading(false);
			},
			error => {
				toast.error(error);
				setLoading(false);
			},
		);
	};

	const _patchCliente = (params, id) => {
		setLoading(true);

		const clienteService = new ClienteService();

		clienteService.patchCliente(
			params,
			id,
			response => {
				if (response) {
					updateClientData(response.data);
					toast.success(t('Cliente atualizado com sucesso!'));
					handleAdicionar(false);
				}
				setLoading(false);
			},
			error => {
				toast.error(error);
				setLoading(false);
			},
		);
	};

	const _deleteCliente = id => {
		const clienteService = new ClienteService();

		clienteService.deleteCliente(
			id,
			response => {
				if (response) {
					toast.success(t('Cliente removido com sucesso!'));
					_getClientes();
				}
			},
			error => {
				_getClientes();
				toast.error(error);
			},
		);
	};

	const updateClientData = client => {
		const newClients = dataCliente.map(cliente =>
			cliente.id === client.id ? client : cliente,
		);

		changeClientColor(client);

		setDataCliente(newClients);
		setEditedClient(client);
	};

	const changeClientColor = client => {
		const newProjects = Object.keys(projects).map(item => {
			return projects[item].cliente.id === client.id
				? { ...projects[item], cliente: client }
				: projects[item];
		});
		const projectsSchema = new schema.Entity('projects');
		const projectResponse = normalize(
			newProjects,
			new schema.Array(projectsSchema),
		);
		setProjects(projectResponse.entities.projects);
	};

	return (
		<>
			<ModalPrincipalGenerico>
				<FormSideGenerico
					title={editing ? t('Editar Cliente') : t('Adicionar Cliente')}
					handleAdicionar={handleAdicionar}
					btnSave={true}
					onClickSave={handleClick}
					loadingBtn={loading}
				>
					{editing ? (
						<ClienteForm
							handleSubmit={_patchCliente}
							item_edit={editedClient}
							editing={editing}
						/>
					) : (
						<ClienteForm handleSubmit={_postCliente} editing={editing} />
					)}
				</FormSideGenerico>

				<ModalConteudoGenerico
					title={t('Clientes')}
					modalGenericoShow={props.modalClienteShow}
					modalIsShowing={props.modalIsShowing}
				>
					<div className="modal-width-pessoas">
						<div className="modal-cabecalho-corpo">
							<div className="d-flex justify-content-center">
								{acessCode === 'm' || acessCode === 'g' ? null : (
									<div
										className="modal-botao"
										onClick={() => {
											handleAdicionar();
										}}
									>
										<FontAwesomeIcon
											icon={faPlus}
											className="trash-modal-icon"
										/>
										{t('Adicionar')}
									</div>
								)}
								<form
									className="form-modal"
									onSubmit={event => {
										event.preventDefault();
										_getClientes();
									}}
								>
									<div
										className="search-modal-button"
										onClick={event => {
											event.preventDefault();
											_getClientes();
										}}
									>
										<FontAwesomeIcon
											icon={faSearch}
											className="search-modal-icon"
										/>
									</div>
									<input
										value={dadosPesquisa}
										autoComplete={'off'}
										type="text"
										name="search"
										className="form-modal-input"
										placeholder={t('Pesquisar')}
										onKeyUp={() => {
											if (dadosPesquisa === '') {
												_getClientes();
											}
										}}
										onChange={e => {
											setDadosPesquisa(e.target.value);
										}}
									/>
								</form>
							</div>
						</div>
						<div
							className={
								loading || dataCliente.length === 0
									? 'center-loading'
									: 'grid grid--auto-fill'
							}
							data-keyword="auto-fill"
						>
							{loading ? (
								<div className="loader" />
							) : dataCliente.length === 0 ? (
								<p className="seletor-responsavel-header-text">
									{acessCode === 'e' ? (
										<span>{t('Campo restrito')}.</span>
									) : (
										<span>{t('Lista vazia')}.</span>
									)}
								</p>
							) : (
								dataCliente.map((item, index) => (
									<button
										disabled={acessCode !== 'a' ? true : false}
										key={index}
										id={`cliente-button${item.id}`}
										type="reset"
										className="grid__item"
										title={item.nome}
										onFocus={() => {
											toggleClass(
												`cliente${item.id}`,
												'modal-imagem-item modal-imagem-item--clicked',
											);
											toggleClass(
												`delete-cliente${item.id}`,
												'modal-container-item-delete modal-container-item-delete--clicked',
											);
											toggleClass(
												`edit-cliente${item.id}`,
												'modal-container-item-edit modal-container-item-edit--clicked',
											);
										}}
										onBlur={() => {
											handleBlurItem(item.id);
										}}
									>
										<div>
											<div
												className="modal-container-item-edit"
												name={`edit-cliente${item.id}`}
												title={t('Editar')}
												onClick={() => {
													handleEditar(true, item);
												}}
											>
												<FontAwesomeIcon
													icon={faPen}
													className="item-modal-edit-icon"
												/>
											</div>
											<div
												className="modal-container-item-delete"
												name={`delete-cliente${item.id}`}
												title={t('Excluir')}
												onClick={() => {
													handleBlurItem(item.id);
													handleAdicionar(false);
													startDeleteItem(item.id);
												}}
											>
												<FontAwesomeIcon
													icon={faTrashAlt}
													className="item-modal-delete-icon"
												/>
											</div>

											<CircularProgressbarWithChildren
												className={`progress-delete-cliente${item.id}`}
												value={deleteInterval}
												styles={{
													path: {
														opacity: 0,
													},
													trail: {
														opacity: 0,
													},
												}}
											>
												<div
													title="Cancelar Exclusão"
													className="d-none"
													id={`cancel-delete-cliente${item.id}`}
												>
													<FontAwesomeIcon
														icon={faUndo}
														className="undo-delete-icon"
													/>
												</div>
												<img
													className="modal-imagem-item"
													name={`cliente${item.id}`}
													src={
														item.logo_cliente ? item.logo_cliente : clienteImage
													}
													alt=""
												/>
											</CircularProgressbarWithChildren>
										</div>
										<div>
											<p className="modal-texto-item">{item.nome}</p>
										</div>
									</button>
								))
							)}
						</div>
					</div>
				</ModalConteudoGenerico>
			</ModalPrincipalGenerico>
		</>
	);
}
