import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
	PeriodModal,
	PeriodText,
	SelectButton,
	SelectContainer,
	SaveButton,
	FormFooter,
	ButtonContainer,
	FormContainer,
	ButtonsContainer,
	ExportButton,
	Input,
	InputWrapper,
	Test,
} from './styled';
import DatePicker from '../../../../../../../components/common/DatePicker';
import { FaPencilAlt } from 'react-icons/fa';
import moment from 'moment';
import { theme } from '../../../../../../../styles/theme';
import useComponentVisible from '../../../../../../../utils/hooks/useClickOutside';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { MdOutlineDocumentScanner } from 'react-icons/md';
import { useAtom } from 'jotai';
import { StartPeriodAtom } from '../../../../../../../atoms/ProjectDetails';
import { useTranslation } from 'react-i18next';
export interface SelectPeriodProps {
	startPeriod: Date;
	setStartPeriod: Dispatch<SetStateAction<Date>>;
	handlePrint: () => void;
	numOfDays: number;
	setNumOfDays: Dispatch<SetStateAction<number>>;
}

export default function SelectPeriod({
	startPeriod,
	setStartPeriod,
	handlePrint,
	numOfDays,
	setNumOfDays,
}: SelectPeriodProps) {
	const { t } = useTranslation();
	const [selectedPeriod, setSelectedPeriod] = useState(startPeriod);
	const [nDays, setNDays] = useState(numOfDays);
	const [selectedStartPeriod, setSelectedStartPeriod] = useAtom(
		StartPeriodAtom,
	);

	useEffect(() => {
		if (!moment(selectedStartPeriod).isSame(startPeriod, 'day')) {
			const thisMonth = moment().month();
			const periodThisMonth = moment(selectedStartPeriod, 'YYYY-MM-DD').set(
				'month',
				thisMonth,
			);

			if (
				moment(selectedStartPeriod, 'YYYY-MM-DD')
					.set('month', moment(startPeriod).month())
					.isSameOrBefore(moment())
			) {
				setStartPeriod(prev =>
					moment(selectedStartPeriod, 'YYYY-MM-DD')
						.set('month', moment(prev).month())
						.toDate(),
				);
			} else {
				setStartPeriod(periodThisMonth.subtract(1, 'month').toDate());
			}
		}
	}, [selectedStartPeriod]);
	const {
		ref,
		isComponentVisible,
		setIsComponentVisible,
	} = useComponentVisible(false);
	const finalDate = moment(startPeriod)
		.add(numOfDays, 'day')
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (selectedPeriod) {
			const thisMonth = moment().month();
			const periodThisMonth = moment(selectedPeriod).set('month', thisMonth);
			if (
				moment(selectedPeriod)
					.set('month', thisMonth)
					.isSameOrBefore(moment())
			) {
				setSelectedStartPeriod(periodThisMonth.format('YYYY-MM-DD'));
				
			} else {
				setSelectedStartPeriod(
					periodThisMonth.subtract(1, 'month').format('YYYY-MM-DD'),
				);
			}
			setNumOfDays(nDays);
			setIsComponentVisible(false);
		}
	};

	return (
		<SelectContainer>
			<PeriodText>
				{t('Período')}:{' '}
				<BiChevronLeft
					cursor={'pointer'}
					onClick={() =>
						setStartPeriod(prev =>
							moment(prev)
								.subtract(numOfDays, 'days')
								.toDate(),
						)
					}
					color={theme.colors.text.titleText}
					strokeWidth={2}
				/>
				<strong>
					{moment(startPeriod).format('DD/MM/YYYY')} -{' '}
					{finalDate.format('DD/MM/YYYY')}
				</strong>
				<BiChevronRight
					cursor={'pointer'}
					onClick={() =>
						setStartPeriod(prev =>
							moment(prev)
								.add(numOfDays, 'days')
								.toDate(),
						)
					}
					color={theme.colors.text.titleText}
					strokeWidth={2}
				/>
			</PeriodText>
			<ButtonsContainer>
				<ExportButton title={t('Gerar relatório PDF')} onClick={handlePrint}>
					{t('Gerar relatório PDF')}
					<MdOutlineDocumentScanner
						fontSize={24}
						color={theme.colors.text.titleText}
					/>
				</ExportButton>
				<SelectButton onClick={() => setIsComponentVisible(true)}>
					{t('Editar duração do período')}
					<FaPencilAlt color="white" />
				</SelectButton>
			</ButtonsContainer>
			{isComponentVisible && (
				<PeriodModal ref={ref}>
					<FormContainer onSubmit={handleSubmit}>
						{t("Selecione o início do período")}.
						<DatePicker
							value={selectedPeriod}
							onChange={e => {
								setSelectedPeriod(e);
							}}
							placeholder={t('DD/MM/YYYY')}
							border={`1px solid ${theme.colors.text.sidebarText}; border-radius: 7px; padding: 5px; margin: 0 !important;`}
						/>
						{t("Informe a duração do período")}.
						<InputWrapper>
						<Input
							step={1}
							value={nDays}
							onChange={e => {
								if(e) {
									setNDays(parseInt(e.target.value))
								}else {
									setNDays(0);
								}

							}}
							type="number"
						/>
						<Test valueLength={nDays.toString().length}>{t("dias")}</Test>
						</InputWrapper>
						<FormFooter>
							<ButtonContainer>
								<SaveButton type="submit">{t('Salvar')}</SaveButton>
							</ButtonContainer>
						</FormFooter>
					</FormContainer>
				</PeriodModal>
			)}
		</SelectContainer>
	);
}
