import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
	NavbarText,
	NavlinkButton,
	NavlinkContainer,
	NavlinkLeft,
	NavlinkRight,
} from './styled';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { FaArrowsRotate } from "react-icons/fa6";
import { theme } from '../../../styles/theme';
import { useTranslation } from 'react-i18next';
import { HeaderLinkOptions, HeaderLinkProps } from './types';

import '../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUser } from '../../../contexts/User';
import { MdOutlineGroups, MdOutlineTextSnippet, MdOutlineViewKanban } from 'react-icons/md';
import  {ReactComponent as PeopleTeam} from '../../../assets/svgs/peopleTeam.svg'
import  {ReactComponent as PageOkrs} from '../../../assets/svgs/pageOkrs.svg'
import { BsGrid, BsLink45Deg } from 'react-icons/bs';
import { validarPerfil } from '../../../utils/funcoes/validarPerfil';



const HeaderLink = ({
	children = <></>,
	type = 'registro',
	headerButtons,
	projectId
}: HeaderLinkProps) => {
	const { t } = useTranslation();
	const [userAccessCode, setUserAccessCode] = useState('');
	const {userDetails} = useUser();
	useEffect(() => {
		getUserdetails();
	}, [userDetails]);

	const getUserdetails = () => {
		if (userDetails !== null) {
			setUserAccessCode(userDetails.acesso_cod);
		}
	};

	const userValidation = () => {
		return userAccessCode === 'g' || userAccessCode === 'a';
	};

	const ListIcon = (
		<AiOutlineUnorderedList
			color={theme.colors.background.mainBg}
			strokeWidth={2}
		/>
	);

	const KanbanIcon = (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.85547 2H13.5221C13.8758 2 14.2149 2.14048 14.4649 2.39052C14.715 2.64057 14.8555 2.97971 14.8555 3.33333V12.6667C14.8555 13.0203 14.715 13.3594 14.4649 13.6095C14.2149 13.8595 13.8758 14 13.5221 14H8.85547M8.85547 2H4.1888C3.83518 2 3.49604 2.14048 3.24599 2.39052C2.99594 2.64057 2.85547 2.97971 2.85547 3.33333V12.6667C2.85547 13.0203 2.99594 13.3594 3.24599 13.6095C3.49604 13.8595 3.83518 14 4.1888 14H8.85547M8.85547 2V14"
				stroke={theme.colors.background.mainBg}
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const HourIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="20"
			width="20"
			viewBox="0 0 48 48"
			fill={theme.colors.background.mainBg}
		>
			<path
				d="m40 41.3 1.4-1.4-3.75-3.75v-5.6h-2v6.4ZM9 42q-1.25 0-2.125-.875T6 39V9q0-1.25.875-2.125T9 6h30q1.25 0 2.125.875T42 9v16.45q-.7-.4-1.475-.65-.775-.25-1.525-.4V9H9v30h15.45q.2.8.45 1.55t.6 1.45Zm0-5.35V39 9v15.4-.2 12.45ZM14 34h10.55q.2-.8.45-1.55t.65-1.45H14Zm0-8.5h17.2q.7-.35 1.35-.575.65-.225 1.45-.425v-2H14Zm0-8.5h20v-3H14Zm22.65 28.95q-3.9 0-6.65-2.775-2.75-2.775-2.75-6.575 0-3.9 2.75-6.675t6.65-2.775q3.85 0 6.625 2.775T46.05 36.6q0 3.8-2.775 6.575Q40.5 45.95 36.65 45.95Z"
			/>
		</svg>
	);

	const PersonIcon = <MdOutlineGroups size={22} color={theme.colors.background.mainBg} />
	const ProjetoReportIcon = <img src={require('../../../assets/svgs/chartFile.svg')} width={'15px'}  />
	const ParamsIcon = <MdOutlineTextSnippet  color={theme.colors.background.mainBg}   />

	let headerLink: HeaderLinkOptions = {
		registro: [
			{
				tour: '',
				link: '/registros/projetos',
				text: t('Projetos'),
			},
			{
				tour: 'react-tour__tarefa',
				link: '/registros/tarefas',
				text: t('Tarefas'),
			},
			{
				tour: 'react-tour__hora',
				link: '/registros/horas',
				text: t('Horas'),
			},
		],
		relatorio: [
			{
				tour: '',
				link: '/relatorios',
				text: t('Relatório geral'),
			},
		],
		task: [
			{
				tour: 'react-tour__tarefa',
				link: '/registros/tarefas',
				text: t('Lista'),
				icon: ListIcon,
			},
			{
				tour: 'react-tour_kanban-header',
				link: '/registros/kanban',
				text: t('Kanban'),
				icon: KanbanIcon,
			},
		],
		hour: [
			{
				link: '/registros/horas',
				text: t('Horas'),
				tour: '',
				icon: HourIcon,
			},
		],
		person: [
			{link: '/pessoas',
			text: t('Pessoas'),
			tour: '',
			icon: PersonIcon
		}
	],
		project: [
			{
				link: '/registros/projetos',
				text: t('Modular'),
				tour: '',
				icon: <BsGrid color={theme.colors.background.mainBg} size={24} />
			},
			{
				link: '/registros/projetos-list',
				text: t('Lista'),
				tour: '',
				icon: <AiOutlineUnorderedList color={theme.colors.background.mainBg} size={24} />
			}
		],
		projectWithReport: [
			{
				link: '/registros/projetos',
				text: t('Modular'),
				tour: '',
				icon: <BsGrid color={theme.colors.background.mainBg} fontSize={20} size={24} />
			},
			{
				link: '/registros/projetos-list',
				text: t('Lista'),
				tour: '',
				icon: <AiOutlineUnorderedList color={theme.colors.background.mainBg} fontSize={20} size={24} />
			},
			{
				link: '/registros/projetos-kanban',
				text: t('Kanban'),
				tour: '',
				icon: <MdOutlineViewKanban color={theme.colors.background.mainBg} fontSize={20} size={24} />
			},
			{
				link: '/relatorios_projeto',
				text: t('Relatório de projetos'),
				tour: 'react-tour__projects-report',
				icon: ProjetoReportIcon
			}
		],
		projectDetails: [
			{
				link: `/registros/projetos/${projectId}/parametros`,
				text: t("Parâmetros"),
				tour: '',
				icon: ParamsIcon
			},
			{
				link: `/registros/projetos/${projectId}/time`,
				text: t("Time"),
				tour: '',
				icon: <PeopleTeam color={theme.colors.background.mainBg} />
			},
			{
				link: `/registros/projetos/${projectId}/anexos`,
				text: t("Anexos"),
				tour: '',
				icon: <BsLink45Deg color={theme.colors.background.mainBg} />
			}
		],
		okrs: [
			{
				link: '/okrs/times',
				text: t('OKRs'),
				tour: '',
				icon: <PageOkrs color={theme.colors.background.mainBg} />
			},
			{
				link: '/okrs/kanban',
				text: t('Kanban'),
				tour: '',
				icon:  KanbanIcon
			},
		]
	};

	if(validarPerfil(userDetails?.acesso_cod)) {
		headerLink = {
			...headerLink,
			okrs: [
				...headerLink.okrs,
				{
					link: '/okrs/ciclos',
					text: t('Ciclos'),
					tour: '',
					icon: <FaArrowsRotate color={theme.colors.background.mainBg} />
				},
			]
		}
	}

	const buildLink = () => {
		if (type === 'project' && userValidation()) {
			return headerLink.projectWithReport;
		} else {
			return headerLink[type];
		}
	};

	const link = buildLink();
	return (
		<>
			<NavlinkContainer>
				<NavlinkLeft>
					{link.map(item => (
						<NavLink
							className={`navbar-item ${item.tour}`}
							to={item.link}
							activeClassName="active-navbar-link"
							key={item.link}
						>
							{item.icon}

							<NavbarText>{item.text}</NavbarText>
						</NavLink>
					))}
				</NavlinkLeft>

				<NavlinkRight style={{gap: headerButtons && headerButtons?.length > 1 ? '15px' : '0'}}>
					{headerButtons?.map((button) => !button.isHidden ? <NavlinkButton 
						id={button.id} 
						onClick={button.handleClick}
						className={button.className}
						key={button.text}
						disabled={button.disabled || false}
						>
							{
								typeof button.icon === 'string' ? 
									<img style={{width: '15px'}} src={button.icon} alt="" />  : 
									<FontAwesomeIcon  icon={button.icon} color='white' />
							}
							{t(button.text)}
					</NavlinkButton> : null
					)}
					{children}
				</NavlinkRight>
			</NavlinkContainer>
		</>
	);
};

export default HeaderLink;
