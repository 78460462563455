import styled from "styled-components";

export const AddButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    padding: 0 8px;
    background-color: #fff;
    color: #426172;
    white-space: nowrap;
    border: 0.5px solid #C1D0D9;
    transition: all 0.2s;

    &:hover {
        box-shadow: 0px 3px 6px #00000029;
    }
`;