import React from 'react';
import './style.css';

const CheckboxList = ({ addSelecionados, itemID, ...rest }) => {
	const atribuirCheck = e => {
		addSelecionados(itemID, e.target.checked);
	};
	return (
		<div>
			<input
				type="checkbox"
				value={itemID}
				onChange={e => atribuirCheck(e)}
				{...rest}
			/>
		</div>
	);
};
export default CheckboxList;
