import React, { useCallback, useState } from 'react';

import { Formik, Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ProjetoService from '../../../../../services/providers/project';
import AsyncSelector from '../../../../../components/common/AsyncSelector';

import './style.css';
import { useTranslation } from 'react-i18next';
import { LoadOptionsAdditional, SelectOptions } from '../../../../../models/Select';
import { Responsible } from '../../../../../models/Responsible';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from '../../../../../utils/extenal-libs';
import ClienteService from '../../../../../services/providers/client';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import SeletorTime from '../../../../../components/common/SeletorTime';
import { ProjetoSchema } from './validation';

export interface ProjectSideFormProps {
    handleLoading: (loading: boolean) => void;
    fillProject: (projectOption: SelectOptions) => void;
    toogleSideModal: (isOpen: boolean) => void;
}

export function ProjectSideForm(props: ProjectSideFormProps) {
	const {t} = useTranslation();
	const [tagSelectedValue, setTagSelectedValue] = useState<Array<string | number>>([]);
	const [tagSelected, setTagSelected] = useState<MultiValue<SelectOptions>>([]);
	const [clienteValorSelecionado, setClienteValorSelecionado] = useState({value: '', label: t('Selecione um cliente')});
	const [timeSelecionado, setTimeSelecionado] = useState<number[]>([]);
	const [admins, setAdmins] = useState<number[]>([]);
    
	const clienteService = new ClienteService();
    
	//services
	const projectService = new ProjetoService();

    const adicionaPessoaTime = useCallback(
		(pessoaRecebida: Responsible) => {
			setTimeSelecionado([...timeSelecionado, pessoaRecebida.id]);
		},
		[timeSelecionado],
	);

    const handleRemovePessoaTime = useCallback(
		(pessoaRecebida: Responsible) => {
			const index = timeSelecionado.findIndex(id => id === pessoaRecebida.id);
			timeSelecionado.splice(index, 1);
		},
		[timeSelecionado],
	);

	const _postProjeto = (params: FormData) => {
        props.handleLoading(true);
		projectService.postProjeto(
			params,
			response => {
				if (response) {
					toast.success(t('Projeto salvo com sucesso!'));
                    props.handleLoading(false);
                    props.fillProject({
                        value: response.data.id,
                        label: response.data.projeto
                    });
                    props.toogleSideModal(false);
				}
			},
			() => {
                props.handleLoading(false);
                toast.error(t('Erro ao tentar salvar projeto!'));
			},
		);
	};

    const pegarValorDeTag = (tagSelectedValue: MultiValue<SelectOptions>) => {
		if (tagSelectedValue !== null) {
			const tags = tagSelectedValue.map(item => item.value);
			setTagSelectedValue(tags);
		} else {
			setTagSelectedValue([]);
		}
	};
    
	const initialValues = {
        nome: '',
        cliente: '',
        notas: '',
        time: [],
        tags: [],
        admins: [],
	};
	

	return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={ProjetoSchema}
                onSubmit={values => {

                    const form_data = new FormData();

                    for (const key in values) {
                        if (key === 'time') {
                            form_data.append(key, timeSelecionado.toString());
                        } else if (key === 'tags') {
                            form_data.append(key, tagSelectedValue.toString());
                        } else if (key === 'admins') {
                            form_data.append(key, admins.toString());
                        } else {
                            form_data.append(key, values[key as keyof typeof values].toString());
                        }
                    }

                    _postProjeto(form_data);
                }}
            >
                {props => {
                    const { handleSubmit, setFieldValue, errors, touched } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                                <fieldset>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-12">
                                                <label className="modal-label" htmlFor="nome">
                                                    {t('Nome do projeto')}*
                                                </label>

                                                <Field
                                                    autoComplete={'off'}
                                                    type="text"
                                                    name="nome"
                                                    className={`${
                                                        errors.nome && touched.nome
                                                            ? 'form-error-style-input-projetos'
                                                            : 'form-default-style-input-projetos'
                                                    }
                                                    `}
                                                />

                                                <ErrorMessage name="nome">
                                                    {msg => (
                                                        <div>
                                                            <div className="input-error-style" role="alert">
                                                                <FontAwesomeIcon
                                                                    icon={faExclamationTriangle}
                                                                    className="mr-1"
                                                                />
                                                                {msg}
                                                            </div>
                                                        </div>
                                                    )}
                                                </ErrorMessage>

                                                <div
                                                >
                                                    <label className="modal-label" htmlFor="cliente">
                                                        {t('Selecione o cliente')}*
                                                    </label>
                                                    <AsyncSelector
                                                        id={'cliente'}
                                                        value={clienteValorSelecionado}
                                                        onChange={(event) => {
                                                            const e = event as SelectOptions;
                                                            setClienteValorSelecionado({
                                                                label: e.label,
                                                                value: e.value.toString()
                                                            });
                                                            setFieldValue('cliente', e !== null ? e.value : '');
                                                        }}
                                                        FieldName={'cliente'}
                                                        placeholder={t('Selecione um cliente')}
                                                        // styles={customStyles}
                                                        error={errors.cliente ? errors.cliente : ''}
                                                        touched={touched.cliente ? touched.cliente : false}
                                                        loadOptions={clienteService.loadClientesSelect as LoadOptions<any, GroupBase<any>, LoadOptionsAdditional>} 
                                                        name="cliente"
                                                        errorMessage={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 ">
                                                <label
                                                    className="modal-label"
                                                    htmlFor="notas"
                                                >
                                                    {t('Notas')}
                                                </label>

                                                <Field
                                                    style={{
                                                        background: '#fff',
                                                    }}
                                                    autoComplete={'off'}
                                                    type="text"
                                                    component="textarea"
                                                    name="notas"
                                                    className={`text-area-side 
                                                        ${
                                                            errors.notas && touched.notas
                                                                ? 'form-error-style-textarea-projetos'
                                                                : 'form-default-style-textarea-projetos'
                                                        }
                                                    `}
                                                />

                                                <ErrorMessage name="notas">
                                                    {msg => (
                                                        <div>
                                                            <div className="input-error-style" role="alert">
                                                                <FontAwesomeIcon
                                                                    icon={faExclamationTriangle}
                                                                    className="mr-1"
                                                                />
                                                                {msg}
                                                            </div>
                                                        </div>
                                                    )}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <br />
                                        <div
                                            style={{ top: '-10px', padding: '0' }}
                                            className={`col-12`}
                                        >
                                            <label
                                                className="modal-label"
                                                style={{ margin: '0px' }}
                                                htmlFor="tags"
                                            >
                                                Tags
                                            </label>
                                            <CreatableSelect
                                                value={tagSelected}
                                                isMulti
                                                onChange={e => {
                                                    if(e && Array.isArray(e)) {
                                                        pegarValorDeTag(e);
                                                        setTagSelected(e);
                                                    }
                                                }}
                                                placeholder={t('Adicione tags')}
                                                options={[]}
                                                styles={customStyles}
                                            />
                                        </div>
                                        <input
                                            style={{ display: 'none' }}
                                            id="input-submit-projeto-side"
                                            type="submit"
                                        />
                                </div>
                                </fieldset>
                            </form>
                    );
                }}
            </Formik>

            <div className="container">
                <SeletorTime
                    setAdmins={setAdmins}
                    adicionaPessoaTime={adicionaPessoaTime}
                    removePessoaTime={handleRemovePessoaTime}
                    isEditing={false}
                    isFormSide={true}
                />
            </div>
        </>
	);
}
