import React from 'react';
import { ItemContainer, ItemWrapper } from './styled';
import { Draggable } from 'react-beautiful-dnd';

interface KanbanItemProps {
    readonly index: number;
    readonly id: string;
    readonly children: React.ReactNode;
    readonly requestingAdd: {
        readonly componentId: number | null
    };
}

export default function KanbanItem({children, id, index, requestingAdd}: KanbanItemProps) {
    const isRequestingAdd = requestingAdd.componentId === parseInt(id);
    return  (<div style={{cursor: isRequestingAdd ? 'progress' : 'initial'}}>
            <ItemWrapper isRequestingAdd={isRequestingAdd}>
                <Draggable key={id} draggableId={id} index={index}>
                    {(provided, snapshot) => {
                        return <ItemContainer isDragging={snapshot.isDragging} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} style={{...provided.draggableProps.style}} >
                            {children}
                        </ItemContainer>
                    }
                    }
                </Draggable>
            </ItemWrapper>
        </div>)

}