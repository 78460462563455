import styled from "styled-components";
import { theme } from "../../../../../../styles/theme";

export const TableRowContainer = styled.tr`
height: 50px;`;

interface TableTdProps {
    pointerEvents?: 'auto' | 'none';
    cursor?: 'pointer' | 'default';
}

export const TableTd = styled.td<TableTdProps>`
	padding: 10px;
    font-size: 14px;
    font-weight: normal;
    color: ${theme.colors.background.mainBg};
    ${({pointerEvents}) => pointerEvents && `pointerEvents: ${pointerEvents}`};
    ${({cursor}) => cursor && `cursor: ${cursor}`};
    .project-name {
        display: flex;
        flex-direction: column;
    }
`;


export const ProjectNameContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const ProjectName = styled.span`
    color: ${theme.colors.text.titleText};
    font-size: 14px;
    font-weight: bold;
`;

export const ClientName = styled.span`
    font-size: 12px;
    color: #838383;
`;

export const CompanyImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 36px;
    max-height: 36px;
    border-radius: 50%;
    img {
        width: 36px;
    }
`

export const ImageContainer = styled.div`
    display: flex;
    img {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-right: -8px;
        font-size: 0.6rem;
    }
`
export const IconContainer = styled.button`
    display: flex;
    border-radius: 8px;
    border: 1px solid ${theme.colors.background.mainBg};
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: white;
    &:disabled {
        cursor: default;
        opacity: 0.7;
    }

`

export const TaskContainer = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    color: ${theme.colors.background.mainBg};
    font-weight: 500;
    &:hover {
        opacity: 0.8;
    }
`

export const ImageOver = styled.span`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -4px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: rgba(0,0,0,.3);
        color: #fff;
        font-weight: 700;
        font-size: 11px;
`