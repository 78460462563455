import styled from 'styled-components';
import { theme } from '../../../../../styles/theme';

interface ProjectNameProps {
	isLoading: boolean;
}

interface MenuButtonProps {
	isLoading: boolean;
}

export const ProjectItem = styled.div`
	background-color: ${theme.colors.background.mainBg};
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
    position: relative;
	border-radius: 4px;
`;

export const ProjectTop = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
`;

export const PhotoContainer = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 100px;
	min-width: 40px;
	min-height: 40px;
`;

export const ProjectInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const ProjectInfoHeader = styled.div`
	display: flex;
	flex-direction: column;
`;



export const ProjectName = styled.span<ProjectNameProps>`
	color: white;
	font-size: 0.75rem;
	font-weight: 600;
	line-height: 14px;
	cursor: pointer;
	:hover {
		${({isLoading}) => isLoading ?'text-decoration: underline;' : 'cursor: progress'}
	}
`;
export const ProjectClient = styled.span`
	color: white;
	font-size: 0.625rem;
	font-weight: 500;
`;

export const ProjectDates = styled.span`
	color: white;
	font-weight: 500;
	font-size: 0.75rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ItemLine = styled.div`
	height: 1px;
	width: 100%;
	background-color: white;
`;

export const OverProfile = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff;
	font-weight: 700;
	position: absolute;
	right: 0;
	bottom: 0;
`;

export const MenuButton = styled.button<MenuButtonProps>`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	border: none;
	background: transparent;
	color: #fff;
	padding: 0;
	${({isLoading}) => isLoading ?'cursor: pointer;' : 'cursor: progress'}

`;

export const TeamContainer = styled.div`
	width: 100%;
	position: relative;
	height: 24px;
`;

export const ItemBar = styled.div`
	border-radius: 4px 0px 0px 4px;
	background: var(--Linear, linear-gradient(180deg, #d7f205 0%, #29dab1 100%));
	width: 5px;
	height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
`;

export const TeamPictures = styled.div`
	display: flex;
	flex-direction: row;
`

export const TeamImg = styled.img`
	width: 24px;
	height: 24px;
	border-radius: 50%;
`;


export const TeamPlus = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: rgba(255,255,255,0.2);
	color: white;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;

	;
`