import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import FieldFormik from '../../../../components/common/Fields/FieldFormik';
import { useTranslation } from 'react-i18next';
import AsyncSelector from '../../../../components/common/AsyncSelector';
import { SelectOptions } from '../../../../models/Select';
import ClienteService from '../../../../services/providers/client';
import { TimeSchema } from './validation';
import SeletorTime from '../../../../components/common/SeletorTime';
import { Responsible } from '../../../../models/Responsible';
import ProjetoService from '../../../../services/providers/project';
import { toast } from 'react-toastify';
import { TimeResponse } from '../../../../services/providers/okrs/times/types';
import { timesOkrsAtom } from '../../../../atoms/Okrs';
import { useSetAtom } from 'jotai';
import { Project } from '../../../../models/Project';
import TimeOkrsService from '../../../../services/providers/okrs/times';

interface TimeFormProps {
	time?: TimeResponse;
	editing: boolean;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	setModalShow: (loading: boolean) => void;
}

interface InitialValues {
	nome: string;
	cliente: string | number;
	time: Responsible[];
}

const TimeForm = ({
	time,
	editing = false,
	loading = false,
	setLoading,
	setModalShow,
}: TimeFormProps) => {
	const { t } = useTranslation();
	const clienteService = new ClienteService();
	const timeOkrsService = new TimeOkrsService();
	const [clienteValorSelecionado, setClienteValorSelecionado] = useState<
		SelectOptions
	>({ value: '', label: t('Selecione um cliente') });
	const [timeSelecionado, setTimeSelecionado] = useState<number[]>([]);
	const [tempItemSelecionado, setTempItemSelecionado] = useState<any>([]);
	const projetoService = new ProjetoService();

    const setTimesOkrs = useSetAtom(timesOkrsAtom)

	const tempEditing = editing && time !== undefined

	const initialValues: InitialValues = tempEditing ? {
		nome: time.nome,
		cliente: time.cliente.id,
		time: time.time
	} :{
		nome: '',
		cliente: '',
		time: [],
	};

	const _getTime = (time: TimeResponse) => {
		setClienteValorSelecionado({
			value: time.cliente.id,
			label: time.cliente.nome,
		});

		const arrayDeIds = time.time.map(
			(time: any) => time.id,
		);
		adicionaPessoaTime(time.time);
		setTimeSelecionado(arrayDeIds);

		setTempItemSelecionado(time);
	};

	
	const handleAfterPostTime = (timeResponse: TimeResponse) => {
		setTimesOkrs(prev => {
			const tempTimes = [...prev.timesOkrs, timeResponse]

			return {
				...prev,
				timesOkrs: tempTimes
			}
		})
	}

	const handleAfterPutTime = (timeResponse: Project) => {
		setTimesOkrs(prev => {
			const tempTimes = [...prev.timesOkrs]
			const findIndex = tempTimes.findIndex(item => item.id === timeResponse.id)

			if(findIndex  > -1) {
				const [removed] = tempTimes.splice(findIndex, 1)
				tempTimes.splice(findIndex, 0, {
					...removed, 
					...timeResponse, 
					tarefas: removed.tarefas
				})
			}

			return {
				...prev,
				timesOkrs: tempTimes
			}
		})
	}

	const _postTime = (params: any) => {
		setLoading(true);
		const newParams = {
			...params,
			time: timeSelecionado.join(','),
			e_time_okr: true,
		};

		projetoService.postProjeto(
			newParams,
			response => {
				if (response) {
					toast.success(t('Time salvo com sucesso!'));
					setLoading(false);
					setModalShow(false);
					timeOkrsService.getTimeOkrsByID({id: response.data.id, filters: ""}).then(projeto => {
						handleAfterPostTime(projeto)
					})
					
				}
			},
			() => {
				toast.error(t('Erro ao tentar salvar time!'));
				setLoading(false);
			},
		);
	};

	const _patchTime = (params: FormData, time: TimeResponse) => {
		setLoading(true);
		projetoService.patchProjeto(
			params,
			time.id,
			response => {
				if (response) {
					toast.success(t('Time editado com sucesso!'));
					setLoading(false);
					setModalShow(false);
					handleAfterPutTime(response.data)
				}
			},
			() => {
				toast.error(t('Erro ao tentar salvar time!'));
				setLoading(false);
			},
		);
	};

	const adicionaPessoaTime = useCallback(
		(pessoaRecebida: Responsible | Responsible[]) => {
			if (Array.isArray(pessoaRecebida)) {
				const pessoas = pessoaRecebida.map(item => item.id);
				setTimeSelecionado(pessoas);
			} else {
				setTimeSelecionado([...timeSelecionado, pessoaRecebida.id]);
			}
		},
		[timeSelecionado],
	);

	const handleRemovePessoaTime = useCallback(
		(pessoaRecebida: Responsible) => {
			const index = timeSelecionado.findIndex(id => id === pessoaRecebida.id);
			timeSelecionado.splice(index, 1);
		},
		[timeSelecionado],
	);

	useEffect(() => {
		if (
			tempItemSelecionado.time &&
			editing &&
			tempItemSelecionado.time.length > 0
		) {
			adicionaPessoaTime(tempItemSelecionado.time);
		}
	}, [tempItemSelecionado]);

	useEffect(() => {
		if (tempEditing) _getTime(time);
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={TimeSchema}
			onSubmit={values => {
				const form_data = new FormData();
				for (const key in values) {
					const typedKey = key as keyof InitialValues;
					if (typedKey === 'time') {
						form_data.append(key, timeSelecionado.toString());
					} else {
						form_data.append(key, values[typedKey].toString());
					}
				}
				tempEditing ? _patchTime(form_data, time) : _postTime(values);
			}}
		>
			{({ errors, touched, setFieldValue }) => {
				return (
					<FormikForm className="modal-width-time">
						<FieldFormik
							title={`${t('Nome do time')}*`}
							autoComplete={'off'}
							nameField="nome"
							errors={errors.nome ? errors.nome : ''}
							touched={touched.nome ? touched.nome : false}
							name="nome"
							disabled={loading}
						/>
						<label className="modal-label" htmlFor={'time'}>
							{`${t('Selecione o cliente')}*`}
						</label>
						<AsyncSelector
							id={'cliente'}
							value={clienteValorSelecionado}
							onChange={e => {
								const selected = e as SelectOptions;
								setClienteValorSelecionado(selected);
								setFieldValue(
									'cliente',
									selected !== null ? selected.value : '',
								);
							}}
							FieldName={'cliente'}
							placeholder={`${t('Selecione um cliente')}*`}
							error={errors.cliente ? errors.cliente : ''}
							touched={touched.cliente ? touched.cliente : false}
							loadOptions={clienteService.loadClientesSelect as any}
							name="cliente"
							errorMessage={true}
							disabled={loading}
						/>
						<div className="mt-4">
							{editing ? (
								<SeletorTime
									projetoTime={tempItemSelecionado.time}
									editandoTime={tempItemSelecionado}
									setAdmins={() => {}}
									adicionaPessoaTime={adicionaPessoaTime}
									removePessoaTime={handleRemovePessoaTime}
									isEditing={editing}
								/>
							) : (
								<SeletorTime
									setAdmins={() => {}}
									adicionaPessoaTime={adicionaPessoaTime}
									removePessoaTime={handleRemovePessoaTime}
									isEditing={editing}
								/>
							)}
						</div>

						<input
							style={{ display: 'none' }}
							id="formSideModal-time"
							type="submit"
						/>
					</FormikForm>
				);
			}}
		</Formik>
	);
};

export default TimeForm;
