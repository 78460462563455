import React, { ReactNode, useCallback } from 'react';
import { faFilter, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderLink from '../../common/HeaderLink';
import { useFilterCommonState } from '../../../contexts/Filter/FilterCommonState';
import { Filter, FilterContainer } from './styled';
import { t } from 'i18next';
import { HeaderLinkOptions } from '../../common/HeaderLink/types';

export interface PageContainerHoraProps {
	filter: ReactNode;
	children: ReactNode;
	leftHeaderContent: ReactNode;
	rightHeaderContent: ReactNode;
	headerType: keyof HeaderLinkOptions;
	showHeader: boolean;
	filtroShadow: boolean;
}

const PageContainerHora = ({
	filter,
	children,
	leftHeaderContent,
	rightHeaderContent,
	headerType,
	showHeader = false,
	filtroShadow = false,
}: PageContainerHoraProps) => {
	const { isFilterOpen, setIsFilterOpen } = useFilterCommonState();

	const handleToggleFilter = useCallback(() => setIsFilterOpen(!isFilterOpen), [setIsFilterOpen, isFilterOpen]);

	const mobileFilterContainer = () => {
		return (
			<div className="registros-btn-filtrar">
				<div className="registros-btn-mobile">
					<button
						className="registros-btn-open"
						onClick={handleToggleFilter}
					>
						Filtros
						{isFilterOpen ? (
							<FontAwesomeIcon icon={faTimes} className="horas-icon-filter" />
						) : (
							<FontAwesomeIcon icon={faFilter} className="horas-icon-filter" />
						)}
					</button>
					<div
						id='react-tour__report-filter'
						className="registros-filter-mobile"
						style={{
							height: isFilterOpen ? 'auto' : '0px',
							overflow: isFilterOpen ? 'unset' : 'hidden',
						}}
					>
						{filter}
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{showHeader && <HeaderLink type={headerType} />}
			<div
				style={{
					gridTemplateColumns: isFilterOpen ? '75% 25%' : '99.5% 0.5%',
					transition: 'all 2s',
				}}
				className="horas-container-geral"
			>
				<div className="registros-container">
					<div className="registros-header">
						<div className="d-flex registros-component">
							{leftHeaderContent}
						</div>
						<div className="d-flex registros-component">
							{rightHeaderContent}
						</div>
					</div>
					{mobileFilterContainer()}
					{children}
				</div>
				<FilterContainer
					hasShadow={filtroShadow}
					className={`horas-filtrar`}
				>
					<button
						onClick={handleToggleFilter}
						className="btn-expandir-filter"
						style={{
							right: isFilterOpen ? '25%' : '0.5%',
						}}
					>
						<FontAwesomeIcon
							style={{ marginTop: '5px' }}
							icon={faSearch}
							className="expandir-icon"
						/>
					</button>
					<div className="relatorio-filtrar-title">{t("Filtrar")}</div>
					<Filter filterOpen={isFilterOpen}>{filter}</Filter>
				</FilterContainer>
			</div>
		</>
	);
};

export default PageContainerHora;
