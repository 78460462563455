import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import HttpService from '../../config/http';
import { ColumnType } from '../../../models/ColumnType';
import { CreateExtraColumn, ExtraColumn } from '../../../models/ExtraColumn';
import { DataType } from '../types';
import { ExtraColumnValues } from '../../../models/ExtraColumnValue';

export class PeopleManagementService {
	private api: HttpService;
	
	constructor(props?: any) {
		this.api = new HttpService(props);
	}

	getColumnsTypes = async () => {
		const result: AxiosResponse<DataType<ColumnType[]>> = await this.api
			.get(`api/v1/pessoas/gestao/colunas-tipo/`);

		if (result.status === 200) {
			return (result.data);
		} else {
				throw new Error(t('Erro ao tentar carregar os tipos das colunas') + "!");
			}
		
	};

    
	getCompanyColumns = async () => {
		const result: AxiosResponse<DataType<ExtraColumn[]>> = await this.api
			.get(`api/v1/pessoas/gestao/colunas-empresa/`);

		if (result.status === 200) {
			return (result.data);
		} else {
				throw new Error(t('Erro ao tentar carregar os colunas') + "!");
		}
	};

    postCompanyColumn = async (params: CreateExtraColumn) => {
		const result: AxiosResponse<ExtraColumn> = await this.api
			.post(`api/v1/pessoas/gestao/colunas-empresa/`, params);

		if (result.status === 201) {
			return (result.data);
		} else {
			throw new Error(t('Erro ao tentar salvar coluna') + "!");
		}
	};

    patchCompanyColumn = async (params: {nome?:string, id:string, ordenacao?: number}) => {
		const result: AxiosResponse<ExtraColumn> = await this.api
			.patch(`api/v1/pessoas/gestao/colunas-empresa/${params.id}/`, params);

		if (result.status === 200) {
			return (result.data);
		} else {
			if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof ExtraColumn]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar atualizar coluna') + "!");
			}
		}
	};

	deleteCompanyColumn = async (id:string) => {
		const result: AxiosResponse<""> = await this.api
			.delete(`api/v1/pessoas/gestao/colunas-empresa/${id}/`);

		if (result.status === 204) {
			return;
		}	else {
			throw new Error(t('Erro ao tentar deletar coluna') + "!");
		}
	};

    postUserValueColumn = async (params: {coluna_empresa:string, valor: string, pessoa: string}) => {
		const result: AxiosResponse<ExtraColumnValues> = await this.api
			.post(`api/v1/pessoas/gestao/colunas-usuario/`, params);

		if (result.status === 201) {
			return (result.data);
		} else {
				throw new Error(t('Erro ao tentar salvar') + "!");
		}
	};

    patchUserValueColumn = async (params: {valueId:string, valor: string, pessoa: string}) => {
		const result: AxiosResponse<ExtraColumn> = await this.api
			.patch(`api/v1/pessoas/gestao/colunas-usuario/${params.valueId}/`, params);

		if (result.status === 200) {
			return (result.data);
		} else {
			if (typeof result.data === typeof {}) {
				const objError = result.data;
				for (const key in objError) {
					throw new Error(`${objError[key as keyof ExtraColumn]}`);
				}
			} else {
				throw new Error(t('Erro ao tentar atualizar') + "!");
			}
		}
	};
}
