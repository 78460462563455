import styled from "styled-components";
import { theme } from "../../../styles/theme";

interface ButtonTypeProps {
    isGridSelected: boolean;
}

export const ButtonList = styled.button<ButtonTypeProps>`
    background-color: ${({isGridSelected}) => isGridSelected ?  '#E0E0E0' : theme.colors.background.mainBg };
    height: 3rem;
    padding: 12px;	
    border: none;
    border-radius: 8px;
    &:disabled {
        cursor: default;
    }
    @media (max-width: 900px) {
        display: none;
    }
`

export const ButtonGrid = styled.button<ButtonTypeProps>`
    background-color: ${({isGridSelected}) => isGridSelected ? theme.colors.background.mainBg : '#E0E0E0'};
    height: 3rem;
    padding: 12px;	
    border: none;
    border-radius: 8px;
    &:disabled {
        cursor: default;
    }


`

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`