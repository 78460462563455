import React, { useEffect} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import 'material-symbols/outlined.css'
import './i18n';

import Routes from './routes';
import { GlobalStyles } from './styles/globalStyles';
import { theme } from './styles/theme';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';

import { clarity } from 'react-microsoft-clarity';
import 'moment/locale/pt-br';

import ReactGA from 'react-ga';

import './global.css';
import './index.css';
import './tempInfoStyle.css';
import { UserProvider } from './contexts/User';
import TagManager from 'react-gtm-module';

registerLocale('pt-BR', ptBR);

//GOOGLE ANALYTICS
const trackingId = 'UA-183975595-1'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.set({
	userId: 'planusprod',
});
ReactGA.pageview(window.location.pathname + window.location.search);

const clarityId = process.env.REACT_APP_ENVIROMENT === "prod"  ? "fg8raw5o3e" : "fizfa8fsb1";

//Google Tag Manager
const gtmId = process.env.REACT_APP_ENVIROMENT === "prod"  ? "GTM-M8F5QBZ" : "";

const tagManagerArgs = {
	gtmId
}

TagManager.initialize(tagManagerArgs);

const App = () => {
	useEffect(() => {
		clarity.init(clarityId);
	}, []);
	return (
		<ThemeProvider theme={theme}>
			<UserProvider>
				<BrowserRouter>
					<GlobalStyles />
					<Routes />
					<ToastContainer
						position="bottom-right"
						autoClose={5000}
						hideProgressBar
					/>
				</BrowserRouter>
			</UserProvider>
		</ThemeProvider>
	)
}

ReactDOM.render(
	<App />,
	document.getElementById('root'),
);
