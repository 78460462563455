import styled from "styled-components";
import { TaskContainerProps, TaskWrapperProps } from "./types";

export const TaskWrapper = styled.div<TaskWrapperProps>`
	pointer-events: ${({isRequestingAdd}) => isRequestingAdd ? 'none' : 'initial'};
`;

export const TaskContainer = styled.div<TaskContainerProps>`
	user-select: none;
    border-left: 5px solid #5AD8E3;
    margin: 0 0 8px 0;
    min-height: 50px;
    color: white;
    background: ${({isDragging}) => isDragging ? '#263b4a' : '#496070'};
    border-radius: 4px;
`;

export const TaskContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 18px;
    padding: 8px;

	p {
		padding: 0;
    	margin: 0;
	}
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

export const Title = styled.p`
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 14px;
	font-size: 0.75rem;
    cursor: pointer;
    padding: 8px;

	:hover {
    	text-decoration: underline;
	}
`;

export const MenuButton = styled.button`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border: none;
    background: transparent;
    color: #fff;
    padding: 0;
`;

export const AssignContainer = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.text.titleText};
`;

export const Bottom = styled.div`
	display: flex;
    justify-content: space-between;
    height: 24px;
    align-items: flex-end;

	.kanban-task-date {
		display: flex;
		gap: 8px;
		align-items: center;
	}
`;

export const AddTimeButton = styled.button`
    display: flex;
    border: none;
    background-color: transparent;
    padding: 0;

	svg {
		color: #fff;
		width: 14px;
		height: 14px;
	}
`;

interface DateTextProps {
    isEditingDate: boolean;
}

export const DateText = styled.span<DateTextProps>`
    display: ${({isEditingDate}) => isEditingDate ? "none" : 'flex'};
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

export const DateInput = styled.input`
    display: flex;
    align-items: center;
    outline: none;
    height: 16px;
    padding: 2px;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;

    ::before {
        color: white !important;
        top: -2px !important;
        right: 0 !important;
        height: 16px !important;
    }
`;

export const ProfileContainer = styled.div`
    display: flex;

	img {
		height: 24px;
		width: 24px;
		border-radius: 50%;
	}
`;

export const OverProfile = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-weight: 700;
`;
