import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import ModalHoras from './ModalHoras';
import HoraService from '../../../services/providers/hour';

import TurTutorial from '../../../components/tourTutorial';
import { LoaderPagination } from '../../../components/common/loaders/LoaderPagination';
import FormFilter from './FormFilter';
import Lista from './Lista';

import { useQuery } from '../../../utils/hooks/useQuery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ReactGA from 'react-ga';

import { useRegistrosDeHoras } from '../../../contexts/RegistrosDeHoras';
import { useCalendarioRegistros } from '../../../contexts/CalendarioRegistros';

import '../../../i18n';

import './style.css';
import { useTranslation } from 'react-i18next';
import RegistrosContainer from '../../../components/containers/Registros';
import {
	
	useHourFilters,
} from '../../../contexts/Filter/Hour';

import {
	BackButton,
	HeaderContainer,
	RegistrosContainerBody,
} from './styled';
import formatObjectToFilter from '../../../utils/funcoes/formatObjectToFilter';
import moment from 'moment';
import HeaderLink from '../../../components/common/HeaderLink';
import { useUser } from '../../../contexts/User';
import { useStopwatch } from '../../../contexts/Stopwatch';

let controller = null; 

export default function Horas(props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	// const [horaData, setHoraData] = useState([]);
	const {
		addRegistros,
		deleteRegistroById,
		setRegistros,
		registros,
		loadingRegistros,
	} = useRegistrosDeHoras();
	const { deleteCalendarioRegistroById } = useCalendarioRegistros();

	const [editingHoras, setEditingHoras] = useState(false);
	const [horaEditada, setHoraEditada] = useState([]);

	const searchHoras = '';
	const tarefasSelectFilter = '';
	const pessoasSelectFilter = '';
	const twoRangeDatesFilter = ['', ''];

	const [totalPaginasHoras, setTotalPaginasHoras] = useState(null);
	const [paginasHoras, setPaginasHoras] = useState(1);
	const { handleToggleModal} = useStopwatch();


	const [modalHorasShow, setModalHorasShow] = useState(false);

	const history = useHistory();
	const { userDetails, loadingUser} = useUser();

	const query = useQuery();
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);




	const handlePostPatchHora = saveAndContinue => {
		if (saveAndContinue === true) {
			setModalHorasShow(true);
		} else {
			setModalHorasShow(false);
		}

		//_getHorasPaginacao();
	};

	const handleDeletar = id => {
		_deleteHoras(id);
	};
	const {
		search,
		selectedTask,
		selectedPerson,
		startDate,
		endDate,
		selectedTime,
	} = useHourFilters();

	const getFormatedDate = date => {
		const selectedDate =
			selectedTime.value === '' ? '' : moment(date).format('YYYY-MM-DD');
		return selectedDate;
	};

	const _getHorasPaginacao = (
		page = 1,
		pessoa = formatObjectToFilter(selectedPerson)
	) => {
		setLoading(true);
 
		if (controller !== null) {
			controller.abort();
		}

		controller = new AbortController();

		const signal = controller.signal;

		const horaService = new HoraService();
		horaService.getHorasPaginacao(
			page,
			{
				search: search,
				tarefa: formatObjectToFilter(selectedTask),
				pessoa: pessoa,
			},
			getFormatedDate(startDate),
			getFormatedDate(endDate),
			response => {
				if (response.results) {
				}
				setLoading(false);
				if (page === 1) {
					setRegistros(response.results);
					setTotalPaginasHoras(response.total_pages);
					setPaginasHoras(page);
				} else {
					addRegistros(response.results);
					setTotalPaginasHoras(response.total_pages);
					setPaginasHoras(page);
				}
				setLoading(false);
			},
			error => {
				setRegistros([]);
				setLoading(false);
				// toast.error(error);
			},
			{ signal },
		);
	};

	const _deleteHoras = id => {
		const horaService = new HoraService();
		horaService.deleteHoras(
			id,
			response => {
				if (response) {
					setModalHorasShow(false);
					deleteRegistroById({
						registroId: id,
						paginasHoras,
						pessoasSelectFilter,
						searchHoras,
						tarefasSelectFilter,
						twoRangeDatesFilter,
					});
					deleteCalendarioRegistroById(id);
					toast.success(t('Registro de hora removido com sucesso!'));
				}
			},
			error => {
				toast.error(error);
			},
		);
	};

	const handleNavClick = useCallback(() => {
		handleToggleModal(true);
	}, [setEditingHoras, setModalHorasShow])

	const handlePagination = (page, pessoa) => {
		_getHorasPaginacao(page, pessoa);
	};

	return (
		<>
			{userDetails && !userDetails.has_finished_tour && <TurTutorial nomeRota="hora"  />}
			<HeaderLink type='hour' headerButtons={[
				{handleClick: handleNavClick,
					icon: faPlus,
					isHidden: userDetails?.acesso_cod === 'e',
					text: 'Hora',
					className: 'react-tour__add-hora'

				}
	
			]} >
			</HeaderLink>
			
			<RegistrosContainer>
				<HeaderContainer>
					{!!query.get('urlBack') && (
						<BackButton
							onClick={history.goBack}
							className="btn-go-back"
						>
							<FontAwesomeIcon icon={faArrowLeft} className="voltar-icon" />
							{t('Voltar')}
						</BackButton>
					)}
					<FormFilter handlePagination={handlePagination} />
				</HeaderContainer>

				<DinamicPagination
					loading={loading}
					totalPages={totalPaginasHoras}
					currentPage={paginasHoras}
					lengthData={registros.length}
					returnPage={handlePagination}
					msgToEmptyData={t('Sem registros de horas nesse período')}
				>
					<Lista
						data={registros}
						loading={loading || loadingRegistros || loadingUser} 
						usuarioPerfil={userDetails?.acesso_cod}
						setEditingHoras={setEditingHoras}
						setHoraEditada={setHoraEditada}
						setModalHorasShow={setModalHorasShow}
					/>
				</DinamicPagination>
				<>
					{modalHorasShow ? (
						<ModalHoras
							handleDeletar={handleDeletar}
							modalHorasShow={setModalHorasShow}
							handlePostPatchHora={handlePostPatchHora}
							editingHoras={editingHoras}
							filter={{
								paginasHoras,
								searchHoras,
								tarefasSelectFilter,
								pessoasSelectFilter,
								twoRangeDatesFilter,
							}}
							// modalIsShowing={modalHorasShow}
							itemSelecionado={horaEditada}
						/>
					) : null}
				</>
			</RegistrosContainer>
		</>
	);
}

const DinamicPagination = ({
	children,
	loading,
	totalPages,
	currentPage,
	lengthData,
	returnPage,
}) => {
	const { t } = useTranslation();
	const paginacaoDinamica = useCallback(e => {
		let element = e.target;
		if (totalPages === currentPage) {
			return;
		} else {
			if (!loading) {
				if (element.scrollHeight - element.scrollTop === element.clientHeight) {
					const page = currentPage + 1;
					returnPage(page);
					element.scrollTop -= 5;
				}
			}
		}
	}, [returnPage, currentPage, totalPages]);

	return (
		<RegistrosContainerBody
			id="registros-horas"
			onScroll={paginacaoDinamica}
		>
			{children}
			{lengthData === 0 && loading === false ? (
				<span
					style={{
						display: 'flex',
						justifyContent: 'center',
						color: '#8099a7',
					}}
				>
					{t('Sem registros de horas nesse período')}
				</span>
			) : (
				<></>
			)}
			<LoaderPagination
				showLoader={totalPages === currentPage}
				loading={loading}
				onClick={e => {
					// getHorasPaginacao({page + 1});
					paginacaoDinamica(e);
				}}
			/>
		</RegistrosContainerBody>
	);
};
