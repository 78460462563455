import React, { useCallback } from 'react';
import { faCheck, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import Icon from '../../../../../../components/common/Icons/icon';
import {
	CheckContainer,
	CheckContent,
	CheckIcon,
	IconContainer,
	TheadToolsContainer,
} from './styled';

interface TheadToolsProps {
	endMultiTasks: (array: string[]) => void;
	selectArrayList: string[];
	selectAllCheckboxList: (source: any) => void;
}

const TheadTools = ({
	endMultiTasks,
	selectArrayList,
	selectAllCheckboxList,
}: TheadToolsProps) => {
	const handleEndTasks = useCallback(() => {
		endMultiTasks(selectArrayList);
	}, [endMultiTasks, selectArrayList])

	return(
	<TheadToolsContainer>
		<input
			title={t('Selecionar Todas')}
			type="checkbox"
			onChange={selectAllCheckboxList}
		/>
		<IconContainer>
			<Icon
				icon={faSortDown}
				title={undefined}
				type={undefined}
				colorIcon={undefined}
				fontSizeIcon={undefined}
				pointer={undefined}
			/>
		</IconContainer>
		<CheckContainer>
			<CheckContent title={t('Encerrar tarefas')} isSelected={selectArrayList.length > 0}>
				<div
					role={'button'}
					onClick={handleEndTasks}
				>
					<CheckIcon icon={faCheck} />
				</div>
			</CheckContent>
		</CheckContainer>
	</TheadToolsContainer>
)};

export default TheadTools;
