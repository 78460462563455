import styled from 'styled-components';
import { theme } from '../../../../../styles/theme';

export const Table = styled.table``;

export const TableHeader = styled.thead`
	color: #838383;
	background-color: ${theme.colors.background.modalBg} ;
	position: sticky;
	top: 0;
	z-index: 1;
	box-shadow: 0px -3px 0px 1px #f7f6f5;
    border-radius: 8px;
`;


export const TableBody = styled.tbody`
    background-color: white;
	position: relative;
`;

interface MemberBlockProps {
	isMember: boolean;
}

export const MemberBlock = styled.div<MemberBlockProps>`
	position: absolute;
	height: 60px;
	left: 0;
	background: ${({isMember}) => isMember ? theme.colors.button.saveBtn : '#D9D9D9'};
`


export const TableRowHeader = styled.tr`
	background-color: #d9d9d9;
	> * {
		&:first-child {
			border-radius: 8px 0px 0px 0px;
            padding-left: 20px;
		}
	}
	> * {
		&:last-child {
			border-radius: 0px 8px 0px 0px;
            padding-right: 20px;

		}
	}
`;

interface TableThProps {
    width?: string;
}

export const TableTh = styled.th<TableThProps>`
	padding: 10px;
    font-size: 14px;
    color: #838383;
    ${({width}) => width && `width: ${width}%`};
    .project-name {
        display: flex;
        flex-direction: column;
    }
`;

export const LoaderCircleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%
`
