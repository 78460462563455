import React, { useCallback, useEffect, useState } from 'react';

import ClienteService from '../../../services/providers/client';
import PessoaService from '../../../services/providers/responsible';

import LoaderProjeto from '../../../components/common/loaders/LoaderPaginationProjeto';
import TurTutorial from '../../../components/tourTutorial';
import { ProjetosContainer } from './components/ProjetosContainer';
import ModalProjeto from './ModalProjeto';

import ReactGA from 'react-ga';
import { useProject } from '../../../contexts/Project';
import './style.css';
import { useUser } from '../../../contexts/User';
import HeaderLink from '../../../components/common/HeaderLink';
import { PageContainer } from './styled';
import ProjectTable from './components/ProjectTable';

import { ProjectsFilter } from './Filtro';
import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ModalCliente from '../RegistroNavBar/Clientes/ModalCliente';
import PersonModal from '../../PersonManagement/PersonModal';
import KanbanProject from './Kanban';
import { useHistory, useLocation} from 'react-router-dom';
import {  ProjectBasic } from '../../../models/Project';
import GridProject from './Grid';

export default function Projetos() {
	const [editingProjeto, setEditingProjeto] = useState(false);
	const [projetoEditado, setProjetoEditado] = useState<ProjectBasic>();
	const [clientVisible, setClientVisible] = useState(false);
	const [personVisible, setPersonVisible] = useState(false);
	const location = useLocation();

	const { t } = useTranslation();
	const history = useHistory();
	const clientService = new ClienteService();
	const pessoaService = new PessoaService();
	const { loadingUser } = useUser();
	const {
		clienteValorSelecionado,
		myProjects,
		loadingProjects,
		projects,
		getProjects,
		activeOnly,
		totalPages,
		pages,
		loadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		loadingFavorites,
	} = useProject();
	const isKanban = location.pathname === '/registros/projetos-kanban';
	const { userDetails } = useUser();
	useEffect(() => {
		if (userDetails && !isKanban) {
			getProjects();
		}
	}, [myProjects, clienteValorSelecionado, activeOnly, userDetails, location]);

	useEffect(() => {
		if (Object.keys(projects)?.length === 0 && userDetails) {
			getProjects();
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [myProjects, clienteValorSelecionado, userDetails]);



	const paginacaoDinamica = (e: any) => {
		const element = e.target;
		if (totalPages === pages) {
			return;
		} else if (!loadingProjects) {
			if (element.scrollHeight - element.scrollTop === element.clientHeight) {
				const paginaAtual = pages + 1;

				getProjects(paginaAtual);
				element.scrollTop -= 10;
			}
		}
		
	};



	const loadStyle = () => {
		const PlanusVisualizacao = localStorage.getItem('PlanusVisualizacao');
		if (!PlanusVisualizacao) {
			pessoaService.getPessoasID(
				userDetails?.id_pessoa,
				response => {
					if(response.data.tipo_visualizacao === 'g' && location.pathname !== '/registros/projetos') {
						history.push('registros/projetos');
					}else if(response.data.tipo_visualizacao === 'l' && location.pathname !== '/registros/projetos-list') {
						history.push('registros/projetos-list');
					}else if(response.data.tipo_visualizacao === 'k' && location.pathname !== '/registros/projetos-kanban') {
						history.push('registros/projetos-kanban')
					}
					localStorage.setItem(
						'PlanusVisualizacao',
						`${response.data.tipo_visualizacao}`,
					);
				},
				() => {},
			);
		}
	};

	const handleAddProject = useCallback(() => {
		setEditingProjeto(false);
		setModalProjectVisible(true);
	}, []);

	const handleOpenClient = useCallback(() => {
		setClientVisible(true);
	}, []);

	const handleAddPerson = () => setPersonVisible(true);

	useEffect(() => {
		clientService.getClientesSelect();
	}, []);

	const visualizacoes = {
		'registros/projetos': 'g',
		'registros/projetos-list': 'l',
		'registros/projetos-kanban': 'k'
	}

	type ProjetosViews = 'registros/projetos' | 'registros/projetos-list' | 'registros/projetos-kanban';
	const saveStyle = () => {
		const pathname = location.pathname as ProjetosViews;
		const tipoVisualizacao = visualizacoes[pathname];
		localStorage.setItem('PlanusVisualizacao', tipoVisualizacao);

		const formData = new FormData();
		formData.append('tipo_visualizacao', tipoVisualizacao);

		if(userDetails) {
			pessoaService.patchPessoa(
				formData,
				userDetails?.id_pessoa,
				() => {},
				() => {},
			);
			loadStyle();
		}

	};
	useEffect(() => {
		saveStyle();
		loadStyle();
	}, [location.pathname])

	return (
		<>
			{userDetails && !userDetails?.has_finished_tour && (
				<TurTutorial nomeRota="projeto" />
			)}
			{clientVisible && (
				<ModalCliente
					modalIsShowing={clientVisible}
					modalClienteShow={setClientVisible}
				/>
			)}
			<PersonModal
				isShowingModal={personVisible}
				setIsShowingModal={setPersonVisible}
			/>
			<HeaderLink
				type={'project'}
				headerButtons={[
					{
						isHidden: !userDetails || userDetails?.acesso_cod === 'e',
						handleClick: handleOpenClient,
						icon: faCog,
						text: t('Clientes'),
						id: 'react-tour__cliente',
					},
					{
						handleClick: handleAddPerson,
						icon: faPlus,
						isHidden: !userDetails || userDetails?.acesso_cod !== 'a',
						text: t('Pessoas'),
						id: 'react-tour__add-pessoa',
					},
					{
						handleClick: handleAddProject,
						icon: faPlus,
						isHidden:
							!userDetails ||
							userDetails?.acesso_cod === 'm' ||
							userDetails?.acesso_cod === 'e',
						text: t('Projeto'),
						id: 'react-tour__add-projeto',
					},
				]}
			/>
			<PageContainer>
				<div
					className={`new-registros-container ${loadingProjects && !isKanban &&
						'loading-filters-project'}`}
				>
					<div className="new-registros-header ">
						<div
							className={`new-registros-component new-registros-component-filters`}
						>
							<ProjectsFilter />
							{modalProjectVisible ? (
								<ModalProjeto
									setModalShow={setModalProjectVisible}
									editingProjeto={editingProjeto}
									itemSelecionado={projetoEditado}
									needToLoadDetails={(!!projetoEditado && !projetoEditado.admins)}
								/>
							) : null}
						</div>
					</div>
					{isKanban ? 
						<KanbanProject setEditingProject={setEditingProjeto}
							setSelectedProject={setProjetoEditado as any} 
						/> :
						<div
							className="registros-container-body-project"
							onScroll={e => paginacaoDinamica(e)}
							id="elemento-scroll"
						>
							<div>
								<ProjetosContainer
									loading={loadingProjects}
									projectsLength={Object.keys(projects).length}
									isGridStyle={location.pathname === '/registros/projetos'}
									opacityOnLoad={loadingFavorites || loadingPostPatch}
								>
									{!!projects && (location.pathname === '/registros/projetos' ? (
										<GridProject setEditingProject={setEditingProjeto} setEditedProject={setProjetoEditado} />
									) : (
										<ProjectTable
											projetoEditado={projetoEditado}
											setEditingProjeto={setEditingProjeto}
											setProjetoEditado={setProjetoEditado}
											setModalProjectVisible={setModalProjectVisible}
											projects={projects}
										/>
									))}
								</ProjetosContainer>
								<LoaderProjeto
									fileData={Object.keys(projects).sort((a, b) => {
										const nameA = projects[a]['nome']?.toLowerCase(),
											nameB = projects[b]['nome']?.toLowerCase();
										return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
									})}
									isGridStyle={location.pathname === '/registros/projetos'}
									showLoader={totalPages === pages}
									loading={loadingProjects || loadingUser}
									onClick={() => getProjects(pages + 1)}
								/>
							</div>
						</div>
					}
				</div>
			</PageContainer>
		</>
	);
}
