import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { MainProject, ProjectBasic } from '../../../../../models/Project';
import {
	Table,
	TableBody,
	TableHeader,
	TableTh,
	TableRowHeader
} from './styled';
import { t } from 'i18next';
import TableRow from './TableRow';
import { useProject } from '../../../../../contexts/Project';

interface ProjectTableProps {
	projects: Entity<MainProject>;
	setEditingProjeto: Dispatch<SetStateAction<boolean>>;
	setModalProjectVisible: Dispatch<SetStateAction<boolean>>;
	setProjetoEditado: Dispatch<SetStateAction<ProjectBasic | undefined>>;
	projetoEditado?: ProjectBasic;
}

interface Entity<T> {
	[name: string]: T;
}

const getProjectsArray = (body: Entity<MainProject>) => {
	return Object.keys(body).sort((a, b) => {
		const nameA = body[a]['nome']?.toLowerCase(),
			nameB = body[b]['nome']?.toLowerCase();
		return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
	}).map(key => body[key]);
};

export default function ProjectTable({
	projects,
	setEditingProjeto,
	setModalProjectVisible,
	setProjetoEditado,
	projetoEditado,
}: ProjectTableProps) {
	const { selectProjectList} = useProject();
	useEffect(() => {
		selectProjectList();
	}, []);

	return (
		<Table>
			<TableHeader>
				<TableRowHeader>
					<TableTh width="6">{t('Cliente')}</TableTh>
					<TableTh width="32">{t('Projeto')}</TableTh>
					<TableTh width="32">{t('Time')}</TableTh>
					<TableTh width="22">{t('Tarefas')}</TableTh>
					<TableTh width="4">{t('Editar')}</TableTh>
					<TableTh width="4">{t('Kanban')}</TableTh>
				</TableRowHeader>
			</TableHeader>
			<TableBody>
				
					{getProjectsArray(projects).map(project => (
						<TableRow
							project={project}
							key={project.id}
							projetoEditado={projetoEditado}
							setEditingProjeto={setEditingProjeto}
							setModalProjectVisible={setModalProjectVisible}
							setProjetoEditado={setProjetoEditado}
						/>
					))}
			</TableBody>
		</Table>
	);
}
