import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderCircle } from '../../../../../components/common/loaders/LoaderCircle';
import { useUser } from '../../../../../contexts/User';

import './style.css';

export const ProjetosContainer = ({
	projectsLength,
	loading,
	children,
	opacityOnLoad,
	isGridStyle
}) => {
	const {t} = useTranslation();
	const { loadingUser } = useUser();
	return (
	<div className={`new-projetos-container `}>
		<div style={{ display: 'flex' }}>
			{isGridStyle && ((loading || loadingUser) ? (
				<div className='loaderProjectContainer'>
					<LoaderCircle />
				</div>
			) : (
				<>
					{!projectsLength > 0 && <p className='no-projects-found'>{t("Nenhum projeto encontrado")}!</p>}
				</>
			))}
		</div>
		<div
			className={`${isGridStyle ? 'new-projetos-grid-card' : 'new-projetos-list-card'}`}
			style={{
				opacity: opacityOnLoad ? '0.7' : '1',
			}}
		>
			{children}
		</div>
	</div>
)};
