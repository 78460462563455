import React, { useEffect, useState } from 'react';
import PageContainerProjectDetail, {
	LocationProjectDetails,
} from '../../../../../components/containers/PageContainer/ProjectDetails';
import { useLocation, useParams } from 'react-router-dom';
import Table from '../Table';
import SelectPeriod from './components/SelectPeriod';
import { BodyContainer, DisplayChartMobile } from './styled';
import CostChart from './components/Charts/CostProfessional';
import { useAtom } from 'jotai';
import {
	NumOfDaysAtom,
	ProjectTeamAtom,
	ProjectTeamLoading,
	ProjectTeamTempAtom,
	StartPeriodAtom,
} from '../../../../../atoms/ProjectDetails';
import HourChart from './components/Charts/HourProfessional';
import moment from 'moment';
import HorasService from '../../../../../services/providers/hour';
import { HoursRecord } from '../../../../../models/HoursRecord';
import CostByPeriod from './components/Charts/CostByPeriod';
import { extractHour } from './utils';
import ProjetoService from '../../../../../services/providers/project';
import {useReactToPrint} from 'react-to-print';
import { t } from 'i18next';

export interface PeopleColor {
	color: string;
	name: string;
}

export default function ProjectTeam() {
	const location = useLocation<LocationProjectDetails>();
	const { id } = useParams<{ id: string }>();
	const projectId = parseInt(id);
	const [projectTeam, setProjectTeam] = useAtom(ProjectTeamAtom);
	const [projectTeamTemp, setProjectTeamTemp] = useAtom(ProjectTeamTempAtom);
	const pageRef = React.useRef(null);
	const [loadingGraphs, setLoadingGraphs] = useState(false);
	const [peopleColor, setPeopleColor] = useState<PeopleColor[]>();
	const [expandTable, setExpandTable] = useState(false);
	const [loadingTeam, setLoadingTeam] = useAtom(ProjectTeamLoading);
	const [selectedStartPeriod] = useAtom(StartPeriodAtom);
	const [selectedNumbOfDays] = useAtom(
		NumOfDaysAtom
	)
	const [startPeriod, setStartPeriod] = useState<Date>(
		moment(selectedStartPeriod, 'YYYY-MM-DD').toDate()
	);
	const [endPeriod, setEndPeriod] = useState<Date>(
		moment()
			.startOf('month')
			.add(parseInt(selectedNumbOfDays), 'days')
			.toDate(),
	);
	const [hoursRecord, setHoursRecord] = useState<HoursRecord[]>([]);
	const [numOfDays, setNumOfDays] = useState<number>(parseInt(selectedNumbOfDays));

	useEffect(() => {
		setNumOfDays(parseInt(selectedNumbOfDays));
	}, [selectedNumbOfDays])

	

	useEffect(() => {
		if (startPeriod) {
			setEndPeriod(
				moment(startPeriod)
					.add(numOfDays, 'days')
					.toDate(),
			);
		}
	}, [startPeriod, numOfDays]);
    
    useEffect(() => {
        if(projectId) {
			setLoadingTeam(true);
            getProjectTeam(projectId);
        }
    }, [projectId]);


    const getProjectTeam = (id: number) => {
		const projetoService = new ProjetoService();
		projetoService.getAllProjetoTime(
			id,
			1).then((result) => {
				setProjectTeamTemp([...result].sort((a, b) => {
					const order = a.pessoa.nome.toUpperCase() > b.pessoa.nome.toUpperCase() ? 1 : 0;
					return a.pessoa.nome.toUpperCase() < b.pessoa.nome.toUpperCase() ? -1 : order;
				}));				

		})	
	};

	const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');

	useEffect(() => {
		const controller = new AbortController();
        const signal = controller.signal;
		if (startPeriod && endPeriod && projectTeamTemp?.length > 0) {
			getWorkedHours(signal);
		}
		return () => {
            controller.abort();
        }
	}, [projectTeamTemp, endPeriod]);

	const formatWorkHours = (hours: HoursRecord[]) => {
		const finalProjectTeam = projectTeamTemp.map(item => {
			const personHours = hours.filter(hour => item.pessoa.id === hour.pessoa);
			const hoursResult = extractHour(personHours);
			return {
				...item,
				horas_realizadas: hoursResult,
			};
		});
		setProjectTeam(finalProjectTeam);
		setLoadingTeam(false);
	};

	const getWorkedHours = (signal: AbortSignal) => {
		setLoadingGraphs(true);
		const hourService = new HorasService();
		hourService.getHoras(
			{
				pessoa: '',
				search: '',
				tarefa: '',
				projeto: id,
				startDate: formatDate(startPeriod),
				endDate: formatDate(endPeriod),
			},
			response => {
				setHoursRecord(response.data);
				formatWorkHours(response.data);
				setLoadingGraphs(false);
			},
			err => {
				console.log(err);
				setHoursRecord([]);
				formatWorkHours([]);
				setLoadingGraphs(false);
			},
			{ signal }
		);
	};


	useEffect(() => {
		setLoadingGraphs(true);
	}, [endPeriod])


    const handlePrint = useReactToPrint({
		content: () => pageRef.current,
		documentTitle: t("Time"),
		pageStyle: `
		@media all {
			.page-break {
			  display: none;
			}
			.print-background {
				-webkit-print-color-adjust: exact;
				print-color-adjust: exact;
				color: black !important;
				background-color: white !important;
			  }
		  }
		  
		  @media print {
			html, body {
			  height: initial !important;
			  overflow: initial !important;
			  -webkit-print-color-adjust: exact;
			}
		  }
		  
		  @media print {
			.page-break {
			  display: block;
			  page-break-before: auto;
			}
		  }
		  
		  @page {
			size: auto;
			margin: 20mm;
		  }
		`
		});

	const loading = loadingGraphs;

	return (
		<PageContainerProjectDetail expandTable={expandTable} ref={pageRef} loading={projectTeam?.length === 0 || loadingTeam} projectId={projectId} location={location}>
			{!expandTable && <SelectPeriod numOfDays={numOfDays} setNumOfDays={setNumOfDays} handlePrint={handlePrint} setStartPeriod={setStartPeriod} startPeriod={startPeriod} />}
			<BodyContainer expandTable={expandTable} >
				<Table expandTable={expandTable} setExpandTable={setExpandTable} getProjectTeam={getProjectTeam} projectId={projectId} hoursRecord={hoursRecord} />
				<DisplayChartMobile>
					<CostChart loading={loading} hoursRecord={hoursRecord} projectTeam={projectTeam} peopleColor={peopleColor} />
				</DisplayChartMobile>
				<CostByPeriod
					loading={loading}
					startPeriod={startPeriod}
					endPeriod={endPeriod}
					hoursRecord={hoursRecord}
					projectTeam={projectTeam}
				/>
				<HourChart setPeopleColor={setPeopleColor} loading={loading} projectTeam={projectTeam} peopleColor={peopleColor} />
			</BodyContainer>
		</PageContainerProjectDetail>
	);
}
