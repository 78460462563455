import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import moment from 'moment';
import { toast } from 'react-toastify';
import { Draggable, DropResult } from 'react-beautiful-dnd';

import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdOutlineDateRange } from 'react-icons/md';
import { BsPersonPlus } from 'react-icons/bs';

import ModalTarefa from '../../ModalTarefa';
import { KanbanChecklist } from '../KanbanChecklist';
import { Subtask } from '../../../../../models/Subtask';
import { ConfirmModal } from '../../../../../components/containers/Modais/ConfirmModal';
import { SelectMultiAssign } from '../../SelectAssign';

import TaskService from '../../../../../services/providers/task';
import { useKanban } from '../../../../../contexts/Kanban/Kanban';
import { useDataTask } from '../../../../../contexts/InfoTarefa/DataTask';
import { SubtaskService } from '../../../../../services/providers/subtask';
import { PatchTaskDto } from '../../../../../models/Task';
import { Responsible } from '../../../../../models/Responsible';

import { dragTaskDifferentColumns, newItemsByColumn } from '../functions';
import { KanbanTaskProps } from './types';
import  profileImage from '../../../../../assets/images/profile.png';

import { 
	AddTimeButton,
	AssignContainer,
	Bottom,
	DateInput,
	DateText,
	MenuButton,
	OverProfile,
	ProfileContainer,
	TaskContainer,
	TaskContent,
	TaskWrapper,
	Title,
	Top
} from './styled';
import { useStopwatch } from '../../../../../contexts/Stopwatch';

export function KanbanTask({
	task,
	index,
	project,
	columnId,
	requestingAddTask,
	client,
}: KanbanTaskProps) {
	const { t } = useTranslation();
	const tarefaService = new TaskService();
    const subtaskService = new SubtaskService();

	const overResponsaveis = task.pessoas_responsaveis.length - 2;
	const slicedResponsaveis = task.pessoas_responsaveis.slice(0, overResponsaveis > 0 ? overResponsaveis * -1 : undefined);
	const overResponsaveisNames = task.pessoas_responsaveis.slice(overResponsaveis * -1).map(resp => resp.nome);

	
    const [isAssigning, setIsAssigning] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
	const [isModalTarefaVisible, setIsModalTarefaVisible] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedSubtask, setSelectedSubtask] = useState<Subtask>();
	
	const { deletedId, setDeletedId, closedId, setClosedId, patchResponse, setPatchResponse } = useDataTask();
	const { columnsManager, setColumnsManager, handleSetSubtasks, setLoadingPagination, paginationInfoByTask, setPaginantionInfo, team } = useKanban();
    const {  handleToggleModal, setPreSelectedRecord } = useStopwatch();

	useEffect(() => {
		if (deletedId && deletedId === task.id) {
			deleteTask(deletedId);
			setDeletedId(null);
		}
		if (closedId && closedId === task.id) {
			endTask(closedId);
			setClosedId(null);
		}
		if (patchResponse && patchResponse.id === task.id) {
			handlePatchTask(patchResponse);
			setPatchResponse(null);
		}
	}, []);

	const handleSetIsModalTarefaVisible = useCallback(() => setIsModalTarefaVisible(true), [setIsModalTarefaVisible]);
	const handleSetIsModalHorasVisible = useCallback(() => {
		if(project) {
			setPreSelectedRecord({
				tarefa: task.id as number,
				tarefa_nome: task.nome,
				projeto: project.id,
				projeto_nome: project.nome,
			})
		}
		handleToggleModal(true);
	}, [handleToggleModal, project, task]);

	const deleteTask = (id: number) => {
		tarefaService.deleteTarefa({
			id: id,
			success: (response) => {
				if (response) {
					const tempItems = [...columnsManager.columns[columnId].items].filter(
						item => item.id !== task.id,
					);

					setColumnsManager({
						...columnsManager,
						columns: newItemsByColumn(
							columnId,
							{ ...columnsManager.columns },
							tempItems,
							true,
						),
					});
					toast.success(t('Tarefa removida com sucesso!'));
				}
				setIsModalTarefaVisible(false);
			},
			errorCallback: (error: any) => {
				toast.error(error);
				setIsModalTarefaVisible(false);
			},
		});
	};

	const changeEndTaskToFinalColumn = () => {
		const result = {
			source: {
				droppableId: task.coluna_kanban || columnsManager.columnsOrder[0],
				index: index
			},
			destination: {
				droppableId: columnsManager.columnsOrder[columnsManager.columnsOrder.length -1],
				index: 0
			}
		};
		const tempColumnsManager = dragTaskDifferentColumns(
			columnsManager,
			result as DropResult,
		);
		setColumnsManager(tempColumnsManager);
	}

	const endTask = useCallback((id: number) => {
		tarefaService.encerrarTarefa({
			id,
			success: (response) => {
				if (response) {
					toast.success(t('Tarefa encerrada com sucesso!'));
				}
				changeEndTaskToFinalColumn();
				setIsModalTarefaVisible(false);
			},
			errorCallback: (error: any) => {
				toast.error(error);
				setIsModalTarefaVisible(false);
			},
		});
	}, [setIsModalTarefaVisible, changeEndTaskToFinalColumn]);


	const handlePatchTask = (response: any, saveAndContinue = false) => {
		if (response.data) {
			const tempItems = [...columnsManager.columns[columnId].items];
			const currentTaskIndex = tempItems.findIndex(item => (item.id === task.id));

			if (project && response.data.projeto === project.id) {
				tempItems[currentTaskIndex] = response.data;
			} else {
				tempItems.splice(currentTaskIndex, 1);
			}

			setColumnsManager({
				...columnsManager,
				columns: newItemsByColumn(
					columnId,
					{ ...columnsManager.columns },
					tempItems,
				),
			});
		}
		setIsModalTarefaVisible(saveAndContinue);
	};


	const loadLastSubtask = (
        tempSubtasks: Subtask[],
		valueToadd: number,
        pagina = 1,
    ) => {
        setLoadingPagination({
            value: true,
            columnId: columnId,
            taskId: task.id
        });
        const subtaskService = new SubtaskService();
        subtaskService.getSubtasksByTask(task.id, pagina).then(result => {
			const results = result.results;
			if (results) {
				handleSetSubtasks([...tempSubtasks, results[results.length-1]], task.id, columnId, valueToadd);
				setPaginantionInfo({
					value: {
						totalPages: result.total_pages,
						currentPage: pagina,
						loading: false
					},
					columnId: columnId,
					taskId: task.id
				});
			}
            
        }).catch(err => {
            console.log(err);
        }).finally(() => 
            setLoadingPagination({
                value: false,
                columnId: columnId,
                taskId: task.id
            })
        );
    }
	
	const deleteSubtask = useCallback(() => {
        if(selectedSubtask){
            const tempSubtasks = task.sub_tarefas.filter(sub => sub.id !== selectedSubtask?.id);

            subtaskService.deleteSubtask(selectedSubtask?.id)
			.then(() => {
				if(paginationInfoByTask(task.id, columnId).totalPages > 1) {
					loadLastSubtask(tempSubtasks, 0, paginationInfoByTask(task.id, columnId).currentPage);
				}
			})
            .catch((err) => {
                handleSetSubtasks(tempSubtasks, task.id, columnId);
                console.log(err);
                toast.error(t('Erro ao tentar deletar subtarefa!'));
            });

            handleSetSubtasks(tempSubtasks, task.id, columnId, -1, selectedSubtask.completada ? 0 : -1);
        }
    }, [handleSetSubtasks, loadLastSubtask, task, paginationInfoByTask]);

	const openConfirmSubtarefaModal = useCallback((subtask: Subtask) => {
		setSelectedSubtask(subtask);
		setIsConfirmModalOpen(true);
	}, [setSelectedSubtask, setIsConfirmModalOpen])

	const patchTaskDate = (date: string) => {
        const newTask: PatchTaskDto =  {
			id: task.id,
			nome: task.nome,
			projeto: task.projeto,
            data_conclusao: moment(
                date,
                'YYYY-MM-DD',
            ).format()
        }
        
        patchTask(newTask);
    };

	const patchTask = (newTask: PatchTaskDto) => {
		
		const tempItems = [...columnsManager.columns[columnId].items];
		const currentTaskIndex = tempItems.findIndex(item => (item.id === task.id));
		
		tarefaService.patchTarefa(
			newTask,
			task.id,
			(response) => {
				if (response) {
					toast.success(t('Tarefa atualizada com sucesso!'));
				}
			},
			(error) => {
				setColumnsManager({
					...columnsManager,
					columns: newItemsByColumn(
						columnId,
						{ ...columnsManager.columns },
						tempItems,
					),
				});
				toast.error(error.toString());
			},
		);
		
		tempItems[currentTaskIndex] = {
			...tempItems[currentTaskIndex], 
			...newTask,
			pessoas_responsaveis: newTask.responsaveis !== undefined
				? team.filter(resp => newTask.responsaveis?.split(",").includes(resp.id.toString()))
				: tempItems[currentTaskIndex].pessoas_responsaveis
		};

		setColumnsManager({
			...columnsManager,
			columns: newItemsByColumn(
				columnId,
				{ ...columnsManager.columns },
				tempItems,
			),
		});
    };

	const patchTaskResponsible = useCallback((responsibles?: Responsible[]) => {
        const newTask: PatchTaskDto =  {
			id: task.id,
			nome: task.nome,
			projeto: task.projeto,
            responsaveis: responsibles !== undefined 
				? responsibles.map(resp => resp.id).toString()
				: task.pessoas_responsaveis.map(resp => resp.id).toString()
        }
        
        patchTask(newTask);
		setIsAssigning(false);
    }, [patchTask, setIsAssigning]);


	const handleSetIsAssigning = useCallback(() => setIsAssigning(!isAssigning), [setIsAssigning]);

    const handlefocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => e.target.select(), []);

    const handleSetIsEditingDate = useCallback(() => setIsEditingDate(true), [setIsEditingDate]);

    const exitEditingDate = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
		if((value !== task.data_conclusao?.slice(0, 10)) && value !== ''){
			patchTaskDate(value);
			e.target.value = "";
		}
		setIsEditingDate(false);
      }, [patchTaskDate, setIsEditingDate]);

    const keyDownDate = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
		if (e.key === 'Enter' && target.value !== '') {
			patchTaskDate(target.value);
			setIsEditingDate(false);                        
			target.value = "";                        
		}
    }, [patchTaskDate, setIsEditingDate]);


	return (
		<div style={{cursor: requestingAddTask.componentId === task.id ? 'progress' : 'initial'}}>
			<TaskWrapper isRequestingAdd={requestingAddTask.componentId === task.id} >
			<Draggable key={task.id} draggableId={task.id.toString()} index={index}>
				{(provided, snapshot) => {
					return (
						<TaskContainer
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							style={{
								...provided.draggableProps.style,
							}}
							isDragging={snapshot.isDragging}
						>
							<TaskContent>
								<Top>
									<Title
										onClick={handleSetIsModalTarefaVisible}
									>
										{task.nome}
									</Title>
								</Top>
								{isAssigning && (
									<AssignContainer title={`${t('Atribuir')} select`}>
										<SelectMultiAssign 
											responsibles={team} 
											saveChanges={patchTaskResponsible} 
											setVisibility={setIsAssigning}
											currentResponsibles={task.pessoas_responsaveis}
										/>
									</AssignContainer>
								)}
								<Bottom>
									<div className="kanban-task-date">
										<AddTimeButton
											type="button"
											onClick={handleSetIsModalHorasVisible}
											title={t("Apontar horas").toString()}
										>
											<FontAwesomeIcon
												icon={faClock}
												className="icon-actions"
											/>
										</AddTimeButton>
										{isEditingDate  && (
											<DateInput
												defaultValue={moment(task.data_conclusao).format('YYYY-MM-DD')}
												type="date"
												onBlur={exitEditingDate}
												title={`${t("Editar data tarefa")} input`}
												autoFocus
												onFocus={handlefocus}
												onKeyDown={keyDownDate}
											/>
										)}
										<DateText
											isEditingDate={isEditingDate}
											title={t("Editar data tarefa").toString()}
											onClick={handleSetIsEditingDate}
										>
											{task.data_conclusao !== null && task.data_conclusao !== "" 
												? moment(task.data_conclusao).format('DD/MM')
												: <MdOutlineDateRange size={14} />
											}
										</DateText>
									</div>
									<ProfileContainer>
										{slicedResponsaveis.map((item) => {
											return (
												<img
													key={item.id}
													src={
														item.foto_perfil ? item.foto_perfil : profileImage
													}
													title={item.nome}
													alt={''}
													style={{
														marginRight: slicedResponsaveis.length === 1 ? '' : '-4px',
													}}
												/>
											);
										})}
										{overResponsaveis > 0 && (
											<OverProfile title={overResponsaveisNames.join(", ")}>
												+{overResponsaveis}
											</OverProfile>
										)}
										<OverProfile>
											<MenuButton 
												type='button'
												title={t('Atribuir').toString()}
												onClick={handleSetIsAssigning}
											>
												<BsPersonPlus size={14} />
											</MenuButton>
										</OverProfile>
									</ProfileContainer>
								</Bottom>
							</TaskContent>
							
							<KanbanChecklist 
								task={task} 
								openConfirmSubtarefaModal={openConfirmSubtarefaModal}
								columnId={columnId}
							/>
						</TaskContainer>
					);
				}}
			</Draggable>

			{isModalTarefaVisible && project ? (
				<ModalTarefa
					setIsVisible={setIsModalTarefaVisible}
					isVisible={isModalTarefaVisible}
					editingTask={true}
					selectedTask={{
						...task,
						projeto: project.id,
						projeto_nome: project.nome,
					}}
					handleEncerrar={endTask}
					fromKanban={true}
					stateToDetail={{ columnsManager, client }}
				/>
			) : null}

			{isConfirmModalOpen && (
				<ConfirmModal 
					isConfirmModalOpen={isConfirmModalOpen}
					setIsConfirmModalOpen={setIsConfirmModalOpen}
					handleConfirm={deleteSubtask}
				/>
			)}
		</TaskWrapper>
		</div>
	);
}
