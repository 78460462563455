import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { BsChevronUp, BsChevronDown} from 'react-icons/bs';
import { toast } from "react-toastify";
import DinamicPagination from "../../../../../components/common/DinamicPagination";

import { ConfirmModal } from "../../../../../components/containers/Modais/ConfirmModal";
import { Responsible } from "../../../../../models/Responsible";
import { CreateSubtaskDto, Subtask } from "../../../../../models/Subtask";
import { Task } from "../../../../../models/Task";
import { SubtaskService } from "../../../../../services/providers/subtask";
import { removeDuplicates } from "../../../../../utils/funcoes/arraysFunctions";
import { AddSubtask } from "../AddSubtask";
import { DetailsSubtask } from "../DetailsSubtask";

import { InfoContainer, InfoContent } from "../styled";
import { ExpandButton, InfoTitleExtra, NewButton, NewButtonDiv, SubtasksContainer, TableHeader } from "./styled";

export interface ChecklistProps {
    task: Task;
    team: Responsible[];
}

export function Checklist({team, task}: ChecklistProps) {
	const { t } = useTranslation();
    const [isChecklistOpen, setIsChecklistOpen] = useState(true);
    const [isAdding, setIsAdding] = useState(false);
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [subtasks, setSubtasks] = useState<Subtask[]>([]);
    const [selectedSubtask, setSelectedSubtask] = useState<Subtask>();

    const subtaskService = new SubtaskService();

    useEffect(() => {
        handleGetSubtasks(1);
    }, []);

    const handleSetSubtasks = (subs: Subtask[]) => {
		const tempSubtasks = removeDuplicates(subs, "id") as Subtask[];

        setSubtasks(() => tempSubtasks);
    };

    const handleGetSubtasks = (page: number) => {
        setLoading(true);
        subtaskService.getSubtasksByTask(task.id, page).then(result => {
            handleSetSubtasks([...subtasks, ...result.results]);
            setTotalPages(result.total_pages);
			setCurrentPage(page);
        }).catch(err => {
            console.log(err);
            toast.error(t("Erro ao carregar subtarefas!"));
        }).finally(() => 
            setLoading(false)
        );
    }
    
    const openModalConfirm = (sub: Subtask) => {
        setSelectedSubtask(sub);
        setIsModalConfirmOpen(true)
    };

	const addSubtask = (title: string) => {
        const subtask: CreateSubtaskDto = { 
            nome: title, 
            tarefa: task.id
        }

        subtaskService.postSubtask(subtask)
            .then(result => {
                handleSetSubtasks([...subtasks, result]);
                setIsAdding(true);
            }).catch((err) => {
                console.log(err);
                toast.error(t('Erro ao tentar criar subtarefa!'));
            });
    };

    const deleteSubtask = () => {
        if(selectedSubtask){
            const tempSubtasks = subtasks.filter(sub => sub.id !== selectedSubtask?.id);

            subtaskService.deleteSubtask(selectedSubtask?.id)
            .then(() => {
                loadLastSubtask(tempSubtasks, currentPage);
            })
            .catch((err) => {
                handleSetSubtasks(tempSubtasks);
                console.log(err);
                toast.error(t('Erro ao tentar deletar subtarefa!'));
            });

            handleSetSubtasks(tempSubtasks);
            setSelectedSubtask(undefined);
        }
        
    }

    const loadLastSubtask = (
        tempSubtasks: Subtask[],
        pagina = 1,
    ) => {
        setLoading(true);
        const subtaskService = new SubtaskService();
        subtaskService.getSubtasksByTask(task.id, pagina).then(result => {
			const results = result.results;
			if (results) {
				handleSetSubtasks([...tempSubtasks, results[results.length-1]]);
				
			}
            
        }).catch(err => {
            console.log(err);
        }).finally(() => 
            setLoading(false)
        );
    }

    const patchSubtask = (newSub: Subtask) => {
        const tempSubtasks = [...subtasks];
        const newSubtaskIndex = tempSubtasks.findIndex((tempSub: any) => tempSub.id === newSub.id);

        subtaskService.patchSubtask(newSub)
            .catch((err) => {
                handleSetSubtasks(tempSubtasks);
                console.log(err);
                toast.error(t('Erro ao tentar atualizar subtarefa!'));
            });

        tempSubtasks.splice(newSubtaskIndex, 1, newSub as Subtask);

        handleSetSubtasks(tempSubtasks);
    };

    return (
        <InfoContainer>
            <InfoTitleExtra>
                <span>{t('Checklist')}:</span>
                <ExpandButton 
                    type='button' 
                    className='subtask-expand'
                    onClick={() => setIsChecklistOpen(!isChecklistOpen)}
                >
                    <span className='subtask-title'>{task.total_subtarefas} {t('Subtarefas')}</span>

                    {isChecklistOpen ? <BsChevronUp size={22} /> : <BsChevronDown size={22} />}
                </ExpandButton>
            </InfoTitleExtra>
            {isChecklistOpen && (
                <>
                <TableHeader >
                    <div>
                        <span>{t('Título')}</span>
                        <div className="subtask-bottom">
                            <div className="subtask-info">
                                <span>{t('Responsável')}</span>
                                <span>{t('Data entrega')}</span>
                            </div>
                        </div>
                    </div>
                </TableHeader>
                <InfoContent>
                        <DinamicPagination
                            loading={loading}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            lengthData={subtasks.length}
                            returnPage={handleGetSubtasks}
                            msgToEmptyData={t('Nenhuma subtarefa encontrada.')}
                        >
                            <SubtasksContainer>
                                {subtasks.map(sub => {
                                    return (
                                        <DetailsSubtask
                                            key={sub.id}
                                            team={team}
                                            subtask={sub} 
                                            openModalConfirm={openModalConfirm} 
                                            handlePatchSubtask={patchSubtask}
                                        />
                                    )
                                })}
                            </SubtasksContainer>
                        </DinamicPagination>
                    <NewButtonDiv>
                        <NewButton 
                            onClick={() => setIsAdding(!isAdding)} 
                        >
                            {t('Nova Subtarefa')}
                        </NewButton>

                        {isAdding && ( 
                            <AddSubtask handleAddSubtask={addSubtask}  setIsAdding={setIsAdding} />
                        )}
                    </NewButtonDiv>
                </InfoContent>
                </>
            )}

            {isModalConfirmOpen && (
                <ConfirmModal 
                    handleConfirm={deleteSubtask}
                    isConfirmModalOpen={isModalConfirmOpen}
                    setIsConfirmModalOpen={setIsModalConfirmOpen}
                />
            )}
        </InfoContainer>
    )
}