import React, { useCallback, useState } from 'react';
import { Responsible } from "../../../../models/Responsible";
import ReactSelect from "react-select";
import styled from 'styled-components';


import { useTranslation } from 'react-i18next';
import '../../../../i18n';

const RespOptionContainer = styled.div`
    display: grid;
    grid-template-columns: 0.15fr 0.85fr;
    gap: 4px;
    align-items: center;

    img {
        width: 100%;
        border-radius: 50%;
    }
`;

interface SelectAssignProps {
    responsibles: Responsible[];
    saveChanges: (responsible?: Responsible) => void;
    setVisibility: React.Dispatch<boolean>;
}

export function SelectAssign({responsibles, saveChanges, setVisibility}: SelectAssignProps) {
    const teamOptions = responsibles.map(resp => ({
        label: resp.nome,
        image: resp.foto_perfil === null ? require('../../../../assets/images/profile.png') : resp.foto_perfil,
        value: resp.id
    }))

    const handleSaveChanges = (id?: number) => {
        const responsible = responsibles.find(resp => resp.id === id);
        saveChanges(responsible);
    };

    return (
        <ReactSelect 
            options={teamOptions} 
            isClearable
            autoFocus
            onBlur={() => setVisibility(false)}
            onChange={(e) => {
                handleSaveChanges(e?.value);
                setVisibility(false);
            }} 
            formatOptionLabel={option => (
                <RespOptionContainer>
                  <img src={option.image} alt="" />
                  <span>{option.label}</span>
                </RespOptionContainer>
            )}
            styles={{
                control: base => ({
                    ...base,
                    borderColor: '#23D8B6',
                    // This line disable the blue border
                    boxShadow: '0 0 0 1px #23D8B6',
                    '&:hover': {
                        border: '1px solid #23D8B6',
                    }
                  })
            }}
        />
    )
}

interface SelectMultiAssignProps {
    responsibles: Responsible[];
    currentResponsibles: Responsible[];
    saveChanges: (responsible?: Responsible[]) => void;
    setVisibility: React.Dispatch<boolean>;
}

export function SelectMultiAssign({responsibles, saveChanges, setVisibility, currentResponsibles}: SelectMultiAssignProps) {
	const { t } = useTranslation();
    const [selectedResponsibles, setSelectedResponsibles] = useState(
        currentResponsibles.map(item => ({
            image: item.foto_perfil === null ? require('../../../../assets/images/profile.png') : item.foto_perfil,
            value: item.id,
            label: item.nome
        }))
    );
    const teamOptions = responsibles.map(resp => ({
        label: resp.nome,
        image: resp.foto_perfil === null ? require('../../../../assets/images/profile.png') : resp.foto_perfil,
        value: resp.id
    }))

    const handleSaveChanges = useCallback(() => {
            const tempIds = selectedResponsibles.map(item => item.value);

            const tempResponsibles = responsibles.filter(resp => tempIds?.includes(resp.id));
            saveChanges(tempResponsibles);

            setVisibility(false);
    }, [setVisibility, saveChanges, selectedResponsibles, responsibles]);

    const handleExit = useCallback(() => handleSaveChanges() ,[handleSaveChanges]);
    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSaveChanges();
            return
        }

        if (e.key === 'Escape') {
            setVisibility(false); 
        }
    }, [handleSaveChanges, setVisibility]);

    return (
        <div title={t("'Esc' para cancelar, 'Enter' para salvar").toString()} >
            <ReactSelect 
                options={teamOptions} 
                value={selectedResponsibles}
                isClearable
                autoFocus
                isMulti
                onBlur={handleExit}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                    setSelectedResponsibles([...e]);
                }} 
                formatOptionLabel={option => (
                    <RespOptionContainer>
                    <img src={option.image} alt="" />
                    <span>{option.label}</span>
                    </RespOptionContainer>
                )}
                styles={{
                    control: base => ({
                        ...base,
                        borderColor: '#23D8B6',
                        // This line disable the blue border
                        boxShadow: '0 0 0 1px #23D8B6',
                        '&:hover': {
                            border: '1px solid #23D8B6',
                        }
                    })
                }}
            />
        </div>
    )
}