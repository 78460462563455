import React, { Dispatch, ReactNode, useCallback } from 'react';

import { ActionsMenu } from '../../../common/ActionsMenu';
import { BodySide, ButtonContainer, FormHeader, FormSide, ModalContent, ModalHeader, ModalFooter, ModalLoader, ModalPrincipal } from './styled';
import { CloseGenerico } from '../modalStyled';

import '../../../../i18n';
import { t } from 'i18next';

import './style.css';
import { GenericButton } from '../../../tailwindCommon/Buttons/GenericButton';

export interface ModalPrincipalGenericoProps {
	modalGenericoShow: Dispatch<boolean>;
	children: ReactNode;
}

function ModalPrincipalGenerico(props: ModalPrincipalGenericoProps) {
	const onFocusModal = useCallback((event: React.MouseEvent) => {
		const target = event.target as HTMLInputElement;
		if (target.className === 'modal-align-generico-side' && props.modalGenericoShow) {
			props.modalGenericoShow(false);
		}
	}, [props.modalGenericoShow]);

	return (
		<ModalPrincipal onMouseDown={onFocusModal}>
			<div className="modal-align-generico-side">
				<div className="modal-align-principal-side">{props.children}</div>
			</div>
		</ModalPrincipal>
	);
}

interface HeaderProps {
	title: string;
	children: ReactNode;
	closeModalShow: (value: boolean) => void;
}

const Header = ({
	title,
	children,
	closeModalShow,
}: HeaderProps) => {
	const handleCloseModal = useCallback(() => {
		closeModalShow(false);
	}, [closeModalShow])
	return (
		<ModalHeader>
			<p className="modal-header-generico-text">{title}</p>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{children}
				<CloseGenerico
					title={t("Clique ou pressione 'Esc' para fechar").toString()}
					onClick={handleCloseModal}
				>
					&times;
				</CloseGenerico>
			</div>
		</ModalHeader>
	);
};

interface PanelOptionsProps {
	handleDeletar?: (itemId: number) => void;
	itemId?: number;
	handleEncerrar?: (itemId: number) => void;
	showCopy: boolean;
	copyText: string;
	isFinished?: boolean;
	handleReactivate?: (itemId: number) => void;
}

const PanelOptions = ({
	handleDeletar,
	itemId,
	handleEncerrar,
	showCopy,
	copyText,
	handleReactivate,
	isFinished
}: PanelOptionsProps) => {

	const handleDelete = useCallback(() => {
		if(handleDeletar && itemId){
			handleDeletar(itemId);
		}
	},[handleDeletar]);

	const handleFinish = useCallback(() => {
		if(handleEncerrar && itemId){
			handleEncerrar(itemId);
		}
	},[handleEncerrar])

	const handleReactivateCallback = useCallback(() => {
		if (handleReactivate && itemId) {
			handleReactivate(itemId);
		}
	}, [handleReactivate]);

	return (
		<>
			<div style={{ position: 'relative' }}>
				<ActionsMenu 
					editOption={false}
					handleDelete={handleDelete} 
					deleteOption={!!handleDeletar}
					handleFinish={handleFinish}
					finishOption={!isFinished && !!handleEncerrar}
					copyOption={!!showCopy}
					copyText={copyText || ''}
					handleReactivate={handleReactivateCallback}
					reactivateOption={isFinished && !!handleReactivate}
				/>
			</div>
		</>
	);
}

export interface ModalConteudoGenericoProps {
	btnSave: boolean;
	handleDeletar?: (itemId: number) => void;
	hour?: boolean;
	itemId?: number;
	loadingBtn: boolean;
	modalGenericoShow: Dispatch<boolean>;
	modalIsShowing: boolean;
	onClickSave: () => void;
	onClickSaveAndContinue: () => void;
	saveAndContinue: boolean;
	showEllipsis: boolean;
	title: string;
	children: ReactNode;
	handleEncerrar?: (itemId: number) => void;
	onScroll?: any;
	showCopy?: boolean;
	copyText: string;
	handleReactivate?: (itemId: number) => void;
	isFinished?: boolean;
}

function ModalConteudoGenerico(props: ModalConteudoGenericoProps) {
	document.addEventListener('keyup', event => {
		if (event.defaultPrevented) {
			return;
		}

		const key = event.key;

		if (key === 'Escape' || key === 'Esc') {
			if (props) {
				props.modalGenericoShow(false);
			}
		}
	});

	return (
		<ModalContent>
			<div role={'dialog'} id='modal-content-side'>
				<Header
					title={props.title}
					closeModalShow={props.modalGenericoShow}
				>
					{props.showEllipsis && (
						<PanelOptions
							itemId={props.itemId}
							handleDeletar={props.handleDeletar}
							handleEncerrar={props.handleEncerrar}
							copyText={props.copyText}
							showCopy={!!props.showCopy}
							handleReactivate={props.handleReactivate}
							isFinished={props.isFinished}
						/>
					)}
				</Header>
				<BodySide
					onScroll={props?.onScroll}
					side={props.hour == undefined || props.hour === false}
					hour={!!props.hour}
					style={{backgroundColor: "#f7f6f5"}}
					className={`modal-bg-center`}
				>
					{/* Corpo do modal renderizado por children */}
					{props.children}
				</BodySide>
			</div>
			<ModalFooter>
				{props.btnSave ? (
					<ButtonContainer>
						<GenericButton
							title={props.loadingBtn ? t('Salvando...').toString() : t('Salvar').toString()}
							disabled={props.loadingBtn}
							onClick={props.onClickSave}
							className='w-full'
						>
							{props.loadingBtn ? (
								<ModalLoader></ModalLoader>
							) : (
								t('Salvar e Fechar')
							)}
						</GenericButton>

						{props.saveAndContinue ? (
							<GenericButton
								title={props.loadingBtn ? t('Salvando...').toString() : t('Salvar e Continuar').toString()}
								disabled={props.loadingBtn}
								onClick={props.onClickSaveAndContinue}
								className='w-full bg-[#23d9b7]'
							>
								{props.loadingBtn ? (
									<ModalLoader></ModalLoader>
								) : (
									t('Salvar e Continuar')
								)}
							</GenericButton>
						) : null}
					</ButtonContainer>
				) : null}
			</ModalFooter>
		</ModalContent>
	);
}

export interface FormSideGenericoProps {
	title: string;
	handleAdicionar: (value: boolean) => void;
	loadingBtn: boolean;
	onClickSave: () => void;
	children: ReactNode;
	btnSave: boolean;
	hour? : boolean;
	saveAndContinue?: boolean;
	onClickSaveAndContinue?: () => void;
}

function FormSideGenerico(props: FormSideGenericoProps) {
	return (
		<FormSide id="side-content-generico" className='form-content-generico-side'>
			<FormHeader className="form-header-generico-side">
				<p className="modal-header-generico-side-text">{props.title}</p>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<span
						onClick={() => {
							props.handleAdicionar(false);
						}}
						className="close-generico-side-form"
					>
						&times;
					</span>
				</div>
			</FormHeader>
			<BodySide
				hour={props.hour !== undefined && props.hour === true}
				side={true}
				className="modal-bg-center"
			>
				{props.children}
			</BodySide>
			<div className="form-footer-generico-side">
				{props.btnSave ? (
					<ButtonContainer>
						<GenericButton
							title={props.loadingBtn ? t('Salvando...').toString() : t('Salvar').toString()}
							disabled={props.loadingBtn}
							onClick={props.onClickSave}
							className='w-full'
						>
							{props.loadingBtn ? (
								<ModalLoader></ModalLoader>
							) : (
								t('Salvar')
							)}
						</GenericButton>

						{props.saveAndContinue ? (
							<GenericButton
								title={props.loadingBtn ? t('Salvando...').toString() : t('Salvar e Continuar').toString()}
								disabled={props.loadingBtn}
								onClick={props.onClickSaveAndContinue}
								className='w-full bg-[#23d9b7]'
							>
								{props.loadingBtn ? (
									<ModalLoader></ModalLoader>
								) : (
									t('Salvar e Continuar')
								)}
							</GenericButton>
						) : null}
					</ButtonContainer>
				) : null}
			</div>
		</FormSide>
	);
}

export { ModalPrincipalGenerico, ModalConteudoGenerico, FormSideGenerico };
