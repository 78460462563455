import styled from "styled-components";

export const Card = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-between;

    > div {
        display: flex;
        gap: 4px;
    }

    .kanban-subtask-dateDiv {
        align-items: center;
    }

    .kanban-subtask-modal-container {
        position: relative;
    }
`;

export const CheckInput = styled.input`
    ::before {
        background-color: #496070 !important;
        border-color: #fff !important;
        border-radius: 0  !important;
    }

    ::after {
        border-color: #fff !important;
    }
`;

export const NameInput = styled.input`
    outline: none;
    height: 16px;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
`;

export const NameText = styled.p`
    margin: 0;
    margin-left: 4px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;    
    max-height: 16px;      
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

interface DateTextProps {
    isEditingDate: boolean;
}

export const DateText = styled.span<DateTextProps>`
    display: ${({isEditingDate}) => isEditingDate ? "none" : 'flex'};
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

export const DateInput = styled.input`
    display: flex;
    align-items: center;
    outline: none;
    height: 16px;
    padding: 2px;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;

    ::before {
        color: white !important;
        top: -2px !important;
        right: 0 !important;
        height: 16px !important;
    }
`;

export const ProfileImage = styled.img`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgba(255,255,255,0.2);
`;

export const ProfileOver = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-weight: 700;
`;

interface MenuButtonProps {
    isOpen?: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
    display: flex;
    color: #fff;
    border: ${({isOpen}) => isOpen ? "1px solid #fff" : "none"};
    border-radius: 4px;
    box-sizing: border-box;
    background: transparent;
    padding: 0;
    cursor: pointer;
    gap: 8px;
    white-space: nowrap;
    z-index: 1;
`;

interface MenuModalProps {
    isAssigning: boolean;
}

export const MenuModal = styled.div<MenuModalProps>`
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    gap: 6px;
    background: #fff;
    padding: 6px 8px;
    width: ${"unset"};

    button {
        color: #496070;
    }
`;


export const AssignContainer = styled.div`
    /* position: absolute; */
    color: ${(props)=>props.theme.colors.text.titleText};
    z-index: 2;
`;