import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import HeaderLink from '../../../components/common/HeaderLink'

import { useTranslation } from 'react-i18next'
import '../../../i18n'

import Tarefas from './Task'
import ModalTarefa from './ModalTarefa'
import FilterTarefas from './filtro'
import { Kanban } from './Kanban/Kanban'
import ModalProjeto from '../Projetos/ModalProjeto'

import { useProject } from '../../../contexts/Project'
import { useFilters } from '../../../contexts/Filter/Task/Filter'
import { useDataTask } from '../../../contexts/InfoTarefa/DataTask'
import { useKanban } from '../../../contexts/Kanban/Kanban'

import ProjetoService from '../../../services/providers/project'
import TarefaService from '../../../services/providers/task'

import { SelectOptions } from '../../../models/Select'
import { MainProject } from '../../../models/Project'
import { Task } from '../../../models/Task'

import { verifyCanEditProject } from '../../../utils/funcoes/validarPerfil'
import { Container, Header } from './styled'

import addPersonIcon from '../../../assets/svgs/addPerson_white.svg'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { HeaderButton } from '../../../components/common/HeaderLink/types'
import { useUser } from '../../../contexts/User'
import { UserDetails } from '../../../models/UserDetails'
import TurTutorial from '../../../components/tourTutorial'

export default function TasksContainer() {
	const { t } = useTranslation()
	const data = useLocation()

	const [isTaskModalVisible, setIsTaskModalVisible] = useState(false)
	const [editingTask, setEditingTask] = useState(true)
	const [editedTask, setEditedTask] = useState<Task>()
	const [loadingEndTask, setLoadingEndTask] = useState<boolean>(false)
	const [acessCode, setAcessCode] = useState('m')
	const [user, setUser] = useState<UserDetails | null>(null)
	const [projectDetail, setProjectDetail] = useState<MainProject>()
	const [loadingProjectDetail, setLoadingProjectDetail] = useState<boolean>(true)

	const { selectedProject } = useFilters()
	const { getTasksPagination, loadingTasks } = useDataTask()
	const { loadingFilters } = useKanban()
	const { pathname } = data

	const { userDetails } = useUser()

	useEffect(() => {
		const selected = Array.isArray(selectedProject) ? selectedProject : [selectedProject]
		if(projectDetail && selected[0].value !== projectDetail?.id){
			setProjectDetail(undefined)
		}
	}, [projectDetail])

	useEffect(() => {
		getUserdetails()
	}, [userDetails])

	const { modalProjectVisible, setModalProjectVisible } = useProject()

	useEffect(() => {
		const selected = Array.isArray(selectedProject) ? selectedProject : [selectedProject]
		if (pathname === '/registros/kanban' && selected[0].value !== '' ) {
			getProjectDetails()
		}
	}, [selectedProject, pathname])

	const getProjectDetails = async () => {
		setLoadingProjectDetail(true)

		const projectService = new ProjetoService()
		const selected = selectedProject as SelectOptions

		const [project, projectDetail]  =
		await Promise.all([
			projectService.getProject( parseInt(selected.value?.toString()), ''),
			projectService.getProjectDetail({
				id: parseInt(selected.value?.toString()),
				serializer: 'none'
			})
		])
		if(project && projectDetail){
			setProjectDetail({...project, ...projectDetail})
		}
		setLoadingProjectDetail(false)

	}

	const getUserdetails = () => {
		if (userDetails !== null) {
			setAcessCode(userDetails.acesso_cod)
			setUser(userDetails)
		}
	}

	const _deleteTarefa = (id: number) => {
		const tarefaService = new TarefaService()
		tarefaService.deleteTarefa(
			{
				id: id,
			success: (response: any) => {
				if (response) {
					setIsTaskModalVisible(false)
					getTasksPagination()
					toast.success(t('Tarefa removida com sucesso!'))
				}
			},
			errorCallback: (error: Error) => {
				toast.error(error.message)
			},
			}
		)
	}

	const encerrarTarefaAnimacao = (id: number | string) => {
		const rowEncerrada = document.getElementById(`tarefa-row${id}`)!
		rowEncerrada.style.transition = 'all 0.5s'
		rowEncerrada.style.opacity = '0%'
		setTimeout(() => {
			rowEncerrada.style.display = 'none'
			setLoadingEndTask(false)
		}, 600)
	}

	const _encerrarTarefa = (id: number | string) => {
		setLoadingEndTask(true)
		const tarefaService = new TarefaService()
		tarefaService.encerrarTarefa(
			{
				id: id,
			success:(response) => {
				if (response) {
					toast.success(t('Tarefa encerrada com sucesso!'))
					getTasksPagination()
					if (isTaskModalVisible) {
						setIsTaskModalVisible(false)
					}
					encerrarTarefaAnimacao(id)
				}
			},
			errorCallback: (error: Error) => {
				toast.error(error.message)
				setLoadingEndTask(false)
			},
			}
		)
	}

	const handleDeletar = useCallback((id: number) => {
		_deleteTarefa(id)
	}, [_deleteTarefa])

	const handleEncerrar = useCallback((id: number | string) => {
		_encerrarTarefa(id)
	}, [_encerrarTarefa])

	const handleClickProject = useCallback(() => {
		setModalProjectVisible(true)
	}, [setModalProjectVisible])

	const handleClickTask = useCallback(() => {
		setEditingTask(false)
		setIsTaskModalVisible(true)
	}, [setIsTaskModalVisible, setEditingTask])
	
	const handleAfterPatch = useCallback(
		() => {
			if (pathname === '/registros/tarefas') {
				getTasksPagination()
			}
		},
		[pathname, getTasksPagination],
	)


	const headerButtons: HeaderButton[] = [
		{
			handleClick: handleClickTask,
			icon: faPlus,
			isHidden: acessCode === 'e',
			text: 'Tarefa',
			className: "react-tour__tarefa-add",
			id: 'add_task'
		},
		{
			handleClick: handleClickProject,
			disabled: !projectDetail || loadingProjectDetail,
			icon: addPersonIcon,
			isHidden: acessCode === 'm' || acessCode === 'e' ||
			pathname !== '/registros/kanban',
			text: 'Membro',
		}
	]
	
	return (
		<>
			<HeaderLink type="task" headerButtons={headerButtons} />
			<Container  kanban={pathname === '/registros/kanban'} loadingFilters={loadingFilters || loadingTasks}>
				<Header>
					<FilterTarefas
						kanban={pathname === '/registros/kanban'}
					/>
				</Header>
				{data.pathname === '/registros/tarefas' ? (
					<>
						{userDetails && !userDetails.has_finished_tour && <TurTutorial nomeRota={'tarefa'} />}
						<Tarefas
							handleDeletar={handleDeletar}
							handleEncerrar={handleEncerrar}
							setEditingTarefa={setEditingTask}
							setTarefaEditada={setEditedTask}
							setModalTarefaShow={setIsTaskModalVisible}
							loadingEncerrarTarefa={loadingEndTask}
							setLoadingEncerrarTarefa={setLoadingEndTask}
						/>
					</>
				) : (
					<>
					{userDetails && !userDetails.has_finished_tour && <TurTutorial nomeRota={'kanban'} />}
						<Kanban />
					</>
				)}

			</Container>
			{isTaskModalVisible ? (
				<ModalTarefa
					setIsVisible={setIsTaskModalVisible}
					isVisible={isTaskModalVisible}
					editingTask={editingTask}
					selectedTask={editedTask}
					handleDeletar={handleDeletar}
					handleEncerrar={handleEncerrar}
					handleAfterPatch={handleAfterPatch}
					fromKanban={data.pathname !== '/registros/tarefas'}
				/>
			) : null}

			{modalProjectVisible && verifyCanEditProject(user, projectDetail?.is_member, projectDetail?.admins) ? (
				<ModalProjeto
					editingProjeto={true}
					itemSelecionado={projectDetail}
					setModalShow={setModalProjectVisible}
					needToLoadDetails={false}
				/>
			) : null}
		</>
	)
}
