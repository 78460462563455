import styled from "styled-components";
import { theme } from "../../styles/theme";

interface PageContainerProps {
    loading?: boolean;
}

export const PageContainer = styled.div<PageContainerProps>`
    display: flex;
    flex-direction: column;
    margin: 2rem;
    cursor: ${({loading}) => loading ? 'progress': 'initial'};
`

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`

export const HeaderButton = styled.button`
    height: 46px;
    border-radius: 10px;
    background-color: ${theme.colors.button.saveBtn};
    color: white;
    border: none;
    font-weight: 500;
    padding: 10px 30px;
`

export const ModalContainer = styled.div`
    position: relative;
`