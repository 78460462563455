import React, { useState, useEffect, useRef } from 'react';
import RelatoriosService from '../../services/providers/report';

import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';

import PlanilhaDeTempo from './planilhaDeTempo';
import Financeiro from './financeiro';
import Calendario from './calendario';
import Custo from './custo';
import CalendarioGeral from './calendarioGeral/calendarioGeral';
import AsyncSelector from '../../components/common/AsyncSelector';

import ReactGA from 'react-ga';

import './style.css';

import { manipulaPeriodoSelecionado } from '../../utils/funcoes/manipularPeriodo';

import PageContainerRelatorio from '../../components/containers/PageContainer/pageContainerRelatorio';
import ClienteService from '../../services/providers/client';
import ProjetoService from '../../services/providers/project';
import TarefaService from '../../services/providers/task';
import PessoaService from '../../services/providers/responsible';
import { customStyles } from '../../utils/extenal-libs';
import { toast } from 'react-toastify';

import ProgressBar from 'react-bootstrap/ProgressBar';

import Select from 'react-select';
import TotalDeHoras from './totalDeHoras';
import { useFilterCommonState } from '../../contexts/Filter/FilterCommonState';
import {  isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/User';
import TurTutorial from '../../components/tourTutorial';
import { useReactToPrint } from 'react-to-print';

export default function ReportPage(props) {
	const {t} = useTranslation();
	const gerarRelatorioRef = useRef();

	const {setIsFilterOpen} = useFilterCommonState();


	// Data
	const [horaData, setHoraData] = useState([]);
	// Data Calendário
	const [diasDaSemanaArray, setDiasDaSemanaArray] = useState([]);
	const [diasDaSemanaArrayAux, setDiasDaSemanaArrayAux] = useState([]);

	const [opcaoCalendario, setOpcaoCalendario] = useState('');

	// Filtros
	const [tipoRelatorioFilter, setTipoRelatorioFilter] = useState('');
	const [intervalo, setIntervalo] = useState({ value: 'hoje', label: t('Hoje') });
	const [intervaloPersonalizado, setIntervaloPersonalizado] = useState(false);
	const [dataInicialFilter, setDataInicialFilter] = useState('');
	const [dataFinalFilter, setDataFinalFilter] = useState('');
	// Filtros (endpoints)
	const [clientesFilterArray, setClientesFilterArray] = useState([]);
	const [projetosFilterArray, setProjetosFilterArray] = useState([]);
	const [tarefasFilterArray, setTarefasFilterArray] = useState([]);
	const [pessoasFilterArray, setPessoasFilterArray] = useState([]);
	const [dataInicialFilterAux, setDataInicialFilterAux] = useState('');
	const [dataFinalFilterAux, setDataFinalFilterAux] = useState('');

	//Loading barra
	const [paginaAtual, setpaginaAtual] = useState(0);
	const [paginaTotal, setpaginaTotal] = useState(1);
	// Loading get
	const [loadingGetHoras, setLoadingGetHoras] = useState(false);
	const [loadingRelatorioCSV, setLoadingRelatorioCSV] = useState(false);
	// checkbox
	const [tipoDeRelarorio, setTipoDeRelatorio] = useState({
		tipo: 'projeto',
		enviando: 'projeto',
	});
	const [totalHorasType, setTotalHorasType] = useState('totalHorasdia');
	const [tipoCusto, setTipoCusto] = useState('projeto');
	// Usuário tipo


	const { userDetails } = useUser();

	const [usuarioHoraMensalAtalho, setUsuarioHoraMensalAtalho] = useState({
		value: '',
		label: null,
	});

	const [custoData, setCustoData] = useState([]);

	const [clienteSelectShow, setClienteSelectShow] = useState('');
	const [projetoSelectShow, setProjetoSelectShow] = useState('');
	const [TarefaSelectShow, setTarefaSelectShow] = useState('');
	const [usuarioSelectShow, setUsuarioSelectShow] = useState('');
	const [tipoRelatorioFilterAux, setTipoRelatorioFilterAux] = useState('');


	//Check
	const [checkOciosas] = useState(false);

	//services
	const clienteService = new ClienteService();
	const projetoService = new ProjetoService();
	const pessoaService = new PessoaService();
	const tarefaService = new TarefaService();

	useEffect(() => {
		getPessoaRelatorioAtalho();
		if (props.location.pathname === '/relatorios/total_horas') {
			setTipoRelatorioFilterAux('totalhoras');
			setTipoRelatorioFilterAux({
				value: 'totalhoras',
				label: 'Total de Horas',
			});
			setIntervalo({
				value: 'mes',
				label: 'Este mês',
			});
			setTimeout(() => {
				gerarRelatorioRef.current.click();
			}, 1000);
		}
	}, []);

	useEffect(() => {
		manipulaPeriodo('hoje');
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if (opcaoCalendario === 'prev' || opcaoCalendario === 'next') {
			gerarRelatorioRef.current.click();
		}
	}, [dataFinalFilterAux]);


	const getPessoaRelatorioAtalho = () => {
		const pessoaAtalho = localStorage.getItem('state relatorio atalho')
			? JSON.parse(localStorage.getItem('state relatorio atalho'))
			: null;
		if (pessoaAtalho !== null) {
			setUsuarioHoraMensalAtalho(pessoaAtalho);
		}
	};

	useEffect(() => {
		if(userDetails) {
			if (tipoRelatorioFilterAux.value === 'totalhoras') {
				if (props.location.pathname === '/relatorios/total_horas') {
					setUsuarioSelectShow({
						label: usuarioHoraMensalAtalho.label,
						value: usuarioHoraMensalAtalho.value,
					});
					setPessoasFilterArray(usuarioHoraMensalAtalho.value);
				} else {
					setUsuarioSelectShow({
						label: userDetails?.username,
						value: userDetails?.id_pessoa,
					});
					setPessoasFilterArray(userDetails?.id_pessoa);
				}
			}
		}
	}, [tipoRelatorioFilterAux, userDetails]);
	const manipulaPeriodo = (opcao, diaAux) => {
		setOpcaoCalendario(opcao);
		opcao === 'personalizado'
			? setIntervaloPersonalizado(true)
			: setIntervaloPersonalizado(false);
		let peridoManipulado = manipulaPeriodoSelecionado(opcao, diaAux);
		setDataInicialFilterAux(peridoManipulado.dataInicial);
		setDataFinalFilterAux(peridoManipulado.dataFinal);
		setDiasDaSemanaArrayAux(peridoManipulado.periodoDeDias);
	};

	const _getRelatorioCSV = (tipo_relatorio = tipoRelatorioFilter) => {
		setLoadingRelatorioCSV(true);
		const relatorioService = new RelatoriosService();
		// let tipo_relatorio = tipoRelatorioFilter;
		if (tipo_relatorio === 'financeiro') {
			switch (tipoDeRelarorio.tipo) {
				case 'projeto':
					tipo_relatorio = 'financeiro-projetos';
					break;
				case 'pessoa':
					tipo_relatorio = 'financeiro-pessoas';
					break;
				case 'tarefa':
					tipo_relatorio = 'financeiro';
					break;

				default:
					break;
			}
		}
		if (tipo_relatorio === 'custo') {
			tipoDeRelarorio.enviando === 'projeto'
				? (tipo_relatorio = 'relatorio-custos')
				: (tipo_relatorio = 'relatorio-custos-pessoas');
		}
		if (tipo_relatorio === 'totalhoras') {
			switch (totalHorasType) {
				case 'totalHorasdia':
					tipo_relatorio = 'total-hora-por-dia';
					break;
				case 'totalHorasTarefa':
					tipo_relatorio = 'total-hora-por-tarefa';
					break;
				default:
					break;
			}
		}
		relatorioService.getRelatorioCSV(
			{
				cliente:clientesFilterArray,
				projetos:projetosFilterArray,
				tarefas:tarefasFilterArray,
				pessoas:pessoasFilterArray,
				dataInicial:dataInicialFilterAux,
				dataFinal:dataFinalFilterAux,
				
			},
			tipo_relatorio,
			() => {
				setLoadingRelatorioCSV(false);
			},
			() => {
				setLoadingRelatorioCSV(false);
			},
		);
	};

	const _getRelatorioXLSX = (tipo_relatorio = tipoRelatorioFilter) => {
		setLoadingRelatorioCSV(true);
		const relatorioService = new RelatoriosService();
		if (tipo_relatorio === 'financeiro') {
			switch (tipoDeRelarorio.tipo) {
				case 'projeto':
					tipo_relatorio = 'financeiro-projetos';
					break;
				case 'pessoa':
					tipo_relatorio = 'financeiro-pessoas';
					break;
				case 'tarefa':
					tipo_relatorio = 'financeiro';
					break;

				default:
					break;
			}
		}
		if (tipo_relatorio === 'custo') {
			tipoDeRelarorio.enviando === 'projeto'
				? (tipo_relatorio = 'relatorio-custos')
				: (tipo_relatorio = 'relatorio-custos-pessoas');
		}
		if (tipo_relatorio === 'totalhoras') {
			switch (totalHorasType) {
				case 'totalHorasdia':
					tipo_relatorio = 'total-hora-por-dia';
					break;
				case 'totalHorasTarefa':
					tipo_relatorio = 'total-hora-por-tarefa';
					break;
				default:
					break;
			}
		}
		relatorioService.getRelatorioXLSX(
			{
				cliente:clientesFilterArray,
				projetos:projetosFilterArray,
				tarefas:tarefasFilterArray,
				pessoas:pessoasFilterArray,
				dataInicial:dataInicialFilterAux,
				dataFinal:dataFinalFilterAux,
				
			},
			tipo_relatorio,
			() => {
				setLoadingRelatorioCSV(false);
			},
			() => {
				setLoadingRelatorioCSV(false);
			},
		);
	};


	const _getHorasAsync = () => {
		setLoadingGetHoras(true);
		const relatorioService = new RelatoriosService();
		relatorioService.getHorasAsync(
			{
				cliente:clientesFilterArray,
				projetos:projetosFilterArray,
				tarefas:tarefasFilterArray,
				pessoas:pessoasFilterArray,
				dataInicial:dataInicialFilterAux,
				dataFinal:dataFinalFilterAux,
				
			},
			response => {
				if (response.data) {
					setHoraData(response.data);
				}
				setLoadingGetHoras(false);
			},
			error => {
				setHoraData([]);
				setLoadingGetHoras(false);
			},
			paginaAtual => {
				setpaginaAtual(paginaAtual);
			},
			paginaFinal => {
				setpaginaTotal(paginaFinal);
			},
		);
	};

	const _getRelatorioCustoTempoOciosoAsync = async () => {
		setLoadingGetHoras(true);
		const relatorioService = new RelatoriosService();
		let horasOciosasTemp = [];
		await relatorioService.getRelatorioCustoTempoOciosoAsync(
			{
				clientID: clientesFilterArray,
				projectID: projetosFilterArray,
				personID: pessoasFilterArray,
				initialDate: dataInicialFilterAux,
				finalDate: dataFinalFilterAux,
			},
			response => {
				if (response.data) {
					let temp = response.data;
					temp.sort((a, b) =>{
							return a.pessoa_nome < b.pessoa_nome ? -1 : 1
					});

					horasOciosasTemp = temp;
				}
			},
			() => {
			},
			() => {
				setpaginaAtual(1);
			},
			() => {
				setpaginaTotal(100);
			},
		);

		return horasOciosasTemp;
	};

	const _getFinanceiro = () => {
		setLoadingGetHoras(true);
		const relatorioService = new RelatoriosService();
		relatorioService.getFinanceiroAsync(
			{
				cliente:clientesFilterArray,
				projetos:projetosFilterArray,
				tarefas:tarefasFilterArray,
				pessoas:pessoasFilterArray,
				dataInicial:dataInicialFilterAux,
				dataFinal:dataFinalFilterAux,
				
			},
			response => {
				if (response.data) {
					setHoraData(response.data);
				}
				setLoadingGetHoras(false);
			},
			error => {
				setHoraData([]);
				setLoadingGetHoras(false);
				// toast.error(error);
			},
			paginaAtual => {
				setpaginaAtual(paginaAtual+1);
			},
			paginaFinal => {
				setpaginaTotal(paginaFinal+100);
			},
		);
	};

	const formatCostPerson = (horasOciosas, horasCusto) => {
		const costWithOcioso = horasCusto.map((item) => {
			const horaOciosa = horasOciosas.filter((hora) => hora.pessoa == item.id).map((hora) => {
				return {
					custo_total_negociado: hora.custo_total_negociado,
					custo_total_pessoa: hora.custo_total_pessoa,
					horas_negociadas: "00:00:00",
					horas_trabalhadas: hora.total_hora_pessoatarefa,
					investimento: hora.investimento,
					pessoa: {id: hora.pessoa, nome: hora.pessoa_nome},
					projeto: {id: 0, nome: "Tempo Ocioso"},
					tarefa: 0,
					valor_pessoanegociado: hora.valor_pessoaporhora,
					valor_pessoaporhora: hora.valor_pessoaporhora
				}
			});

			if(isUndefined(item?.custos_pessoa_projeto)) {
				return item;
			}
			return {
				...item,
				custos_pessoa_projeto: [...item.custos_pessoa_projeto, ...horaOciosa]
			}
		});
		return costWithOcioso;
	}

	const formatCostProject = (horasOciosas, horasCusto) => {
		const horaOciosa = {
			id: 0,
			nome: "Tempo Ocioso",
			pessoas_projeto: horasOciosas.map((hora) => {
				return {
					custo_total_negociado: hora.custo_total_negociado,
					custo_total_pessoa: hora.custo_total_pessoa,
					horas_negociadas: "00:00:00",
					horas_trabalhadas: hora.total_hora_pessoatarefa,
					investimento: hora.investimento,
					pessoa: {id: hora.pessoa, nome: hora.pessoa_nome},
					projeto: {id: 0, nome: "Tempo Ocioso"},
					tarefa: 0,
					valor_pessoanegociado: hora.valor_pessoaporhora,
					valor_pessoaporhora: hora.valor_pessoaporhora
				}
			})
		}
		const horaWithOciosa = [...horasCusto, horaOciosa]
		return horaWithOciosa;
	}
	const handleCostReportData = (horasOciosas, horasCusto, tipo) => {
		if(tipo === 'pessoa'){
			setCustoData([]);
			
			setCustoData(formatCostPerson(horasOciosas, horasCusto));
		}else if(tipo === 'projeto') {
			setCustoData([]);
			setCustoData(formatCostProject(horasOciosas, horasCusto));
		}
	}

	const getRelatorioCusto = tipo => {
		setLoadingGetHoras(true);
		_getRelatorioCustoTempoOciosoAsync().then((horasOciosas) => {
		const relatorioService = new RelatoriosService();
		if (tipo === 'tarefa'){
			setTipoDeRelatorio({
				tipo: 'projeto',
				enviando: 'relatorio-custos',
			})
			tipo = 'projeto'
		}
		const type = {
			pessoa: 'custos-pessoas-agrupados',
			projeto: 'custos-projetos-agrupados'
		};
		const name = type[tipo];
		relatorioService.getRelatorioCustoAsync(
			{
				clientID: clientesFilterArray,
				projectID: projetosFilterArray,
				personID: pessoasFilterArray,
				initialDate: dataInicialFilterAux,
				finalDate: dataFinalFilterAux,
			},
			name,
			response => {
				if (response.data) {
					//*Ao alterar o custo data sem mudar o custo do tipo terá um erro. O react tenta ler um campo que não existe.*//
					setTipoCusto(tipo);
					handleCostReportData(horasOciosas, response.data, tipo);
				}

				setLoadingGetHoras(false);
			},
			error => {
				setLoadingGetHoras(false);
			},
			paginaAtual => {
				setpaginaAtual(paginaAtual);
			},
			paginaFinal => {
				setpaginaTotal(paginaFinal);
			},
		);
	});
	};

	const validacaoDePerfil = () => {
		if (userDetails?.acesso_cod === 'a') {
			return [
				{
					value: 'financeiro',
					label: t('Relatório Financeiro'),
				},
				{
					value: 'custo',
					label: t('Relatório de Custos'),
				},
				{
					value: 'calendario',
					label: t('Calendário Semanal individual'),
				},
				{
					value: 'calendariogeral',
					label: t('Calendário Semanal'),
				},
				{
					value: 'totalhoras',
					label: t('Total de Horas'),
				},
			];
		} else if (userDetails?.acesso_cod === 'g') {
			return [
				{
					value: 'totalhoras',
					label: t('Total de Horas'),
				},
				{
					value: 'financeiro',
					label: t('Relatório Financeiro'),
				},
				{
					value: 'calendario',
					label: t('Calendário Semanal individual'),
				},
				{
					value: 'calendariogeral',
					label: t('Calendário Semanal'),
				},
			];
		} else if (userDetails?.acesso_cod === 'e') {
			return [
				{
					value: 'totalhoras',
					label: t('Total de Horas'),
				},
				{
					value: 'financeiro',
					label: t('Relatório Financeiro'),
				},
			];
		} else if (userDetails?.acesso_cod === 'm'  || !userDetails) {
			return [
				{
					value: 'totalhoras',
					label: t('Total de Horas'),
				},
			];
		} 
	};

	const atribuirSelecionadosAsFiltros = (selectedOption, filtro) => {
		const arrayProjetos = [];
		const arrayTarefas = [];
		const arrayPessoas = [];
		if (selectedOption !== null) {
			switch (filtro) {
				case 'projeto':
					selectedOption.forEach(option => {
						arrayProjetos.push(option.value);
					});
					setProjetosFilterArray(arrayProjetos ? arrayProjetos : []);
					break;
				case 'tarefa':
					selectedOption.forEach(option => {
						arrayTarefas.push(option.value);
					});
					setTarefasFilterArray(arrayTarefas ? arrayTarefas : []);
					break;
				case 'pessoa':
					selectedOption.forEach(option => {
						arrayPessoas.push(option.value);
					});
					setPessoasFilterArray(arrayPessoas ? arrayPessoas : []);
					break;
				default:
					break;
			}
		}
	};

	const resetFilters = () => {
		setClienteSelectShow('');
		setClientesFilterArray('');
		setProjetoSelectShow('');
		setProjetosFilterArray('');
		setTarefaSelectShow('');
		setProjetosFilterArray('');
		setUsuarioSelectShow('');
		setPessoasFilterArray('');
	};
	const relatorioRef = React.useRef(null);

	const exportToPDF = ({
		dataInfo, 
		dataValues, 
		periodoString, 
		title
	}) => {
		const doc = new jsPDF('landscape'); // Criar um novo documento PDF no modo paisagem
		const tableColumnInfo = Object.keys(dataInfo?.[0] ?? []);
		const tableColumn = Object.keys(dataValues?.[0] ?? []);

		const tableRowsInfo = [];
		const tableRows = [];
	  
		const valueToBreak = 18 - tableColumnInfo.length > 5 ? 18 - tableColumnInfo.length : 5;
		const columnsBreaks = Math.floor(tableColumn.length / valueToBreak);

		dataInfo.forEach((item) => {
			const rowData = tableColumnInfo.map((column) => {
			  return item[column]
			});
			tableRowsInfo.push(rowData);
		  });

		dataValues.forEach((item) => {
		  const rowData = tableColumn.map((column) => {
			return item[column]
		  });
		  tableRows.push(rowData);
		});

		for(let i = 0; i <= columnsBreaks; i++){
			autoTable(doc, {
				head: [
					[{
						content: title,
						colSpan: tableColumn.slice(i*valueToBreak, ((i+1)*valueToBreak)-1).length + tableColumnInfo.length,
					}],
					[{
						content: periodoString,
						colSpan: tableColumn.slice(i*valueToBreak, ((i+1)*valueToBreak)-1).length + tableColumnInfo.length
					}],
					[
						...tableColumnInfo,
						...tableColumn.slice(i*valueToBreak, ((i+1)*valueToBreak)-1)
					]
				],
				body: tableRows.map((item, index) => {
					const info = tableRowsInfo[index] ? tableRowsInfo[index] : tableColumnInfo.map(() => "")

					return [...info, ...item.slice(i*valueToBreak, ((i+1)*valueToBreak)-1)]
				}),
				headStyles:{
					fillColor: "#426172",
				},
			  });
		}
		doc.save('relatorio.pdf'); // Salvar o documento como "tabela.pdf"
	};
	  
	const handlePrint = ({
			dataInfo, 
			dataValues, 
			periodoString, 
			title
		}) => {
		if(dataValues?.length>0) {
			exportToPDF({
				dataInfo, 
				dataValues, 
				periodoString, 
				title
			})
		}
	}	

	return (
		<>
			<TurTutorial nomeRota={'report'} />
			<PageContainerRelatorio
				ref={relatorioRef}
				filter={
					<>

						{loadingGetHoras && (
							<>
								<ProgressBar
									striped
									animated
									now={paginaAtual}
									min="0"
									max={paginaTotal}
									label={`${Number((paginaAtual / paginaTotal) * 100).toFixed(
										2,
									)}%`}
								/>
							</>
						)}
						<div className="relatorio-container-input">
							<div className="relatorio-text-filter"> {t("Tipo de relatório")} </div>
							<Select
								value={tipoRelatorioFilterAux}
								onChange={e => {
									resetFilters();
									if (e.value === 'calendario' || e.value === 'calendariogeral') {
										setUsuarioSelectShow([]);
										setPessoasFilterArray('');
										manipulaPeriodo('semana');
									}
									setTipoRelatorioFilterAux(e);
								}}
								options={validacaoDePerfil()}
								isClearable={false}
								isMulti={false}
								isSearchable={false}
								closeMenuOnSelect={true}
								placeholder={t('Selecione o tipo')}
								styles={customStyles}
							/>
							{tipoRelatorioFilterAux.value === 'financeiro' && (
								<div className="relatorio-input-tipos">
									<div>
										<input
											type="checkbox"
											id="scales"
											name="scales"
											checked={tipoDeRelarorio.tipo === 'projeto'}
											onChange={() =>
												setTipoDeRelatorio({
													tipo: 'projeto',
													enviando: tipoDeRelarorio.enviando,
												})
											}
										/>
										<label htmlFor={'scales'} style={{ marginLeft: '10px' }}>
											{t("Projeto")}
										</label>
									</div>
									<div style={{ marginLeft: '6px' }}>
										<input
											type="checkbox"
											id="scales"
											name="scales"
											checked={tipoDeRelarorio.tipo === 'pessoa'}
											onChange={() =>
												setTipoDeRelatorio({
													tipo: 'pessoa',
													enviando: tipoDeRelarorio.enviando,
												})
											}
										/>
										<label htmlFor={'scales'} style={{ marginLeft: '10px' }}>
											{t("Pessoa")}
										</label>
									</div>
									<div style={{ marginLeft: '6px' }}>
										<input
											type="checkbox"
											id="scales"
											name="scales"
											checked={tipoDeRelarorio.tipo === 'tarefa'}
											onChange={() =>
												setTipoDeRelatorio({
													tipo: 'tarefa',
													enviando: tipoDeRelarorio.enviando,
												})
											}
										/>
										<label htmlFor={'scales'} style={{ marginLeft: '10px' }}>
											{t("Tarefas")}
										</label>
									</div>
								</div>
							)}
							{tipoRelatorioFilterAux.value === 'totalhoras' && (
								<div className="relatorio-input-tipos">
									<div>
										<input
											type="checkbox"
											id="scales"
											name="scales"
											checked={totalHorasType === 'totalHorasdia'}
											onChange={() => setTotalHorasType('totalHorasdia')}
										/>
										<label htmlFor={'scales'} style={{ marginLeft: '10px' }}>
											{t("Por Dia")}
										</label>
									</div>
									<div style={{ marginLeft: '6px' }}>
										<input
											type="checkbox"
											id="scales"
											name="scales"
											checked={totalHorasType === 'totalHorasTarefa'}
											onChange={() => setTotalHorasType('totalHorasTarefa')}
										/>
										<label htmlFor={'scales'} style={{ marginLeft: '10px' }}>
											{t("Por Tarefas")}
										</label>
									</div>
								</div>
							)}
							{tipoRelatorioFilterAux.value === 'custo' && (
								<div className="relatorio-input-tipos">
									<div>
										<input
											type="checkbox"
											id="scales"
											name="scales"
											checked={tipoDeRelarorio.tipo === 'projeto'}
											onChange={() =>
												setTipoDeRelatorio({
													tipo: 'projeto',
													enviando: tipoDeRelarorio.enviando,
												})
											}
										/>
										<label htmlFor={'scales'} style={{ marginLeft: '10px' }}>
											{t("Projeto")}
										</label>
									</div>
									<div style={{ marginLeft: '6px' }}>
										<input
											type="checkbox"
											id="scales"
											name="scales"
											checked={tipoDeRelarorio.tipo === 'pessoa'}
											onChange={() =>
												setTipoDeRelatorio({
													tipo: 'pessoa',
													enviando: tipoDeRelarorio.enviando,
												})
											}
										/>
										<label htmlFor={'scales'} style={{ marginLeft: '10px' }}>
											{t("Pessoa")}
										</label>
									</div>
								</div>
							)}
							{tipoRelatorioFilterAux.value !== 'calendario' &&
							tipoRelatorioFilterAux.value !== 'calendariogeral' ? (
								<div className="relatorio-intervalo-personalizado">
									<div className="relatorio-text-filter">
										{t("Intervalo de Tempo")}
									</div>
									<Select
										value={intervalo}
										onChange={e => {
											setDataInicialFilter('');
											setDataFinalFilter('');
											setIntervalo(e);
											manipulaPeriodo(e.value);
										}}
										options={[
											{
												value: 'hoje',
												label: t('Hoje'),
											},
											{
												value: 'ontem',
												label: t('Ontem'),
											},
											{
												value: 'semana',
												label: t('Esta Semana'),
											},
											{
												value: 'semanaP',
												label: t('Semana Passada'),
											},
											{
												value: 'mes',
												label: t('Este mês'),
											},
											{
												value: 'mesP',
												label: t('Mês Passado'),
											},
											{
												value: 'ano',
												label: t('Este ano'),
											},
											{
												value: 'anoP',
												label: t('Ano Passado'),
											},
											{
												value: 'personalizado',
												label: t('Personalizado'),
											},
										]}
										isClearable={false}
										isMulti={false}
										isSearchable={false}
										closeMenuOnSelect={true}
										// placeholder={'Selecione o tipo'}
										styles={customStyles}
									/>
									{intervaloPersonalizado && (
										<>
											<div className="relatorio-text-filter">

												{t("Data Inicial")}
											</div>
											<input
												type="date"
												id="diaa"
												name="dataInicial"
												className="relatorios-input-data"
												value={dataInicialFilterAux}
												onChange={e =>
													setDataInicialFilterAux(e.currentTarget.value)
												}
											/>
											<div className="relatorio-text-filter"> {t("Data Final")} </div>
											<input
												type="date"
												id="diaa"
												name="dataFinal"
												className="relatorios-input-data"
												value={dataFinalFilterAux}
												onChange={e =>
													setDataFinalFilterAux(e.currentTarget.value)
												}
											/>
										</>
									)}
								</div>
							) : null}
							{!checkOciosas && (
								<>

									{tipoRelatorioFilterAux.value !== 'calendario' ? (
										<>
											<div className="relatorio-text-filter"> {t("Clientes")} </div>
											<AsyncSelector
												value={clienteSelectShow}
												loadOptions={clienteService.loadClientesSelect}
												additional={{
													page: 1,
													placeholder: t('Todos'),
												}}
												onChange={e => {
													setClienteSelectShow(e);
													setProjetoSelectShow('');
													setProjetosFilterArray([]);
													setTarefaSelectShow('');
													setTarefasFilterArray([]);
													setUsuarioSelectShow('');
													setPessoasFilterArray([]);
													setClientesFilterArray(e != null ? e.value : '');
												}}
												isDisabled={userDetails?.acesso_cod === 'e'}
												isClearable={true}
												isMulti={false}
												// closeMenuOnSelect={true}
												placeholder={t('Todos')}
												noOptionsMessage={() => t('Vazio')}
												styles={customStyles}
											/>
										</>
									) : null}
									{tipoRelatorioFilterAux.value !== 'calendario' ? (
										<>
											<div className="relatorio-text-filter"> {t("Projetos")} </div>
											<AsyncSelector
												loadOptions={projetoService.loadProjetosAllSelect}
												additional={{
													page: 1,
													placeholder: t('Todos'),
													cliente: clientesFilterArray,
												}}
												value={projetoSelectShow}
												onChange={e => {
													setProjetoSelectShow(e);
													setTarefaSelectShow('');
													setTarefasFilterArray([]);
													setUsuarioSelectShow('');
													setPessoasFilterArray([]);
													atribuirSelecionadosAsFiltros(e ? e : [], 'projeto');
												}}
												isMulti={true}
												key={JSON.stringify(clientesFilterArray)}
												// closeMenuOnSelect={false}
												placeholder={t('Todos')}
												// noOptionsMessage={() => t('Vazio')}
												styles={customStyles}
											/>
										</>
									) : null}
									{tipoRelatorioFilterAux.value !== 'calendario' &&
									tipoRelatorioFilterAux.value !== 'calendariogeral' &&
									tipoRelatorioFilterAux.value !== 'custo' ? (
										<>
											<div className="relatorio-text-filter"> {t("Tarefas")} </div>
											<AsyncSelector
												value={TarefaSelectShow}
												loadOptions={tarefaService.loadTarefasSelect}
												additional={{
													page: 1,
													cliente: clientesFilterArray,
													projeto: projetosFilterArray,
													placeholder: t('Todas'),
												}}
												onChange={e => {
													setTarefaSelectShow(e);
													setUsuarioSelectShow('');
													setPessoasFilterArray([]);
													atribuirSelecionadosAsFiltros(e ? e : [], 'tarefa');
												}}
												isDisabled={userDetails?.acesso_cod === 'e'}
												isMulti={true}
												placeholder={t('Todas')}
												noOptionsMessage={() => t('Vazio')}
												styles={customStyles}
												key={JSON.stringify(
													`${projetosFilterArray}${clientesFilterArray}`,
												)}
												// closeMenuOnSelect={false}
											/>
										</>
									) : null}
									<div className="relatorio-text-filter"> {t("Pessoas")} </div>
									{tipoRelatorioFilterAux.value === 'calendario' ||
									tipoRelatorioFilterAux.value === 'totalhoras' ? (
										<AsyncSelector
											value={usuarioSelectShow}
											onChange={e => {
												setUsuarioSelectShow(e);
												setPessoasFilterArray(e != null ? e.value : '');
											}}
											isDisabled={userDetails?.acesso_cod === 'e'}
											isMulti={false}
											placeholder={'Selecione uma pessoa'}
											noOptionsMessage={() => t('Vazio')}
											styles={customStyles}
											loadOptions={pessoaService.loadPessoasSelect}
											additional={{
												page: 1,
												cliente: clientesFilterArray,
												projeto: projetosFilterArray,
												tarefa: tarefasFilterArray,
												userDetails: userDetails
											}}
											key={JSON.stringify(
												`${clientesFilterArray}${projetosFilterArray}${tarefasFilterArray}`,
											)}
										/>
									) : (
										<AsyncSelector
											value={usuarioSelectShow}
											onChange={e => {
												setUsuarioSelectShow(e);
												atribuirSelecionadosAsFiltros(e ? e : [], 'pessoa');
											}}
											isDisabled={userDetails?.acesso_cod === 'e'}
											isMulti={true}
											placeholder={t('Todas')}
											styles={customStyles}
											loadOptions={pessoaService.loadPessoasSelect}
											additional={{
												page: 1,
												cliente: clientesFilterArray,
												projeto: projetosFilterArray,
												tarefa: tarefasFilterArray,
												userDetails: userDetails
											}}	
											key={JSON.stringify(
												`${clientesFilterArray}${projetosFilterArray}${tarefasFilterArray}`,
											)}
										/>
									)}
								</>
							)}
						</div>
						<div className="relatorio-container-btn">
							<span
								onClick={() => {
									resetFilters();
								}}
								className="relatorio-text-limpar"
							>
								{t("limpar filtros")}
							</span>
							<button
								disabled={loadingGetHoras}
								ref={gerarRelatorioRef}
								className="relatorio-btn-filtro"
								onClick={() => {
									if (tipoRelatorioFilterAux === '') {
										toast.error('Selecione o Tipo de relatório!');
									} else {
										if (
											(tipoRelatorioFilterAux.value === 'calendario' ||
												tipoRelatorioFilterAux.value === 'totalhoras') &&
											usuarioSelectShow.length === 0
										) {
											toast.error(
												'É necessário selecionar uma pessoa para gerar o relatório!',
											);
										} else if (tipoRelatorioFilterAux.value === 'financeiro') {
											_getFinanceiro();
											setDataInicialFilter(dataInicialFilterAux);
											setDataFinalFilter(dataFinalFilterAux);
											setDiasDaSemanaArray(diasDaSemanaArrayAux);
											setTipoRelatorioFilter(tipoRelatorioFilterAux.value);
											setTipoDeRelatorio({
												...tipoDeRelarorio,
												enviando: tipoDeRelarorio.tipo,
											});
											setIsFilterOpen(true);
										} else if (tipoRelatorioFilterAux.value === 'custo') {
											getRelatorioCusto(tipoDeRelarorio.tipo);
											setDataInicialFilter(dataInicialFilterAux);
											setDataFinalFilter(dataFinalFilterAux);
											setDiasDaSemanaArray(diasDaSemanaArrayAux);
											setTipoRelatorioFilter(tipoRelatorioFilterAux.value);
											setTipoDeRelatorio({
												...tipoDeRelarorio,
												enviando: tipoDeRelarorio.tipo,
											});
										} else {
											_getHorasAsync();
											setDataInicialFilter(dataInicialFilterAux);
											setDataFinalFilter(dataFinalFilterAux);
											setDiasDaSemanaArray(diasDaSemanaArrayAux);
											setTipoRelatorioFilter(tipoRelatorioFilterAux.value);
											setIsFilterOpen(true);
										}
									}
								}}
							>
								{t("Gerar Relatório")}
							</button>
						</div>
					</>
				}
			>
				{(() => {
					switch (tipoRelatorioFilter) {
						case 'calendario':
							return (
								<Calendario
									handlePrint={handlePrint}
									loadingGetHoras={loadingGetHoras}
									registros={horaData}
									diasDaSemana={diasDaSemanaArray}
									dataInicial={dataInicialFilter}
									dataFinal={dataFinalFilter}
									navegacaoSemanal={manipulaPeriodo}
								/>
							);
						case 'planilha':
							return (
								<PlanilhaDeTempo
									handlePrint={handlePrint}
									loadingGetHoras={loadingGetHoras}
									registros={horaData}
									getRelatorioCSV={_getRelatorioCSV}
									getRelatorioXLSX={_getRelatorioXLSX}
									loadingRelatorioCSV={loadingRelatorioCSV}
								/>
							);
						case 'financeiro':
							return (
								<Financeiro
									handlePrint={handlePrint}
									loadingGetHoras={loadingGetHoras}
									valorPessoaHoraPorTarefa={horaData}
									dataInicial={dataInicialFilter}
									dataFinal={dataFinalFilter}
									tipoDeRelarorio={tipoDeRelarorio}
									getRelatorioCSV={_getRelatorioCSV}
									getRelatorioXLSX={_getRelatorioXLSX}
									loadingRelatorioCSV={loadingRelatorioCSV}
								/>
							);
						case 'custo':
							return (
								<Custo
									handlePrint={handlePrint}
									horaOcioData={[]}
									custoData={custoData}
									dataInicial={dataInicialFilter}
									dataFinal={dataFinalFilter}
									tipoDeRelarorio={tipoCusto}
									getRelatorioCSV={_getRelatorioCSV}
									getRelatorioXLSX={_getRelatorioXLSX}
									loadingRelatorioCSV={loadingRelatorioCSV}
									loadingGetHoras={loadingGetHoras}
								/>
							);

						case 'calendariogeral':
							return (
								<CalendarioGeral
									handlePrint={handlePrint}
									loadingGetHoras={loadingGetHoras}
									registros={horaData}
									diasDaSemana={diasDaSemanaArray}
									dataInicial={dataInicialFilter}
									dataFinal={dataFinalFilter}
									pessoasFilterArray={pessoasFilterArray}
									projetosFilterArray={projetosFilterArray}
									clientesFilterArray={clientesFilterArray}
									navegacaoSemanal={manipulaPeriodo}
									getRelatorioCSV={_getRelatorioCSV}
									getRelatorioXLSX={_getRelatorioXLSX}
									loadingRelatorioCSV={loadingRelatorioCSV}
								/>
							);
						case 'totalhoras':
							return (
								<TotalDeHoras
									handlePrint={handlePrint}
									relatorioType={totalHorasType}
									loadingGetHoras={loadingGetHoras}
									registros={horaData}
									dataInicial={dataInicialFilter}
									dataFinal={dataFinalFilter}
									getRelatorioCSV={_getRelatorioCSV}
									getRelatorioXLSX={_getRelatorioXLSX}
									loadingRelatorioCSV={loadingRelatorioCSV}
								/>
							);
						default:
							return null;
					}
				})()}
			</PageContainerRelatorio>
		</>
	);
}
