import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import AuthService from '../../../services/security/auth';
import { LoginSchema } from './validation.js';
import Login from '../index';
import CheckBox from '../components/CheckBox';
import ButtonForm from '../components/ButtonForm';
import FormInput from '../components/FormInput';
import BannerLogin from '../components/BannerLogin';

import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import './style.css';

import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';

export const LoginForm = () => {
	const {t} = useTranslation();
	const initialValues = { email: '', password: '' };
	const initialLembrar_senha =
		window.localStorage.getItem('Lembrar_Senha') === 'checked' ? true : false;
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [lembrarSenha, setLembrarSenha] = useState(initialLembrar_senha);

	const authService = new AuthService();

	useEffect(() => {
		if (lembrarSenha === true) {
			window.localStorage.setItem('Lembrar_Senha', 'checked');
		} else if (lembrarSenha === false) {
			window.localStorage.setItem('Lembrar_Senha', 'noChecked');
		}
	}, [lembrarSenha]);

	const { setUserDetails, setLoadingUser} = useUser();


	const doLogin = values => {
		setLoading(true);
		const form = new FormData();
		for (var key in values) {
			form.append(key, values[key]);
		}
		authService.login(
			form,
			response => {
				if (response.data) {
					setUserDetails(response.data.user_details);
					setLoadingUser(false);
				}
			},
			error => {
				toast.error(error.toString());
				setLoading(false);
			}		
		);
	};

	return (
		<Login>
			<div className="login-limiter">
				<Formik
					initialValues={initialValues}
					validationSchema={LoginSchema}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);
						doLogin(values);
					}}
				>
					{({ errors, touched }) => (
						<Form className="login-form-container">
							<BannerLogin
								titulo=""
								subTitulo={t("Insira suas credenciais para entrar na plataforma")}
							/>
							<FormInput
								nome="E-mail"
								campo="email"
								errors={errors.email}
								touched={touched.email}
							/>
							<FormInput
								id="input-password"
								nome="Password"
								campo="password"
								errors={errors.password}
								touched={touched.password}
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								type={showPassword ? 'text' : 'password'}
								autocomplete={lembrarSenha ? 'on' : 'off'}
								readonly={lembrarSenha ? false : 'readonly'}
								onClick={() =>
									document
										.getElementById('input-password')
										.removeAttribute('readonly')
								}
							/>
							<div className="login-senhaAdm">
								<div
									className="lembrar-senha"
									onClick={() => setLembrarSenha(!lembrarSenha)}
								>
									<CheckBox checked={lembrarSenha} />
									<span>{t("Lembrar")}</span>
								</div>
								<div style={{textAlign: 'right'}}>
									<Link to="/login/esqueci_senha/">{t("Esqueci a senha")}</Link>
								</div>
							</div>
							<div className="login-senhaAdm"></div>
							<ButtonForm loading={loading} />
						</Form>
					)}
				</Formik>
			</div>
		</Login>
	);
};
