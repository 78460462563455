import React, { ReactNode } from 'react';
import { Container } from './styled';

interface RegistrosContainerProps {
	children: ReactNode;
	loadingFilters?: boolean;
}

export default function RegistrosContainer({
	children,
	loadingFilters,
}: RegistrosContainerProps) {
	return <Container loadingFilters={!!loadingFilters}>{children}</Container>;
}
