import { normalize, schema } from 'normalizr';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ProjetoService from '../../services/providers/project';
import { useAtom } from 'jotai';
import { columnsManagerAtom } from '../../atoms/Kanban/KanbanProject';
import { useUser } from '../User';
import { t } from "i18next";
export const ProjectContext = createContext();

let controller = null; 

export const initialValues = {
	clienteValorSelecionado: { value: '', label: t('Clientes') },
	activeOnly: true,
	myProjects: false,
	setClienteValorSelecionado: () => {},
	setActiveOnly: () => {},
	setMyProjects: () => {},
	selectedFilters: [
		{ label: t('Clientes'), value: 'client' },
		{ label: t('Projetos'), value: 'project' },
		{ label: t('Responsáveis'), value: 'person' },
		{ label: t('Autor'), value: 'author' },
		{ label: t('Status'), value: 'status' },
		{ label: t('Tag'), value: 'tag' },
		{ label: t('Encerradas'), value: 'encerradas' },
	],
	setSelectedFilters: () => {},
};

const projectsSchema = new schema.Entity('projects');
const favoritesSchema = new schema.Entity(
	'favorites',
	{},
	{ idAttribute: (value) => value.projeto.id },
);
const ProjectProvider = ({ children }) => {
	const [loadingProjects, setLoadingProjects] = useState(false);
	const [projects, setProjects] = useState({});
	const [projectsDetails, setProjectsDetails] = useState({});
	const [searchProject, setSearchProject] = useState('');
	const [totalPages, setTotalPages] = useState(null);
	const [pages, setPages] = useState(1);
	const [loadingPostPatch, setLoadingPostPatch] = useState(false);
	const [modalProjectVisible, setModalProjectVisible] = useState(false);
	const [loadingFavorites, setLoadingFavorites] = useState(false);
	const [myProjects, setMyProjects] = useState(initialValues.myProjects);
	const [activeOnly, setActiveOnly] = useState(initialValues.activeOnly);
	const [clienteValorSelecionado, setClienteValorSelecionado] = useState(initialValues.clienteValorSelecionado);
	const [favoritesProjects, setFavoritesProjects] = useState({});
	const [selectedProjectLink, setSelectedProjectLink] = useState('/registros/projetos');

	const selectProjectGrid = () => setSelectedProjectLink('/registros/projetos');
	const selectReport = () => setSelectedProjectLink('/relatorios_projeto');
	const selectProjectList = () => setSelectedProjectLink('/registros/projetos-list');
	const selectProjectKanban = () => setSelectedProjectLink('/registros/projetos-kanban');

	const [manager, setManager] = useAtom(columnsManagerAtom)

	const { userDetails } = useUser();

	const getProjects = (page = 1) => {
		if (page === 1) {
			setTotalPages(null);
		}
		setLoadingProjects(true);

		
		if (controller !== null) {
			controller.abort();
		}

		controller = new AbortController();

		const projetoService = new ProjetoService();
		const id = '';
		let filters = myProjects || userDetails?.acesso_cod !== 'a' ? `time__in=${userDetails?.id_pessoa}&` : '';
		filters = !activeOnly && userDetails?.acesso_cod === 'a' ? filters.concat(`all=true&`) : filters;
		filters = filters.concat(
			clienteValorSelecionado
				? `cliente=${clienteValorSelecionado.value}`
				: '',
		);

		projetoService.getProjetosPaginacaoFiltered(
			page,
			searchProject,
			filters,
			id,
			response => {
				if (response) {
					const projectResponse = normalize(
						response.results,
						new schema.Array(projectsSchema),
					);
					const projectsEntity = projectResponse.entities.projects
						? projectResponse.entities.projects
						: {};

					if (page === 1) {
						setProjects(projectsEntity);
						setPages(page);
						setTotalPages(response.total_pages);
						setLoadingPostPatch(false);
					} else {
						setProjects(prevData => ({ ...prevData, ...projectsEntity }));
						setPages(page);
						setTotalPages(response.total_pages);
						setLoadingPostPatch(false);
					}
				}
				setLoadingProjects(false);
			},
			() => {
				setProjects([]);
				setLoadingProjects(false);
			},
			controller.signal
		);
	};

	const getFavorites = () => {
		setLoadingFavorites(true);
		const projetoService = new ProjetoService();
		projetoService.getFavoritos(
			response => {
				if (response.data) {
					const projectResponse = normalize(
						response.data,
						new schema.Array(favoritesSchema),
					);
					setFavoritesProjects(projectResponse.entities.favorites);
				}
				setLoadingFavorites(false);
			},
			() => {
				setLoadingFavorites(false);
			},
		);
	};

	const closeProject = id => {
		const projectService = new ProjetoService();
		projectService.encerrarProjeto(
			id,
			response => {
				if (response) {
					setModalProjectVisible(false);
					toast.success(t('Projeto encerrado com sucesso!'));
					setProjects(data => {
						const copy = { ...data };
						if(activeOnly) {
							delete copy[id];
						} else {
							copy[id] = response.data;
						}
						return copy;
					});
				}
			},
			error => toast.error(error),
		);
	};

	const reactivateProject = id => {
		const projectService = new ProjetoService();
		projectService.reativarProjeto(
			id,
			response => {
				if (response) {
					setModalProjectVisible(false);
					toast.success(t('Projeto reativado com sucesso!'));
					setProjects(data => {
						const copy = { ...data };
						copy[id] = response.data;
						return copy;
					});
				}
			},
			error => toast.error(error),
		);
	};

	const removeProjetoFromKanban = (id) => {
		const columns = manager.columnsOrder.map(columnId => {
			const tempColumn = {...manager.columns[columnId]}
			tempColumn.items = tempColumn.items.filter(item => item.id !== id)

			if(tempColumn.items.length < manager.columns[columnId].items.length){
				return {
					column: tempColumn,
					id: columnId
				}
			} else {
				return null
			}
		})

		const tempColumns = columns.filter(item => item !== null)

		if(tempColumns.length>0) {
			setManager(prev => {
				const tempManager = {...prev}

				tempManager.columns[tempColumns[0].id].items = tempColumns[0].column.items

				return tempManager
			})
		}
	}

	const deleteProject = id => {
		const projetoService = new ProjetoService();
		
		projetoService.deleteProjeto(
			id,
			response => {
				if (response) {
					setModalProjectVisible(false);
					toast.success(t('Projeto removido com sucesso!'));
					setProjects(data => {
						const copy = { ...data };
						delete copy[id];
						return copy;
					});

					removeProjetoFromKanban(id)
				}
			},
			error => {
				toast.error(error);
			},
		);
	};

	const handlePostPatchProjeto = saveAndContinue => {
		if (saveAndContinue === true) {
			setModalProjectVisible(true);
		} else {
			setModalProjectVisible(false);
		}
	};

	const postFavorites = valorID => {
		const projetoService = new ProjetoService();
		const objID = { projeto: valorID };
		projetoService.postFavoritos(
			objID,
			response => {
				if (response.data.message) {
					toast.success(t(response.data.message));
				} else {
					toast.success(t('Projeto adicionado aos favoritos!'));
				}
				const copyProjects = { ...projects };
				const newFavoriteProject = copyProjects[valorID];
				setFavoritesProjects(data => ({
					...data,
					[valorID]: { id: response.data.id, projeto: newFavoriteProject },
				}));
				setProjects(data => {
					const copy = { ...data };
					delete copy[valorID];
					return copy;
				});
			},
			error => {
				toast.error(error);
			},
		);
	};

	const deleteFavorites = valorID => {
		const projetoService = new ProjetoService();
		projetoService.deleteFavoritos(
			valorID,
			response => {
				if (response.data.message) {
					toast.success(t(response.data.message));
				} else {
					toast.success(t('Projeto removido dos favoritos!'));
				}
				const copyFavorites = { ...favoritesProjects };
				const oldFavoriteProject = copyFavorites[valorID];
				setProjects(data => ({
					...data,
					[valorID]: oldFavoriteProject.projeto,
				}));

				setFavoritesProjects(data => {
					const copy = { ...data };
					delete copy[valorID];
					return copy;
				});
			},
			error => {
				toast.error(error);
			},
		);
	};

	const projectValue = useMemo(() => ({
				clienteValorSelecionado,
				setClienteValorSelecionado,
				myProjects,
				setMyProjects,
				loadingProjects,
				projects,
				getProjects,
				searchProject,
				setSearchProject,
				totalPages,
				setTotalPages,
				pages,
				setPages,
				loadingPostPatch,
				setLoadingPostPatch,
				modalProjectVisible,
				setModalProjectVisible,
				loadingFavorites,
				setLoadingFavorites,
				favoritesProjects,
				setFavoritesProjects,
				closeProject,
				deleteProject,
				handlePostPatchProjeto,
				postFavorites,
				deleteFavorites,
				getFavorites,
				setProjects,
				setActiveOnly,
				activeOnly,
				reactivateProject,
				selectedProjectLink,
				setSelectedProjectLink,
				selectProjectGrid,
				selectProjectKanban,
				selectProjectList,
				selectReport,
				projectsDetails, 
				setProjectsDetails
	}), [
		clienteValorSelecionado,
		setClienteValorSelecionado,
		myProjects,
		setMyProjects,
		loadingProjects,
		projects,
		getProjects,
		searchProject,
		setSearchProject,
		totalPages,
		setTotalPages,
		pages,
		setPages,
		loadingPostPatch,
		setLoadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		loadingFavorites,
		setLoadingFavorites,
		favoritesProjects,
		setFavoritesProjects,
		closeProject,
		deleteProject,
		handlePostPatchProjeto,
		postFavorites,
		deleteFavorites,
		getFavorites,
		setProjects,
		setActiveOnly,
		activeOnly,
		reactivateProject,
		selectedProjectLink,
		setSelectedProjectLink,
		selectProjectGrid,
		selectProjectKanban,
		selectProjectList,		
		selectReport,
		projectsDetails, 
		setProjectsDetails
	])

	return (
		<ProjectContext.Provider
			value={projectValue}
		>
			{children}
		</ProjectContext.Provider>
	);
};

function withProject (Child){
	return function ProjectComponent (props) {
		return (
			<ProjectContext.Consumer>
				{context => <Child {...props} {...context} />}
			</ProjectContext.Consumer>
		);
	}
} 

const useProject = () => {
	const context = useContext(ProjectContext);
	const {
		clienteValorSelecionado,
		setClienteValorSelecionado,
		myProjects,
		setMyProjects,
		loadingProjects,
		projects,
		getProjects,
		searchProject,
		setSearchProject,
		totalPages,
		setTotalPages,
		pages,
		setPages,
		loadingPostPatch,
		setLoadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		loadingFavorites,
		setLoadingFavorites,
		favoritesProjects,
		setFavoritesProjects,
		closeProject,
		deleteProject,
		handlePostPatchProjeto,
		postFavorites,
		deleteFavorites,
		getFavorites,
		setProjects,
		setActiveOnly,
		activeOnly,
		reactivateProject,
		selectedProjectLink,
		setSelectedProjectLink,
		selectProjectGrid,
		selectProjectKanban,
		selectProjectList,		
		selectReport,
		projectsDetails, 
		setProjectsDetails
	} = context;

	return {
		clienteValorSelecionado,
		setClienteValorSelecionado,
		myProjects,
		setMyProjects,
		loadingProjects,
		projects,
		getProjects,
		searchProject,
		setSearchProject,
		totalPages,
		setTotalPages,
		pages,
		setPages,
		loadingPostPatch,
		setLoadingPostPatch,
		modalProjectVisible,
		setModalProjectVisible,
		loadingFavorites,
		setLoadingFavorites,
		favoritesProjects,
		setFavoritesProjects,
		closeProject,
		deleteProject,
		handlePostPatchProjeto,
		postFavorites,
		deleteFavorites,
		getFavorites,
		setProjects,
		setActiveOnly,
		activeOnly,
		reactivateProject,
		selectedProjectLink,
		setSelectedProjectLink,
		selectProjectGrid,
		selectProjectKanban,
		selectProjectList,		
		selectReport,
		projectsDetails, 
		setProjectsDetails
	};
};

export { ProjectProvider, withProject, useProject };
