import React, { useState, useEffect, useCallback } from 'react';

import ClienteService from '../../../../../services/providers/client';
import ProjetoService from '../../../../../services/providers/project';
import { ProjetoSchema } from './validation';

import { customStyles } from '../../../../../utils/extenal-libs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';

import '../../../../../form/formGeral.css';
import './style.css';
import AsyncSelector from '../../../../../components/common/AsyncSelector';
import { useProject } from '../../../../../contexts/Project';
import { useKanban } from '../../../../../contexts/Kanban/Kanban';
import { useTranslation } from 'react-i18next';
import {  ProjectBasic } from '../../../../../models/Project';
import { SelectOptions } from '../../../../../models/Select';
import { Responsible } from '../../../../../models/Responsible';
import { ErrorContainer, FieldModal, IconDetail, LabelModal, ModalAddButton, ModalBgCenter, TagsContainer, TextAreaModal } from './styled';
import { verifyCanEditProject } from '../../../../../utils/funcoes/validarPerfil';
import { useUser } from '../../../../../contexts/User';
import { useHistory, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { columnsManagerAtom } from '../../../../../atoms/Kanban/KanbanProject';
import { newItemsByColumn } from '../../../Tarefas/Kanban/functions';

export interface ProjetoFormProps {
	item_edit?: ProjectBasic;
	admins: number[];
	timeSelecionado: number[];
	saveAndContinue: boolean;
	perfilValido: boolean;
	handleAdicionar: (isSideOpen?: boolean) => void;
	selectedNewClient?: SelectOptions;
	editing: boolean;
	handlePatchProjeto?: (saveAndContinue: boolean) => void;

}

interface InitialValues {
	nome: string;
	cliente: string | number;
	notas: string;
	time: Responsible[],
	admins: Responsible[];
	tags: SelectOptions[];
}

export const ProjetoForm = ({
	item_edit,
	admins,
	editing,
	timeSelecionado,
	saveAndContinue,
	perfilValido,
	handleAdicionar,
	selectedNewClient,
	handlePatchProjeto
}: ProjetoFormProps) => {
	const location = useLocation();
	const [columnsManagerProject, setColumnsManagerProject] = useAtom(columnsManagerAtom);
	const { t } = useTranslation();
	const [optionTags, setOptionTags] = useState<SelectOptions[]>([]);
	const [tagSelectedValue, setTagSelectedValue] = useState<string[]>([]);
	const [tagSelected, setTagSelected] = useState<SelectOptions[]>();
	const [clienteValorSelecionado, setClienteValorSelecionado] = useState<SelectOptions>({ value: '', label: t('Selecione um cliente') });
	const { setTeam } = useKanban();
	const { userDetails } = useUser();
	const history = useHistory();
	const {
		setProjects,
		setLoadingPostPatch,
		setModalProjectVisible,
		handlePostPatchProjeto
	} = useProject();
	//services
	const clienteService = new ClienteService();

	useEffect(() => {
		if (selectedNewClient) {
			setClienteValorSelecionado(selectedNewClient);
		}
	}, [selectedNewClient]);

	useEffect(() => {
		if (typeof item_edit !== 'undefined') {
			console.log(item_edit)
			generateOptions(item_edit.tags ?? [], 'tags');
			setTagSelectedValue(item_edit.tags ?? []);

			setClienteValorSelecionado({
				value: item_edit.cliente.id,
				label: item_edit.cliente.nome,
			});
		}
	}, [item_edit]);

	let initialValues: InitialValues | null = null;
	if (typeof item_edit !== 'undefined') {
		initialValues = {
			nome: item_edit.nome,
			cliente: item_edit.cliente.id,
			notas: item_edit.notas,
			time: item_edit.time,
			tags: optionTags,
			admins: [],
		};
	} else {
		initialValues = {
			nome: '',
			cliente: selectedNewClient?.value ?? '',
			notas: '',
			time: [],
			tags: [],
			admins: [],
		};
	}

	const generateOptions = (objeto: string[], filtro: string) => {
		const options: SelectOptions[] = [];
		objeto.forEach(item => {
			options.push({ value: item, label: item });
		});

		if (filtro === 'tags') {
			setOptionTags(options);
			setTagSelected(options);
		}
	};

	const _postProjeto = (params: any) => {
		setLoadingPostPatch(true);
		const projetoService = new ProjetoService();
		projetoService.postProjeto(
			params,
			response => {
				if (response) {
					toast.success(t('Projeto salvo com sucesso!'));
					setModalProjectVisible(false);
					projetoService.getProjetos(response.data.id, result => {
						setProjects((prevData: any) => ({
							...prevData,
							[response.data.id]: result.data,
						}));
					}, (err) => console.error(err));
					handlePostPatchProjeto(saveAndContinue);


				}
				setLoadingPostPatch(false);
			},
			() => {
				toast.error(t('Erro ao tentar salvar projeto!'));
				setLoadingPostPatch(false);
			},
		);
	};

	const _patchProjeto = (params: any, id: number) => {
		setLoadingPostPatch(true);
		const projetoService = new ProjetoService();
		projetoService.patchProjeto(
			params,
			id,
			response => {
				if (response) {
					toast.success(t('Projeto atualizado com sucesso!'));
					setModalProjectVisible(false);
					setProjects((prevData: any) => {
						if(prevData[response.data.id]){
							return {
							...prevData,
							[response.data.id]: {
								...prevData[response.data.id],
								...response.data,
								cliente: {
									...prevData?.[response.data.id]?.cliente,
									...response.data.cliente,
								},
							}}	
						}					
					});
					
					const columnId = item_edit?.coluna_kanban ?? columnsManagerProject.columnsOrder[0];
					if(columnsManagerProject.columns?.[columnId]) {
						const tempItems = [...(columnsManagerProject.columns?.[columnId]?.items ?? [])];
						const currentProjectIndex = tempItems.findIndex(
							item => item.id === item_edit?.id
						)
						tempItems[currentProjectIndex] = {...tempItems[currentProjectIndex], ...response.data, cliente: {...response.data.cliente, logo_cliente: tempItems?.[currentProjectIndex].cliente.logo_cliente} }
						setColumnsManagerProject(prev => {
							return {...prev, columns: newItemsByColumn(columnId, {...prev.columns}, tempItems) }
						})
					}
					
					setTeam(response.data.time);
					
					if (handlePatchProjeto) {
						handlePatchProjeto(saveAndContinue)
					} else {
						handlePostPatchProjeto(saveAndContinue);
					}
				}

				setLoadingPostPatch(false);
			},
			error => {
				console.log(error)
				toast.error(error);
				setLoadingPostPatch(false);
			},
		);
	};

	const pegarValorDeTag = (tagSelectedValue: SelectOptions[] | null) => {
		if (tagSelectedValue !== null) {
			const tags = tagSelectedValue.map(item => item.value.toString());
			setTagSelectedValue(tags);
		} else {
			setTagSelectedValue([]);
		}
	};

	const handleGoProject = useCallback(() => {
		if (item_edit && verifyCanEditProject(userDetails, item_edit.is_member, item_edit.admins)) {
			setModalProjectVisible(false);
			history.push(`/registros/projetos/${item_edit.id}/parametros`);
		}
	}, [item_edit, history]);

	return (
			<Formik
				initialValues={initialValues}
				validationSchema={ProjetoSchema}
				enableReinitialize={true}
				onSubmit={values => {
					const form_data = new FormData();
					// if (editing) {
					// Usuário está editando.
					for (const key in values) {
						const typedKey = key as keyof InitialValues;
						if (typedKey === 'time') {
							form_data.append(key, timeSelecionado.toString());
						} else if (typedKey === 'tags') {
							form_data.append(key, tagSelectedValue.toString());
						} else if (typedKey === 'admins') {
							form_data.append(key, admins.toString());
						} else {
							form_data.append(key, values[typedKey].toString());
						}
					}

					if (editing && item_edit) {
						_patchProjeto(form_data, item_edit.id);
					} else {
						_postProjeto(form_data);
					}
				}}
			>
				{({ errors, touched, setFieldValue}) => {



					return (
						<FormikForm>
							<ModalBgCenter>
								<div className="container">
									<div className="row">
										<div className="col-12 col-md-6 col-lg-6">
											{!editing ? (
												<ModalAddButton
													onClick={() => handleAdicionar()}
												>
													<FontAwesomeIcon
														icon={faPlus}
														className="plus-modal-icon trash-modal-icon"
													/>
													{t("Adicionar Cliente")}
												</ModalAddButton>
											) : (
												!location.pathname.includes(`registros/projetos/${item_edit?.id}`) && 
													<ModalAddButton 
														onClick={handleGoProject}
													>
														{t("Detalhes do projeto")}
														<IconDetail
															icon={faAngleRight}
														/>
													</ModalAddButton>
												)
											}
											<LabelModal htmlFor="nome">
												{t('Nome do projeto')}*
											</LabelModal>

											<FieldModal
												errors={errors.nome}
												touched={touched.nome}
												validProfile={perfilValido}
												autoComplete={'off'}
												type="text"
												name="nome"
												id='nome'
											/>

											<ErrorMessage name="nome">
												{msg => (
													<div>
														<ErrorContainer role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</ErrorContainer>
													</div>
												)}
											</ErrorMessage>

											<div
												className={`
												${perfilValido === false && 'campo-desabilitado'}`}
											>
												<LabelModal htmlFor="cliente">
													{t('Selecione o cliente')}*
												</LabelModal>
												<AsyncSelector
													id={'cliente'}
													value={clienteValorSelecionado}
													onChange={e => {
														const selected = e as SelectOptions;
														setClienteValorSelecionado(selected);
														setFieldValue('cliente', selected !== null ? selected.value : '');
													}}
													FieldName={'cliente'}
													placeholder={t('Selecione um cliente')}
													// styles={customStyles}
													error={errors.cliente ? errors.cliente : ''}
													touched={touched.cliente ? touched.cliente : false}
													loadOptions={clienteService.loadClientesSelect as any}
													name="cliente"
													errorMessage={true}
												/>
											</div>
										</div>
										<div className="col-12 col-md-6 col-lg-6 ">
											<LabelModal
												htmlFor="notas"
											>
												{t('Notas')}
											</LabelModal>

											<TextAreaModal
												validProfile={perfilValido}
												errors={errors.notas}
												touched={touched.notas}
												disabled={!perfilValido}
												style={{
													background: '#fff',
												}}
												autoComplete={'off'}
												type="text"
												component="textarea"
												name="notas"
												id='notas'
											/>

											<ErrorMessage name="notas">
												{msg => (
													<div>
														<div className="input-error-style" role="alert">
															<FontAwesomeIcon
																icon={faExclamationTriangle}
																className="mr-1"
															/>
															{msg}
														</div>
													</div>
												)}
											</ErrorMessage>
										</div>
									</div>
									<br />
									<TagsContainer
										validProfile={perfilValido}
										className={`col-12`}
									>
										<LabelModal
											className="modal-label"
											style={{ margin: '0px' }}
											htmlFor="tags"
										>
											Tags
										</LabelModal>
										<CreatableSelect
											value={tagSelected}
											isMulti
											onChange={e => {
												const selected = e as SelectOptions[];
												pegarValorDeTag(selected);
												setTagSelected(selected);
											}}
											placeholder={t('Adicione tags')}
											options={optionTags}
											styles={customStyles}
										/>
									</TagsContainer>
									<input
										style={{ display: 'none' }}
										id="input-submit-projeto"
										type="submit"
									/>
								</div>
							</ModalBgCenter>
						</FormikForm>
					);
				}}
			</Formik>
	);
};
