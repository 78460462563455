import styled from "styled-components";

interface KanbanWrapperFormProps {
	loading: boolean;
}

export const KanbanWrapperForm = styled.div<KanbanWrapperFormProps>`
    font-family: 'Helvetica', sans-serif;
    font-size: 0.75rem;
	flex-grow: 1;
    position: relative;
	${({loading}) => loading && 'cursor: progress'}

`;

export const ColumnsContainer = styled.div`
	gap: 8px;
    overflow-x: auto;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    user-select: none;
    white-space: nowrap;
    inset: 0px;
`;

export const AddColumns = styled.div`
    display: flex;
    height: min-content;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

	.kanban-add-column-button {
		width: min-content;
		border: none;
		background-color: transparent;
		padding: 8px;
	}

	input:focus {
		border: 1px solid #496070;
		outline: none;
		border-radius: 4px;
		padding: 0 4px;
	}

	button {
		border-radius: 8px;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
	}      
`;
