import React, { useCallback, useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import logoPlanus from '../../../assets/images/logo-planus.png';
import AuthService from '../../../services/security/auth';
// import targetIcon from '../../../assets/svgs/target.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHome,
	faBars,
	faSignOutAlt,
	faTimes,
	faTasks,
	faClock,
} from '@fortawesome/free-solid-svg-icons';
import {
	faFolder,
	faFileAlt,
	faUser,
} from '@fortawesome/free-regular-svg-icons';

import { useKanban } from '../../../contexts/Kanban/Kanban';
import { 
	ControlMe, 
	HeaderSmall, 
	IconStyled, 
	InputCheck, 
	LogoutContainer, 
	MenuLabel, 
	ProfileContainer, 
	ProfileDefaultImage, 
	ProfileTextContainer, 
	TopBarContainer, 
	TopNavContainer 
} from './styled';

import { t } from 'i18next';
import '../../../i18n';
import { useUser } from '../../../contexts/User';


export default function HeaderContainer() {
	const service = new AuthService();
	const { selectedHeaderLink} = useKanban();
	const [checked, setChecked] = useState(false);
	const { userDetails } = useUser();
	useEffect(() => {
			window.addEventListener('resize', checkMenu);
	});

	const checkMenu = useCallback(() => {
		const elem = document.getElementById('check-small') as HTMLInputElement;
		elem.checked = false;
		setChecked(false);
	}, [setChecked])

	const handleToggleMenu = useCallback((e: React.ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		setChecked(target.checked);
	}, [setChecked])

	const handleShowInfoPessoa = useCallback(() => {
		document.getElementById('show-infoPessoa-modal')?.click();
	}, []);
	
	return (
		<>
			<HeaderSmall>
				<div>
					<img alt="" src={logoPlanus} className="logo-planus-small" />
				</div>
				<div />
				<div>
					<InputCheck type="checkbox" id="check-small" onChange={handleToggleMenu} />

					<MenuLabel htmlFor={'check-small'} isCheked={checked} title={t('Abrir/Fechar menu').toString()}>
						<div id="open-close-small">
							<FontAwesomeIcon icon={faBars} className="bars-icon" />
							<FontAwesomeIcon icon={faTimes} className="close-icon" />
						</div>
					</MenuLabel>
					<ControlMe isCheked={checked}/>
				</div>
			</HeaderSmall>
			<TopBarContainer className="top-bar-container" isCheked={checked} >
				<div className="top-bar" >
					<NavLink to="/" onClick={checkMenu}>
						<TopNavContainer >
							<div className="d-flex justify-content-between align-items-center">
								<div className="top-icons-container">
									<IconStyled icon={faHome} className="top-home-icon" />
								</div>
								<p className="top-sideBar-text">{t("Início")}</p>
							</div>
						</TopNavContainer>
					</NavLink>
					<NavLink to="/registros/projetos" onClick={checkMenu}>
						<TopNavContainer>
							<div className="d-flex justify-content-between align-items-center">
								<div className="top-icons-container">
									<IconStyled
										icon={faFolder}
										className="top-folder-icon"
									/>
								</div>
								<p className="top-sideBar-text">{t("Projetos")}</p>
							</div>
						</TopNavContainer>
					</NavLink>
					<NavLink to={{pathname: `/registros/${selectedHeaderLink}`, state: {fromHome: true}}} onClick={checkMenu}>
						<TopNavContainer>
							<div className="d-flex justify-content-between align-items-center">
								<div className="top-icons-container">
									<IconStyled
										icon={faTasks}
										className="top-folder-icon"
									/>
								</div>
								<p className="top-sideBar-text">{t("Tarefas")}</p>
							</div>
						</TopNavContainer>
					</NavLink>
					<NavLink to="/registros/horas" onClick={checkMenu}>
						<TopNavContainer>
							<div className="d-flex justify-content-between align-items-center">
								<div className="top-icons-container">
									<IconStyled
										icon={faClock}
										className="top-folder-icon"
									/>
								</div>
								<p className="top-sideBar-text">{t("Horas")}</p>
							</div>
						</TopNavContainer>
					</NavLink>
					
					<NavLink to="/okrs/times" onClick={checkMenu}>
						<TopNavContainer>
							<div className="d-flex justify-content-between align-items-center">
								<div className="top-icons-container">
									{/* <IconStyled icon={targetIcon} className="top-file-icon" /> */}
								</div>

								<p className="top-sideBar-text">{t('OKRs')}</p>
							</div>
						</TopNavContainer>
					</NavLink>

					<NavLink to="/relatorios" onClick={checkMenu}>
						<TopNavContainer>
							<div className="d-flex justify-content-between align-items-center">
								<div className="top-icons-container">
									<IconStyled icon={faFileAlt} className="top-file-icon" />
								</div>

								<p className="top-sideBar-text">{t("Relatórios")}</p>
							</div>
						</TopNavContainer>
					</NavLink>

					<TopNavContainer  onClick={service.logout}>
						<div className="d-flex justify-content-between align-items-center">
							<div className="top-icons-container">
								<IconStyled
									icon={faSignOutAlt}
									className="top-faSignOutAlt-icon"
								/>
							</div>

							<p className="top-sideBar-text">{t("Sair")}</p>
						</div>
					</TopNavContainer>

					<ProfileContainer>
						<div></div>
						<div
							className="top-profile-bottom"
							onClick={handleShowInfoPessoa}
						>
							<ProfileDefaultImage
								alt={userDetails?.username}
								src={userDetails?.foto_perfil}
							/>
							<ProfileTextContainer >
								<p className="top-profile-text">{userDetails?.username}</p>
								<LogoutContainer>
									<FontAwesomeIcon icon={faUser} className="top-faUser-icon" />
									<p className="top-profile-text-description">{t("Perfil")}</p>
								</LogoutContainer>
							</ProfileTextContainer>
						</div>
					</ProfileContainer>
				</div>
			</TopBarContainer>
		</>
	);
}
