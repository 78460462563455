import React, {  Dispatch, FocusEvent, KeyboardEvent, SetStateAction } from 'react';
import { AddBottom, ColumnContainer, ColumnContent, DeleteButton, DraggableWrapper, Icon, TitleContainer } from './styled';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {  ColumnManaged, ColumnsManager } from '../../../../atoms/Kanban/types';
import { useUser } from '../../../../contexts/User';
import { t } from 'i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LoaderPagination } from '../../../common/loaders/LoaderPagination';

export interface KanbanColumnProps {
    react_tour_id?: string;
    index: number;
    column: ColumnManaged<any>;
    handleDeleteColumn: (columnId: string) => void;
    children: React.ReactNode;
    isAdd: boolean;
    addObject?: AddObject;
    setAddObject?: Dispatch<SetStateAction<AddObject>>;
    handleAdd?: (columnId: string, name: string) => void;
    loading: boolean;
    style:React.CSSProperties;
    onScroll: React.UIEventHandler<HTMLDivElement>;
    columnsManager: ColumnsManager<any>;
    setColumnsManager: Dispatch<SetStateAction<ColumnsManager<any>>>
    onChangeColumnName: (columnId: string, title: string) => void;
    editingTitle: {
        isEditing: boolean,
        columnId: string,
    },
    setEditingTitle: Dispatch<SetStateAction<{
        isEditing: boolean,
        columnId: string,
    }>>;
    handlePagination: (page: number) => void;
}

export interface AddObject {
    isAdding: boolean;
    columnId: string;
}



export default function KanbanColumn({ loading, handlePagination, editingTitle, setEditingTitle, columnsManager, onChangeColumnName, onScroll, style, handleAdd, react_tour_id,addObject, setAddObject, isAdd, index, column, handleDeleteColumn, children }: Readonly<KanbanColumnProps>) {
    const showLoader = (column?.totalPages === column.page ||
    (!column.doneTotalPages ||
        column.doneTotalPages === column.page)) &&
    !loading

    const onBlurColumnName = (e: FocusEvent<HTMLInputElement, Element>) => {
        setEditingTitle({
            isEditing: false,
            columnId: '',
        });
        e.target.value = '';
    }

    const onKeyDownColumnName = (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (e.key === 'Enter') {
            onChangeColumnName(column.id, target.value);
            target.value = '';
        }
    }

    const onBlurAddTask = () => {
        if(setAddObject) {
            setAddObject({
                isAdding: false,
                columnId: '',
            })
        }
    }

    const onKeyDownAddTask = (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (e.key === 'Enter' && handleAdd) {
            handleAdd(column.id, target.value);
            target.value = '';
        }
    }

    const handleAddTask = () =>
    {
        if(setAddObject) {
            setAddObject({
                isAdding: true,
                columnId: column.id,
            })
        }
    }

    const {userDetails } = useUser();
    const canEdit = userDetails?.acesso_cod === 'a';
    return <DraggableWrapper
        className='kanban-content'
        id={react_tour_id}
        style={style}
        onScroll={onScroll}
    >
        <Draggable draggableId={column.id} index={index}>
            {provided => {
                return (
                    <ColumnContainer {...provided.draggableProps} ref={provided.innerRef}>
                        <TitleContainer {...provided.dragHandleProps} isEditingTitle={false}>
                            {editingTitle.columnId === column.id && editingTitle.isEditing ? (
                                <input
                                    defaultValue={column.nome}
                                    type="text"
                                    onBlur={onBlurColumnName}
                                    autoFocus
                                    onFocus={e => e.target.select()}
                                    onKeyDown={onKeyDownColumnName} />
                            ) :
                                <button style={{backgroundColor: "transparent", border: "none", padding: 0}} type='button' onClick={() => setEditingTitle({ columnId: column.id, isEditing: true })}>
                                    <h3
                                    >
                                        {column.nome}
                                    </h3>
                                </button>
                            }
                            {index > 0 && canEdit && (
                                <DeleteButton
                                    className="actions-menu-button"
                                    type="button"
                                    onClick={() => handleDeleteColumn(column.id)}
                                    title={t('Deletar').toString()}
                                    style={{
                                        cursor: columnsManager.columns[column.id].items.length > 0
                                            ? 'no-drop'
                                            : 'pointer',
                                    }}
                                >
                                    <Icon icon={faTrash} />
                                </DeleteButton>
                            )}
                        </TitleContainer>
                        <Droppable direction='vertical' droppableId={column.id}>
                            {(provided, snapshot) => {
                                return (
                                    <ColumnContent
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        isDragging={snapshot.isDraggingOver}
                                        >
                                            {children}
                                            {isAdd && 
                                                <AddBottom>
                                                    {addObject?.isAdding && addObject?.columnId === column.id && (
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                name="add-task"
                                                                title={t('Adicionar tarefa input').toString()}
                                                                onBlur={onBlurAddTask}
                                                                autoFocus
                                                                onKeyDown={onKeyDownAddTask}
                                                        />
                                                    )}
                                                    <button
                                                        type="button"
                                                        onClick={handleAddTask}
                                                    >
                                                        {t('Adicionar Tarefa')}
                                                    </button>
                                                </AddBottom>}
                                                    <LoaderPagination
                                                        onClick={() => {
                                                            handlePagination(column.page + 1)
                                                        }}
                                                        loading={loading}
                                                        showLoader={showLoader}
                                                        type='button'                                                    
                                                    />
                                                {provided.placeholder}
                                        </ColumnContent>
                                )
                            }}
                        </Droppable>
                    </ColumnContainer>
                );
            }}
        </Draggable>

    </DraggableWrapper>;
}
