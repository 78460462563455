import React, { useCallback, useEffect, useState } from 'react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';

import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import { KanbanSubtask } from '../KanbanSubtask';
import { KanbanAddSubtask } from '../KanbanAddSubtask';
import { SubtaskBody, SubtaskTop, Container, NewButton } from './styled';
import { KanbanChecklistProps } from './types';
import { CreateSubtaskDto } from '../../../../../models/Subtask';
import { SubtaskService } from '../../../../../services/providers/subtask';
import { toast } from 'react-toastify';
import DinamicPagination from '../../../../../components/common/DinamicPagination';
import { useKanban } from '../../../../../contexts/Kanban/Kanban';

export function KanbanChecklist({
	columnId,
	task,
	openConfirmSubtarefaModal,
}: KanbanChecklistProps) {
	const { t } = useTranslation();
	const [isChecklistOpen, setIsChecklistOpen] = useState(false);
	const [editSubtaskId, setEditSubtaskId] = useState<number | null>(null);
	const [isAdding, setIsAdding] = useState(false);
	const {
		paginationInfoByTask,
		setPaginantionInfo,
		setLoadingPagination,
		handleSetSubtasks,
	} = useKanban();

	const { loading, currentPage, totalPages } = paginationInfoByTask(
		task.id,
		columnId,
	);

	const subtaskService = new SubtaskService();

	const handleGetSubtasks = useCallback(
		(page: number) => {
			setLoadingPagination({
				value: true,
				columnId: columnId,
				taskId: task.id,
			});

			subtaskService
				.getSubtasksByTask(task.id, page)
				.then(result => {
					handleSetSubtasks(
						[...task.sub_tarefas, ...result.results],
						task.id,
						columnId,
					);
					setPaginantionInfo({
						value: {
							totalPages: result.total_pages,
							currentPage: page,
							loading: false,
						},
						columnId: columnId,
						taskId: task.id,
					});
				})
				.catch(err => {
					console.log(err);
					toast.error(t('Erro ao carregar subtarefas!'));
					setLoadingPagination({
						value: false,
						columnId: columnId,
						taskId: task.id,
					});
				});
		},
		[
			handleSetSubtasks,
			setPaginantionInfo,
			setLoadingPagination,
			columnId,
			task,
		],
	);

	const hanldeSetAddingSubtask = useCallback(() => {
		setIsAdding(true);
	}, [setIsAdding]);

	const addSubtask = useCallback(
		(title: string) => {
			setLoadingPagination({
				value: true,
				columnId: columnId,
				taskId: task.id,
			});

			const subtask: CreateSubtaskDto = {
				nome: title,
				tarefa: task.id,
			};

			subtaskService
				.postSubtask(subtask)
				.then(result => {
					handleSetSubtasks(
						[...task.sub_tarefas, result],
						task.id,
						columnId,
						1,
						1
					);
					setIsAdding(true);
					setIsChecklistOpen(true);
				})
				.catch(err => {
					console.log(err);
					toast.error(t('Erro ao tentar criar subtarefa!'));
				})
				.finally(() =>
					setLoadingPagination({
						value: false,
						columnId: columnId,
						taskId: task.id,
					}),
				);
		},
		[setLoadingPagination, setIsAdding, setIsChecklistOpen, handleSetSubtasks],
	);

	useEffect(() => {
		if (
			task.sub_tarefas.length === 0 &&
			task.total_subtarefas > 0 &&
			currentPage === 1
		) {
			handleGetSubtasks(1);
		}
	}, [currentPage]);


	return (
		<Container isAssigning={editSubtaskId !== null}>
			<div>
			{task.total_subtarefas > 0 && (
				<div>
					<SubtaskTop>
						<button
							type="button"
							className="subtask-expand"
							onClick={() => setIsChecklistOpen(!isChecklistOpen)}
						>
							<span className="subtask-title">
								{task.total_subtarefas-task.total_subtarefas_incompletas}/{task.total_subtarefas} {t('Subtarefas')}
							</span>

							{isChecklistOpen ? (
								<BsChevronDown size={22} />
							) : (
								<BsChevronUp size={22} />
							)}
						</button>
					</SubtaskTop>
					{isChecklistOpen && (
						<DinamicPagination
							loading={loading}
							totalPages={totalPages}
							currentPage={currentPage}
							lengthData={task.sub_tarefas.length}
							returnPage={handleGetSubtasks}
							msgToEmptyData={t('Nenhuma subtarefa encontrada.')}
						>
							<SubtaskBody>
								{task.sub_tarefas.map((sub, index) => {
									return (
										<KanbanSubtask
											key={`sub-${sub.id}`}
											subtask={sub}
											editSubtaskId={editSubtaskId}
											setEditSubtaskId={setEditSubtaskId}
											openConfirmSubtarefaModal={openConfirmSubtarefaModal}
											columnId={columnId}
											taskId={task.id}
											subtasks={task.sub_tarefas}
											index={index}
										/>
									);
								})}
							</SubtaskBody>
						</DinamicPagination>
					)}
				</div>
			)}

			{(isChecklistOpen || task.total_subtarefas === 0) && (
				<NewButton onClick={hanldeSetAddingSubtask}>
					{t('Nova Subtarefa')}
				</NewButton>
			)}

			{isAdding && (task.sub_tarefas.length === 0 || isChecklistOpen) && (
				<KanbanAddSubtask
					handleAddSubtask={addSubtask}
					setIsAdding={setIsAdding}
				/>
			)}
			</div>
		</Container>
	);
}
