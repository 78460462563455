import React, { useCallback, useState } from 'react';
import KanbanColumn from '../../../../../components/containers/Kanban/KanbanColumn';
import { useKanban } from '../../../../../contexts/Kanban/Kanban';
import { Task } from '../../../../../models/Task';
import TaskService from '../../../../../services/providers/task';
import moment from 'moment';
import { KanbanService } from '../../../../../services/providers/kanban';
import { PostPatchResponse } from '../../../../../services/providers/task/types';
import { setAddedItems, updateColumnsValues } from '../functions';
import { KanbanTask } from '../KanbanTask';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { dinamicPagination } from '../../../../../utils/funcoes/dinamicPagination';
import {  ColumnManaged } from '../../../../../atoms/Kanban/types';
import { TaskManaged } from '../../../../../contexts/Kanban/types';
import { useLocation } from 'react-router-dom';
import { useFilters } from '../../../../../contexts/Filter/Task/Filter';
import ProjetoService from '../../../../../services/providers/project';

interface KanbanBodyProps {
	readonly id: string;
	readonly index: number;
}

export default function KanbanBody({ id, index }: KanbanBodyProps) {
	const {pathname} = useLocation();
	const {
		columnsManager,
		setColumnsManager,
		requestingAddColumn,
		handleGetColumnsPaginate,
		handleGetFirstColumnPaginate,
		handleGetLastColumnPaginate,
	} = useKanban();
	const {
		selectedKeyResult
	} = useFilters();
	const [editingTitle, setEditingTitle] = useState({
        isEditing: false,
        columnId: '',
    });
	const [requestingAddTask, setRequestingAddTask] = useState<any>({
		componentId: null,
	});
	const [loading, setLoading] = useState(false);

	const [addTask, setAddTask] = useState({
		isAdding: false,
		columnId: '',
	});
	const kanbanService = new KanbanService();
	const taskService = new TaskService();
	const projectService = new ProjetoService();
	const column = columnsManager.columns[id];
	
	const isOkr = pathname === '/okrs/kanban'

	const keyresultId = Array.isArray(selectedKeyResult)
		? selectedKeyResult[0].value
		: selectedKeyResult.value;

	const handleAddTask = (columnId: string, title: string) => {
		if (title.trim() !== '' && columnsManager.project) {
			const destColumn = columnsManager.columns[columnId];
			const destItems = [...destColumn.items];
			const task: Task = {
				id: uuid(),
				nome: title,
				status: destColumn.status.title,
				ordenacao: destItems.length,
				pessoas_responsaveis: [],
				data_conclusao: moment()
					.add(1, 'days')
					.format('YYYY-MM-DDThh:mm:ss'),
				coluna_kanban: index === 0 ? null : columnId,
				projeto: columnsManager.project.id,
				sub_tarefas: [],
				total_subtarefas: 0,
				total_subtarefas_incompletas: 0,
				projeto_nome: columnsManager.project.nome,
				cliente: columnsManager.project.cliente.id,
				cliente_nome: columnsManager.project.cliente.nome,
			};
			const params = {
				nome: task.nome,
				projeto: columnsManager.project.id,
				status: task.status,
				data_conclusao: task.data_conclusao,
				ordernacao: task.ordenacao,
				coluna_kanban: index === 0 ? null : columnId,
				kanban: true,
				keyresult: isOkr ? keyresultId.toString() : undefined
			};

			setRequestingAddTask({
				componentId: task.id,
			});
			setColumnsManager(prev => {
				const tempDestColumn = prev.columns[columnId];
				const tempDestItems = [...tempDestColumn.items];

				return ({
					...prev,
					columns: {
						...prev.columns,
						[columnId]: {
							...tempDestColumn,
							items: [...tempDestItems, task],
						},
					},
					columnsOrder: prev.columnsOrder,
			})});

			taskService.postTarefa(
				params,
				(response: PostPatchResponse) => {
					setColumnsManager(prev => {
						const tempDestColumn = prev.columns[columnId];
						const tempDestItems = [...tempDestColumn.items];
						const tempItems = [
							...tempDestItems.filter(item => item.id !== task.id),
							{ ...task, ...response, id: response.id },
						];
						const newColunm: ColumnManaged<TaskManaged> = {
							...tempDestColumn,
							addedItemsIds: setAddedItems(prev.columns[id], tempItems),
							items: tempItems,
						};

						return ({
							...prev,
							columns: {
								...prev.columns,
								[columnId]: newColunm,
							},
							columnsOrder: prev.columnsOrder,
					})});
					setRequestingAddTask({ componentId: null });
				},
				(error: any) => {
					setColumnsManager({ ...columnsManager });
					setRequestingAddTask({ componentId: null });
					console.log(error.toString());
					toast.error(t('Erro ao adicionar tarefa!'));
				},
			);
		}
	};

	const handleDeleteColumn = (columnId: string) => {
		if (columnsManager.columns[columnId].items.length === 0 && columnsManager.project) {
			kanbanService
				.deleteColumn({
					columnId: columnId,
					projectId: columnsManager.project.id,
				})
				.catch(err => {
					toast.error(err);
				});

			setColumnsManager(prev => {
				const newColumnsOrder = [...prev.columnsOrder].filter(
					id => id !== columnId,
				);

				return {
					...prev,
					columns: updateColumnsValues(prev.columns, newColumnsOrder),
					columnsOrder: newColumnsOrder,
			}});
		}
	};

	const handlePaginateInsideColumn = async (page: number) => {
		setLoading(true);
		const {
			paginateItems,
			totalPages,
			doneTotalPages,
		} = await getPaginateTasks(page, column, index);
		const previousIds = column.items.map(item => item.id)
		const tempPaginateItems = paginateItems.filter((item: TaskManaged) => !previousIds.includes(item.id))
		const newItems = [...column.items, ...tempPaginateItems];
		handleUpdateStateItems(newItems, page, totalPages, doneTotalPages);
	};

	const getPaginateTasks = async (
		page: number,
		column: ColumnManaged<TaskManaged>,
		index: number,
	) => {
		if (index === 0) {
			return await handleGetFirstColumnPaginate(page);
		} else if (index === columnsManager.columnsOrder.length - 1) {
			return await handleGetLastColumnPaginate(page, column);
		} else {
			return await handleGetColumnsPaginate(page, column);
		}
	};

	const handleUpdateStateItems = useCallback((
		newItems: Task[],
		page: number,
		totalPages: number,
		doneTotalPages: number,
	) => {
		setColumnsManager(prev => ({
			...prev,
			columns: {
				...prev.columns,
				[id]: {
					...prev.columns[id],
					page: page,
					totalPages: totalPages ?? prev.columns[id].totalPages,
					doneTotalPages: doneTotalPages,
					removedQnt: 0,
					items: newItems,
				},
			},
		}));
		setLoading(false);
	}, [setColumnsManager, id]);

  const handlePatchFirstColumnName = (columnData: {
    id: string;
    projeto: number;
    nome: string;
  }) => {
		const form_data = new FormData();
		form_data.append('primeira_coluna', columnData.nome);

		projectService.patchProjeto(
			form_data,
			columnData.projeto,
			() => {},
			() => {
				setColumnsManager({ ...columnsManager });

				toast.error(t('Erro ao atualizar nome da coluna!'));
			},
		);
	};

	const handleChangeColumnName = (columnId: string, title: string) => {    
    if (!columnsManager.project?.id) return;
    
		const columnData = {
			nome: title,
      id: columnId,
      projeto: columnsManager.project?.id,
    };

    if (index > 0) {
      kanbanService.patchColumn(columnData).catch(() => {
        setColumnsManager({ ...columnsManager });

        toast.error(t('Erro ao atualizar nome da coluna!'));
      });
    } else {
      handlePatchFirstColumnName(columnData);
    }

    setColumnsManager(prev => {
			const tempColumns = { ...prev.columns };

			tempColumns[columnId] = {
				...tempColumns[columnId],
				nome: title,
			};

			return({
				...prev,
				columns: tempColumns,
        })
      });

      setEditingTitle({
          columnId: '',
          isEditing: false,
      });
    };
	
	return (
		<KanbanColumn
			handlePagination={handlePaginateInsideColumn}
			setEditingTitle={setEditingTitle}
			editingTitle={editingTitle}
			onChangeColumnName={handleChangeColumnName}
			setColumnsManager={setColumnsManager}
			columnsManager={columnsManager}
			handleAdd={handleAddTask}
			addObject={addTask}
			isAdd={true}
			setAddObject={setAddTask}
			column={columnsManager.columns[id]}
			handleDeleteColumn={handleDeleteColumn}
			index={index}
			react_tour_id="react_tour-kaban-column"
			loading={loading}
			style={
				requestingAddColumn.componentId === id
					? { pointerEvents: 'none', cursor: 'progress', position: 'relative' }
					: { position: 'relative' }
			}
			onScroll={e =>
				dinamicPagination({
					element: e.target,
					callBack: handlePaginateInsideColumn,
					loading: loading,
					page: column.page,
					totalPages:
						column.doneTotalPages && column.doneTotalPages > column.totalPages
							? column.doneTotalPages
							: column.totalPages,
				})
			}
		>
			{columnsManager.project && columnsManager.columns[id].items.map((item, index) => {
				return (
					<KanbanTask
						client={columnsManager.project?.cliente.nome ?? ''}
						task={item}
						index={index}
						key={item.id}
						project={columnsManager.project}
						columnId={id}
						requestingAddTask={requestingAddTask}
					/>
				);
			})}
		</KanbanColumn>
	);
}
