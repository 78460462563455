import React, { Dispatch, useCallback, useEffect, useState } from 'react';

import TarefaService from '../../../../services/providers/task';

import formatArrayToFilter from '../../../../utils/funcoes/formatArrayToFilter';

import { toast } from 'react-toastify';

import { PageContainerFilter, RegistrosContainerBody } from './styled';
import List from '../Components/List';
import { useLocation } from 'react-router-dom';

import { useFilters  } from '../../../../contexts/Filter/Task/Filter';
import { useDataTask } from '../../../../contexts/InfoTarefa/DataTask';
import { useKanban } from '../../../../contexts/Kanban/Kanban';
import { Task } from '../../../../models/Task';
import { SelectOptions } from '../../../../models/Select';

import '../../RegistroNavBar/style.css';
import './style.css';
import { useUser } from '../../../../contexts/User';
import { useStopwatch } from '../../../../contexts/Stopwatch';
import { HoursRecordSimpleSelected } from '../../../../models/HoursRecord';
interface TarefasProps {
	handleDeletar: (id:  number) => void;
	handleEncerrar: (id: string | number) => void;
	setEditingTarefa: Dispatch<React.SetStateAction<boolean>>;
	setTarefaEditada: React.Dispatch<React.SetStateAction<Task | undefined>>;
	setModalTarefaShow: Dispatch<React.SetStateAction<boolean>>;
	loadingEncerrarTarefa: boolean;
	setLoadingEncerrarTarefa: Dispatch<React.SetStateAction<boolean>>;
}

interface LocationData {
	task?: Task;
	fromHome?: boolean;
	projeto?: SelectOptions;
}

export function Tarefas({
	handleDeletar,
	handleEncerrar,
	setEditingTarefa,
	setTarefaEditada,
	setModalTarefaShow,
	loadingEncerrarTarefa,
	setLoadingEncerrarTarefa
}: TarefasProps) {
	// Data
	const location = useLocation<LocationData>();

    const { setPreSelectedRecord, handleToggleModal  } = useStopwatch();

	const {getTasksPagination, loadingTasks, totalPages, pages, deletedId, setDeletedId, closedId, setClosedId} = useDataTask();
	const {selectedProject, checkMyTasks, checkCreatedByMe, setSelectedProject, selectedClient, checkClosedTask, selectedTag, selectedStatus, startDate, endDate} = useFilters();

	useEffect(() => {
		getTasksPagination();
	}, [
		selectedClient,
		selectedProject,
		checkClosedTask,
		selectedTag,
		selectedStatus,
		startDate,
		endDate,
		checkMyTasks,
		checkCreatedByMe
	]);
	// Horas
	const [horaEditada, setHoraEditada] = useState<Task>();

	const [acessCode, setAcessCode] = useState('m');

	const getDataAtual = () => {
		const date = new Date();
		date.setHours(0, 0, 0, 0);
		const data = date.getTime();
		return data;
	};

	const dataAtual = getDataAtual();


	const { selectTask } = useKanban();
	const { userDetails } = useUser();

	useEffect(() => {
		if(horaEditada){
			const tempHourRecord: HoursRecordSimpleSelected = {
				projeto: horaEditada.projeto,
				projeto_nome: horaEditada.projeto_nome || "",
				tarefa: horaEditada.id as number,
				tarefa_nome: horaEditada.nome
			};
			setPreSelectedRecord(tempHourRecord)
		}
	}, [horaEditada]);

	useEffect(() => {
		if (!location?.state?.task || location?.state?.fromHome) {
			if (location?.state?.projeto) {
				const projeto = location.state?.projeto;
				setSelectedProject([projeto]);
				getUserdetails(projeto?.value);
			} else {
				getUserdetails(formatArrayToFilter(selectedProject as SelectOptions[]) || '');
			}
		}
		getDataAtual();
	}, []);

	useEffect(() => {
		if (deletedId) {
			handleDeletar(deletedId);
			setDeletedId(null);
		}
		if (closedId) {
			handleEncerrar(closedId);
			setClosedId(null);
		}
	}, []);

	const getUserdetails = (projetoID: string | number = '') => {
		getTasksPagination(projetoID);
		if (userDetails !== null) {
			setAcessCode(userDetails.acesso_cod);
		}
	};

	const reabrirTarefa = useCallback((id: string | number) => {
		const tarefaService = new TarefaService();
		tarefaService.reabrirTarefa(
			{
				id: id,
			success: (response: any) => {
				if (response) {
					toast.success('Tarefa reiniciada com sucesso!');
					getTasksPagination();
					encerrarTarefaAnimacao(id);
				}
			},
			errorCallback: (error: any) => {
				toast.error(error);
			},
			}
		);
	}, [getTasksPagination]);

	const encerrarTarefaAnimacao = (id: string | number) => {
		const rowEncerrada = document.getElementById(`tarefa-row${id}`);
		if(rowEncerrada) {
			rowEncerrada.style.transition = 'all 0.5s';
			rowEncerrada.style.opacity = '0%';
			setTimeout(() => {
				rowEncerrada.style.display = 'none';
				setLoadingEncerrarTarefa(false);
			}, 600);
		}
		
	};

	const converterDuracaoTotal = useCallback((horaDuracao: string) => {
		const horasSlice = horaDuracao.split(':');
		const horas = horasSlice[0];
		const minutos = horasSlice[1];
		return `${horas}h ${minutos}m`;
	}, []);

	const paginacaoDinamica = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const element = e.target as HTMLElement;
		if (totalPages === pages) {
			return;
		} else {
			if (!loadingTasks) {
				if (element.scrollHeight - element.scrollTop === element.clientHeight) {
					const paginaAtual = pages + 1;
					getTasksPagination(formatArrayToFilter(selectedProject as SelectOptions[]) || '', paginaAtual);
				}
			}
		}
	};

	useEffect(() => {
		selectTask();
	}, []);

	const handleDinamicPagination = useCallback((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		paginacaoDinamica(e);
	}, [paginacaoDinamica])

	return (
		<>
			<PageContainerFilter>
				<RegistrosContainerBody
					onScroll={handleDinamicPagination}
				>
					<List
						// hooks
						accessCode={acessCode}
						dataAtual={dataAtual}
						loadingEncerrarTarefa={loadingEncerrarTarefa}
						// setHooks
						setEditingHoras={() => {}}
						setHoraEditada={setHoraEditada}
						setModalHorasShow={handleToggleModal}
						setEditingTarefa={setEditingTarefa}
						setTarefaEditada={setTarefaEditada}
						setModalTarefaShow={setModalTarefaShow}
						// functions
						handleEncerrar={handleEncerrar}
						reabrirTarefa={reabrirTarefa}
						converterDuracaoTotal={converterDuracaoTotal}
					/>
				</RegistrosContainerBody>
			</PageContainerFilter>
		</>
	);
}

export default Tarefas;
