import React from 'react';

import { Skeleton } from '@mui/material';

import { ColumnsContainer, TasksContainer } from "./styled";
import { random } from 'lodash';

function KanbanTaskSkeleton() {
	return (
		<div
			style={{
				backgroundColor: 'grey',
				width: '234px',
				height: '72px',
				borderRadius: '4px',
				padding: 8,
				opacity: 0.1,
			}}
			className="kanban-task-content"
		>
			<Skeleton
				className="kanban-task-title"
				width={'218px'}
				sx={{ bgcolor: 'transparent' }}
			/>
			<div className="kanban-task-down-content">
				<div className="kanban-task-date">
					<Skeleton width="50px" sx={{ bgcolor: 'white' }} />
				</div>
				<div className="kanban-task-profiles">
					<Skeleton
						sx={{
							bgcolor: 'white',
							borderColor: 'black',
							borderWidth: '1px',
						}}
						variant="circular"
						width="24px"
						height="24px"
					/>
					<Skeleton
						sx={{
							bgcolor: 'white',
							borderColor: 'black',
							borderWidth: '1px',
						}}
						variant="circular"
						width="24px"
						height="24px"
					/>
				</div>
			</div>
		</div>
	);
}

interface KanbanColumnSkeletonProps {
	columnArray: number[]
}

function KanbanColumnSkeleton({ columnArray }:KanbanColumnSkeletonProps) {
	return (
		<TasksContainer>
			<Skeleton
				variant="rectangular"
				height="30px"
				width="250px"
				sx={{ bgcolor: '#CED8DF' }}
				style={{ minHeight: '30px' }}
			/>
			{columnArray.map((item, index) => {
				return <KanbanTaskSkeleton key={`${index}-${item}`} />;
			})}
			<div
				style={{
					borderRadius: '4px',
					width: '234px',
					height: '32px',
				}}
			>
				<Skeleton
					sx={{ bgcolor: '#CED8DF' }}
					variant="circular"
					height={'32px'}
					width="234px"
					style={{ borderRadius: '4px', opacity: '0.1' }}
				/>
			</div>
		</TasksContainer>
	);
}

export default function KanbanSkeleton() {
	const columnOne = [1, 2, 3, 4, 5, 6, 7, 8];
	const columnTwo = [1, 2, 3, 4, 5];
	const columnThree = [1, 2, 3, 4, 5, 6, 7];
	const columnFour = [1, 2, 3];
	const columnCount = [columnOne, columnTwo, columnThree, columnFour];

	return (
		<ColumnsContainer id='react-tour_kanban'>
			{columnCount.map((columns) => (
				<KanbanColumnSkeleton columnArray={columns} key={random(0, 100)} />
			))}
		</ColumnsContainer>
	);
}
