import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const TheadToolsContainer = styled.div`
	height: 30px;
	width: 7%;
	text-align: center;
	color: #c0cfd8;
	margin-top: -4px;
`;

export const IconContainer = styled.span`
	position: absolute;
	margin: 2px 8px;
	font-size: 12px;
	cursor: none;
`;

export const CheckContainer = styled.span`
	position: absolute;
	padding: 5px 0px 0px 25px;
	font-size: 12px;
`;

interface CheckContentProps {
	isSelected: boolean;
}

export const CheckContent = styled.div<CheckContentProps>`
	display: flex;
	justify-content: center;
	width: 70%;
	pointer-events: ${({isSelected}) => isSelected ? "initial" : "none"};
`;
export const CheckIcon = styled(FontAwesomeIcon)`
	font-size: 15px;
	color: rgba(66, 97, 114, 0.5019607843137255);
	cursor: pointer;
	margin-right: 10px;
`;
