import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export interface TitleContainerProps {
	isEditingTitle: boolean;
}

export interface ColumnContentProps {
	isDragging: boolean;
}

export const DraggableWrapper = styled.div`
	box-sizing: border-box;
    display: inline-block;
    height: 100%;
    scroll-margin: 8px;
    vertical-align: top;
    white-space: nowrap;
	position: relative;

`

export const ColumnContainer = styled.div`
	max-width: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
    white-space: normal;
    width: 100%!important;
	height: 100%;
`;

export const TitleContainer = styled.div<TitleContainerProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	background-color: #ced8df;
	border-radius: 8px 8px 0 0;

	h3 {
		font-size: 0.75rem;
		font-weight: 700;
		color: #496070;
		margin: 0;
		cursor: text;
		overflow-wrap: break-word;
		padding-right: 2px;
		max-width: 200px;
		display: ${({ isEditingTitle }) => (isEditingTitle ? 'none' : 'initial')};
	}

	button {
		font-size: 10px;
		cursor: pointer;
	}

	input:focus {
		border: 1px solid #496070;
		outline: none;
		border-radius: 4px;
		padding: 0 4px;
	}
`;

export const DeleteButton = styled.button`
	width: min-content;
	border: none;
	background-color: transparent;
	padding: 0;
`;

export const Icon = styled(FontAwesomeIcon)`
	color: #406173;
	font-size: 13px;
	transition: all 0.2s;
`;

export const ColumnContent = styled.div<ColumnContentProps>`
	padding: 8px;
	width: 250px;
	border-radius: 0 0 8px 8px;
	/* height: calc(100vh - 320px); */
	background-color: ${({ isDragging }) =>
		isDragging ? 'lightblue' : '#E6EBEF'};
	display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 7px;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const AddBottom = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
width: 100%;
color: #496070;
font-size: 0.875rem;

input:focus {
	border: 1px solid #496070;
	outline: none;
	border-radius: 4px;
	padding: 0 4px;
}

button {
	width: 100%;
	padding: 0 16px;
	height: 32px;
	background-color: #ced8df;
	border-radius: 4px;
	border: none;
	cursor: pointer;
	color: #496070;
	text-align: left;
	font-size: 0.875rem;
}
`;
