import React, { Dispatch, SetStateAction } from 'react';
import { ProjectBasic, ProjectKanban } from '../../../../../models/Project';
import {
    ItemBar,
	ItemLine,
	MenuButton,
	OverProfile,
	PhotoContainer,
	ProjectClient,
	ProjectInfo,
	ProjectInfoHeader,
	ProjectItem,
	ProjectName,
	ProjectTop,
	TeamContainer,
	TeamImg,
	TeamPictures,
	TeamPlus,
} from './styled';
import { BsPersonPlus } from 'react-icons/bs';
import { t } from 'i18next';
import { useProject } from '../../../../../contexts/Project';
import KanbanItem from '../../../../../components/containers/Kanban/KanbanItem';

import profileImage from '../../../../../assets/images/profile.png'
import clientImage from '../../../../../assets/images/company.png'

interface ProjectKanbanItemProps {
	readonly project: ProjectKanban;
	readonly index: number;
	readonly setEditingProject: Dispatch<SetStateAction<boolean>>;
	readonly setSelectedProject: Dispatch<SetStateAction<ProjectBasic | undefined>>

}

export default function ProjectKanbanItem({ project, index, setEditingProject, setSelectedProject }: Readonly<ProjectKanbanItemProps>) {
	const {setModalProjectVisible} = useProject();

	const handleEditProject = () => {
		if(project.time) {
			setSelectedProject(project);
			setEditingProject(true);
			setModalProjectVisible(true);
		}
	}
	return (
		<KanbanItem id={project.id?.toString()} requestingAdd={{componentId: null}} index={index}>
			<ProjectItem>
            <ItemBar />
			<ProjectTop>
				<PhotoContainer src={project.cliente?.logo_cliente ?? clientImage}></PhotoContainer>
				<ProjectInfo>
					<ProjectInfoHeader>
						<ProjectName isLoading={!!project.time} onClick={handleEditProject}>{project.nome}</ProjectName>
						<ProjectClient>{project.cliente?.nome}</ProjectClient>
					</ProjectInfoHeader>
					{/* <ProjectDates>
						{project.inicio_previsto &&
							project.termino_previsto &&
							moment(project.inicio_previsto).format('DD/MM/YYYY') +
								' à ' +
								moment(project.inicio_previsto).format('DD/MM/YYYY')}
					</ProjectDates> */}
				</ProjectInfo>
			</ProjectTop>
			<ItemLine />
			<TeamContainer>
				{project.time && <TeamPictures>
					{project.time.slice(0, 4).map(teamPerson => {
						return <TeamImg title={teamPerson.nome} key={teamPerson.id} src={teamPerson.foto_perfil ?? profileImage} />
					})}
					{project.time.length > 4 && <TeamPlus  onClick={handleEditProject} title={project.time.slice(3, project.time.length - 1).map(item => item.nome).join(', ')}> +{project.time.length - 4}</TeamPlus>}
				</TeamPictures> }
				<OverProfile>
					<MenuButton
						isLoading={!project.time}
						type="button"
						title={t('Atribuir').toString()}
						onClick={handleEditProject}
					>
						<BsPersonPlus  size={14} />
					</MenuButton>
				</OverProfile>
			</TeamContainer>
		</ProjectItem>
		</KanbanItem>
	);
}
