
import React, { HtmlHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface PageInsideContainerProps extends HtmlHTMLAttributes<HTMLDivElement>{}

export function PageInsideContainer({className,...props}: PageInsideContainerProps) {
    const classes = twMerge(
        "m-8 overflow-y-auto h-full flex flex-col",
        "h-[calc(100vh-220px)] sm:h-[calc(100vh-200px)] 2xl:h-[calc(100vh-(7.8vw+80px))]",
        className
    )

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}