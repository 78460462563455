import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import ModalHoras from '../../../pages/Registros/Horas/ModalHoras';
import RelatoriosService from '../../../services/providers/report';

import moment from 'moment';

import { HoursRecord } from '../../../models/HoursRecord';
import {
	MainContent,
	CounterContent,
	TodayContainer,
	HorasText,
	MainContainerWrapper,
	WeekContent,
	TodayContent,
	IconContainer,
	Separator,
} from './styled';

import { t } from 'i18next';
import '../../../i18n';
import { useUser } from '../../../contexts/User';
import { StopwatchTitle } from '../../common/StopwatchTitle';
import StopwatchHeader from '../../common/StopwatchHeader';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useStopwatch } from '../../../contexts/Stopwatch';
import { GenericConfirmModal } from '../Modais/GenericConfirmModal';

export interface MainContainerProps {
	autoRefresh: boolean;
	children: ReactNode;
}

export default function MainContainer({
	children,
	autoRefresh = true,
}: MainContainerProps) {
	const [totalHoje, setTotalHoje] = useState('0:00');
	const [totalSemana, setTotalSemana] = useState('0:00');
	const [totalMensal, setTotalMensal] = useState('0:00');
	const [modalHorasShow, setModalHorasShow] = useState(false);
	const [loadingHome, setLoadingHome] = useState(true);
	const [usuarioAccess, setUsuarioAccess] = useState('');
	const { userDetails} = useUser();
	
	const { confirmModal, getStopwatch } = useStopwatch();

	useEffect(() => {
		getUserdetails();
	}, [userDetails]);

	useEffect(() => {
		getStopwatch();
	}, []);

	const getUserdetails = () => {
		if (userDetails !== null) {
			defineDataInicialFinal(userDetails.id_pessoa);
			setUsuarioAccess(userDetails.acesso_cod);
		} 
	};

	const atualizaHorasNoBtnAtalho = useCallback(() => {
		getUserdetails();
	}, [getUserdetails]);

	useEffect(() => {
		getUserdetails();
	}, [])

	const defineDataInicialFinal = (idUsuario: number) => {
		const startOfMonth = moment()
			.startOf('month')
			.subtract(1, 'week')
			.format('YYYY-MM-DD');
		const endOfMonth = moment()
			.endOf('month')
			.format('YYYY-MM-DD');

		_getHoras(idUsuario, startOfMonth, endOfMonth);
	};



	const _getHoras = (
		idPessoa: number,
		dataInicial: string,
		dataFinal: string,
	) => {
		const relatorioService = new RelatoriosService();
		relatorioService.getHoras(
			{
				cliente: '',
				projetos: '',
				tarefas: '',
				pessoas: idPessoa.toString(),
				dataInicial,
				dataFinal,
			},
			(response: any) => {
				if (response.data) {
					calculaHorasDiaSemana(response.data);
				}
			},
			() => {},
		);
	};

	const calculaHorasDiaSemana = (registros: HoursRecord[]) => {
		let totalHorasDia = 0;
		let totalMinutosDia = 0;
		let totalHorasSemana = 0;
		let totalMinutosSemana = 0;
		let totalHorasMes = 0;
		let totalMinutosMes = 0;
		let dataInicio = moment().format('YYYY-MM-DD');
		let dataFim = moment().toString();
		let diaAlvo = moment().format('ddd');
		const startOfMonth = moment()
			.startOf('month')
			.format('YYYY-MM-DD');
		const endOfMonth = moment()
			.endOf('month')
			.format('YYYY-MM-DD');

		while (diaAlvo !== 'dom') {
			diaAlvo = moment(dataInicio)
				.subtract(1, 'days')
				.format('ddd');
			dataInicio = moment(dataInicio)
				.subtract(1, 'days')
				.format('YYYY-MM-DD');
		}

		dataFim = moment(dataInicio)
			.add(6, 'days')
			.format('YYYY-MM-DD');

		registros.forEach(registro => {
			if (registro.data === moment().format('YYYY-MM-DD')) {
				totalHorasDia = totalHorasDia + parseInt(registro.duracao.slice(0, 2));
				totalMinutosDia =
					totalMinutosDia + parseInt(registro.duracao.slice(3, 5));
			}
			if (
				moment(registro.data).isBetween(dataInicio, dataFim, undefined, '[]')
			) {
				totalHorasSemana =
					totalHorasSemana + parseInt(registro.duracao.slice(0, 2));
				totalMinutosSemana =
					totalMinutosSemana + parseInt(registro.duracao.slice(3, 5));
			}
			if (
				moment(registro.data).isBetween(
					startOfMonth,
					endOfMonth,
					undefined,
					'[]',
				)
			) {
				totalHorasMes = totalHorasMes + parseInt(registro.duracao.slice(0, 2));
				totalMinutosMes =
					totalMinutosMes + parseInt(registro.duracao.slice(3, 5));
			}
		});
		const totalDia = `${totalHorasDia +
			parseInt((totalMinutosDia / 60).toString())}:${
			totalMinutosDia % 60 < 10
				? `0${totalMinutosDia % 60}`
				: `${totalMinutosDia % 60}`
		}`;
		const totalSemanal = `${totalHorasSemana +
			parseInt((totalMinutosSemana / 60).toString())}:${
			totalMinutosSemana % 60 < 10
				? `0${totalMinutosSemana % 60}`
				: `${totalMinutosSemana % 60}`
		}`;
		const totalPeriodo = `${totalHorasMes +
			parseInt((totalMinutosMes / 60).toString())}:${
			totalMinutosMes % 60 < 10
				? `0${totalMinutosMes % 60}`
				: `${totalMinutosMes % 60}`
		}`;

		setTotalHoje(totalDia);
		setTotalSemana(totalSemanal);
		setTotalMensal(totalPeriodo);
	};
	const handlePostPatchHora = useCallback(
		(saveAndContinue: boolean) => {
			if (autoRefresh) {
				setLoadingHome(false);
			}
			if (saveAndContinue === true) {
				setModalHorasShow(true);
			} else {
				setModalHorasShow(false);
			}
			setLoadingHome(true);
		},
		[setModalHorasShow, setLoadingHome],
	);


	return (
		<>
			{modalHorasShow ? (
				<ModalHoras
					modalHorasShow={setModalHorasShow}
					modalIsShowing={modalHorasShow}
					editingHoras={false}
					handlePostPatchHora={handlePostPatchHora}
				/>
			) : null}
			<StopwatchTitle />
			{confirmModal.isOpen && (
				<GenericConfirmModal
					buttons={confirmModal.buttons}
					title= {confirmModal.title}
					text={confirmModal.text}
				/>
			)}

			{usuarioAccess !== 'e' && (
				<MainContent className="react-tour__hora-atalho">
					<button
						id="atualiza-horas-atalho-btn"
						onClick={atualizaHorasNoBtnAtalho}
						style={{ display: 'none' }}
					/>

					<StopwatchHeader />
					<TodayContainer title={t('Total de horas do dia').toString()}>
						<CounterContent>
							<TodayContent>
								<div>
									<HorasText>
										{t('Hoje')}:
										<br />
										<b className="text-horas-externo">
											{totalHoje} {t('Horas')}
										</b>
									</HorasText>
								</div>
							</TodayContent>

							<Separator />

							<div className="week-content">
								<div className="week-inside">
									<HorasText>
										{t('Semana')}:{' '}
										<b className="text-horas-externo">
											{totalSemana} {t('Horas')}
										</b>
									</HorasText>
								</div>
								<WeekContent>
									<HorasText>
										{t('Mês')}:{' '}
										<b className="text-horas-externo">
											{totalMensal} {t('Horas')}
										</b>
									</HorasText>
								</WeekContent>
							</div>
							<IconContainer
								to={{
									pathname: `/registros/horas`,
								}}
								className="projetos-link-tarefa"
							>
								<FaRegCalendarAlt color="black" />
							</IconContainer>
						</CounterContent>
					</TodayContainer>
				</MainContent>
			)}
			<MainContainerWrapper className="col main-container">
				<div className="container-fluid p-0">
					{loadingHome && <>{children}</>}
				</div>
			</MainContainerWrapper>
		</>
	);
}
