import { t } from 'i18next';
import React from 'react';
import {
	TableRow,
	TableTh,
	TableThEmpty,
	TableThEntrega,
	TableThHoras,
} from './styled';

interface THeadProps {
	responsavelWidthContainer: React.MutableRefObject<any>;
	accessCode: string;
}

const Thead = ({ responsavelWidthContainer, accessCode }: THeadProps) => {
	return (
		<thead>
			<TableRow role={'row'}>
				<TableThEmpty role={'columnheader'} width="7%"></TableThEmpty>
				<TableThEmpty role={'columnheader'} width="4%"></TableThEmpty>
				<TableThEmpty role={'columnheader'} width="7%">
					{t('ID')}
				</TableThEmpty>
				<TableTh role={'columnheader'} width="30%">
					{t('Nome da tarefa')}
				</TableTh>
				<TableTh
					role={'columnheader'}
					display="none"
					ref={responsavelWidthContainer}
					width="17%"
				>
					{t('Responsável(is)')}
				</TableTh>
				<TableThEntrega role={'columnheader'} width="10%">
					{t('Entrega')}
				</TableThEntrega>
				<TableThHoras role={'columnheader'} width="10%">
					{t('Horas gastas')}
				</TableThHoras>
				{accessCode !== 'e' && (
					<TableThHoras width="10%">{t('Ações')}</TableThHoras>
				)}
			</TableRow>
		</thead>
	)
};

export default Thead;
