import moment from 'moment';
import React, { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useStopwatch as useContextStopwatch } from '../../../contexts/Stopwatch';
import { StopwatchContainer } from './styled';

export interface StopwatchProps {
	autoStart?: boolean;
	addSeconds?: number;
}
export default function Stopwatch({
	autoStart,
	addSeconds = 0,
}: StopwatchProps) {
	const stopwatchOffset = new Date();
	stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + addSeconds);
	const { days, seconds, minutes, hours, isRunning } = useStopwatch({
		autoStart,
		offsetTimestamp: stopwatchOffset,
	});
	const {
		setTitle,
		currentStopwatch,
		setConfirmModal,
		handleStopwatch,
	} = useContextStopwatch();

	const closeConfirmModal = () => {
		setConfirmModal(prev => ({ ...prev, isOpen: false }));
	};

	const formatHour = (days: number, hours: number) => {
		const finalHour = (days * 24) + hours;
		return formatTime(finalHour);  
	}

	const onStopModalNoExtra = () => {
		if (currentStopwatch !== '') {
			const { projeto, projeto_nome, tarefa, tarefa_nome } = currentStopwatch;
			handleStopwatch({
				projectId: projeto,
				projectName: projeto_nome,
				taskId: tarefa,
				taskName: tarefa_nome,
				horas_extras: false,
			});
		}
	};

	const onStopModalExtra = () => {
		if (currentStopwatch !== '') {
			const { projeto, projeto_nome, tarefa, tarefa_nome } = currentStopwatch;
			handleStopwatch({
				projectId: projeto,
				projectName: projeto_nome,
				taskId: tarefa,
				taskName: tarefa_nome,
				horas_extras: true,
			});
		}
	};

	const handleOvertime = () => {
		setConfirmModal({
			buttons: [
				{ title: 'Continuar', onClick: closeConfirmModal },
				{
					title: 'Completar com carga horária diária',
					onClick: onStopModalNoExtra,
				},
				{ title: 'Encerrar com tempo atual', onClick: onStopModalExtra },
			],
			isOpen: true,
			title: 'Você ainda está aí?',
			text:
				'Percebemos que o cronômetro já está rodando a um tempinho, e queríamos confirmar que você não esqueceu dele.',
		});
	};

	useEffect(() => {
		if (
			hours > 12 &&
			currentStopwatch !== '' &&
			currentStopwatch.data === moment().format('YYYY-MM-DD')
		) {
			handleOvertime();
		}
	}, []);

	useEffect(() => {
		if (isRunning) {
			setTitle(
				`${formatHour(days, hours)}:${formatTime(minutes)}:${formatTime(seconds)}`,
			);
			if (hours === 12 && minutes === 0 && seconds === 0) {
				handleOvertime();
			}
		} else {
			setTitle('');
		}
	}, [hours, minutes, seconds]);
	const formatTime = (time: number) => {
		return String(time).padStart(2, '0');
	};

	return (
		<StopwatchContainer>
			{formatHour(days, hours)}:{formatTime(minutes)}:{formatTime(seconds)}
		</StopwatchContainer>
	);
}
