import styled from "styled-components";

export const PageContentScroll = styled.div`
    height: 80vh;
    overflow: auto;
    @media (max-width: 1400px) {
        height: 75vh;
        overflow: auto;
    }
    @media (max-width: 1000px) {
        height: 75vh;
        overflow: auto;
    }
    @media (max-width: 768px) {
        height: 75vh;
        overflow: auto;
    }
`

interface PageContentProps {
    expandTable: boolean;
}

export const PageContent = styled.div<PageContentProps>`
    margin: 40px 40px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    ${({expandTable}) => expandTable && 'height: calc(100% - 80px)'}
    @media print {
        background-color: red;
        margin: 0;
    }
`