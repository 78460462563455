import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ActionsMenu } from "../../../../../../components/common/ActionsMenu";
import { Task } from "../../../../../../models/Task";
import { LocationData } from "../types";
import { ActionsMenuContainer, Header, InfoBackIcon, InfoBtnBack, TaskId, TaskTitle, TaskTitleContainer } from './styled';
import { t } from "i18next";

interface InfoTarefaHeaderProps {
    task: Task;
    urlBack: string;
    deleteTask: (id: string | number) => void;
    onClickEdit: () => void;
    closeTask: (id: string | number) => void;
}

export default function InfoTarefaHeader({task, urlBack, closeTask, deleteTask, onClickEdit}: InfoTarefaHeaderProps) {
    const data = useLocation<LocationData>();
    const handleDeleteTask = useCallback(() => {
		deleteTask(task?.id);
	}, [task, deleteTask]);

    const handleEditTask = useCallback(() => {
		onClickEdit();
	}, [onClickEdit]);

    const handleFinish = useCallback(() => {
		closeTask(task?.id);
	}, [task, closeTask])
    return (
        <Header>
					<InfoBtnBack
						to={{
							pathname: urlBack,
							state: data?.state?.fromKanban
								? {}
								: { task: task },
						}}
					>
						<InfoBackIcon icon={faArrowLeft} className="voltar-icon" />
						{t('Voltar')}
					</InfoBtnBack>
					<ActionsMenuContainer
					>
						<TaskTitleContainer>
							<TaskId>{task?.id}</TaskId>
							<TaskTitle>{task?.nome}</TaskTitle>
						</TaskTitleContainer>
						<ActionsMenu
							copyOption={true}
							copyText={window.location.href}
							handleDelete={handleDeleteTask}
							handleEdit={handleEditTask}
							handleFinish={handleFinish}
							hasSeparator={false}
						/>
					</ActionsMenuContainer>
				</Header>
    )
}