import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import ProjetoService from '../../../../../services/providers/project';
import {
	validateCanSeeProjetoCard,
	validateIsNotMember,
	verifyCanEditProject,
} from '../../../../../utils/funcoes/validarPerfil';

import ProfileImg from '../../../../../assets/images/profile.png';
import { ReactComponent as KanbanIcon } from '../../../../../assets/svgs/kanban.svg';

import { faBookmark, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from 'react-router-dom';
import { useFilters } from '../../../../../contexts/Filter/Task/Filter';
import { useProject } from '../../../../../contexts/Project';

import {
	ImageOver
} from '../ProjectTable/TableRow/styled';
import './style.css';
import { useTranslation } from 'react-i18next';

export const ProjetoCard = ({
	reloadID,
	acessCode,
	usuario,
	data,
	handleFavoritos,
	card_is_favorite,
	setEditingProjeto,
	setProjetoEditado,
	bgHeader,
	id,
}) => {
	const {t} = useTranslation();
	const {
		loadingFavorites,
		loadingPostPatch,
		setModalProjectVisible,
		setProjects,
		setFavoritesProjects,
		myProjects,
		projectsDetails,
		setProjectsDetails
	} = useProject();
	const { setSelectedProject } = useFilters();
	const disabledClick = () => (whenLoad() ? 'none' : 'auto');
	const timeWidthContainer = useRef();
	const { widthContainer } = useResize(timeWidthContainer);
	const changeOpacity = () => (whenLoad() ? '0' : '1');
	const history = useHistory();

	const whenLoad = () => loadingFavorites || loadingPostPatch;

	// DETALHES DO PROJETO
	const projetoService = new ProjetoService();
	const [loading, setIsLoading] = useState(true);

	useEffect(() => {
        if (
            data.admins == undefined ||
            data.time == undefined ||
            data.tags == undefined ||
            data.is_favorite == undefined ||
            data.is_member == undefined
        ) {
            const tempDetails = projectsDetails?.[data.id];

            if(tempDetails) {
                data.is_member = tempDetails.is_member;
                data.admins = tempDetails.admins;
                data.time = tempDetails.time;
                data.tags = tempDetails.tags;
                data.is_favorite = tempDetails.is_favorite;
				setIsLoading(false);
            } else {
                getProjetodetails();
            }
        } else {
            setIsLoading(false);
        }
    }, [data]);

	useEffect(() => {
		if (reloadID === data.id) {
			getProjetodetails();
		}
	}, [loadingPostPatch]);

	const handleSetProject = useCallback(
		() =>
			setSelectedProject({
				value: data.id,
				label: data.nome,
			}),
		[data],
	);

	const getProjetodetails = async () => {
		setIsLoading(true);
		projetoService.getDetalheProjeto(
			data.id,
			response => {
				if (response !== null) {
					data.is_member = response.is_member;
					data.admins = response.admins;
					data.time = response.time;
					data.tags = response.tags;
					data.is_favorite = response.is_favorite;
					card_is_favorite
						? setProjects(prevData => ({ ...prevData, [id]: data }))
						: setFavoritesProjects(prevData => ({
								...prevData,
								[id]: { ...prevData[id], projeto: data },
						  }));
					setProjectsDetails(prev => ({...prev, [response.id]: response}))
						
				}
				setIsLoading(false);
			},
			e => {
				console.log(e);
			},
		);
	};

	const disabledClickAndHidden = () => {
		return {
			pointerEvents: disabledClick(),
			opacity: changeOpacity(),
		};
	};

	const showHiddenCard = () =>
		validateCanSeeProjetoCard(
			acessCode,
			data.is_member,
			data.is_favorite,
			card_is_favorite,
		)
			? 'none'
			: 'initial';

	const headerCard = () => (
		<div className={`header-card-container`}>
			<div
				className={`projetos-header-card`}
				style={{
					pointerEvents: disabledClick(),
					background:
						!myProjects && validateIsNotMember(data.is_member) && '#cecece',
					cursor: verifyCanEditProject(usuario, data.is_member, data.admins)
						? 'pointer'
						: 'default',
				}}
				onClick={() => {
					if (verifyCanEditProject(usuario, data.is_member, data.admins)) {
						history.push(`/registros/projetos/${data.id}`, { data });
					}
				}}
			>
				<div
					style={{
						opacity: changeOpacity,
					}}
				>
					<div className={`projetos-header-card-container`}>
						<div className={`projetos-header-card-title`} title={data.nome}>
							{data.nome}
						</div>
					</div>
					<div className={`projetos-empresa-nome`}>{data.cliente.nome}</div>
				</div>
			</div>
		</div>
	);
	// Se o serialzier for antigo, então tarefas é um array senão um int
	const bodyCard = () => (
		<div className={`projetos-body-tarefa`} style={disabledClickAndHidden()}>
			<div className={`projetos-tarefa`}>
				<div>
					<FontAwesomeIcon
						icon={faBookmark}
						className="projetos-tarefa-icone"
					/>
				</div>
				<Link
					to={{
						pathname: `/registros/tarefas`,
						state: { projeto: { value: data.id, label: data.nome } },
					}}
					className={`projetos-link-tarefa`}
				>
					<span>
						{data.tarefas !== null
							? typeof data.tarefas === typeof []
								? data.tarefas.length
								: data.tarefas
							: '0'}

						<p className={`projetos-tarefa-text`}>{t("tarefas")}</p>
					</span>
				</Link>
			</div>
			<div className={'projetos-header-icons'}>
				{/* {validateCanAccessAdvancedConfigProjeto(usuario, admins) && ( */}
				{(verifyCanEditProject(usuario, data.is_member, data.admins) ||
					data.is_member) && (
					<Link
						to={{
							pathname: `/registros/kanban`,
							state: { data },
						}}
						onClick={handleSetProject}
						className={'projetos-kanban-icon'}
					>
						<KanbanIcon title="Kanban" />
					</Link>
				)}

				{verifyCanEditProject(usuario, data.is_member, data.admins) && (
					<div
						onClick={() => {
							if (!loading) {
								setEditingProjeto(true);
								setProjetoEditado(data);
								setModalProjectVisible(true);
							}
						}}
						style={{ cursor: loading ? 'progress' : 'pointer' }}
					>
						{/* <EditIcon title='Editar' /> */}
						<FontAwesomeIcon
							icon={faEdit}
							className="projetos-tarefa-icone"
							title={t("Editar")}
						/>
					</div>
				)}
			</div>
		</div>
	);
	const imageGroup = () => {
		const overTeam = data.time && data.time.length - 6;
		const slicedTeam =
			data.time && data.time.slice(0, overTeam > 0 ? overTeam * -1 : undefined);
		const restOfTheTeam =
			data.time && data.time.slice(data.time.length - overTeam);

		return (
			<div
				className={`profile-img-container`}
				style={{
					opacity: changeOpacity(),
				}}
				ref={timeWidthContainer}
			>
				{loading || !data.time && !slicedTeam ? (
					<>
							<img src={ProfileImg} alt="" title={'...'} />
							<img src={ProfileImg} alt="" title={'...'} />
							<img src={ProfileImg} alt="" title={'...'} />
							<ImageOver title={'+1'}>
								+1
							</ImageOver>
						</>
				) : (
					<>
						{data?.time !== null ? (
							<>
								{slicedTeam.map((item, index) => {
									return (
										<>
											<img
												style={{
													marginRight: item.length === 1 ? '' : '-4px',
												}}
												src={
													item.foto_perfil !== null
														? item.foto_perfil
														: ProfileImg
												}
												key={item.id}
												alt=""
												title={item.nome}
											/>
										</>
									);
								})}
								{overTeam > 0 && (
									<span
										title={restOfTheTeam.map(item => item.nome)}
										className={`common-profile-img-over`}
									>
										+{overTeam}
									</span>
								)}
							</>
						) : (
							<span style={{ height: '20px' }}>{t("Sem time")}</span>
						)}
					</>
				)}
			</div>
		);
	};

	return (
		<>
			<div
				style={{
					pointerEvents: disabledClick(),
					display: showHiddenCard(),
				}}
				className={`projetos-card`}
			>
				{headerCard()}
				<div className='projetos-disabled-container '>
					{!data.item_ativo && (
						<div className={`card-projetos-disabled`}>
							{t("Desativado")}
						</div>
					)}
				</div>
				{bodyCard()}
				{imageGroup()}
			</div>
		</>
	);
};

const useResize = myRef => {
	const [widthContainer, setWidthContainer] = useState(0);
	useEffect(() => {
		setWidthContainer(myRef.current.offsetWidth);
	}, []);
	useEffect(() => {
		const handleResize = () => {
			setWidthContainer(myRef.current.offsetWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [myRef]);

	return { widthContainer };
};
