import { t } from 'i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { History } from '../../../../../../models/History';
import { Task } from '../../../../../../models/Task';
import TarefaService from '../../../../../../services/providers/task';
import { TooltipHistorico } from './TooltipHistorico';
import HistoricoForm from './HistoricoForm';
import {
	InfoHistoricoBody,
	InfoHistoricoContainer,
	InfoHistoricoTitle,
} from './styled';
import { useStopwatch } from '../../../../../../contexts/Stopwatch';

interface InfoTarefaHistoricoProps {
	task: Task;
}

export default function InfoTarefaHistorico({
	task,
}: InfoTarefaHistoricoProps) {
	const [dataHistory, setDataHistory] = useState<History[]>([]);
	const {setHandleAfterPostPatch, setPreSelectedRecord, handleToggleModal} = useStopwatch();

	const getTarefaHistorico = useCallback(() => {
		const tarefaService = new TarefaService();
		tarefaService.getTarefaHistorico(
			task?.id,
			(response: History[]) => {
				if (response) {
					setDataHistory(response);
				}
			},
			() => {},
		);
	}, [TarefaService, task, setDataHistory])

	
	useEffect(() => {
		getTarefaHistorico();
	}, [task])


    const deleteTaskComentario = (id: number) => {
		const tarefaServiceHistorico = new TarefaService();
		tarefaServiceHistorico.deleteTarefaComentario(
			id,
			response => {
				if (response) {
					getTarefaHistorico();
					toast.success(`${t('Registro de histórico removido com sucesso')}!`);
				}
			},
			error => {
				toast.error(error);
			},
		);
	};
	const _postTarefaComentario = (params: {
		descricao: string;
		tarefa: number | string;
	}) => {
		const tarefaService = new TarefaService();
		tarefaService.postTarefaComentario(
			params,
			response => {
				if (response) {
					toast.success(`${t('Tarefa salva com sucesso')}!`);
					getTarefaHistorico();
				}
			},
			error => {
				toast.error(error);
			},
		);
	};

	const handleSubmitComentario = useCallback(
		(values: { descricao: string }) => {
			const params = {
				descricao: values.descricao,
				tarefa: task.id,
			};
			_postTarefaComentario(params);
			getTarefaHistorico();
			values['descricao'] = '';
		},
		[task, _postTarefaComentario, getTarefaHistorico],
	);

	const handleDeletar = useCallback(
		(id: number) => {
			deleteTaskComentario(id);
		},
		[deleteTaskComentario],
	);

	const _patchTarefaComentario = (
		params: { descricao: string },
		id: number,
	) => {
		const tarefaService = new TarefaService();
		tarefaService.patchTarefaComentario(
			params,
			id,
			response => {
				if (response) {
					toast.success(`${t('Tarefa salva com sucesso')}!`);
					getTarefaHistorico();
				}
			},
			error => {
				toast.error(error);
			},
		);
	};

	const handlePostPatchHora = useCallback(
		() => {
			getTarefaHistorico();
		},
		[getTarefaHistorico],
	);

	const handleEditarComentario = useCallback(
		(values: { descricao: string }, id: number) => {
			_patchTarefaComentario(values, id);
			getTarefaHistorico();
		},
		[_patchTarefaComentario, getTarefaHistorico],
	);

	const formatDate = (date: string) => {
		const formattedDate = moment(date).format('YYYY-MM-DD');
		return formattedDate;
	};

	const handleEdit = useCallback(
		(data: History) => {
			setHandleAfterPostPatch(() => handlePostPatchHora);
			setPreSelectedRecord({projeto: task.projeto, projeto_nome: task.projeto_nome || '', tarefa: data.tarefa, tarefa_nome: task.nome, data: formatDate(data.data), descricao: data.descricao, duracao: data.duracao, hourId: data.id_objeto, tipo: 'trab'})
			handleToggleModal(true);
		},
		[setPreSelectedRecord, handleToggleModal, task, setHandleAfterPostPatch, handlePostPatchHora],
	);

   

	return (
		<InfoHistoricoContainer>
			<InfoHistoricoTitle>{t('Histórico')}:</InfoHistoricoTitle>
			<InfoHistoricoBody>
				<HistoricoForm
					handleSubmitComentario={handleSubmitComentario}
					task={task}
					handlePostPatchHora={handlePostPatchHora}
				/>
				{dataHistory.map(data => {
					return (
						<TooltipHistorico
							data={data}
							key={data.id}
							handleDeletar={handleDeletar}
							handleEditarComentario={handleEditarComentario}
							_getTarefaHistorico={getTarefaHistorico}
							handleEdit={handleEdit}
						/>
					);
				})}
			</InfoHistoricoBody>
		</InfoHistoricoContainer>
	);
}
